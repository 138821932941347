import api from '../services/api';
import { handleRequestError } from '../services/security';

export async function getGenero( params ){

  let responseFromApi;

  try {
    const response = await api.get('/genero', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    console.log(error);
    responseFromApi = {valid: false, status: error.response.status, message: error.response.data, data: []};
  }

  return responseFromApi;

}

export async function postGenero( params ){
    
  let responseFromApi;

  try {
    const response = await api.post('/genero', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putGenero( params ){
    
  let responseFromApi;

  try {
    const response = await api.put('/genero', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}


export async function deleteGenero( params ){
    
  let responseFromApi;

  try {
    const response = await api.delete('/genero', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}