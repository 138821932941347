import React, { useState, useEffect } from 'react';
import { Paper, CardHeader, Grid, TextField, Button, Typography, FormControlLabel, Checkbox, LinearProgress } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import { AtomicModal, Confirmation } from '../../../../../components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import moment from 'moment';

function EditarSolicitarFerias({classes, display, onSalvar, onCancelar, onFechar, data, periodo, histFerias}){

  const [agendado, setAgendado] = useState(0);
  const [loading, setLoading] = useState(false);
  
  const [ferias, setFerias] = useState({
    Data_Inicio: null,
    Data_Fim: null,
    Data_Retorno: null,
    Abono_Pecuniario: 0,
    Erro_Data_Inicio: null,
    Erro_Data_Fim: null,
    Erro_Submeter: null
  });

  const [confirmation, setConfirmation] = useState({
    open: false,
    confirm: false,
    title: '',
    text: '',
    button: null,
    onConfirm: () => {}
  });

  useEffect(() => {
    setFerias({
      ...ferias,
      Data_Inicio: data.Data_Inicio,
      Data_Fim: data.Data_Fim,
      Data_Retorno: data.Data_Retorno,
      Abono_Pecuniario: data.Abono_Pecuniario
    });
    setAgendado(data.Duracao);
  }, [data]);

  function closeEditarFerias(){
    onFechar();
  }

  function validarSalvarFerias(){
    setLoading(true);
    let valid = true;

    if(!ferias.Data_Inicio){
      setFerias({...ferias, Erro_Data_Inicio: 'Campo obrigatório'});
      valid = false;
      setLoading(false);
    }

    if(valid && !ferias.Data_Fim){
      setFerias({...ferias, Erro_Data_Fim: 'Campo obrigatório'});
      valid = false;
      setLoading(false);
    }

    if(valid && periodo.Mais14 === false){
      let periodos = periodo.Periodos_Completados + periodo.Periodos_Agendados

      if(periodos === 3 && agendado < 14){
        setFerias({...ferias, Erro_Submeter: 'Você precisa usar todo o saldo de férias restante'});
        setLoading(false);
        valid = false;
      }

      if(periodos === 2){
        if(agendado < 14){
          if(periodo.Saldo - (agendado - data.Duracao) - periodo.Pecuniario - ferias.Abono_Pecuniario < 14){
            setFerias({...ferias, Erro_Submeter: 'Esse período precisa ter pelo menos 14 dias'});
            setLoading(false);
            valid = false;
          }
        }
      }

      if(periodos === 1 && ferias.Abono_Pecuniario === 10){
        if(agendado < 14){
          if(periodo.Saldo - (agendado - data.Duracao) - periodo.Pecuniario - ferias.Abono_Pecuniario < 14){
            setFerias({...ferias, Erro_Submeter: 'Esse período precisa ter pelo menos 14 dias ou manter um saldo de pelo menos 14 dias'});
            setLoading(false);
            valid = false;
          }
        }
      }

    }

    if(valid && (periodo.Saldo - (agendado - data.Duracao) < 5 && periodo.Saldo - (agendado - data.Duracao) !== 0)){
      setFerias({...ferias, Erro_Submeter: 'Você não pode manter um saldo menor do que 5 dias'});
      setLoading(false);
      valid = false;
    }

    if(valid && (periodo.Pecuniario === 0 && periodo.Saldo - (agendado - data.Duracao) < 10)){
      setConfirmation({open: true, confirm: false, button: null, title: 'Abono Pecuniário', text: 'Você está mantendo um saldo residual de menos de 10 dias. Dessa forma não será possível vender 10 dias de férias. Deseja continuar?'});
      valid = false;
    }

    if(valid){

      const histFer = histFerias.filter(fer => fer.Status !== 'Cancelado' && fer.Id_Ferias !== data.Id_Ferias);

      for(let i = 0; i < histFer.length; i++){
        if(moment(ferias.Data_Inicio, 'DD-MM-YYYY').isBetween(moment(histFer[i]['Data_Inicio'], 'DD-MM-YYYY'), moment(histFer[i]['Data_Fim'], 'DD-MM-YYYY'))){
          setConfirmation({open: true, confirm: false, button: 'OK', title: 'Conflito', text: 'As suas férias iniciam dentro do periodo de outras férias já solicitadas. Por favor, ajuste o período desejado.'});
          valid = false;
          setLoading(false);
          break;
        }

        if(moment(ferias.Data_Fim, 'DD-MM-YYYY').isBetween(moment(histFer[i]['Data_Inicio'], 'DD-MM-YYYY'), moment(histFer[i]['Data_Fim'], 'DD-MM-YYYY'))){
          setConfirmation({open: true, confirm: false, button: 'OK', title: 'Conflito', text: 'As suas férias terminam dentro do periodo de outras férias já solicitadas. Por favor, ajuste o período desejado.'});
          valid = false;
          setLoading(false);
          break;
        }
      }
    }

    if(valid){
      salvarFerias();
    }
    
  }


  function salvarFerias(){
    setConfirmation({
      open: false,
      confirm: false,
      title: '',
      text: '',
      onConfirm: () => {}
    });

    let dadosFerias = {
      Periodo_Aquisitivo_Inicio: moment(periodo.Periodo_Aquisitivo.Inicio, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      Periodo_Aquisitivo_Fim: moment(periodo.Periodo_Aquisitivo.Fim, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      Data_Inicio:  moment(ferias.Data_Inicio, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      Data_Fim: moment(ferias.Data_Fim, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      Data_Retorno: moment(ferias.Data_Retorno, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      Duracao: moment(ferias.Data_Fim, 'DD-MM-YYYY').diff(moment(ferias.Data_Inicio, 'DD-MM-YYYY'), 'days') + 1,
      Abono_Pecuniario: ferias.Abono_Pecuniario
    }

    setAgendado(0);

    onSalvar(dadosFerias);

    setFerias({
      Data_Inicio: null,
      Data_Fim: null,
      Data_Retorno: null,
      Abono_Pecuniario: 0,
      Erro_Data_Inicio: null,
      Erro_Data_Fim: null,
      Erro_Submeter: null
    });

    setLoading(false);
  }


  function handleDataInicio(value){

    if(value){
      if(['Saturday', 'Sunday'].includes(value.format('dddd'))){
        setFerias({...ferias, Erro_Data_Inicio: 'As férias não podem iniciar em final de semana'});
        return;
      }
    }
    
    setFerias({...ferias, Data_Inicio: value, Data_Fim: null, Data_Retorno: null, Erro_Data_Inicio: null});
    setAgendado(0);
  }

  function handleDataFim(value){

    let dataRetorno = null;

    if(value){
      dataRetorno = value.clone();

      for(let i = 1; i < 3; i++){
        dataRetorno.add(1, 'd');
        if(!['Saturday', 'Sunday'].includes(dataRetorno.format('dddd'))){
          break;
        }
      }
    }
      
    setFerias({...ferias, Data_Fim: value, Data_Retorno: dataRetorno, Erro_Data_Fim: null, Erro_Submeter: null});
    setAgendado(value.diff(moment(ferias.Data_Inicio, 'DD-MM-YYYY'), 'days') + 1);

  }

  function minDateDataInicio(){
    if(moment().diff(moment(data.Periodo_Aquisitivo_Fim, 'DD-MM-YYYY').add(1, 'd')) > 0){
      return moment();
    }else{
      return moment().diff(moment(data.Periodo_Aquisitivo_Fim, 'DD-MM-YYYY').add(1, 'd'));
    }
  }

  function onCancelarFerias(){
    setConfirmation({open: true, confirm: false, title: 'Cancelar Férias', text: 'Você tem certeza que deseja cancelar essa solicitação de férias?', onConfirm: cancelarFerias});
  }

  async function cancelarFerias(){
    setConfirmation({
      open: false,
      confirm: false,
      title: '',
      text: '',
      onConfirm: () => {}
    });
    setLoading(true);
    await onCancelar();
    loading(false);
  }

  function closeConfirmation(){
    setConfirmation({...confirmation, open: false});
    setLoading(false);
  }

  return (
    <AtomicModal open={display} width={'800px'} handleClose={closeEditarFerias}>
      <Paper className={classes.modal}>
        {loading && <LinearProgress />}
        <CardHeader title={`Solicitação de Férias #${data.Id_Ferias}`} style={{textAlign: 'center'}}/>
        
        <Grid container spacing={1} className={classes.modalFields}>

          <Grid item md={3} xs={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                margin="dense"
                id="periodo-aquisitivo-inicio"
                name="Periodo_Aquisitivo_Inicio"
                label="Periodo Aquisitivo - Início"
                inputVariant="outlined"
                format="DD/MM/YYYY"
                value={moment(data.Periodo_Aquisitivo_Inicio, 'DD-MM-YYYY')}
                disabled={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item md={3} xs={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                margin="dense"
                id="periodo-aquisitivo-fim"
                name="Periodo_Aquisitivo_Fim"
                label="Periodo Aquisitivo - Fim"
                inputVariant="outlined"
                format="DD/MM/YYYY"
                value={moment(data.Periodo_Aquisitivo_Fim, 'DD-MM-YYYY')}
                disabled={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          { data.Status === 'Revisão' ? 
          <Grid item md={3} xs={3}>
            <TextField
              fullWidth
              label="Saldo Restante"
              margin="dense"
              name="Saldo"
              disabled={true}
              value={`${periodo.Saldo - (agendado - data.Duracao) - (ferias.Abono_Pecuniario - data.Abono_Pecuniario)} dias`}
              variant="outlined"
            />
          </Grid>
          : null } 

          <Grid item md={3} xs={3}>
            <TextField
              fullWidth
              label="Status"
              margin="dense"
              name="Status"
              disabled={true}
              value={data.Status}
              variant="outlined"
            />
          </Grid>
          
          { data.Status !== 'Revisão' ?
          <Grid item md={3} xs={3}>
            <div></div>
          </Grid>
          : null}

          <Grid item md={3} xs={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                allowKeyboardControl={false}
                margin="dense"
                id="data-inicio"
                name="Data_Inicio"
                label="Data Início"
                inputVariant="outlined"
                format="DD/MM/YYYY"
                value={ferias.Data_Inicio === null ? null : moment(ferias.Data_Inicio, 'DD-MM-YYYY')}
                disabled={data.Status === 'Revisão' && !loading ? false : true}
                /*
                minDate={data.Status !== 'Revisão' ? null : minDateDataInicio}
                maxDate={
                  data.Status !== 'Revisão' ? null :
                  moment().add(1, 'y')
                }
                */
                onChange={value => handleDataInicio(value)}
              />
              <div style={{fontFamily: 'Arial', fontSize: 11, color: 'red'}}>{ferias.Erro_Data_Inicio}</div>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item md={3} xs={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                allowKeyboardControl={false}
                margin="dense"
                id="data-fim"
                name="Data_Fim"
                label="Data Fim"
                inputVariant="outlined"
                format="DD/MM/YYYY"
                value={ferias.Data_Fim === null ? null : moment(ferias.Data_Fim, 'DD-MM-YYYY')}
                disabled={(data.Status === 'Revisão' && !loading && ferias.Data_Inicio !== null) ? false : true}
                /*
                minDate={
                  data.Status !== 'Revisão' ? null :
                  ferias.Data_Inicio !== null ? moment(ferias.Data_Inicio).add(4, 'd') : null
                }
                maxDate={
                  data.Status !== 'Revisão' ? null :
                  ferias.Data_Inicio !== null ? moment(ferias.Data_Inicio).add(periodo.Saldo - 1, 'd') : null
                }
                */
                onChange={value => handleDataFim(value)}
              />
              <div style={{fontFamily: 'Arial', fontSize: 11, color: 'red'}}>{ferias.Erro_Data_Fim}</div>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item md={3} xs={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                margin="dense"
                id="data-retorno"
                name="Data_Retorno"
                label="Data Retorno"
                inputVariant="outlined"
                format="DD/MM/YYYY"
                value={ferias.Data_Retorno === null ? null : moment(ferias.Data_Retorno, 'DD-MM-YYYY')}
                disabled={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item md={3} xs={3}>
            <TextField
              fullWidth
              label="Duração em dias"
              margin="dense"
              name="Duracao"
              disabled={true}
              value={`${agendado} dias`}
              variant="outlined"
            />
          </Grid>

          <Grid item md={12} xs={12}>
            <FormControlLabel
              disabled={data.Status !== 'Revisão' || data.Pecuniario > 0 || data.Saldo < 10 ? true : false}
              control={
                <Checkbox
                  checked={ferias.Abono_Pecuniario}
                  onChange={(event, newValue) => setFerias({...ferias, Abono_Pecuniario: newValue ? 10 : 0})}
                  name="Abono_Pecuniario"
                  color="primary"
                />
              }
              label={`Desejo "vender" 10 dias de férias a título de abono pecuniário.`}
            />
          </Grid>
        </Grid>
        
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Button variant="contained" onClick={closeEditarFerias} disabled={loading}>
            FECHAR
          </Button>

          {data.Status === 'Revisão' &&
            <Button
              color="primary"
              variant="contained"
              onClick={validarSalvarFerias}
              disabled={loading}
              className={classes.modalSaveButton}
            >
              SALVAR
            </Button>
          }

          {['Revisão', 'Solicitado'].includes(data.Status) &&
            <Button
              color="secondary"
              variant="contained"
              onClick={onCancelarFerias}
              disabled={loading}
              style={{marginLeft: 10}}
            >
              CANCELAR
            </Button>
          }

          <div style={{marginLeft: '10px', fontFamily: 'Arial', fontSize: 14, color: 'red', width: '480px'}}>{ferias.Erro_Submeter}</div>

        </div>
      </Paper>

      <Confirmation
        title={confirmation.title}
        open={confirmation.open}
        setOpen={closeConfirmation}
        onConfirm={confirmation.onConfirm}
        button={confirmation.button}
      >
        <Typography variant="subtitle2" component="div">{confirmation.text}</Typography>
      </Confirmation>
    </AtomicModal>
  )
}

export default withStyles(styles)(EditarSolicitarFerias);