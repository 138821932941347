import React, { useState, useEffect } from 'react';
import { CircularProgress, Paper, CardHeader, Grid, TextField, Button, Tooltip, Typography, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { AtomicModal, UploadFile } from '../../../../components';
import { CST_ANO, CST_MES_NUMERO } from '../../../../constants';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { getFuncionarios } from '../../../../requests/funcionariosRequest';
import { listTipoHolerite } from '../../../../requests/tipoHoleriteRequest';
import * as yup from 'yup';
import { Formik, Form } from 'formik';

function NovoHolerite({classes, display, onSalvar, onFechar}){

  const initialValues = {
    Ano: CST_ANO[0].Ano,
    Mes: CST_MES_NUMERO[0].Nome,
    Id_Tipo_Holerite: 1,
    Id_Funcionario: ''
  };
  
  const [funcionarios, setFuncionarios] = useState([]);
  const [tipoHolerite, setTipoHolerite] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState([]);

  useEffect(() => { carregarFuncionarios() }, []);
  useEffect(() => { carregarTipoHolerite() }, []);

  async function carregarFuncionarios(){
    const response = await getFuncionarios({});

    if (response.valid) {
      setFuncionarios(response.data);
    }
  }

  async function carregarTipoHolerite(){
    const response = await listTipoHolerite({});

    if (response.valid) {
      setTipoHolerite(response.data);
    }
  }

  function closeNovoHolerite(handleReset){
    handleReset();
    setFile([]);
    onFechar();
  }

  async function salvarHolerite(holerite, resetForm){
    setLoading(true);
    await onSalvar(holerite, file[0]);
    resetForm(initialValues);
    setLoading(false);
  }

  function handleUpload(newFile) {
    const uploadedFile = {
      file: newFile,
      name: newFile[0].name,
    };

    setFile([...file, uploadedFile]);
  };
  
  const schema = yup.object().shape({
    Ano: yup.string().required('Campo Obrigatório'),
    Mes: yup.string().required('Campo Obrigatório'),
    Id_Tipo_Holerite: yup.string().required('Campo Obrigatório'),
    Id_Funcionario: yup.number().required('Campo Obrigatório').moreThan(0, 'Selecione um funcionário na lista')    
  })

  return (
    <Formik initialValues={initialValues} onSubmit={(values, { resetForm }) => salvarHolerite(values, resetForm)} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleReset,
        setFieldValue
      } = props
      return (
        <AtomicModal open={display} width={400} handleClose={() => closeNovoHolerite()}>
          <Form>
          <Paper className={classes.modal}>
            <CardHeader title="Novo Holerite" style={{textAlign: 'center'}}/>
              <Grid container spacing={1} className={classes.modalFields}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Ano"
                    margin="dense"
                    name="Ano"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.Ano}
                    variant="outlined"
                    disabled={loading}
                    helperText={errors.Ano && touched.Ano ? errors.Ano : ''}
                    error={errors.Ano && touched.Ano ? true : false}
                    onBlur={handleBlur}
                  >
                    {CST_ANO.map(option => (
                      <option key={option.Ano} value={option.Ano}>
                        {option.Ano}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Mês"
                    margin="dense"
                    name="Mes"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.Mes}
                    variant="outlined"
                    disabled={loading}
                    helperText={errors.Mes && touched.Mes ? errors.Mes : ''}
                    error={errors.Mes && touched.Mes ? true : false}
                    onBlur={handleBlur}
                  >
                    {CST_MES_NUMERO.map(option => (
                      <option key={option.Id} value={option.Nome}>
                        {option.Nome}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Tipo"
                    margin="dense"
                    name="Id_Tipo_Holerite"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.Id_Tipo_Holerite}
                    variant="outlined"
                    disabled={loading}
                    helperText={errors.Id_Tipo_Holerite && touched.Id_Tipo_Holerite ? errors.Id_Tipo_Holerite : ''}
                    error={errors.Id_Tipo_Holerite && touched.Id_Tipo_Holerite ? true : false}
                    onBlur={handleBlur}
                  >
                    {tipoHolerite.map(option => (
                      <option key={option.Id_Tipo_Holerite} value={option.Id_Tipo_Holerite}>
                        {option.Nome}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Autocomplete
                    options={funcionarios}
                    getOptionLabel={(option) => option.Nome}
                    renderInput={(params) => <TextField {...params} label="Funcionário" variant="outlined" />}
                    size="small"
                    style={{marginTop: 8}}
                    disabled={loading}
                    onChange={(event, newValue) => {
                      if(newValue === null){ 
                        setFieldValue('Id_Funcionario', '')
                      }else {
                        setFieldValue('Id_Funcionario', newValue.Id_Funcionario)
                      }
                    }}                      
                    defaultValue={{Id_Funcionario: '', Nome: ''}}
                  />
                  {errors.Id_Funcionario && touched.Id_Funcionario ? 
                  <div style={{fontFamily: 'Arial', fontSize: 11, color: 'red'}}>{errors.Id_Funcionario}</div>
                  : ''}
                </Grid>

                {file.length === 0 ?
                  <Grid item xs={12} sm={12}>
                    <UploadFile onUpload={handleUpload} accept={['application/pdf']}/>
                  </Grid>
                :
                  <Grid item xs={12} sm={12} direction="column">
                    <div className={classes.fileItemBlock}>
                      <Tooltip title="Remover">
                        <IconButton color="secondary" onClick={() => setFile([])} disabled={loading}>
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                      <Typography variant="body1">{file[0].name}</Typography>
                    </div>
                  </Grid>
                }
              </Grid>
              
              <Button variant="contained" onClick={() => closeNovoHolerite(handleReset)} >
                CANCELAR
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading || file.length === 0}
                className={classes.modalSaveButton}
              >
                SALVAR
                {loading ? 
                  <CircularProgress 
                  size={14}
                  color="secondary" />
                  : ''
                }
              </Button>
            </Paper>
            </Form>
          </AtomicModal>
        )
      }}
    </Formik>
  )
}

export default withStyles(styles)(NovoHolerite);