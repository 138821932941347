import React from 'react';
import { Route, useLocation, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';



const RouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;

  const location = useLocation();
  const token = localStorage.getItem('token')

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          {token !== null ?
            <Component {...matchProps} />
          :
            <Redirect to={{ pathname: "/login", state: { from: location } }} />
          }
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
