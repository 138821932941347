import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

const CustomSnackBar = props => {

    return (
        <Snackbar anchorOrigin={{horizontal: 'center', vertical: 'top'}} open={props.display} autoHideDuration={6000} onClose={props.onClose}>
            <Alert severity={props.severity}>
                {props.text}
            </Alert>
        </Snackbar>
    );

};

CustomSnackBar.propTypes = {
    display: PropTypes.bool,
    severity: PropTypes.string,
    text: PropTypes.string,
    onClose: PropTypes.func
};

export default CustomSnackBar;