import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { listArquivos, postArquivo, putArquivo, deleteArquivo } from '../../requests/arquivoRequest';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, 
         IconButton, Card, CardHeader, CardContent, CardActions, Divider, Button, Icon, Link } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import NovoDocumento from './NovoDocumento';
import EditarDocumento from './EditarDocumento';
import { CustomSnackBar, Confirmation } from '../../components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { downloadFile } from '../../services/fileSystem';
import { docIcon, gifIcon, jpgIcon, pdfIcon, pngIcon, pptIcon, svgIcon, txtIcon, xlsIcon, xmlIcon, zipIcon } from '../../img/svg/files';

function FormDocumentos({ classes, idFuncionario }) {

  const [arquivos, setArquivos] = useState([]);

  const [form, setForm] = useState({
    displayNovo: false,
    displayEditar: false,
    editDocumento: {},
    confirmation: false,
    idDocumento: 0
  });

  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  useEffect(() => { carregarDocumentos(idFuncionario) }, [idFuncionario]);

  async function carregarDocumentos(idFuncionario){

    if(idFuncionario !== ""){
      const response = await listArquivos({Entidade: 'Funcionario', Id_Entidade: idFuncionario});
      if (!response) return
  
      setArquivos(response.data);
    }
  }

  async function salvarNovoDocumento(arquivo, descricao){

    const data = JSON.stringify({ Entidade: 'Funcionario', Id_Entidade: idFuncionario, Descricao: descricao});

    const body = new FormData();
    body.append('Entidade', 'Funcionario');
    body.append('Id_Entidade', idFuncionario);
    body.append('Descricao', descricao);
    body.append('file', arquivo.file, arquivo.name);

    const response = await postArquivo(body);

    if(response.valid){
      setForm({...form, displayNovo: false});
      carregarDocumentos(idFuncionario);
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

  }

  async function salvarEditarDocumento(documento){
    
    const response = await putArquivo(documento.Id_Arquivo, {Descricao: documento.Descricao});

    if(response.valid){
      setForm({...form, displayEditar: false});
      carregarDocumentos(idFuncionario);
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }
  
  }

  async function onDeletarDocumento(){

    const response = await deleteArquivo(form.idDocumento, {});

    if(response.valid){
      carregarDocumentos(idFuncionario);
      setAlert({display: true, severity: 'success', text: 'Documento apagado com sucesso!'});
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setForm({...form, confirmation: false});
    
  }

  function openDialog(idDocumento, openConfirm) {
    setForm({...form, confirmation: openConfirm, idDocumento: idDocumento});
  }

  function onEditarDocumento(values){
    setForm({...form, displayEditar: true, editDocumento: values});
  }

  function getFileIcon(ext){
    switch(ext.toLowerCase()){
      case 'doc':
      case 'docx':
        return docIcon;
      case 'gif':
        return gifIcon;
      case 'jpg':
      case 'jpeg':
        return jpgIcon;
      case 'pdf':
        return pdfIcon;
      case 'png':
        return pngIcon;
      case 'ppt':
      case 'pptx':
        return pptIcon;
      case 'svg':
        return svgIcon;
      case 'txt':
        return txtIcon;
      case 'xls':
      case 'xlsx':
        return xlsIcon;
      case 'xml':
        return xmlIcon;
      case 'zip':
      case 'rar':
        return zipIcon;
      default:

    }
  }

  return (
    <div>
      <Card style={{marginLeft: -20, marginRight: -20}}>
        <CardHeader title="Documentos"/>
        <Divider />
        <CardContent>
          <TableContainer component={Paper} className={classes.table}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.header}>
                  <TableCell className={classes.tableHeader}></TableCell>
                  <TableCell className={classes.tableHeader}>Arquivo</TableCell>
                  <TableCell className={classes.tableHeader}>Descrição</TableCell>
                  <TableCell className={classes.tableHeader} align="center">Criado Por</TableCell>
                  <TableCell className={classes.tableHeader} align="center">Data Criação</TableCell>
                  <TableCell className={classes.tableHeader} align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {arquivos.map((row) => (
                  <TableRow key={row.Id_Arquivo}>
                    <TableCell component="th" scope="row">
                      <Icon classes={{root: classes.iconRoot}}>
                        <img className={classes.imageIcon} src={getFileIcon(row.Extensao)}/>
                      </Icon>
                    </TableCell>
                    <TableCell>
                      <Link onClick={() => downloadFile(row.Id_Arquivo)} className={classes.downloadLink}>
                        {row.Arquivo}.{row.Extensao}
                      </Link>
                    </TableCell>
                    <TableCell>{row.Descricao}</TableCell>
                    <TableCell align="center">{row.Criado_Por}</TableCell>
                    <TableCell align="center">{moment(row.Data_Hora_Criacao).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                    <TableCell align="center">
                      <IconButton className={classes.button} onClick={() => onEditarDocumento(row)}>
                        <Edit fontSize='small'/>
                      </IconButton>
                      <IconButton className={classes.button} onClick={() => openDialog(row.Id_Arquivo, true)}>
                        <Delete fontSize='small'/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <Divider/>
        <CardActions>
          <Button variant="contained" color="primary" onClick={() => setForm({...form, displayNovo: true})} >
          ADICIONAR
          </Button>
        </CardActions>
      </Card>

      <NovoDocumento display={form.displayNovo} onSalvar={salvarNovoDocumento} onFechar={() => setForm({...form, displayNovo: false})}/>
      <EditarDocumento display={form.displayEditar} data={form.editDocumento} onSalvar={salvarEditarDocumento} onFechar={() => setForm({...form, displayEditar: false})}/>
      
      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setForm({...form, alertDisplay: false})}
      />
      <Confirmation 
        title="Apagar documento?"
        open={form.confirmation}
        setOpen={openDialog}
        onConfirm={onDeletarDocumento}
      >
        <Typography variant="subtitle2">Você tem certeza que deseja apagar esse documento?</Typography>
      </Confirmation>
    </div>
  )

}

FormDocumentos.propTypes = {
  idFuncionario: PropTypes.string
};

export default withStyles(styles)(FormDocumentos);