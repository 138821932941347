import React, { useState, useEffect } from 'react';
import { CircularProgress, Paper, CardHeader, Grid, TextField, Button } from '@material-ui/core';
import { AtomicModal } from '../../../components';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';


function EditarDocumento({classes, display, data, onSalvar, onFechar}){

  const [loading, setLoading] = useState(false)

  function closeModal(){
    onFechar();
  }

  async function salvarDocumento(documento){
    setLoading(true);
    await onSalvar(documento);
    setLoading(false);
  }

  const schema = yup.object().shape({
    Descricao: yup.string().required('Campo Obrigatório')
  });

  return (
    <Formik initialValues={data} onSubmit={(values) => salvarDocumento(values)} enableReinitialize={true} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldValue
      } = props
      return (
        <AtomicModal open={display}  width={`30%`} handleClose={() => closeModal()}>
          <Form>
            <Paper className={classes.modal}>
              <CardHeader title="Alterar Descrição" />

              <Grid container spacing={1} className={classes.modalFields}>
                <Grid item md={12} xs={12} >
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    label="Descrição"
                    margin="dense"
                    name="Descricao"
                    onChange={handleChange}
                    value={values.Descricao}
                    variant="outlined"
                    helperText={errors.Descricao && touched.Descricao ? errors.Descricao : ''}
                    error={errors.Descricao && touched.Descricao ? true : false}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
              
              <Button variant="contained" onClick={() => closeModal()} >
                CANCELAR
              </Button>

              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={loading}
                className={classes.modalSaveButton}
              >
                SALVAR
                {loading ? 
                  <CircularProgress 
                  size={14}
                  color="secondary" />
                  : ''
                }
              </Button>
            </Paper>
          </Form>
        </AtomicModal>
      )
      }}
    </Formik>
  )
}

export default withStyles(styles)(EditarDocumento);