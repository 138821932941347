export const styles = theme => ({
  table: {
    width: '40',
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: 14,
    paddingTop: 8,
    paddingBottom: 8
  },
  header: {
    backgroundColor: '#DDDDDD'
  },
  button: {
    paddingTop: 0,
    paddingBottom: 0
  },
  card: {
    marginTop: 30,
    marginLeft: 25,
    
  },
  TableCellLink: {
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer'
    }
  },
  rowButton: {
    paddingTop: 5,
    paddingBottom: 5,
    marginTop: -10,
    marginBottom: -10
  }
})