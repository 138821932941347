export const styles = theme => ({
  modalNew: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10
  },
  modalFields: {
    marginBottom: 20
  },
  modalSaveButton: {
    marginLeft: 10
  },
  inputRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: 'red'
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: 'red'
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: 'red'
    }
  }
});