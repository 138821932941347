import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getAlocacao, postAlocacao, putAlocacao, deleteAlocacao } from '../../requests/alocacaoRequest';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, 
         IconButton, Card, CardHeader, CardContent, CardActions, Divider, Button } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import NovoAlocacao from './NovoAlocacao';
import EditarAlocacao from './EditarAlocacao';
import { CustomSnackBar, Confirmation } from '../../components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';


function FormAlocacao({ classes, idFuncionario }) {

  const [alocacao, setAlocacao] = useState([]);

  const [alocacaoProps, setAlocacaoProps] = useState({
  displayNovo: false,
  displayEditar: false,
  alertDisplay: false,
  alertSeverety:'',
  alertText:'',
  confirmation: false,
  idAlocacao: 0,
  editAlocacao: {
    Id_Funcionario: 0,
    Id_Alocacao: 0,
    Id_Unidade_Negocio: 1,
    Id_Gestor: 0,
    Data_Alocacao: '',
  }
  });

  useEffect(() => { carregarAlocacao(idFuncionario) }, [idFuncionario]);

  async function carregarAlocacao(idFuncionario){

    if(idFuncionario !== ""){
      const response = await getAlocacao({params: {Id_Funcionario: idFuncionario}});
      if (!response) return
  
      setAlocacao(response.data);
    }
  }
  async function salvarNovaAlocacao(alocacao){

    const response = await postAlocacao({...alocacao, Id_Funcionario: idFuncionario});

    if(response.valid){
      setAlocacaoProps({...alocacaoProps, displayNovo: false});
      carregarAlocacao(idFuncionario);
    }else{
      setAlocacaoProps({...alocacaoProps, alertDisplay: true, alertSeverity: 'error', text: response.message});
    }

  }
  async function salvarEditarAlocacao(alocacao){
    
    const response = await putAlocacao({...alocacao});

    if(response.valid){
      setAlocacaoProps({...alocacaoProps, displayEditar: false});
      carregarAlocacao(idFuncionario);
    }else{
      setAlocacaoProps({...alocacaoProps, alertDisplay: true, alertSeverity: 'error', alertText: response.message});
    }
  
  }
  async function onDeletarAlocacao(){

    const response = await deleteAlocacao({Id_Alocacao: alocacaoProps.idAlocacao});

    if(response.valid){
      carregarAlocacao(idFuncionario);
      setAlocacaoProps({...alocacaoProps, alertDisplay: true, alertSeverity: 'success', alertText: 'Alocação apagada com sucesso!', confirmation: false});
    }else{
      setAlocacaoProps({...alocacaoProps, alertDisplay: true, alertSeverity: 'error', alertText: response.message, confirmation: false});
    }
    
  }

  function openDialog(idAlocacao, openConfirm) {
    setAlocacaoProps({...alocacaoProps, confirmation: openConfirm, idAlocacao: idAlocacao});
  }

  function onEditarAlocacao(values){
    setAlocacaoProps({...alocacaoProps, displayEditar: true, editAlocacao: {...values}});
  }

  return (
    <div>
      <Card style={{marginLeft: -20, marginRight: -20}}>
        <CardHeader title="Alocação"/>
        <Divider />
        <CardContent>
          <TableContainer component={Paper} className={classes.table}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.header}>
                  <TableCell className={classes.tableHeader} align="center">Unidade de Negócio</TableCell>
                  <TableCell className={classes.tableHeader} align="center">Cliente</TableCell>
                  <TableCell className={classes.tableHeader} align="center">Gestor</TableCell>
                  <TableCell className={classes.tableHeader} align="center">Início Alocação</TableCell>
                  <TableCell className={classes.tableHeader} align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {alocacao.map((row) => (
                  <TableRow key={row.Id_Alocacao}>
                    <TableCell align="center">{row.Unidade_Negocio}</TableCell>
                    <TableCell align="center">{row.Cliente}</TableCell>
                    <TableCell align="center">{row.Gestor}</TableCell>
                    <TableCell align="center">{moment(row.Data_Alocacao).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align="center">
                      <IconButton size="small" className={classes.button} onClick={() => openDialog(row.Id_Alocacao, true)}>
                        <Delete fontSize='small'/>
                      </IconButton>
                      <IconButton size="small" className={classes.button} onClick={() => onEditarAlocacao(row)}>
                        <Edit fontSize='small'/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <Divider/>
        <CardActions>
          <Button variant="contained" color="primary" onClick={() => setAlocacaoProps({...alocacaoProps, displayNovo: true})} >
          ADICIONAR
          </Button>
        </CardActions>
      </Card>

      <NovoAlocacao display={alocacaoProps.displayNovo} onSalvar={salvarNovaAlocacao} onFechar={() => setAlocacaoProps({...alocacaoProps, displayNovo: false})}/>
      <EditarAlocacao display={alocacaoProps.displayEditar} data={alocacaoProps.editAlocacao} onSalvar={salvarEditarAlocacao} onFechar={() => setAlocacaoProps({...alocacaoProps, displayEditar: false})}/>
            
      <CustomSnackBar 
        display={alocacaoProps.alertDisplay}
        severity={alocacaoProps.alertSeverity}
        text={alocacaoProps.alertText}
        onClose={() => setAlocacaoProps({...alocacaoProps, alertDisplay: false})}
      />
      <Confirmation 
        title="Apagar Alocação?"
        open={alocacaoProps.confirmation}
        setOpen={openDialog}
        onConfirm={onDeletarAlocacao}
        >
          <Typography variant="subtitle2">Você tem certeza que deseja apagar essa alocação?</Typography>
      </Confirmation>
    </div>
  )

}

FormAlocacao.propTypes = {
  idFuncionario: PropTypes.string
};

export default withStyles(styles)(FormAlocacao);
