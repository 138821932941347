export const styles = theme => ({
  modal: {
    paddingLeft: 10,
    paddingBottom: 10,
    paddingRight: 10
  },
  modalFields: {
    marginBottom: 20
  },
  modalSaveButton: {
    marginLeft: 10
  },
  criterioBlock: {
    marginBottom: 10,
    padding: 14
  },
  labelCriterio: {
    fontWeight: 'bold'
  },
  labelNota: {
    marginTop: 10
  },
  buttonAddCriteria: {
    marginTop: 8
  }
})