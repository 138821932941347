import React, { useState, useEffect } from 'react';
import { CircularProgress, Paper, CardHeader, Grid, TextField, Button, InputAdornment } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AtomicModal } from '../../../components';
import { CST_TIPO_REMUNERACAO } from '../../../constants';
import { getCargo } from '../../../requests/cargoAction';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { TextMaskCurrency } from '../../../masks';


function EditarRemuneracao({classes, display, data, onSalvar, onFechar}){

  const [loading, setLoading] = useState(false)

  const [cargo, setCargo] = useState([]);

  useEffect(() => { carregarCargos() }, []);

  async function carregarCargos(){
    const response = await getCargo();
    if (response.valid){
      setCargo(response.data);
    }
  }

  function closeModal(){
    onFechar();
  }

  function salvarRemuneracao(remuneracao){
    setLoading(true);
    onSalvar(remuneracao);
    setLoading(false);
  }

  const schema = yup.object().shape({
    Id_Tipo_Remuneracao: yup.number().required('Campo Obrigatório'),
    Taxa_Hora: yup.mixed().when('Id_Tipo_Remuneracao', {
      is: (Id_Tipo_Remuneracao) => Id_Tipo_Remuneracao == '3',
      then: yup.mixed('Digite apenas números').required('Campo Obrigatório'),
    }),
    Valor: yup.mixed().when('Id_Tipo_Remuneracao', {
      is: (Id_Tipo_Remuneracao) => Id_Tipo_Remuneracao != '3',
      then: yup.mixed('Digite apenas números').required('Campo Obrigatório'),
    }),
    Id_Cargo: yup.number().required('Campo Obrigatório'),
    Inicio_Validade: yup.date().required('Campo Obrigatório')
  });

  return (
    <Formik initialValues={data} onSubmit={(values) => salvarRemuneracao(values)} enableReinitialize={true} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldValue
      } = props
      return (
        <AtomicModal open={display} handleClose={() => closeModal()}>
          <Form>
            <Paper className={classes.modal}>
              <CardHeader title="Alterar Remuneração" />

              <Grid container spacing={1} className={classes.modalFields}>
                <Grid item md={3} xs={6} >
                  <TextField
                    fullWidth
                    label="Tipo"
                    margin="dense"
                    name="Id_Tipo_Remuneracao"
                    select
                    SelectProps={{ native: true }}
                    onChange={(event) => { handleChange(event); setFieldValue('Valor', ''); setFieldValue('Taxa_Hora', ''); console.log(values);}}
                    value={values.Id_Tipo_Remuneracao}
                    variant="outlined"
                    helperText={errors.Id_Tipo_Remuneracao && touched.Id_Tipo_Remuneracao ? errors.Id_Tipo_Remuneracao : ''}
                    error={errors.Id_Tipo_Remuneracao && touched.Id_Tipo_Remuneracao ? true : false}
                    onBlur={handleBlur}
                  >
                    {CST_TIPO_REMUNERACAO.map(option => (
                      <option key={option.Id_Tipo_Remuneracao} value={option.Id_Tipo_Remuneracao}>
                        {option.Nome}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                { values.Id_Tipo_Remuneracao === '3' ?
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="Taxa/Hora"
                      margin="dense"
                      name="Taxa_Hora"
                      onChange={handleChange}
                      value={values.Taxa_Hora}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        inputComponent: TextMaskCurrency
                      }}
                      variant="outlined"
                      helperText={errors.Taxa_Hora && touched.Taxa_Hora ? errors.Taxa_Hora : ''}
                      error={errors.Taxa_Hora && touched.Taxa_Hora ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>
                :
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="Valor"
                      margin="dense"
                      name="Valor"
                      onChange={handleChange}
                      value={values.Valor}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        inputComponent: TextMaskCurrency
                      }}
                      variant="outlined"
                      helperText={errors.Valor && touched.Valor ? errors.Valor : ''}
                      error={errors.Valor && touched.Valor ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>
                }
                <Grid item md={3} xs={6}>
                  <Autocomplete
                    classes={errors.Id_Cargo && touched.Id_Cargo ? {inputRoot: classes.inputRoot} : ''}
                    options={cargo}
                    getOptionLabel={(option) => option.Descricao}
                    renderInput={(params) => <TextField {...params} label="Cargo" variant="outlined" />}
                    size="small"
                    style={{marginTop: 8}}
                    onChange={(event, newValue) => {
                      if(newValue === null){ 
                        setFieldValue('Id_Cargo', '')
                      }else {
                        setFieldValue('Id_Cargo', newValue.Id_Cargo)
                      }
                    }}
                    defaultValue={{Id_Cargo: values.Id_Cargo, Descricao: values.Cargo}}
                  />
                  {errors.Id_Cargo && touched.Id_Cargo ? 
                  <div style={{fontFamily: 'Arial', fontSize: 11, color: 'red'}}>{errors.Id_Cargo}</div>
                  : ''}
                </Grid>
                <Grid item md={3} xs={6}>
                  <TextField
                      fullWidth
                      label="Início Validade"
                      margin="dense"
                      name="Inicio_Validade"
                      type="date"
                      onChange={handleChange}
                      value={values.Inicio_Validade}
                      variant="outlined"
                      InputLabelProps={{
                          shrink: true,
                      }}
                      helperText={errors.Inicio_Validade && touched.Inicio_Validade ? errors.Inicio_Validade : ''}
                      error={errors.Inicio_Validade && touched.Inicio_Validade ? true : false}
                      onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
              
              <Button variant="contained" onClick={() => closeModal()} >
                CANCELAR
              </Button>

              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={loading}
                className={classes.modalSaveButton}
              >
                SALVAR
                {loading ? 
                  <CircularProgress 
                  size={14}
                  color="secondary" />
                  : ''
                }
              </Button>
            </Paper>
          </Form>
        </AtomicModal>
      )
      }}
    </Formik>
  )
}

export default withStyles(styles)(EditarRemuneracao);