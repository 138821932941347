import React, { useState, useEffect } from 'react';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton,
    Card, CardHeader, CardContent, CardActions, Divider, Button, TablePagination, CircularProgress,
    Menu, MenuItem, Box, Collapse } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { getReembolso, postReembolso, putReembolso } from '../../../requests/reembolsoRequest';
import { postArquivo } from '../../../requests/arquivoRequest';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { CustomSnackBar, TablePaginationActions, StatusChip, Confirmation } from '../../../components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NovoReembolso from './components/NovoReembolso';
import EditarReembolso from './components/EditarReembolso';
import { convertCurrencyBR, convertCurrencyUS, formatCurrencyDisplay } from '../../../utils';
import moment from 'moment';

function Reembolso({classes, idUsuario, history}){
  
  const [reembolsos, setReembolsos] = useState([]);

  const [form, setForm] = useState({
    displayNovo: false,
    displayEditar: false,
    editDisabled: false,
    editarReembolso: false,
    confirmation: false,
  });

  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState([]);

  useEffect(() => { carregarReembolsos() }, [idUsuario]);

  async function carregarReembolsos(){
    setLoading(true);

    const response = await getReembolso(idUsuario);
    
    if (response.valid) {
      setReembolsos(response.data);
    } else {
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setLoading(false);
  }

  function handlePageChange(event, newPage) {
    setPage(newPage);
  };

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function onEditarReembolso(){
    setForm({...form, displayEditar: true, editDisabled: false});
    setAnchorEl(null);
  }

  function onVisualizarReembolso(){
    setForm({...form, displayEditar: true, editDisabled: true});
    setAnchorEl(null);
  }

  function onCancelarReembolso(arg, openConfirm){
    setForm({...form, confirmation: openConfirm});
    setAnchorEl(null);
  }

  async function cancelarReembolso(){

    const body = {Id_Reembolso: form.editarReembolso.Id_Reembolso, Status: 'Cancelado' };

    const response = await putReembolso(body);

    if(response.valid){
      setForm({...form, confirmation: false});
      carregarReembolsos();
      setAlert({display: true, severity: 'success', text: 'Reembolso cancelado'});
    }else{
      setAlert({display: true, severity: 'error', text: 'Erro ao cancelar reembolso'});
    }

  }

  async function salvarNovoReembolso(reembolso, files){

    const valor = convertCurrencyUS(reembolso.Valor);

    const response = await postReembolso({...reembolso, Valor: valor});

    if(response.valid){

      files.forEach(async (file) => {
        const responseFile = await salvarAnexo(file.file, response.data.Id_Reembolso);
      })

      carregarReembolsos();
      setForm({...form, displayNovo: false});
      setAlert({display: true, severity: 'success', text: 'Reembolso criado com sucesso!'});
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

  }

  async function salvarEditarReembolso(reembolso){

    const valor = convertCurrencyUS(reembolso.Valor);

    const body = {
      Id_Reembolso: reembolso.Id_Reembolso,
      Id_Tipo_Reembolso: reembolso.Id_Tipo_Reembolso,
      Data_Gasto: reembolso.Data_Gasto,
      Descricao: reembolso.Descricao,
      Valor: valor
    }

    const response = await putReembolso(body);

    if(response.valid){
      carregarReembolsos();
      setForm({...form, displayEditar: false});
      setAlert({display: true, severity: 'success', text: 'Reembolso alterado com sucesso!'});
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

  }

  async function salvarAnexo(arquivo, idReembolso){

    const body = new FormData();
    body.append('file', arquivo[0], arquivo[0].name);
    body.append('Entidade', 'Reembolso');
    body.append('Id_Entidade', idReembolso);

    const response = await postArquivo(body);

    return response.valid;

  }

  function abrirMenuOpcoes(event, reembolso){
    const valor = convertCurrencyBR(reembolso.Valor);
    const dataGasto = moment(reembolso.Data_Gasto).format('YYYY-MM-DD');
    setForm({...form, editarReembolso: {...reembolso, Valor: valor, Data_Gasto: dataGasto}});
    let anchor = {top: event.clientY, left: event.clientX};
    setAnchorEl(anchor);
  }

  function fecharMenuOpcoes(){
    setAnchorEl(null);
  }

  function handleOpenClose(index){
    let o = [...open];
    o[index] = !open[index];
    setOpen(o);
  }

  function Row({row, index}) {
  
    return (
      <React.Fragment>
        <TableRow className={classes.row}>
          <TableCell className={classes.mainRowCell}>
            <IconButton size="small" onClick={() => handleOpenClose(index)}>
              {open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell className={classes.mainRowCell}>#{row.Id_Reembolso}</TableCell>
          <TableCell className={classes.mainRowCell}>{row.Descricao}</TableCell>
          <TableCell className={classes.mainRowCell}>{row.Tipo_Reembolso}</TableCell>
          <TableCell align="center" className={classes.mainRowCell}>{formatCurrencyDisplay(row.Valor)}</TableCell>
          <TableCell align="center" className={classes.mainRowCell}>
            <StatusChip status={row.Status} />
          </TableCell>
          <TableCell align="center" className={classes.mainRowCell}>{moment(row.Data_Gasto).format('DD/MM/YYYY')}</TableCell>
          <TableCell align="center" className={classes.mainRowCell}>
            <Tooltip title="Opções">
              <IconButton size="small" onClick={(event) => abrirMenuOpcoes(event, row)} id={`icon-button-${row.Id_Reembolso}`}>
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
            <Menu anchorReference="anchorPosition" anchorPosition={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={fecharMenuOpcoes} 
              anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} transformOrigin={{vertical: 'top', horizontal: 'right'}}>
              <MenuItem onClick={onVisualizarReembolso}>Visualizar</MenuItem>
              { form.editarReembolso.Status === 'Rejeitado' && <MenuItem onClick={onEditarReembolso}>Editar</MenuItem> }
              { ['Aberto', 'Rejeitado'].includes(form.editarReembolso.Status) && <MenuItem onClick={() => onCancelarReembolso(false, true)}>Cancelar</MenuItem> }
            </Menu>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open[index]} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small">
                  <TableHead className={classes.subHeader}>
                    <TableRow>
                      <TableCell className={classes.subTableHeader}>Nome</TableCell>
                      <TableCell className={classes.subTableHeader} align="center">Status</TableCell>
                      <TableCell className={classes.subTableHeader}>Observação</TableCell>
                      <TableCell className={classes.subTableHeader} align="center">Data/Hora</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.historico.map((historicoRow) => (
                      <TableRow key={historicoRow.Id_Historico_Reembolso}>
                        <TableCell component="th" scope="row">{historicoRow.usuario.Nome}</TableCell>
                        <TableCell align="center">
                          <StatusChip status={historicoRow.Status}/>
                        </TableCell>
                        <TableCell component="th" scope="row">{historicoRow.Observacao}</TableCell>
                        <TableCell align="center">{moment(historicoRow.Data_Hora).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }


  return(
    <div>
      <Card className={classes.card}>
        <CardHeader title="Meus Reembolsos"/>
        <Divider />
        {loading ?
          <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
            <CircularProgress />
          </div>
        :
          <CardContent>
            <TableContainer component={Paper} className={classes.table}>
              <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.header}>
                      <TableCell className={classes.tableHeaderCollapse}/>
                      <TableCell className={classes.tableHeader}>ID</TableCell>
                      <TableCell className={classes.tableHeader}>Descrição</TableCell>
                      <TableCell className={classes.tableHeader}>Tipo</TableCell>
                      <TableCell align="center" className={classes.tableHeader}>Valor</TableCell>
                      <TableCell align="center" className={classes.tableHeader}>Status</TableCell>
                      <TableCell align="center" className={classes.tableHeader}>Data do Gasto</TableCell>
                      <TableCell className={classes.tableHeader}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                    ? reembolsos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : reembolsos
                    ).map((row, index) => (
                      <Row key={row.Id_Reembolso} row={row} index={index}/>
                  ))}
                  </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        }
        <Divider/>
        <CardActions style={{height: 30}}>
          <Button variant="contained" color="primary" onClick={() => setForm({...form, displayNovo: true})}>NOVO</Button>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
            count={reembolsos.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
            style={{position: 'absolute', right: 10}}
          />
        </CardActions>
      </Card>

      {form.displayNovo && <NovoReembolso display={form.displayNovo} onSalvar={salvarNovoReembolso} onFechar={() => setForm({...form, displayNovo: false})} idUsuario={idUsuario}/>}
      {form.displayEditar && <EditarReembolso disabled={form.editDisabled} display={form.displayEditar} data={form.editarReembolso} onSalvar={salvarEditarReembolso} onFechar={() => setForm({...form, displayEditar: false})}/>}
      
      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setAlert({display: false, severity: '', text: ''})}
      />

      <Confirmation 
        title="Cancelar reembolso?"
        open={form.confirmation}
        setOpen={onCancelarReembolso}
        onConfirm={cancelarReembolso}
        >
          Você tem certeza que deseja cancelar esse reembolso?
      </Confirmation>
      
    </div>
  )

}

const mapStateToProps = store => ({ 
  nome: store.Usuario.usuario.Nome,
  usuario: store.Usuario.usuario.Usuario,
  idUsuario: store.Usuario.usuario.Id_Usuario
})

export default connect(mapStateToProps, null)(withRouter(withStyles(styles)(Reembolso)));