import api from '../services/api';
import { handleRequestError } from '../services/security';


export async function listUnidadeNegocio( params ){

  let responseFromApi;

  try {
    const response = await api.get('/unidade-negocio', {params: params});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function getUnidadeNegocio( id, params ){

  let responseFromApi;

  try {
    const response = await api.get(`/unidade-negocio/${id}`, {params: params});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function getAllUnidadeNegocio( params ){

  let responseFromApi;

  try {
    const response = await api.get(`/unidade-negocio/`, {params: params});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postUnidadeNegocio( params ){
    
  let responseFromApi;

  try {
    const response = await api.post('/unidade-negocio', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putUnidadeNegocio( body ){
    
  let responseFromApi;

  try {
    const response = await api.put('/unidade-negocio', body);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}


export async function deleteUnidadeNegocio( id ){
    
  let responseFromApi;

  try {
    const response = await api.delete(`/unidade-negocio/${id}`);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
  
}