import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Backdrop,
    Card, CardHeader, CardContent, CardActions, Divider, Button, TablePagination, CircularProgress, Typography } from '@material-ui/core';
import { listVaga, putVaga, postVaga } from '../../../requests/vagaRequest';
import { postArquivo } from '../../../requests/arquivoRequest';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { CustomSnackBar } from '../../../components';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import NovaVaga from './NovaVaga';
import EditarVaga from './EditarVaga';

function Vagas({classes, history, idUsuario, perfil}){
  
  const [vagas, setVagas] = useState([]);

  const [form, setForm] = useState({
    displayNovo: false,
    displayEditar: false,
    editarVaga: {},
    editDisabled: false,
    confirmation: false,
    idFormulario: 0
  });

  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  useEffect(() => { carregarVagas() }, []);

  async function carregarVagas(){
    setLoading(true);

    if(!perfil.funcoes.read.includes('GerirHolerite')){
      setLoading({...loading, pastas: false});
      history.push('/proibido');
    }

    const response = await listVaga({byStatus: true});
    
    if(response.valid) {
      setVagas(response.data);
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setLoading(false);
  }

  function onNovaVaga(){
    setForm({...form, displayNovo: true});
  }

  async function salvarNovaVaga(vaga, files){

    setLoading(true);

    const response = await postVaga(vaga);

    if(response.valid){

      files.forEach(async (file) => {
        const responseFile = await salvarAnexo(file.file, response.data.Id_Vaga);
      })

      carregarVagas();
      setForm({...form, displayNovo: false});
      setAlert({display: true, severity: 'success', text: 'Vaga criada com sucesso!'});
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setLoading(false);

  }

  function onEditarVaga(vaga){

    let editDisabled = false;

    if(['Preenchida', 'Cancelada'].includes(vaga.Status)){
      editDisabled = true;
    }

    setForm({...form, displayEditar: true, editDisabled: editDisabled, editarVaga: vaga });
  }

  async function salvarEditarReembolso(vaga){

    const response = await putVaga(vaga);

    if(response.valid){
      carregarVagas();
      setForm({...form, displayEditar: false});
      setAlert({display: true, severity: 'success', text: 'Vaga alterada com sucesso!'});
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

  }

  async function salvarAnexo(arquivo, idVaga){

    const body = new FormData();
    body.append('file', arquivo[0], arquivo[0].name);
    body.append('Entidade', 'Vaga');
    body.append('Id_Entidade', idVaga);

    const response = await postArquivo(body);

    return response.valid;

  }

  async function onDragEnd(result){
    if (!result.destination) return;

    setLoading(true);
    
    const { source, destination } = result;
  
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = vagas[source.droppableId];
      const destColumn = vagas[destination.droppableId];
      const sourceItems = [...sourceColumn];
      const destItems = [...destColumn];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      setVagas({
        ...vagas,
        [source.droppableId]: sourceItems,
        [destination.droppableId]: destItems
      });

      const response = await putVaga({Id_Vaga: removed.Id_Vaga, novoStatus: destination.droppableId});

      //if(!response.valid){
        await carregarVagas();
      //}

    } else {
      const column = vagas[source.droppableId];
      const copiedItems = vagas[source.droppableId];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setVagas({
        ...vagas,
        [source.droppableId]: copiedItems
      });
    }

    setLoading(false);
  };


  return(
    <div>
      <Card className={classes.card}>
        <CardHeader title="Quadro de Vagas"/>
        <Divider />
          <CardContent>
            <div style={{ display: "flex", height: "100%", overflowY: 'auto' }}>
              <DragDropContext onDragEnd={result => onDragEnd(result)}>
                {Object.entries(vagas).map(([status, vagaItems], index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                      }}
                    >
                      <Typography variant="subtitle2" style={{fontWeight: 'bold'}}>{status}</Typography>
                      <div style={{ margin: 8 }}>
                        <Droppable droppableId={status} key={status}>
                          {(provided, snapshot) => {
                            return (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                  background: snapshot.isDraggingOver
                                    ? "lightblue"
                                    : "lightgrey",
                                  padding: 4,
                                  width: 250,
                                  minHeight: 500
                                }}
                              >
                                {vagaItems.map((item, index) => {
                                  return (
                                    <Draggable
                                      key={item.Id_Vaga}
                                      draggableId={item.Id_Vaga + item.Titulo}
                                      index={index}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                              userSelect: "none",
                                              padding: 5,
                                              margin: "0 0 8px 0",
                                              minHeight: "95px",
                                              backgroundColor: snapshot.isDragging
                                                ? "#DDDDDD"
                                                : "#FFFFFF",
                                              color: "black",
                                              borderRadius: '5px',
                                              ...provided.draggableProps.style
                                            }}
                                            onDoubleClick={() => onEditarVaga(item)}
                                          >
                                            <div className={classes[item.Status]}>
                                              <div style={{marginLeft: 5}}>
                                                <Typography variant="body2" style={{color: 'black', fontWeight: 'bold'}}>#{item.Id_Vaga} - {item.Titulo}</Typography>
                                                <br/>
                                                <Typography variant="caption" style={{color: 'black'}}>{item.Unidade_Negocio}</Typography>
                                                <br/>
                                                <Typography variant="caption" style={{color: 'black'}}>{item.Cliente}</Typography>
                                                <br/>
                                                <Typography variant="caption" style={{color: 'black'}}>{item.Nivel_Carreira}</Typography>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </div>
                            );
                          }}
                        </Droppable>
                      </div>
                    </div>
                  );
                })}
              </DragDropContext>
              <Backdrop className={classes.backdrop} open={loading} onClick={() => setLoading(false)}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          </CardContent>
        <Divider/>
        <CardActions>
          <Button variant="contained" color="primary" onClick={onNovaVaga}>NOVO</Button>
        </CardActions>
      </Card>
      
      <NovaVaga display={form.displayNovo} onSalvar={salvarNovaVaga} onFechar={() => setForm({...form, displayNovo: false})} idUsuario={idUsuario}/>
      <EditarVaga disabled={form.editDisabled} display={form.displayEditar} data={form.editarVaga} onSalvar={salvarEditarReembolso} onFechar={() => setForm({...form, displayEditar: false})}/>

      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setAlert({display: false, severity: '', text: ''})}
      />
      
    </div>
  )

}

const mapStateToProps = store => ({ 
  nome: store.Usuario.usuario.Nome,
  usuario: store.Usuario.usuario.Usuario,
  idUsuario: store.Usuario.usuario.Id_Usuario,
  perfil: store.Perfil
})

export default connect(mapStateToProps, null)(withRouter(withStyles(styles)(Vagas)));