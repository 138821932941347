import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { postFuncionario, putFuncionario, getFuncionarios } from '../../requests/funcionariosRequest';
import { getUsuarios } from '../../requests/usuarioRequest';
import { getContratacao } from '../../requests/contratacaoAction';
import { getGenero } from '../../requests/generoAction';
import { getEstadoCivil } from '../../requests/estadoCivilAction';
import { getStatus } from '../../requests/statusRequest';
import { CustomSnackBar } from '../../components';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  CircularProgress,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { TextMaskTelefone, TextMaskCPF, TextMaskCNPJ } from '../../masks';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { connect } from 'react-redux';

function FormFuncionario({classes, disabled, acao, funcData, perfil}) {

  let history = useHistory();

  const [stateForm, setStateForm] = useState({
    submitting: false,
    disabled: disabled,
    action: acao,
    loading: true
  });

  const [showAlert, setShowAlert] = useState({
    display: false,
    severity: '',
    text: ''
  });

  const [usuarios, setUsuarios] = useState([]);
  const [contratacao, setContratacao] = useState([]);
  const [genero, setGenero] = useState([]);
  const [estadoCivil, setEstadoCivil] = useState([]);
  const [statusFuncionario, setStatusFuncionario] = useState([]);
  const [gestor, setGestor] = useState([]);

  useEffect(() => { carregarDados() }, []);
  
  async function carregarDados(){
    setStateForm({...stateForm, loading: true});
    await carregarUsuarios();
    await carregarContratacao();
    await carregarGenero();
    await carregarEstadoCivil();
    await carregarStatusFuncionario();
    await carregarGestor();
    setStateForm({...stateForm, loading: false});
  }

  async function carregarUsuarios(){
    const response = await getUsuarios();
    if (!response) return
    setUsuarios(response.data);
  }

  async function carregarContratacao(){
    const response = await getContratacao();
    if (!response) return
    setContratacao(response.data);
  }

  async function carregarGenero(){
    const response = await getGenero();
    if (!response) return
    setGenero(response.data);
  }

  async function carregarEstadoCivil(){
    const response = await getEstadoCivil();
    if (!response) return
    setEstadoCivil(response.data);
  }

  async function carregarStatusFuncionario(){
    const response = await getStatus({Tipo: 'Funcionario'});
    if (!response) return
    setStatusFuncionario(response.data);
  }

  async function carregarGestor(){
    const response = await getFuncionarios();
    if (!response) return
    setGestor(response.data);
  }

  function onEditFuncionario() {
    setStateForm({...stateForm, disabled: false});
  }

  function handleCloseAlert() {
    setShowAlert({display: false, severity: '', text: ''});
  };

  async function salvarFuncionario(values) {

    setStateForm({...stateForm, submitting: true});
    
    let response;
    let text;

    if(stateForm.action === 'Novo'){
      response = await postFuncionario(values);
    }else{
      response = await putFuncionario(values);
    }

    if(response.valid){
        
      if(stateForm.action === 'Novo'){
        text = 'Novo funcionário cadastrado com sucesso!';
        history.push('/funcionarios');
      } else {
        stateForm.disabled = true;
        stateForm.action = 'Exibir';

        text = 'Funcionário alterado com sucesso!';
        setStateForm({...stateForm, disabled: true});
      }
      
      setShowAlert({display: true, severity: 'success', text: text});

    }else{
      setShowAlert({display: true, severity: 'error', text: response.message});
      setStateForm({...stateForm, submitting: false});
    }
  }
  
  function onCancelarEditar(handleReset) {
    handleReset();
    setStateForm({action: 'Exibir', disabled: true});
  }

  function cancelarNovo(){

    const toFuncionarios = { 
      pathname: '/funcionarios'
    };
    
    return toFuncionarios;

  }

  const schema = yup.object().shape({
    Nome: yup.string().required('Campo Obrigatório'),
    Registro: yup.string().required('Campo Obrigatório'),
    Id_Contratacao: yup.number().required('Campo Obrigatório'),
    Email: yup.string().email('Formato Inválido').required('Campo Obrigatório'),
    Telefone: yup.string().min(14).required('Campo Obrigatório'),
    CPF: yup.string().min(14).required('Campo Obrigatório'),
    RG: yup.string().required('Campo Obrigatório'),
    Nascimento: yup.date().required('Campo Obrigatório'),
    Id_Genero: yup.number().required('Campo Obrigatório'),
    Id_Estado_Civil: yup.number().required('Campo Obrigatório'),
    Id_Endereco: yup.number().nullable().default(null),
    Data_Admissao: yup.date().required('Campo Obrigatório'),
    Data_Desligamento: yup.date().nullable().default(null),
    Id_Status: yup.number().required('Campo Obrigatório'),
    Qtd_Filho: yup.number()
  });

  return (
    <Formik initialValues={funcData} onSubmit={(values) => salvarFuncionario(values)} enableReinitialize={true} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleReset,
        setFieldValue
      } = props
      return (
        <Card className={classes.cardStyle}>
          <Form>

            <CustomSnackBar display={showAlert.display} severity={showAlert.severity} text={showAlert.text} onClose={handleCloseAlert} />

            <CardHeader title={stateForm.action + ' Funcionário'} />
            <Divider />
            {stateForm.loading ?
              <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
                <CircularProgress />
              </div>
            :
              <div>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Nome"
                      margin="dense"
                      name="Nome"
                      onChange={handleChange}
                      required
                      value={values.Nome}
                      variant="outlined"
                      disabled={stateForm.disabled}
                      helperText={errors.Nome && touched.Nome ? errors.Nome : ''}
                      error={errors.Nome && touched.Nome ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="Registro"
                      margin="dense"
                      name="Registro"
                      onChange={handleChange}
                      value={values.Registro}
                      variant="outlined"
                      disabled={stateForm.disabled}
                      helperText={errors.Registro && touched.Registro ? errors.Registro : ''}
                      error={errors.Registro && touched.Registro ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="Contratação"
                      margin="dense"
                      name="Id_Contratacao"
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.Id_Contratacao}
                      variant="outlined"
                      disabled={stateForm.disabled}
                      helperText={errors.Id_Contratacao && touched.Id_Contratacao ? errors.Id_Contratacao : ''}
                      error={errors.Id_Contratacao && touched.Id_Contratacao ? true : false}
                      onBlur={handleBlur}
                    >
                      {contratacao.map(option => (
                        <option key={option.Id_Contratacao} value={option.Id_Contratacao}>
                          {option.Nome}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      margin="dense"
                      name="Email"
                      onChange={handleChange}
                      required
                      value={values.Email}
                      variant="outlined"
                      disabled={stateForm.disabled}
                      helperText={errors.Email && touched.Email ? errors.Email : ''}
                      error={errors.Email && touched.Email ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item md={3} xs={6} key="GridTelefone">
                    <TextField
                      fullWidth
                      label="Telefone"
                      margin="dense"
                      name="Telefone"
                      value={values.Telefone}
                      onChange={handleChange}
                      variant="outlined"
                      key="TextFieldTelefone"
                      InputProps={{
                        inputComponent: TextMaskTelefone,
                      }}
                      disabled={stateForm.disabled}
                      helperText={errors.Telefone && touched.Telefone ? errors.Telefone : ''}
                      error={errors.Telefone && touched.Telefone ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="Nascimento"
                      margin="dense"
                      name="Nascimento"
                      type="date"
                      onChange={handleChange}
                      value={values.Nascimento}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={stateForm.disabled}
                      helperText={errors.Nascimento && touched.Nascimento ? errors.Nascimento : ''}
                      error={errors.Nascimento && touched.Nascimento ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="CPF"
                      margin="dense"
                      name="CPF"
                      onChange={handleChange}
                      value={values.CPF}
                      variant="outlined"
                      InputProps={{
                        inputComponent: TextMaskCPF
                      }}
                      disabled={stateForm.disabled}
                      helperText={errors.CPF && touched.CPF ? errors.CPF : ''}
                      error={errors.CPF && touched.CPF ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="RG"
                      margin="dense"
                      name="RG"
                      onChange={handleChange}
                      value={values.RG}
                      variant="outlined"
                      disabled={stateForm.disabled}
                      helperText={errors.RG && touched.RG ? errors.RG : ''}
                      error={errors.RG && touched.RG ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="CNPJ"
                      margin="dense"
                      name="CNPJ"
                      onChange={handleChange}
                      value={values.CNPJ}
                      variant="outlined"
                      InputProps={{
                        inputComponent: TextMaskCNPJ
                      }}
                      disabled={stateForm.disabled}
                      helperText={errors.CNPJ && touched.CNPJ ? errors.CNPJ : ''}
                      error={errors.CNPJ && touched.CNPJ ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="Gênero"
                      margin="dense"
                      name="Id_Genero"
                      onChange={handleChange}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={values.Id_Genero}
                      variant="outlined"
                      disabled={stateForm.disabled}
                      helperText={errors.Id_Genero && touched.Id_Genero ? errors.Id_Genero : ''}
                      error={errors.Id_Genero && touched.Id_Genero ? true : false}
                      onBlur={handleBlur}
                    >
                      {genero.map(option => (
                        <option key={option.Id_Genero} value={option.Id_Genero}>
                          {option.Nome}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="Estado Civil"
                      margin="dense"
                      name="Id_Estado_Civil"
                      onChange={handleChange}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={values.Id_Estado_Civil}
                      variant="outlined"
                      disabled={stateForm.disabled}
                      helperText={errors.Id_Estado_Civil && touched.Id_Estado_Civil ? errors.Id_Estado_Civil : ''}
                      error={errors.Id_Estado_Civil && touched.Id_Estado_Civil ? true : false}
                      onBlur={handleBlur}
                    >
                      {estadoCivil.map(option => (
                        <option key={option.Id_Estado_Civil} value={option.Id_Estado_Civil}>
                          {option.Nome}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="Admissão"
                      margin="dense"
                      name="Data_Admissao"
                      onChange={handleChange}
                      value={values.Data_Admissao}
                      variant="outlined"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={stateForm.disabled}
                      helperText={errors.Data_Admissao && touched.Data_Admissao ? errors.Data_Admissao : ''}
                      error={errors.Data_Admissao && touched.Data_Admissao ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="Desligamento"
                      margin="dense"
                      name="Data_Desligamento"
                      onChange={handleChange}
                      value={values.Data_Desligamento}
                      variant="outlined"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={stateForm.disabled}
                      helperText={errors.Data_Desligamento && touched.Data_Desligamento ? errors.Data_Desligamento : ''}
                      error={errors.Data_Desligamento && touched.Data_Desligamento ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="Status"
                      margin="dense"
                      name="Id_Status"
                      onChange={handleChange}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={values.Id_Status}
                      variant="outlined"
                      disabled={stateForm.disabled}
                      helperText={errors.Id_Status && touched.Id_Status ? errors.Id_Status : ''}
                      error={errors.Id_Status && touched.Id_Status ? true : false}
                      onBlur={handleBlur}
                    >
                      {statusFuncionario.map(option => (
                        <option key={option.Id_Status} value={option.Id_Status}>
                          {option.Nome}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <TextField
                      fullWidth
                      label="Quantidade de Filhos"
                      type='number'
                      margin="dense"
                      name="Qtd_Filho"
                      onChange={handleChange}
                      value={values.Qtd_Filho}
                      InputProps={{ inputProps: { min: 0, max: 10 } }}
                      variant="outlined"
                      disabled={stateForm.disabled}
                      helperText={errors.Qtd_Filho && touched.Qtd_Filho ? errors.Qtd_Filho : ''}
                      error={errors.Qtd_Filho && touched.Qtd_Filho ? true : false}
                      onBlur={handleBlur}
                    />
                </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
              <CardHeader title="Dados Corporativos" style={{marginBotton: 15, marginTop: -10}} />
                <Grid container spacing={2}>
                  <Grid item md={3} xs={3}>
                    <Autocomplete
                      options={usuarios}
                      getOptionLabel={(option) => option.Usuario}
                      renderInput={(params) => <TextField {...params} label="Usuário" variant="outlined" />}
                      size="small"
                      style={{marginTop: 8}}
                      onChange={(event, newValue) => {
                        console.log(newValue);
                        if(newValue === null){ 
                          setFieldValue('Id_Usuario', null)
                        }else {
                          setFieldValue('Id_Usuario', newValue.Id_Usuario)
                        }
                      }}                      
                      defaultValue={{Id_Usuario: values.Id_Usuario, Usuario: values.Usuario}}
                      disabled={stateForm.disabled}
                    />
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <Autocomplete
                      options={gestor}
                      getOptionLabel={(option) => option.Nome}
                      renderInput={(params) => <TextField {...params} label="Gestor" variant="outlined" />}
                      size="small"
                      style={{marginTop: 8}}
                      onChange={(event, newValue) => {
                        console.log(newValue);
                        if(newValue === null){ 
                          setFieldValue('Id_Gestor', null)
                        }else {
                          setFieldValue('Id_Gestor', newValue.Id_Funcionario)
                        }
                      }}                      
                      defaultValue={{Id_Funcionario: values.Id_Gestor, Nome: values.Nome_Gestor}}
                      disabled={stateForm.disabled}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              </div>
            }
            <Divider />
            <CardActions>
              {stateForm.action === 'Novo' ?
                <Link to={cancelarNovo} style={{ textDecoration: 'none' }}>
                  <Button variant="contained" >
                    CANCELAR
                  </Button>
                </Link>
              : null }

              {stateForm.disabled ? 
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onEditFuncionario}
                  disabled={!perfil.funcoes.update.includes('Funcionários')}
                >
                  EDITAR
                </Button>
              :
                <div>
                  {stateForm.action !== 'Novo' ?
                    <Button variant="contained" onClick={() => onCancelarEditar(handleReset)} style={{marginRight: 10}}>
                      CANCELAR
                    </Button>
                  : null }
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={stateForm.submitting}
                  > SALVAR
                    {stateForm.submitting ? 
                      <CircularProgress 
                      size={14}
                      color="secondary" />
                      : ''
                    }
                  </Button>
                </div>
              }
            </CardActions>
          </Form>
        </Card>
      )
    }}
    </Formik>
  );
};

FormFuncionario.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = store => ({ 
  perfil: store.Perfil,
})

export default connect(mapStateToProps, null) (withStyles(styles)(FormFuncionario));