import React, { useState } from 'react';
import { Paper, Typography, Button, Tooltip, IconButton, Card, CardHeader, ListSubheader,
  CardContent, Divider, CircularProgress, Grid, TextField, List, ListItem, ListItemText } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { getHolerite } from '../../../requests/holeriteRequest';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CST_ANO, CST_MES_NUMERO } from '../../../constants';

import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import { getFileURLFromS3 } from '../../../services/aws-s3';
import { CustomSnackBar } from '../../../components';
 
import { downloadFile } from '../../../services/fileSystem';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function Holerite({classes, idFuncionario, idUsuario}){
  
  const [loading, setLoading] = useState(false);
  const [loadingHolerite, setLoadingHolerite] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [listaHolerite, setListaHolerite] = useState([]);

  const [periodo, setPeriodo] = useState({
    Ano: CST_ANO[0].Ano,
    Mes: CST_MES_NUMERO[0].Nome,
    localizado: false
  });

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [goToPage, setGoToPage] = useState(1);

  const [file, setFile] = useState(null);
  const [arquivo, setArquivo] = useState(null);

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  });

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setLoading(false)
  }

  function previousPage() {
    setGoToPage(pageNumber - 1);
    changePage(-1);
  }

  function nextPage() {
    setGoToPage(pageNumber + 1);
    changePage(1);
  }

  function firstPage() {
    setPageNumber(1);
    setGoToPage(1);
  }

  function lastPage() {
    setPageNumber(numPages);
    setGoToPage(numPages);
  }

  function onGoToPage(key) {
    if(key === 'Enter'){
      if(Number.isInteger(goToPage)){
        if(goToPage > numPages){
          setPageNumber(numPages);
          setGoToPage(numPages);
        }else if(goToPage < 1){
          setPageNumber(1);
          setGoToPage(1);
        }else{
          setPageNumber(goToPage);
        }
      }else{
        setPageNumber(1);
        setGoToPage(1);
      }
    }
  }

  function changeGoToPage(pg){

    if(pg === ''){
      setGoToPage(pg);
    }    

    if(Number.isInteger(parseInt(pg))){
      setGoToPage(parseInt(pg));
    }
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  async function downloadHolerite(){
    setLoadingDownload(true);
    await downloadFile(arquivo.Id_Arquivo);
    setLoadingDownload(false);
  }

  async function carregarListaHolerite(){
    setLoading(true);

    const request = await getHolerite({params: {Ano: periodo.Ano, Mes: periodo.Mes, 'FUNCIONARIO.Id_Funcionario': idFuncionario, 'STATUS.Nome': 'Disponível'}});

    if(request.valid && request.data.length){
      setListaHolerite(request.data);
    }

    setLoading(false);
  }

  async function onLocalizarHolerite(idHolerite){
    setLoadingHolerite(true);
    //if(idFuncionario){
      //const request = await getHolerite({params: {Ano: periodo.Ano, Mes: periodo.Mes, 'FUNCIONARIO.Id_Funcionario': idFuncionario, 'STATUS.Nome': 'Disponível'}});
      const request = await getHolerite({params: {Id_Holerite: idHolerite, 'FUNCIONARIO.Id_Funcionario': idFuncionario}});

      if(request.valid && request.data.length){
        const arquivoPDF = request.data[0].arquivo;
        setArquivo(arquivoPDF);

        if(arquivoPDF.Extensao === 'pdf'){
          const s3FileFullPath = arquivoPDF.Url;
          const s3Url = `https://${process.env.REACT_APP_AWS_BUCKET}.s3.amazonaws.com/`;
          const s3FilePath = s3FileFullPath.slice(s3FileFullPath.indexOf(s3Url) + s3Url.length)
          
          const src = await getFileURLFromS3(s3FilePath);

          if(src !== null){
            setFile(src);
          }
        }

        setPeriodo({...periodo, localizado: true});
      }else{
        setAlert({display: true, severity: 'warning', text: 'Holerite não disponível'});
        setPeriodo({...periodo, localizado: false});
        setArquivo(null);
      }
    /*
    }else{
      setAlert({display: true, severity: 'error', text: 'Usuário não associado ao funcionário. Entre em contato com o RH!'});
    }
    */
    setLoadingHolerite(false);
  }  
  
  return(
    <div>
      <Card className={classes.card}>
        <CardHeader title="Meus Holerites"/>
        <Divider />
        <CardContent>
          <Grid container spacing={1} className={classes.modalFields} alignItems="center">
            <Grid item md={2} xs={2}>
              <TextField
                fullWidth
                label="Ano"
                margin="dense"
                name="Ano"
                onChange={(event) => setPeriodo({...periodo, [event.target.name]: event.target.value})}
                required
                select
                SelectProps={{ native: true }}
                value={periodo.Ano}
                variant="outlined"
                disabled={loading}
              >
                {CST_ANO.map(option => (
                  <option key={option.Ano} value={option.Ano}>
                    {option.Ano}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={2} xs={2}>
              <TextField
                fullWidth
                label="Mês"
                margin="dense"
                name="Mes"
                onChange={(event) => setPeriodo({...periodo, [event.target.name]: event.target.value})}
                required
                select
                SelectProps={{ native: true }}
                value={periodo.Mes}
                variant="outlined"
                disabled={loading}
              >
                {CST_MES_NUMERO.map(option => (
                  <option key={option.Id} value={option.Nome}>
                    {option.Nome}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={2} xs={2}>
              <Button variant="contained" color="primary" onClick={carregarListaHolerite} style={{marginTop: '4px'}}>LOCALIZAR</Button>
            </Grid>
          </Grid>
        </CardContent>

        <Divider />
        
        <CardContent style={{padding: '5px'}}>
          {loading ?
            <div style={{ position: 'relative', top: 10, left: '50%', height: 50}}>
              <CircularProgress size={20}/>
            </div>
          : listaHolerite.length === 0 ?
            <div>
              <Typography variant="body1">Selecione um período para pesquisar</Typography>
            </div>
          :
            <div>
              <Typography variant="body1" style={{fontWeight: 'bold'}}>Selecione um holerite</Typography>
              <List style={{width: '800px'}}>
                {
                  listaHolerite.map(hol => {
                    return(
                      <ListItem key={hol.Id_Holerite} dense style={{paddingTop: '2px', paddingBottom: '2px'}} button onClick={() => onLocalizarHolerite(hol.Id_Holerite)}>
                        <ListItemText primary={hol.Tipo_Holerite}/>
                        <ListItemText style={{left: '300px', position: 'absolute'}} primary={`${hol.Mes} [Ano ${hol.Ano}]`}/>
                      </ListItem>
                    )
                  })
                }
              </List>
            </div>
          }
        </CardContent>

        <Divider />

        <CardContent>

          {loadingHolerite ?
            <div style={{ position: 'relative', top: 10, left: '50%', height: 50}}>
              <CircularProgress size={20}/>
            </div>
          : periodo.localizado ?
            <div style={{textAlign: 'center'}}>

              <div style={{position: 'absolute'}}>
                {loadingDownload ?
                  <CircularProgress />
                :
                  <Tooltip title="Download">
                    <IconButton color="primary" onClick={downloadHolerite}>
                      <GetAppIcon fontSize="medium"/>
                    </IconButton>
                  </Tooltip>
                }
              </div>

              <div style={{display: 'flex', justifyContent: 'center'}}>
                <Paper style={{position: 'relative', width: '610px', height: '520px', overflowY: 'auto', overflowX: 'hidden'}}>
                  <Document
                    file={file}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={() => setLoading(false)}
                  >
                    <Page pageNumber={pageNumber}/>
                  </Document>
                </Paper>
              </div>
              
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5px'}}>
                <Button disabled={pageNumber <= 1} variant="outlined" size="small" onClick={firstPage} style={{marginRight: '5px'}} >
                  {'<<'}
                </Button>
                <Button disabled={pageNumber <= 1} variant="outlined" size="small" onClick={previousPage} style={{marginRight: '5px'}}>
                  {'<'}
                </Button>
                <TextField name="irParaPagina" value={goToPage} size="small" onChange={(e) => changeGoToPage(e.target.value)} style={{width: '20px', marginRight: '5px'}} onKeyPress={(e) => onGoToPage(e.key)} inputProps={{min: 0, style: { textAlign: 'center' }}}/>
                <Typography variant="body2" style={{marginRight: '5px'}}>de {numPages}</Typography>
                <Button disabled={pageNumber >= numPages} variant="outlined" size="small" onClick={nextPage} style={{marginRight: '5px'}}>
                  {'>'}
                </Button>
                <Button disabled={pageNumber >= numPages} variant="outlined" size="small" onClick={lastPage} style={{marginRight: '5px'}}>
                  {'>>'}
                </Button>
              </div>
            </div>
          :
            null
          }
        </CardContent>
      </Card>

      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setAlert({display: false, severity: '', text: ''})}
      />
    </div>
  )

}

const mapStateToProps = store => ({ 
  nome: store.Usuario.usuario.Nome,
  usuario: store.Usuario.usuario.Usuario,
  idUsuario: store.Usuario.usuario.Id_Usuario,
  idFuncionario: store.Usuario.usuario.Id_Funcionario
})

export default connect(mapStateToProps, null)(withRouter(withStyles(styles)(Holerite)));