import React, { useState, useEffect } from 'react';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    Card, CardHeader, CardContent, CardActions, Divider, Button, TablePagination, CircularProgress } from '@material-ui/core';
import { getAvaliacoes, postAvaliacao, putAvaliacao } from '../../requests/avaliacaoAction';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { CustomSnackBar, TablePaginationActions, StatusAvaliacao } from '../../components';
import { connect } from 'react-redux';
import { postResposta } from '../../requests/respostaAction';
import { postRespostaCriterio } from '../../requests/respostaCriterioAction';
import { getCriterioFormularios } from '../../requests/criterioFormularioAction';
import { withRouter } from 'react-router-dom';

function MinhasAvaliacoes({classes, idUsuario, history}){
  
  const [avaliacoes, setAvaliacoes] = useState([]);

  const [form, setForm] = useState({
    displayNovo: false,
    displayEditar: false,
    editarAvaliacao: {},
    confirmation: false,
  });

  const [loading, setLoading] = useState(false);
  const [loadingIniciar, setLoadingIniciar] = useState(false);

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  useEffect(() => { carregarAvaliacoes() }, []);

  async function carregarAvaliacoes(){
    setLoading(true);

    const response = await getAvaliacoes({Id_Usuario: idUsuario});
    if (response.valid) {
      setAvaliacoes(response.data);
    } else {
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setLoading(false);
  }

  function handlePageChange(event, newPage) {
    setPage(newPage);
  };

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function onEditarAvaliacao(avaliacao){
    const toEditarAvaliacao = { 
      pathname: 'minhasAvaliacoes/editar',
      avaliacao: avaliacao
    };
    
    return toEditarAvaliacao;
  }

  async function salvarNovoAvaliacao(avaliacao){

    const response = await postAvaliacao(avaliacao);

    if(response.valid){
      carregarAvaliacoes();
      setForm({...form, displayNovo: false});
      setAlert({display: true, severity: 'success', text: 'Avaliacao criado com sucesso!'});
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

  }

  async function onIniciarAvaliacao(avaliacao){
    setLoadingIniciar(true);

    const responseCriterio = await getCriterioFormularios({Id_Formulario: avaliacao.Id_Formulario});
    
    if (responseCriterio.valid) {
      
      const criterios = responseCriterio.data;

      const resposta = {Id_Avaliacao: avaliacao.Id_Avaliacao, Tipo: 1};

      const responseResposta = await postResposta(resposta);

      if(responseResposta.valid){
        const respostaCriterio = criterios.map((criterio) => {
          return {Id_Resposta: responseResposta.data.Id_Resposta, Id_Criterio: criterio.Id_Criterio}
        });
  
        const responseRespostaCriterio = await postRespostaCriterio({Resposta_Criterios: respostaCriterio});
  
        if(responseRespostaCriterio.valid){

          const responseAtualizarStatus = await putAvaliacao({Id_Avaliacao: avaliacao.Id_Avaliacao, Status: 'Auto Avaliação'});

          if (responseAtualizarStatus.valid) {
            return toRealizarAvaliacao(avaliacao);
          }else{
            setAlert({display: true, severity: 'error', text: responseAtualizarStatus.message});
          }
          
        }else{
          setAlert({display: true, severity: 'error', text: responseRespostaCriterio.message});
        }

      }else{
        setAlert({display: true, severity: 'error', text: responseResposta.message});
      }

    } else {
      setAlert({display: true, severity: 'error', text: responseCriterio.message});
    }
    
    setLoadingIniciar(false);
  }

  function onContinuarAvaliacao(avaliacao){
    const toIniciarAvaliacao = {
      pathname: '/minhasAvaliacoes/realizar',
      avaliacao: avaliacao
    };

    history.push(toIniciarAvaliacao);
  }

  function toRealizarAvaliacao(avaliacao){
    const toIniciarAvaliacao = {
      pathname: '/minhasAvaliacoes/realizar',
      avaliacao: avaliacao
    };

    history.push(toIniciarAvaliacao);
  }

  return(
    <div>
      <Card className={classes.card}>
        <CardHeader title="Minhas Avaliações"/>
        <Divider />
        {loading ?
          <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
            <CircularProgress />
          </div>
        :
          <CardContent>
            <TableContainer component={Paper} className={classes.table}>
              <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.header}>
                      <TableCell className={classes.tableHeader}>Periodo</TableCell>
                      <TableCell className={classes.tableHeader}>Gestor</TableCell>
                      <TableCell align="center" className={classes.tableHeader}>Status</TableCell>
                      <TableCell className={classes.tableHeader}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                    ? avaliacoes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : avaliacoes
                    ).map((row) => (
                      <TableRow key={row.Id_Avaliacao}>
                        <TableCell>Ano {row.Periodo.Ano} - {row.Periodo.Semestre}º Semestre</TableCell>
                        <TableCell>{row.Gestor}</TableCell>
                        <TableCell align="center">
                          <StatusAvaliacao status={row.Status} />
                        </TableCell>
                        <TableCell align="center">
                          {row.Status === 'Enviado' ?
                            <Button className={classes.rowButton} variant="contained" color="primary" disabled={loadingIniciar} onClick={() => onIniciarAvaliacao(row)}>
                              INICIAR
                            {loadingIniciar ? 
                              <CircularProgress 
                              size={14}
                              color="secondary" />
                              : ''
                            }
                            </Button>
                          : row.Status === 'Auto Avaliação' ?
                            <Button className={classes.rowButton} variant="contained" color="primary" onClick={() => onContinuarAvaliacao(row)}>CONTINUAR</Button>
                          : null }
                        </TableCell>
                      </TableRow>
                  ))}
                  </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        }
        <Divider/>
        <CardActions style={{height: 30}}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
            count={avaliacoes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
            style={{position: 'absolute', right: 10}}
          />
        </CardActions>
      </Card>
      
      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setAlert({display: false, severity: '', text: ''})}
      />
      
    </div>
  )

}

const mapStateToProps = store => ({ 
  nome: store.Usuario.usuario.Nome,
  usuario: store.Usuario.usuario.Usuario,
  idUsuario: store.Usuario.usuario.Id_Usuario
})

export default connect(mapStateToProps, null)(withRouter(withStyles(styles)(MinhasAvaliacoes)));