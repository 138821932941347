export const styles = theme => ({
  imgLogo: {
    height: 45,
    width: 150
  },
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
})