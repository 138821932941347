import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';
import ExportButton from '../../../../components/ExportButton';

function OportunidadesToolbar({classes, data, onFilterOportunidade, perfil}) {

  return (
    <Grid container direction="row" wrap="nowrap" justify="flex-end">
      <SearchInput
        className={classes.searchInput}
        placeholder="Localizar"
        onChange={onFilterOportunidade}
      />
      <span className={classes.spacer} />
      <Link to="/oportunidades/novo" style={{ textDecoration: 'none', marginRight: 5 }}>
        <Button
          color="primary"
          variant="contained"
          disabled={!perfil.funcoes.create.includes('Funcionários')}
        >
          Adicionar
        </Button>
      </Link>
      <ExportButton data={data} />
    </Grid>
  );
};

OportunidadesToolbar.propTypes = {
  onFilterOportunidade: PropTypes.func
};

const mapStateToProps = store => ({ 
  perfil: store.Perfil,
})

export default connect(mapStateToProps, null) (withStyles(styles)(OportunidadesToolbar));
