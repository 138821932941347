import api from '../services/api';
import { handleRequestError } from '../services/security';

export async function getFormularios( params ){

  let responseFromApi;

  try {
    const response = await api.get('/formularioAll', params);
  
    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    console.log(error);
    responseFromApi = {valid: false, status: error.response.status, message: error.response.data, data: []};
  }
  
  return responseFromApi;

}

export async function getFormulario( params ){

  let responseFromApi;

  try {
    const response = await api.get('/formulario', {params: params});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postFormulario( params ){
    
  let responseFromApi;

  try {
    const response = await api.post('/formulario', params);

    if (response.status === 200) {
        responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putFormulario( params ){
    
  let responseFromApi;

  try {
    const response = await api.put('/formulario', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}


export async function deleteFormulario( params ){
    
  let responseFromApi;

  try {
    const response = await api.delete('/formulario', params);

    if (response.status === 200) {
        responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}