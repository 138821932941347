import React, { useState, useEffect } from 'react';
import { CircularProgress, CardHeader, Grid, TextField, Button, Card, Divider, CardContent, CardActions, InputLabel, 
  RadioGroup, FormControlLabel, Radio, IconButton, Typography, AppBar, Tabs, Tab } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { CustomSnackBar, Confirmation, TabPanel, StatusAvaliacao } from '../../../../components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { getResposta } from '../../../../requests/respostaAction';
import { putRespostaCriterio } from '../../../../requests/respostaCriterioAction';
import { getReuniaoAvaliacao, postReuniaoAvaliacao, putReuniaoAvaliacao } from '../../../../requests/reuniaoAvaliacaoAction';
import { putAvaliacao } from '../../../../requests/avaliacaoAction';
import { useLocation } from "react-router-dom";

function VisualizarAvaliacao({classes, history}){

  const location = useLocation();
  
  const [reuniao, setReuniao] = useState({});
  const [respostas, setRespostas] = useState([]);
  const [respostasGestor, setRespostasGestor] = useState([]);

  const [tab, setTab] = useState(2)

  const [loading, setLoading] = useState(false);
  const [submitSave, setSubmitSave] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  useEffect(() => { carregarResposta() }, []);

  async function carregarResposta(){
    setLoading(true);

    const response = await getResposta({Id_Avaliacao: location.avaliacao.idAvaliacao, Tipo: 1});
    if (response.valid) {
      setRespostas(response.data);
    } else {
      setRespostas({});
    }

    const responseGestor = await getResposta({Id_Avaliacao: location.avaliacao.idAvaliacao, Tipo: 2});
    if (responseGestor.valid) {
      setRespostasGestor(responseGestor.data);
    } else {
      setRespostasGestor({});
    }

    const responseConclusao = await getReuniaoAvaliacao({Id_Avaliacao: location.avaliacao.idAvaliacao, Tipo: 2});
    if (responseConclusao.valid) {
      setReuniao(responseConclusao.data);
    } else {
      setReuniao({});
    }

    setLoading(false);
  }

  function returnToAvaliacoes(){
    history.push('/avaliacoes');
  }

  function handleChangeConclusao(event, index){
    let r = {...reuniao};
    r.Comentario = event.target.value;

    setReuniao(r);
  }

  async function onSalvarAvaliacao(){
    setSubmitSave(true);

    const response = await putReuniaoAvaliacao({Id_Reuniao_Avaliacao: reuniao.Id_Reuniao_Avaliacao, Comentario: reuniao.Comentario});
    
    if (response.valid) {
      setAlert({display: true, severity: 'success', text: 'Avaliação salva com sucesso!'});
      setTimeout(() => history.push('/avaliarFuncionario'), 1500);
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setSubmitSave(false);
  }

  async function onConcluirAvaliacao(){
    setConfirmation(false);
    setSubmit(true);

    const response = await putReuniaoAvaliacao({Id_Reuniao_Avaliacao: reuniao.Id_Reuniao_Avaliacao, Comentario: reuniao.Comentario});
    
    if (response.valid) {

      const responseAtualizarStatus = await putAvaliacao({Completar: {Id_Avaliacao: respostasGestor.Id_Avaliacao, Status: 'Completado'}});

      if (responseAtualizarStatus.valid) {
        setAlert({display: true, severity: 'success', text: 'Avaliação concluída com sucesso!'});
        setTimeout(() => history.push('/avaliarFuncionario'), 1500);
      }else{
        setAlert({display: true, severity: 'error', text: responseAtualizarStatus.message});
      }
      
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setSubmit(false);
  }

  return (
    <div>
      <CustomSnackBar display={alert.display} severity={alert.severity} text={alert.text} onClose={() => setAlert({display: false, severity: '', text: ''})}/>
      <Confirmation 
        title="Concluir Avaliaçao?"
        open={confirmation}
        setOpen={setConfirmation}
        onConfirm={onConcluirAvaliacao}
        >
          <Typography variant="subtitle2">Você tem certeza que deseja concluir essa avaliação?</Typography>
      </Confirmation>

      <Card>
        {loading ?
          <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
            <CircularProgress />
          </div>
        :
          <div>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={3} xs={6}>
                  <Typography variant="h3">Período Avaliado</Typography>
                  <Typography variant="subtitle2">Ano {location.avaliacao.ano} - {location.avaliacao.semestre}º Semestre</Typography>
                  <Typography variant="subtitle2">Gestor: {location.avaliacao.gestor}</Typography>
                  <Typography variant="h6">Funcionário: {location.avaliacao.funcionario}</Typography>
                  <StatusAvaliacao status={location.avaliacao.Status}/>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />

            <AppBar position="static">
              <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)} aria-label="funcionario-tabs">
                <Tab label="Funcionário" id="Funcionario" />
                <Tab label="Gestor" id="Gestor" />
                <Tab label="Conclusão" id="Conclusão" />
              </Tabs>
            </AppBar>
            <TabPanel value={tab} index={0}>

              <CardContent>
                
                {(respostas.RespostaCriterio === undefined ? [] : respostas.RespostaCriterio).map((item, index) => (
                  <Card className={classes.criterioBlock}>

                    <InputLabel className={classes.labelCriterio}>{index+1} - {item.Criterio.Criterio}</InputLabel>

                    {item.Criterio.Tipo === 1 || item.Criterio.Tipo === 3 ?
                      <RadioGroup name="nota" value={item.Nota} row className={classes.labelNota} disabled={true}>
                        <FormControlLabel value={1} control={<Radio />} label="Não satisfatório" />
                        <FormControlLabel value={2} control={<Radio />} label="Precisa melhorar" />
                        <FormControlLabel value={3} control={<Radio />} label="Atende às expectativas" />
                        <FormControlLabel value={4} control={<Radio />} label="Acima da expectativa" />                          
                      </RadioGroup>
                    : null}

                    {item.Criterio.Tipo === 2 || item.Criterio.Tipo === 3 ?  
                      <TextField
                        fullWidth
                        margin="dense"
                        name="Criterio"
                        variant="outlined"
                        value={item.Comentario}
                        multiline
                        disabled={true}
                        rows={4}
                      />
                    : null}
                  </Card>
                ))}
              </CardContent>
            </TabPanel>
            <TabPanel value={tab} index={1}>

              <CardContent>
                {(respostasGestor.RespostaCriterio === undefined ? [] : respostasGestor.RespostaCriterio).map((item, index) => (
                  <Card className={classes.criterioBlock}>

                    <InputLabel className={classes.labelCriterio}>{index+1} - {item.Criterio.Criterio}</InputLabel>

                    {item.Criterio.Tipo === 1 || item.Criterio.Tipo === 3 ?
                      <RadioGroup name="nota" value={item.Nota} row className={classes.labelNota} disabled={true}>
                        <FormControlLabel value={1} control={<Radio />} label="Não satisfatório" />
                        <FormControlLabel value={2} control={<Radio />} label="Precisa melhorar" />
                        <FormControlLabel value={3} control={<Radio />} label="Atende às expectativas" />
                        <FormControlLabel value={4} control={<Radio />} label="Acima da expectativa" />                          
                      </RadioGroup>
                    : null}

                    {item.Criterio.Tipo === 2 || item.Criterio.Tipo === 3 ?  
                      <TextField
                        fullWidth
                        margin="dense"
                        name="Criterio"
                        variant="outlined"
                        value={item.Comentario}
                        multiline
                        rows={4}
                        disabled={true}
                      />
                    : null}
                  </Card>
                ))}
              </CardContent>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <CardContent>
                <InputLabel className={classes.labelCriterio}>Conclusão</InputLabel>
                <TextField
                  fullWidth
                  margin="dense"
                  name="Conclusao"
                  variant="outlined"
                  value={reuniao.Comentario}
                  onChange={(event) => handleChangeConclusao(event)}
                  multiline
                  disabled={location.disabled}
                  rows={4}
                />
              </CardContent>
            </TabPanel>
          </div>
        }
        <Divider />
        <CardActions>
          <Button variant="contained" onClick={returnToAvaliacoes}>VOLTAR</Button>
          <Button variant="contained" color="primary" disabled={submitSave || location.disabled} onClick={onSalvarAvaliacao}>
            SALVAR
            {submitSave ? 
              <CircularProgress 
              size={14}
              color="secondary" />
              : ''
            }
          </Button>
        
          <Button variant="contained" color="primary" disabled={submit || location.disabled} onClick={() => setConfirmation(true)}>
            CONCLUIR
            {submit ? 
              <CircularProgress 
              size={14}
              color="secondary" />
              : ''
            }
          </Button>
        </CardActions>

      </Card>
    </div>
  )
}

export default withStyles(styles)(VisualizarAvaliacao);