import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { getCargos } from '../../requests/cargosRequest';
import { CustomSnackBar } from '../../components';
import { SearchInput } from '../../components';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  CircularProgress,
  Grid,
  Button
} from '@material-ui/core';
import TablePaginationActions from './TablePaginationActions';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { connect } from 'react-redux';
import ExportButton from '../../components/ExportButton';
import FilterButton from '../../components/FilterButton';
import { convertCurrencyBR, convertCurrencyUS, formatCurrencyDisplay } from '../../utils';

function Cargos({classes, perfil}) {

  const [dataTable, setDataTable] = useState([]);
  const [listaCargos, setListaCargos] = useState([]);
  const [filterConfig, setFilterConfig] = useState([]);

  const [loading, setLoading] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const [showAlert, setShowAlert] = useState({
    display: false,
    severity: '',
    text: ''
  });

  useEffect(() => { fetchDataForTable(); }, []);

  function handlePageChange(event, newPage){
    setPage(newPage);
  };

  function handleRowsPerPageChange(event){
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function fetchDataForTable() {
    setLoading(true);

     const response = await getCargos();

    if (response.valid){
      setDataTable(response.data);
      setListaCargos(response.data);
    }else{
      setShowAlert({
        display: true,
        severity: 'error',
        text: response.message
      });
    }

    let loadData = [];



   const resultData = await Promise.all(loadData);

    setFilterConfig([
       {field: 'Descricao', name: 'Descrição', type: 'text'},
       {field: 'Ativo', name: 'Ativo?', type: 'text'},
    ]);
    
    setLoading(false);
  }


  function exibirCargo(cargo){

    const toExibirCargo = { 
      pathname: 'cargos/exibir', 
      cargo: cargo
    };
    
    return toExibirCargo;

  }

  const handleCloseAlert = () => {
    setShowAlert({
        display: false,
        severity: '',
        text: ''
    });
  };

  function filtrarCargo(event){
    let dataValues = listaCargos.filter(func => func.Descricao.toLowerCase().includes(event.target.value.toLowerCase()));;
    setDataTable(dataValues);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataTable.length - page * rowsPerPage);

  return (
    
    <div className={classes.rootOuter}>
      { /* <CargosToolbar data={dataTable} onFilterCargo={filtrarCargo}/> */}
      <Grid container direction="row" wrap="nowrap" justify="flex-end">
        <SearchInput
          className={classes.searchInput}
          placeholder="Localizar"
          onChange={filtrarCargo}
        />
        <span className={classes.spacer} />
        <Link to="/cargos/novo" style={{ textDecoration: 'none', marginRight: 5 }}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            disabled={!perfil.funcoes.create.includes('Cargos')}
          >
            Adicionar
          </Button>
        </Link>
        <div style={{marginRight: 5}}>
          <FilterButton
            dataset={listaCargos}
            filterConfig={filterConfig}
            setFilteredData={setDataTable}
            width={'md'}
          />
        </div>
        <ExportButton data={dataTable} />
      </Grid>
      <div className={classes.contentOuter}>

        <Card className={clsx(classes.root)}>

          <CustomSnackBar display={showAlert.display} severity={showAlert.severity} text={showAlert.text} onClose={handleCloseAlert} />
          {loading ?
            <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
              <CircularProgress />
            </div>
          :
            <CardContent className={classes.content}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Cargo</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Cargo Ativo?</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? dataTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : dataTable
                    ).map(cargo => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={cargo.Id_Cargo}
                    >
                      <TableCell>
                      <Link to={exibirCargo(cargo)} style={{ textDecoration: 'none' }}>
                        <Typography variant="body1">{cargo.Descricao}</Typography>
                      </Link>
                      </TableCell>
                      <TableCell>{cargo.Valor !== null ? formatCurrencyDisplay(cargo.Valor) : '-'}</TableCell>
                      <TableCell>{cargo.Ativo === 0 ? "Não" : "Sim"}</TableCell>
                      
                      
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}

                </TableBody>
              </Table>
            </CardContent>
          }
          <CardActions className={classes.actions}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
              count={dataTable.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              ActionsComponent={TablePaginationActions}
              
            />
          </CardActions>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = store => ({ 
  perfil: store.Perfil,
})

export default connect(mapStateToProps, null) (withRouter((withStyles(styles)(Cargos))));