import React, {useState} from 'react';
import logo from '../../img/atomic-logo3.png';
import { postRecuperarSenha } from '../../requests/recuperarSenhaRequest';
import './RecuperarSenha.css';
import { withRouter } from 'react-router-dom';
import { CustomSnackBar } from '../../components';
import { Container, TextField, Link, Button, Typography } from '@material-ui/core';

function RecuperarSenha({history}) {

  const [email, setEmail] = useState('');
  const [emailEnviado, setEmailEnviado] = useState(false);
  const [emailNaoExiste, setEmailNaoExiste] = useState(false);

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  });

  const onSubmit = async (e) => {
    e.preventDefault()

    const response = await postRecuperarSenha(email);

    switch(response.status){
      case 200:
        setEmailNaoExiste(false);
        setEmailEnviado(true);
        setTimeout(() => history.push('/login'), 3000);
        break;
      case 404:
        setEmailNaoExiste(true);
        break;
      default:
        setEmailNaoExiste(false);
        setAlert({display: true, severity: 'error', text: 'Erro no servidor'});
    }

  }
  

  return (
    <div className="login">
      
      <CustomSnackBar display={alert.display} severity={alert.severity} text={alert.text} onClose={() => setAlert({display: false, severity: '', text: ''})}/>

      <div className="logo">
        <img src={logo} alt="Atomic Solutions" />
      </div><br />

      <form className="form-login" method="POST" onSubmit={onSubmit}>

        {!emailEnviado ?         

          <Container component="main" maxWidth="xs">

            <Typography variant="subtitle2">Digite seu e-mail. Um link será enviado para que você troque sua senha.</Typography>

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="E-mail"
              type="text"
              name="Email"
              value={email}
              onChange={(e) => { setEmail(e.target.value) }}
              autoFocus={true}
            />

            {emailNaoExiste && <Typography style={{color: '#FF0000'}} variant="subtitle2">O email digitado não está cadastrado!</Typography> }

            <Button
              style={{marginTop: 20}}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Enviar
            </Button>

          </Container>
        :
          <Container component="main" maxWidth="xs">
            <Typography variant="subtitle2">Aguarde! Em breve você receberá um e-mail com instruções para alterar sua senha!</Typography>
          </Container>
        }
        
      </form>

    </div>
  );
};


export default withRouter(RecuperarSenha);