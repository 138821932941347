import api from '../services/api';
import { handleRequestError } from '../services/security';

export async function getOportunidades(params){

  let responseFromApi;

  try {
    const response = await api.get('/oportunidade', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postOportunidade( params ){
    
  let responseFromApi;

  try {
    const response = await api.post('/oportunidade', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putOportunidade( params ){
    
  let responseFromApi;
  
  try {
    const response = await api.put('/oportunidade', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function deleteOportunidade( params ){
    
  let responseFromApi;

  try {
    const response = await api.delete('/oportunidade', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}