import React, { useState } from 'react';
import { Grid, Card, CardHeader, CardContent, Divider, Step, StepLabel, Stepper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { postAvaliacao } from '../../../../requests/avaliacaoAction';
import SelecionarFuncionarios from '../SelecionarFuncionarios/SelecionarFuncionarios';
import SelecionarFormulario from '../SelecionarFormulario';
import ResumoNovaAvaliacao from '../ResumoNovaAvaliacao';
import { withRouter } from 'react-router';
import { useLocation } from "react-router-dom";

function NovaAvaliacao({classes, history}){
  
  const location = useLocation();

  const [funcionarios, setFuncionarios] = useState([]);

  const [formulario, setFormulario] = useState();
  
  const [stepAtivo, setStepAtivo] = useState(0);

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  async function onSalvarAvaliacao(avaliacao){
    const response = await postAvaliacao({Avaliacao: avaliacao});

    if(response.valid){
      setAlert({display: true, severity: 'success', text: 'Avaliações criadas com sucesso!'});
      setTimeout(() => history.push('/avaliacoes'), 1500);
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }
  }
  
  return(
    <Card>
      <CardHeader title="Criar Avaliações"/>
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Stepper activeStep={stepAtivo}>
              <Step key="Funcionarios" >
                <StepLabel>Selecione os Funcionários</StepLabel>
              </Step>
              <Step key="Avaliacao" >
                <StepLabel>Selecione a Avaliação</StepLabel>
              </Step>
              <Step key="Criar" >
                <StepLabel>Revisar e Criar</StepLabel>
              </Step>
            </Stepper>
          </Grid>
          <Grid item md={12} xs={12}>
            {stepAtivo === 0 ?
              <SelecionarFuncionarios funcionarios={funcionarios} setFuncionarios={setFuncionarios} periodo={location.periodo} setStepAtivo={setStepAtivo}/>
            : stepAtivo === 1 ?
              <SelecionarFormulario formulario={formulario} setFormulario={setFormulario} setStepAtivo={setStepAtivo} />
            :
              <ResumoNovaAvaliacao idFuncionarios={funcionarios} idFormulario={formulario} setStepAtivo={setStepAtivo} onSalvarAvaliacao={onSalvarAvaliacao} periodo={location.periodo} />
            }
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )

}

export default withRouter(withStyles(styles)(NovaAvaliacao));