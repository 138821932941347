import React, { useState, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Divider, Drawer, List, ListItem, Button, Collapse, ListItemIcon, ListItemText } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import PeopleIcon from '@material-ui/icons/People';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import WorkIcon from '@material-ui/icons/Work';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import TextsmsIcon from '@material-ui/icons/Textsms';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DeckIcon from '@material-ui/icons/Deck';
import SpeedIcon from '@material-ui/icons/Speed';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { Profile } from './components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { connect } from 'react-redux';


function Sidebar({ classes, open, variant, onClose, className, perfil, nome, usuario, ...rest }) {

  const [openAcessos, setOpenAcessos] = useState(false);
  const [openGestaoAvaliacao, setOpenGestaoAvaliacao] = useState(false);
  const [openAvaliacao, setOpenAvaliacao] = useState(false);
  const [openReembolso, setOpenReembolso] = useState(false);
  const [openRH, setOpenRH] = useState(false);

  const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  ));

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root)}>
        <Profile />
        <Divider className={classes.divider} />
        <List>
          {perfil.funcoes.read.includes('Dashboard') ?
            <ListItem className={classes.item} disableGutters key="Dashboard">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to="/dashboard"
              >
                <DashboardIcon className={classes.icon} />
                Dashboard
              </Button>
            </ListItem>
            : null}

          {perfil.funcoes.read.includes('Funcionários') ?
            <ListItem className={classes.item} disableGutters key="Funcionarios">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to="/funcionarios"
              >
                <PeopleIcon className={classes.icon} />
                Funcionarios
              </Button>
            </ListItem>
            : null}
          {perfil.funcoes.read.includes('Funcionários') ?
            <ListItem className={classes.item} disableGutters key="Cargos">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to="/cargos"
              >
                <WorkIcon className={classes.icon} />
                Cargos
              </Button>
            </ListItem>
            : null}
          {perfil.funcoes.read.includes('Oportunidades') ?
            <ListItem className={classes.item} disableGutters key="Oportunidades">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to="/oportunidades"
              >
                <TrackChangesIcon className={classes.icon} />
                Oportunidades
              </Button>
            </ListItem>
            : null}

          {perfil.funcoes.read.includes('Clientes') &&
            <ListItem className={classes.item} disableGutters key="Cliente">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to="/cliente"
              >
                <LocationCityIcon className={classes.icon} />
                Cliente
              </Button>
            </ListItem>
          }

          {perfil.funcoes.read.includes('Ativos') ?
            <ListItem className={classes.item} disableGutters key="Ativos">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to="/ativos"
              >
                <WebAssetIcon className={classes.icon} />
                Ativos
              </Button>
            </ListItem>
            : null}
          {perfil.funcoes.read.includes('Extrato') ?
            <ListItem className={classes.item} disableGutters key="Extrato">
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to="/extratos"
              >
                <AccountBalanceWalletIcon className={classes.icon} />
                Extrato
              </Button>
            </ListItem>
            : null}

          {perfil.funcoes.read.includes('Geral') &&
            <>
              <ListItem button className={classes.button} onClick={() => setOpenRH(!openRH)}>
                <ListItemIcon>
                  <AccessibilityIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ style: { fontSize: 12 } }} primary="RH" style={{ marginLeft: -20 }} />
                {openRH ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openRH} timeout="auto" unmountOnExit>
                {
                  /*
                  perfil.funcoes.read.includes('SolicitarFerias') &&
                    <ListItem className={classes.colapsedItem} disableGutters key="Solicitar Ferias">
                      <Button
                        activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to="/rh/solicitarFerias"
                      >
                        <FlightTakeoffIcon className={classes.icon}/>
                        Solicitar Férias
                      </Button>
                    </ListItem>
                  */
                }
                {
                  /*
                  perfil.funcoes.read.includes('GerirFerias') &&
                    <ListItem className={classes.colapsedItem} disableGutters key="Gestão - Ferias">
                      <Button
                        activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to="/rh/gestaoFerias"
                      >
                        <DeckIcon className={classes.icon}/>
                        Gestão - Férias
                      </Button>
                    </ListItem>
                  */
                }
                {perfil.funcoes.read.includes('Geral') &&
                  <ListItem className={classes.colapsedItem} disableGutters key="Meus Holerites">
                    <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      to="/rh/holerite"
                    >
                      <ReceiptIcon className={classes.icon} />
                      Meus Holerites
                    </Button>
                  </ListItem>
                }
                {perfil.funcoes.read.includes('GerirHolerite') &&
                  <ListItem className={classes.colapsedItem} disableGutters key="Gestão Holerite">
                    <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      to="/rh/gestaoHolerite"
                    >
                      <ReceiptIcon className={classes.icon} />
                      Gestão Holerite
                    </Button>
                  </ListItem>
                }
                {perfil.funcoes.read.includes('Vagas') &&
                  <ListItem className={classes.colapsedItem} disableGutters key="Vagas">
                    <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      to="/rh/vagas"
                    >
                      <EmojiPeopleIcon className={classes.icon} />
                      Vagas
                    </Button>
                  </ListItem>
                }
              </Collapse>
            </>
          }

          {perfil.funcoes.read.includes('Geral') ?
            <>
              <ListItem button className={classes.button} onClick={() => setOpenReembolso(!openReembolso)}>
                <ListItemIcon>
                  <MonetizationOnIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ style: { fontSize: 12 } }} primary="Reembolsos" style={{ marginLeft: -20 }} />
                {openReembolso ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openReembolso} timeout="auto" unmountOnExit>
                <ListItem className={classes.colapsedItem} disableGutters key="Meus Reembolsos">
                  <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/reembolso/meusReembolsos"
                  >
                    <MonetizationOnIcon className={classes.icon} />
                    Meus Reembolsos
                  </Button>
                </ListItem>
                {(perfil.funcoes.read.includes('PagarReembolso') ||
                  perfil.funcoes.read.includes('AprovarReembolso')) &&
                  <ListItem className={classes.colapsedItem} disableGutters key="Aprovação Reembolsos">
                    <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      to="/reembolso/gestao"
                    >
                      <MonetizationOnIcon className={classes.icon} />
                      Gestão de Reembolsos
                    </Button>
                  </ListItem>
                }
              </Collapse>
            </>
            : null}
          {perfil.funcoes.read.includes('Usuários') ?
            <>
              <ListItem button className={classes.button} onClick={() => setOpenAcessos(!openAcessos)}>
                <ListItemIcon>
                  <HowToRegIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ style: { fontSize: 12 } }} primary="Acessos" style={{ marginLeft: -20 }} />
                {openAcessos ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openAcessos} timeout="auto" unmountOnExit>
                <ListItem className={classes.colapsedItem} disableGutters key="Usuários">
                  <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/usuarios"
                  >
                    <AssignmentIndIcon className={classes.icon} />
                    Usuários
                  </Button>
                </ListItem>
                <ListItem className={classes.colapsedItem} disableGutters key="Perfis">
                  <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/perfis"
                  >
                    <AssignmentIcon className={classes.icon} />
                    Perfis
                  </Button>
                </ListItem>
                <ListItem className={classes.colapsedItem} disableGutters key="Funcoes">
                  <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/funcoes"
                  >
                    <SettingsApplicationsIcon className={classes.icon} />
                    Funções
                  </Button>
                </ListItem>
              </Collapse>
            </>
            : null}

          {perfil.funcoes.read.includes('Geral') ?
            <>
              <ListItem button className={classes.button} onClick={() => setOpenAvaliacao(!openAvaliacao)}>
                <ListItemIcon>
                  <SpeedIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ style: { fontSize: 12 } }} primary="Avaliação" style={{ marginLeft: -20 }} />
                {openAvaliacao ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openAvaliacao} timeout="auto" unmountOnExit>
                <ListItem className={classes.colapsedItem} disableGutters key="Minhas Avaliações">
                  <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/minhasAvaliacoes"
                  >
                    <ThumbUpIcon className={classes.icon} />
                    Minhas Avaliações
                  </Button>
                </ListItem>
                {perfil.funcoes.read.includes('Gestor') ?
                  <ListItem className={classes.colapsedItem} disableGutters key="Avaliar Funcionário">
                    <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      to="/avaliarFuncionario"
                    >
                      <SupervisedUserCircleIcon className={classes.icon} />
                      Avaliar Funcionário
                    </Button>
                  </ListItem>
                  : null}
              </Collapse>
            </>
            : null}

          {perfil.funcoes.read.includes('Avaliação') ?
            <>
              <ListItem button className={classes.button} onClick={() => setOpenGestaoAvaliacao(!openGestaoAvaliacao)}>
                <ListItemIcon>
                  <ThumbsUpDownIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ style: { fontSize: 12 } }} primary="Gestão de Avaliação" style={{ marginLeft: -20 }} />
                {openGestaoAvaliacao ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openGestaoAvaliacao} timeout="auto" unmountOnExit>
                <ListItem className={classes.colapsedItem} disableGutters key="Avaliações">
                  <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/avaliacoes"
                  >
                    <ThumbsUpDownIcon className={classes.icon} />
                    Avaliações
                  </Button>
                </ListItem>
                <ListItem className={classes.colapsedItem} disableGutters key="Critérios">
                  <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/criterios"
                  >
                    <TextsmsIcon className={classes.icon} />
                    Critérios
                  </Button>
                </ListItem>
                <ListItem className={classes.colapsedItem} disableGutters key="Formulários">
                  <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/formularios"
                  >
                    <ImportContactsIcon className={classes.icon} />
                    Formulários
                  </Button>
                </ListItem>
              </Collapse>
            </>
            : null}
        </List>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

const mapStateToProps = store => ({
  perfil: store.Perfil,
})

export default connect(mapStateToProps, null)(withStyles(styles)(Sidebar));