import api from '../services/api';
import { handleRequestError } from '../services/security';

export async function getPeriodoAvaliacoes( params ){

  let responseFromApi;

  try {
    const response = await api.get('/periodoAvaliacaoAll', params);
  
    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    console.log(error);
    responseFromApi = {valid: false, status: error.response.status, message: error.response.data, data: []};
  }
  
  return responseFromApi;

}

export async function getPeriodoAvaliacao( params ){

  let responseFromApi;

  try {
    const response = await api.get('/periodoAvaliacao', { params: params });

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postPeriodoAvaliacao( params ){
    
  let responseFromApi;

  try {
    const response = await api.post('/periodoAvaliacao', params);

    if (response.status === 200) {
        responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putPeriodoAvaliacao( params ){
    
  let responseFromApi;

  try {
    const response = await api.put('/periodoAvaliacao', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}


export async function deletePeriodoAvaliacao( params ){
    
  let responseFromApi;

  try {
    const response = await api.delete('/periodoAvaliacao', params);

    if (response.status === 200) {
        responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}