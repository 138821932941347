export const styles = theme => ({
  
  buttonConfirm: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    backgroundColor: '#3F51B5',
    "&:hover": {
      backgroundColor: '#A3A9F7'
    },
  },
  buttonCancel: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    backgroundColor: '#F03036',
    "&:hover": {
      backgroundColor: '#F78689'
    },
  },
  dialog: {
    border: 1
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18
  }

})