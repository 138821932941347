import api from '../services/api';
import { handleRequestError } from '../services/security';

export async function listHolerite( params ){

  let responseFromApi;

  try {
    const response = await api.get('/holerite', {params: params});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function getHolerite( params ){

  let responseFromApi;

  try {
    const response = await api.get('/holerite', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postHolerite( params ){
    
  let responseFromApi;

  try {
    const response = await api.post('/holerite', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putHolerite( body ){
    
  let responseFromApi;

  try {
    const response = await api.put('/holerite', body);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}


export async function deleteHolerite( params ){
    
  let responseFromApi;

  try {
    const response = await api.delete('/holerite', {params: params});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}