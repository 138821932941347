import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { getOportunidades } from '../../requests/oportunidadesRequest';
import { CustomSnackBar } from '../../components';
import { OportunidadesToolbar } from './components';
import { SearchInput } from '../../components';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  CircularProgress,
  Grid,
  Button
} from '@material-ui/core';
import TablePaginationActions from './TablePaginationActions';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { connect } from 'react-redux';
import ExportButton from '../../components/ExportButton';
import FilterButton from '../../components/FilterButton';

function Oportunidades({ classes, perfil }) {

  const [dataTable, setDataTable] = useState([]);
  const [listaOportunidades, setListaOportunidades] = useState([]);
  const [filterConfig, setFilterConfig] = useState([]);

  const [loading, setLoading] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const [showAlert, setShowAlert] = useState({
    display: false,
    severity: '',
    text: ''
  });

  useEffect(() => { fetchDataForTable(); }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function fetchDataForTable() {
    setLoading(true);

    const response = await getOportunidades();

    if (response.valid) {
      setDataTable(response.data);
      setListaOportunidades(response.data);
    } else {
      setShowAlert({
        display: true,
        severity: 'error',
        text: response.message
      });
    }
    let loadData = [];

    // loadData.push(carregar());
    // loadData.push(carregarGenero());
    // loadData.push(carregarEstadoCivil());
    // loadData.push(carregarStatusFuncionario());
  
    const resultData = await Promise.all(loadData);
  
    setFilterConfig([
      {field: 'Descricao', name: 'Descrição', type: 'text'},
      // {field: 'Contratacao', name: 'Contratação', type: 'select', select: resultData[0]},
      // {field: 'Genero', name: 'Gênero', type: 'select', select: resultData[1]},
      // {field: 'Estado_Civil', name: 'Estado Civil', type: 'select', select: resultData[2]},
      // {field: 'Status', name: 'Status', type: 'select', select: resultData[3]},
      // {field: 'Email', name: 'Email', type: 'text'},
      // {field: 'Telefone', name: 'Telefone', type: 'text'},
      // {field: 'CPF', name: 'CPF', type: 'text'},
      // {field: 'CPF', name: 'CPF', type: 'text'},
      // {field: 'Nascimento', name: 'Nascimento', type: 'date'},
      // {field: 'Data_Admissao', name: 'Data de Admissão', type: 'date'},
      // {field: 'Data_Desligamento', name: 'Data de Desligamento', type: 'date'}
    ]);

    setLoading(false);
  }


 


  function exibirOportunidade(oportunidade) {

    const toExibirOportunidade = {
      pathname: 'oportunidades/exibir',
      oportunidade: oportunidade
    };

    return toExibirOportunidade;

  }

  const handleCloseAlert = () => {
    setShowAlert({
      display: false,
      severity: '',
      text: ''
    });
  };

  function filtrarOportunidade(event) {
    let dataValues = listaOportunidades.filter(func => func.Descricao.toLowerCase().includes(event.target.value.toLowerCase()));;
    setDataTable(dataValues);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataTable.length - page * rowsPerPage);

  return (

    <div className={classes.rootOuter}>
      <Grid container direction="row" wrap="nowrap" justify="flex-end">
        <SearchInput
          className={classes.searchInput}
          placeholder="Localizar"
          onChange={filtrarOportunidade}
        />
        <span className={classes.spacer} />
        <Link to="/oportunidades/novo" style={{ textDecoration: 'none', marginRight: 5 }}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            disabled={!perfil.funcoes.create.includes('Oportunidades')}
          >
            Adicionar
          </Button>
        </Link>
        <div style={{marginRight: 5}}>
          <FilterButton
            dataset={listaOportunidades}
            filterConfig={filterConfig}
            setFilteredData={setDataTable}
            width={'md'}
          />
        </div>
        <ExportButton data={dataTable} />
      </Grid>
      <div className={classes.contentOuter}>

        <Card className={clsx(classes.root)}>

          <CustomSnackBar display={showAlert.display} severity={showAlert.severity} text={showAlert.text} onClose={handleCloseAlert} />
          {loading ?
            <div style={{ position: 'relative', top: 150, left: '50%', height: 300 }}>
              <CircularProgress />
            </div>
            :
            <CardContent className={classes.content}>
              <Table>
                <TableHead>
                  <TableRow>
                  <TableCell>	Detalhes	</TableCell>
                    <TableCell>	Oportunidade	</TableCell>
                    <TableCell>	Criada por	</TableCell>
                    <TableCell>	Descrição	</TableCell>
                    <TableCell>	Data da Oportunidade	</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? dataTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : dataTable
                  ).map(oportunidade => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={oportunidade.Id_Oportunidade}
                    >
                      <TableCell>
                        <div className={classes.nameContainer}>
                          <Link to={exibirOportunidade(oportunidade)} style={{ textDecoration: 'none' }}>
                            <Avatar className={classes.avatar} src={oportunidade.avatarUrl}>
                              {'i'}
                            </Avatar>
                          </Link>
                          {/* <Link to={exibirOportunidade(oportunidade)} style={{ textDecoration: 'none' }}>
                            <Typography variant="body1">{oportunidade.id_Oportunidade}</Typography>
                          </Link> */}
                        </div>
                      </TableCell>
                      <TableCell>{oportunidade.Id_Oportunidade}</TableCell>
                      <TableCell>{oportunidade.UsuarioNome}</TableCell>
                      <TableCell>{oportunidade.Descricao}</TableCell>
                      <TableCell>{oportunidade.Data_Fechamento}</TableCell>
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}

                </TableBody>
              </Table>
            </CardContent>
          }
          <CardActions className={classes.actions}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
              count={dataTable.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              ActionsComponent={TablePaginationActions}

            />
          </CardActions>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = store => ({ 
  perfil: store.Perfil,
})

export default connect(mapStateToProps, null) (withRouter((withStyles(styles)(Oportunidades))));