import React, { useState, useEffect } from 'react';
import logo from '../../img/atomic-logo3.png';
import { getRecuperarSenha, putRecuperarSenha } from '../../requests/recuperarSenhaRequest';
import { putUsuario, getUsuarioByUsername } from '../../requests/usuarioRequest';
import './TrocarSenha.css';
import { withRouter, useParams } from 'react-router-dom';
import { CustomSnackBar } from '../../components';
import { Container, TextField, Link, Button, Typography, CircularProgress  } from '@material-ui/core';

function TrocarSenha({history}) {

  const { chave } = useParams();

  const [status, setStatus] = useState('aguarde');
  const [senhaNaoConfere, setSenhaNaoConfere] = useState(false);
  const [loading, setLoading] = useState(false);
  const [usuario, setUsuario] = useState({});

  const [form, setForm] = useState({
    NovaSenha: '',
    RepetirSenha: ''
  });

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  });

  useEffect(() => { validarTrocaSenha() }, []);

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  async function validarTrocaSenha(){

    await timeout(3000);

    let response;

    if(chave){
      console.log(chave);
      response = await getUsuarioByUsername(chave);
      
      if(response.status === 200){
        setStatus('trocaSolicitada');
      }else{
        response = await getRecuperarSenha(chave);

        if(response.status === 200){
          setStatus('recuperarSenha');
        }
      }

      if(response.status === 200){
        setUsuario(response.data);
      }else{
        switch(response.message){
          case 'Link expirado':
            setStatus('linkExpirado');
            setTimeout(() => history.push('/login'), 3000);
            break;
          case 'Link inválido':
            setStatus('linkInvalido');
            setTimeout(() => history.push('/login'), 3000);
            break;
          default:
            setStatus('erroServidor');
            setTimeout(() => history.push('/login'), 3000);
        }
      }

    }

  }

  function handleValueInput(fieldName, fieldValue) {
    setForm({ ...form, [fieldName]: fieldValue })
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    setLoading(true);

    setSenhaNaoConfere(false);

    if(form.NovaSenha !== form.RepetirSenha){
      setSenhaNaoConfere(true);
    }else{

      if(status === 'recuperarSenha'){
        const body = {Id_Usuario: usuario.Id_Usuario, Senha: form.NovaSenha, Chave: chave};
        const response = await putRecuperarSenha(body);

        if(response.status === 200){
          setAlert({display: true, severity: 'success', text: 'Senha alterada com sucesso!'});
          setTimeout(() => history.push('/login'), 3000);
        }else{
          switch(response.message){
            case 'Chave incorreta':
              setAlert({display: true, severity: 'error', text: 'Chave incorreta. Solicite novamente o e-mail para troca de senha'});
              break;
            case 'Chave revogada':
              setAlert({display: true, severity: 'error', text: 'Erro: Esse link já foi utilizado'});
              break;
            default:
              setAlert({display: true, severity: 'error', text: 'Erro no servidor'});
          }
  
          setLoading(false);
        }

      }else{
        const body = {Id_Usuario: usuario.Id_Usuario, Senha: form.NovaSenha, Trocar_Senha: 0};
        const response = await putUsuario(body);

        if(response.status === 200){
          setAlert({display: true, severity: 'success', text: 'Senha alterada com sucesso!'});
          setTimeout(() => history.push('/atomic'), 3000);
        }else{
          setAlert({display: true, severity: 'error', text: response.message});
          setLoading(false);
        }
      }      
      
    }
  }
  

  return (
    <>
      <CustomSnackBar display={alert.display} severity={alert.severity} text={alert.text} onClose={() => setAlert({display: false, severity: '', text: ''})}/>
      
      <div className="login">

        <div className="logo">
          <img src={logo} alt="Atomic Solutions" />
        </div><br />

        {status === 'aguarde' ?
          <Container className="contentCenter" component="main" maxWidth="xs">
            <Typography variant="h2">Aguarde...</Typography>
            <CircularProgress size={80}/>
          </Container>
        : status === 'recuperarSenha' || status === 'trocaSolicitada' ?

          <form className="form-login" method="POST" onSubmit={onSubmit}>
              
            <Container component="main" maxWidth="xs">

              <Typography variant="subtitle2">Digite a sua nova senha:</Typography>

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Nova Senha"
                type="password"
                name="NovaSenha"
                value={form.NovaSenha}
                onChange={(e) => { handleValueInput(e.target.name, e.target.value) }}
                autoFocus={true}
                disabled={loading}
              />

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Repetir Senha"
                type="password"
                name="RepetirSenha"
                value={form.RepetirSenha}
                onChange={(e) => { handleValueInput(e.target.name, e.target.value) }}
                autoFocus={false}
                disabled={loading}
              />

              {senhaNaoConfere && <Typography style={{color: '#FF0000'}} variant="subtitle2">As duas senhas digitadas não conferem</Typography> }

              <Button
                style={{marginTop: 20}}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Salvar
                {loading && <CircularProgress size={25}/>}
              </Button>

            </Container>

            
          </form>
        : status === 'linkExpirado' ?
          <Container className="contentCenter" component="main" maxWidth="xs">
            <Typography variant="subtitle2">Esse link é válido por apenas 15 minutos e foi expirado. Solicite a troca de senha novamente.</Typography>
          </Container>
        : status === 'linkInvalido' ?
          <Container className="contentCenter" component="main" maxWidth="xs">
            <Typography variant="subtitle2">Link inválido. Solicite a troca de senha novamente.</Typography>
          </Container>
        :
          <Container className="contentCenter" component="main" maxWidth="xs">
            <Typography variant="subtitle2">Erro no servidor. Abra o link novamente.</Typography>
          </Container>
        }

      </div>
    </>

  );
};


export default withRouter(TrocarSenha);