import React, { useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Badge, Hidden, IconButton, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPerfil } from '../../../../actions/perfilActions';
import { setUsuario } from '../../../../actions/usuarioAction';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

import logo from '../../../../img/atomic-logo.png';

function Topbar({classes, onSidebarOpen, setPerfil, setUsuario, history}) {

  const [notifications] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  function handleCloseMenu(){
    setAnchorEl(null);
  }

  function handleOpenMenu(event){
    setAnchorEl(event.currentTarget);
  }

  function logOut(){
    setAnchorEl(null);
    localStorage.removeItem('token');
    setPerfil({perfis: [], funcoes: {read: [], create: [], update: [], delete: []}});
    setUsuario({Id_Usuario: 0, Nome: '', Usuario: ''});
    history.push('/atomic');
  }

  function toMinhaConta(){
    setAnchorEl(null);
    history.push('/minhaconta');
  }

  return (
    <AppBar
      className={classes.root}
    >
      <Toolbar>
        <RouterLink to="/atomic">
          <img className="imgLogo" alt="Logo" src={logo}/>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge badgeContent={notifications.length} color="primary" variant="dot">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton className={classes.signOutButton} color="inherit">
            <SettingsIcon />
          </IconButton>
          <IconButton className={classes.signOutButton} color="inherit" onClick={handleOpenMenu}>
            <MenuIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            <MenuItem onClick={toMinhaConta}>Minha Conta</MenuItem>
            <MenuItem onClick={logOut}>Sair</MenuItem>
          </Menu>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func
};

const mapDispatchToProps = dispatch  => {
  return bindActionCreators({
    setPerfil: setPerfil,
    setUsuario: setUsuario
  }, 
  dispatch
  );
};

export default connect(null, mapDispatchToProps) (withRouter(withStyles(styles)(Topbar)));