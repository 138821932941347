import React, { useState, useEffect } from 'react';
import { CircularProgress, Paper, CardHeader, CardContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
  Card, Divider, IconButton, CardActions, TablePagination} from '@material-ui/core';
import { AtomicModal, TablePaginationActions, CustomSnackBar, StatusChip } from '../../../../components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { listCargaHolerite } from '../../../../requests/cargaHoleriteRequest';
import NotesIcon from '@material-ui/icons/Notes';

function HistoricoCarga({classes, display, onFechar, onSetLog, onExibirLog}){

  const [cargaHolerite, setCargaHolerite] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  });

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  useEffect(() => { carregarCargaHolerite() }, [])

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function carregarCargaHolerite(){
    setLoading(true);
    const response = await listCargaHolerite({});

    if(response.valid){
      setCargaHolerite(response.data);
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setLoading(false);
  }

  function openLog(log){
    onSetLog(log);
    onExibirLog();
  }
  

  return (
    <>
      <AtomicModal open={display} width={1000} height={400} handleClose={onFechar}>
        <Card>
          <CardHeader title="Histórico de Carga de Holerites" style={{textAlign: 'center'}}/>
          <Divider />
          {loading ?
            <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
              <CircularProgress />
            </div>
          :
            <CardContent>
              <TableContainer component={Paper} className={classes.table}>
                <Table className={classes.table} size="small">
                    <TableHead>
                      <TableRow className={classes.header}>
                        <TableCell className={classes.tableHeader} align="center">Usuário</TableCell>
                        <TableCell className={classes.tableHeader} align="center">Ano</TableCell>
                        <TableCell className={classes.tableHeader} align="center">Mês</TableCell>
                        <TableCell className={classes.tableHeader} align="center">Status</TableCell>
                        <TableCell className={classes.tableHeader} align="center">Qtde</TableCell>
                        <TableCell className={classes.tableHeader} align="center">Sucesso</TableCell>
                        <TableCell className={classes.tableHeader} align="center">Erro</TableCell>
                        <TableCell className={classes.tableHeader} align="center">Data/Hora</TableCell>
                        <TableCell className={classes.tableHeader} align="center">Log</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {(rowsPerPage > 0
                      ? cargaHolerite.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : cargaHolerite
                      ).map((row) => (
                        <TableRow key={row.Id_Carga_Holerite}>
                          <TableCell align="center">{row.Usuario}</TableCell>
                          <TableCell align="center">{row.Ano}</TableCell>
                          <TableCell align="center">{row.Mes}</TableCell>
                          <TableCell align="center">
                            <StatusChip status={row.Status} width="120"/>
                          </TableCell>
                          <TableCell align="center">{row.Quantidade}</TableCell>
                          <TableCell align="center">{row.Sucesso}</TableCell>
                          <TableCell align="center">{row.Erro}</TableCell>
                          <TableCell align="center">{row.Data_Hora}</TableCell>
                          <TableCell align="center">
                            <IconButton className={classes.button} onClick={() => openLog(row.Log)}>
                              <NotesIcon fontSize='small'/>
                            </IconButton>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          }
          
        </Card>
        <TablePagination
          rowsPerPageOptions={[5]}
          count={cargaHolerite.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          ActionsComponent={TablePaginationActions}
          style={{position: 'fixed', right: 10, bottom: 10}}
        />
      </AtomicModal>

      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setAlert({display: false, severity: '', text: ''})}
      />
    </>
  )
}

export default withStyles(styles)(HistoricoCarga);