import api from '../services/api';
import { handleRequestError } from '../services/security';

export async function getStatus( params ){
  
  let responseFromApi;

  try {
    const response = await api.get('/status', { params: params });

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postStatus( params ){
  
  let responseFromApi;

  try {
    const response = await api.post('/status', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putStatus( params ){
    
    let responseFromApi;
  
    try {
      const response = await api.put('/status', params);
  
      if (response.status === 200) {
        responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
      }
    } catch(error) {
      console.log(error);
      responseFromApi = {valid: false, status: error.response.status, message: error.response.data, data: {}};
    }
  
    return responseFromApi;
    
}


export async function deleteStatus( params ){
    
    let responseFromApi;
  
    try {
      const response = await api.delete('/status', params);
  
      if (response.status === 200) {
        responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
      }
    } catch(error) {
      console.log(error);
      responseFromApi = {valid: false, status: error.response.status, message: error.response.data, data: {}};
    }
  
    return responseFromApi;
    
}