import React, { useState, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { Grid, ButtonGroup, Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import ExportFields from './ExportFields';

import { getExcel } from '../../requests/excelRequest';


function ExportButton({classes, data}){

  const options = ['Exportar', 'Selecionar Campos'];

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const [fields, setFields] = useState([]);
  const [openFields, setOpenFields] = useState(false);

  function handleClick(){
    exportToExcel(data);
  };

  function handleMenuItemClick(event, index){
    
    if(index === 1){
      extractFieldsFromData();
      setOpenFields(true);
    }else{
      exportToExcel(data);
    }

    setOpen(false);
  };

  function handleToggle(){
    setOpen((prevOpen) => !prevOpen);
  };

  function handleClose(event){
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function extractFieldsFromData(){
    let columns = [];
    
    data.forEach((row) => {
      for (var column in row) {
        if(!columns.includes(column)){
          columns.push(column);
        }
      }
    });

    setFields(columns);
  }

  function onExportSelectedFieldsToExcel(selectedFields){
    
    let modifiedData = [];

    data.forEach((row) => {

      let item = {};

      for (var column in row) {
        if(selectedFields.includes(column)){
          item = {...item, [column]: row[column]}
        }
      }

      modifiedData.push(item);
    });
    
    exportToExcel(modifiedData);
  }

  async function exportToExcel(exportData){
    const response = await getExcel(exportData);

    if (response.valid){
      const url = window.URL.createObjectURL(new Blob([response.data]));
      
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Export_${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      
      window.URL.revokeObjectURL(url);
      link.remove();
    }else{
      console.log('falha');
    }

  }


  return (
    <div>
      <Grid item>
        <ButtonGroup size="small" variant="contained" color="primary" ref={anchorRef} >
          <Button onClick={handleClick} size="small">{options[0]}</Button>
          <Button
            color="primary"
            size="small"
            onClick={handleToggle}
            style={{paddingTop: 0, paddingBottom: 0}}
          >
            <ArrowDropDown size="small" />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === 0}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>

      <ExportFields display={openFields} fields={fields} onClose={() => setOpenFields(false)} onConfirm={onExportSelectedFieldsToExcel} />
    </div>
  )
}

export default withStyles(styles)(ExportButton);