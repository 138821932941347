import React, { useState, useEffect } from 'react';
import { Paper, CardHeader, Grid, TextField, Button, FormControlLabel, Checkbox, LinearProgress } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import { AtomicModal, ObservacaoConfirmar, StatusChip } from '../../../../../components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import moment from 'moment';

function EditarFerias({classes, display, onSalvar, onFechar, data}){

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');

  const [observacao, setObservacao] = useState({
    open: false,
    title: '',
    text: ''
  });
    
  function closeEditarFerias(){
    onFechar();
  }

  function cancelarAlterarStatus(){
    setObservacao({open: false, title: '', text: ''});
    setStatus('');
  }

  async function alterarStatus(status){
    setStatus(status);

    let title = '';
    let text = '';

    switch(status){
      case 'Aprovado':
        title = 'Confirmar Aprovação das Férias';
        text = 'Você confirma a aprovação das férias?';
        break;
      case 'Revisão':
        title = 'Confirmar Revisão das Férias';
        text = 'Você confirma o envio para revisão das férias?';
        break;
      case 'Cancelado':
        title = 'Confirmar Cancelamento das Férias';
        text = 'Você confirma o cancelamento das Férias?';
        break;
    }

    setObservacao({open: true, title: title, text: text});
  }

  async function salvarEditarFerias(observacao){
    setObservacao({open: false, title: '', text: ''});
    setLoading(true);
    await onSalvar(data.Id_Ferias, {Status: status, Observacao: observacao});
    onFechar();
    setStatus('');
    setLoading(false);
  }

  return (
    <AtomicModal open={display} width={'700px'} handleClose={closeEditarFerias}>
      <Paper className={classes.modal}>
        {loading && <LinearProgress />}
        <CardHeader title={`Solicitação de Férias #${data.Id_Ferias}`} style={{textAlign: 'center'}}/>
        
        <Grid container spacing={1} className={classes.modalFields}>

          <Grid item md={4} xs={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                margin="dense"
                id="periodo-aquisitivo-inicio"
                name="Periodo_Aquisitivo_Inicio"
                label="Periodo Aquisitivo - Início"
                inputVariant="outlined"
                format="DD/MM/YYYY"
                value={moment(data.Periodo_Aquisitivo_Inicio, 'DD-MM-YYYY')}
                disabled={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item md={4} xs={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                margin="dense"
                id="periodo-aquisitivo-fim"
                name="Periodo_Aquisitivo_Fim"
                label="Periodo Aquisitivo - Fim"
                inputVariant="outlined"
                format="DD/MM/YYYY"
                value={moment(data.Periodo_Aquisitivo_Fim, 'DD-MM-YYYY')}
                disabled={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid container md={4} xs={4} alignItems="center">
            <div>
              <StatusChip status={data.Status} width={130}/>
            </div>
          </Grid>
          
          <Grid item md={4} xs={4}>
            <div></div>
          </Grid>

          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              label="Funcionario"
              margin="dense"
              name="Funcionario"
              disabled={true}
              value={data.Funcionario}
              variant="outlined"
            />
          </Grid>
          
          <Grid item md={4} xs={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                clearable
                margin="dense"
                id="data-inicio"
                name="Data_Inicio"
                label="Data Início"
                inputVariant="outlined"
                format="DD/MM/YYYY"
                value={moment(data.Data_Inicio, 'DD-MM-YYYY')}
                disabled={true}                
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item md={4} xs={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                clearable
                margin="dense"
                id="data-fim"
                name="Data_Fim"
                label="Data Fim"
                inputVariant="outlined"
                format="DD/MM/YYYY"
                value={moment(data.Data_Fim, 'DD-MM-YYYY')}
                disabled={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item md={4} xs={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                margin="dense"
                id="data-retorno"
                name="Data_Retorno"
                label="Data Retorno"
                inputVariant="outlined"
                format="DD/MM/YYYY"
                value={moment(data.Data_Retorno, 'DD-MM-YYYY')}
                disabled={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item md={4} xs={4}>
            <TextField
              fullWidth
              label="Duração em dias"
              margin="dense"
              name="Duracao"
              disabled={true}
              value={`${data.Duracao} dias`}
              variant="outlined"
            />
          </Grid>
          <Grid item md={8} xs={8}>
            <FormControlLabel
              disabled={true}
              control={
                <Checkbox
                  checked={data.Abono_Pecuniario === 10 ? true : false}
                  name="Abono_Pecuniario"
                  color="primary"
                />
              }
              label={`Desejo "vender" 10 dias de férias a título de abono pecuniário.`}
            />
          </Grid>
        </Grid>
        
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Button variant="contained" onClick={closeEditarFerias} >
            FECHAR
          </Button>

          { data.Status === 'Solicitado' ?
          <>
            <Button
              color="primary"
              variant="contained"
              onClick={() => alterarStatus('Aprovado')}
              disabled={loading}
              className={classes.modalGreenButton}
            >
              APROVAR
            </Button>

            <Button
              color="primary"
              variant="contained"
              onClick={() => alterarStatus('Revisão')}
              disabled={loading}
              className={classes.modalOrangeButton}
              >
              REVISÃO
            </Button>

            <Button
              color="secondary"
              variant="contained"
              onClick={() => alterarStatus('Cancelado')}
              disabled={loading}
              style={{marginLeft: 10}}
              >
              CANCELAR
            </Button>
          </>
          : null }

        </div>
      </Paper>
      <ObservacaoConfirmar title={observacao.title} text={observacao.text} open={observacao.open} onClose={cancelarAlterarStatus} onConfirm={salvarEditarFerias} width={'sm'} />
    </AtomicModal>
  )
}

export default withStyles(styles)(EditarFerias);