import api from '../services/api';
import { handleRequestError } from '../services/security';

export async function getAlocacao( params ){

  let responseFromApi;

  try {
    const response = await api.get('/alocacao', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postAlocacao( params ){
    
  let responseFromApi;

  try {
    const response = await api.post('/alocacao', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putAlocacao( params ){
    
  let responseFromApi;

  try {
    const response = await api.put('/alocacao', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}


export async function deleteAlocacao( params ){
    
  let responseFromApi;

  try {
    const response = await api.delete('/alocacao', { params: params });

    if (response.status === 200) {
        responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}