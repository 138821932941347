import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { postOportunidade, putOportunidade, getOportunidades } from '../../requests/oportunidadesRequest';
import { getUsuarios } from '../../requests/usuarioRequest';
import { getContratacao } from '../../requests/contratacaoAction';
import { getGenero } from '../../requests/generoAction';
import { getEstadoCivil } from '../../requests/estadoCivilAction';
import { getStatus } from '../../requests/statusRequest';
import { CustomSnackBar } from '../../components';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  CircularProgress,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { TextMaskTelefone, TextMaskCPF, TextMaskCNPJ } from '../../masks';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { connect } from 'react-redux';

function FormOportunidade({ classes, disabled, acao, funcData, perfil, usuario }) {

  let history = useHistory();

  const [stateForm, setStateForm] = useState({
    submitting: false,
    disabled: disabled,
    action: acao,
    loading: true
  });

  const [showAlert, setShowAlert] = useState({
    display: false,
    severity: '',
    text: ''
  });

  const [usuarios, setUsuarios] = useState([]);
  const [contratacao, setContratacao] = useState([]);
  const [genero, setGenero] = useState([]);
  const [estadoCivil, setEstadoCivil] = useState([]);
  const [statusOportunidade, setStatusOportunidade] = useState([]);
  const [gestor, setGestor] = useState([]);

  useEffect(() => { carregarDados() }, []);

  async function carregarDados() {
    setStateForm({ ...stateForm, loading: true });
    // await carregarUsuarios();
    // await carregarContratacao();
    // await carregarGenero();
    // await carregarEstadoCivil();
    // await carregarStatusOportunidade();
    // await carregarGestor();
    setStateForm({ ...stateForm, loading: false });
  }

  async function carregarUsuarios() {
    const response = await getUsuarios();
    if (!response) return
    setUsuarios(response.data);
  }

  async function carregarContratacao() {
    const response = await getContratacao();
    if (!response) return
    setContratacao(response.data);
  }

  async function carregarGenero() {
    const response = await getGenero();
    if (!response) return
    setGenero(response.data);
  }

  async function carregarEstadoCivil() {
    const response = await getEstadoCivil();
    if (!response) return
    setEstadoCivil(response.data);
  }

  async function carregarStatusOportunidade() {
    const response = await getStatus({ Tipo: 'Oportunidade' });
    if (!response) return
    setStatusOportunidade(response.data);
  }

  async function carregarGestor() {
    const response = await getOportunidades();
    if (!response) return
    setGestor(response.data);
  }

  function onEditOportunidade() {
    setStateForm({ ...stateForm, disabled: false });
  }

  function handleCloseAlert() {
    setShowAlert({ display: false, severity: '', text: '' });
  };

  async function salvarOportunidade(values) {

    setStateForm({ ...stateForm, submitting: true });

    let response;
    let text;

    if (stateForm.action === 'Novo') {
      response = await postOportunidade(values);
    } else {
      response = await putOportunidade(values);
    }

    if (response.valid) {

      if (stateForm.action === 'Novo') {
        text = 'Novo oportunidade cadastrado com sucesso!';
        history.push('/oportunidades');
      } else {
        stateForm.disabled = true;
        stateForm.action = 'Exibir';

        text = 'Oportunidade alterado com sucesso!';
        setStateForm({ ...stateForm, disabled: true });
      }

      setShowAlert({ display: true, severity: 'success', text: text });

    } else {
      setShowAlert({ display: true, severity: 'error', text: response.message });
      setStateForm({ ...stateForm, submitting: false });
    }
  }

  function onCancelarEditar(handleReset) {
    handleReset();
    setStateForm({ action: 'Exibir', disabled: true });
  }

  function cancelarNovo() {

    const toOportunidades = {
      pathname: '/oportunidades'
    };

    return toOportunidades;

  }

  const schema = yup.object().shape({
    // Nome: yup.string().required('Campo Obrigatório'),
    // Registro: yup.string().required('Campo Obrigatório'),
    // Id_Contratacao: yup.number().required('Campo Obrigatório'),
    // Email: yup.string().email('Formato Inválido').required('Campo Obrigatório'),
    // Telefone: yup.string().min(14).required('Campo Obrigatório'),
    // CPF: yup.string().min(14).required('Campo Obrigatório'),
    // RG: yup.string().required('Campo Obrigatório'),
    // Nascimento: yup.date().required('Campo Obrigatório'),
    // Id_Genero: yup.number().required('Campo Obrigatório'),
    // Id_Estado_Civil: yup.number().required('Campo Obrigatório'),
    // Id_Endereco: yup.number().nullable().default(null),
    // Data_Admissao: yup.date().required('Campo Obrigatório'),
    // Data_Desligamento: yup.date().nullable().default(null),
    // Id_Status: yup.number().required('Campo Obrigatório')
  });

  return (
    <Formik initialValues={funcData} onSubmit={(values) => salvarOportunidade(values)} enableReinitialize={true} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          handleReset,
          setFieldValue
        } = props
        return (
          <Card className={classes.cardStyle}>
            <Form>

              <CustomSnackBar display={showAlert.display} severity={showAlert.severity} text={showAlert.text} onClose={handleCloseAlert} />

              <CardHeader title={stateForm.action + ' Oportunidade'} />
              <Divider />
              {stateForm.loading ?
                <div style={{ position: 'relative', top: 150, left: '50%', height: 300 }}>
                  <CircularProgress />
                </div>
                :
                <div>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item md={2} xs={12}>
                        <TextField
                          fullWidth
                          label="ID da Oportunidade"
                          margin="dense"
                          name="Id_Oportunidade"
                          onChange={handleChange}
                          value={values.Id_Oportunidade}
                          variant="outlined"
                          disabled={true}
                          helperText={errors.Id_Oportunidade && touched.Id_Oportunidade ? errors.Id_Oportunidade : ''}
                          error={errors.Id_Oportunidade && touched.Id_Oportunidade ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={6}
                      >
                        <TextField
                          fullWidth
                          label="Criado por"
                          margin="dense"
                          name="UsuarioNome"
                          //type="date"
                          onChange={handleChange}
                          value={values.UsuarioNome}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={true}
                          helperText={errors.UsuarioNome && touched.UsuarioNome ? errors.UsuarioNome : ''}
                          error={errors.UsuarioNome && touched.UsuarioNome ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid
                        item
                        md={3}
                        xs={6}
                      >
                        <TextField
                          fullWidth
                          label="Data"
                          margin="dense"
                          name="Data_Fechamento"
                          type="date"
                          onChange={handleChange}
                          value={values.Data_Fechamento}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={stateForm.disabled}
                          helperText={errors.Data_Fechamento && touched.Data_Fechamento ? errors.Data_Fechamento : ''}
                          error={errors.Data_Fechamento && touched.Data_Fechamento ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          minRows={4}
                          maxRows={8}
                          label="Descrição"
                          margin="dense"
                          name="Descricao"
                          onChange={handleChange}
                          value={values.Descricao}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.Descricao && touched.Descricao ? errors.Descricao : ''}
                          error={errors.Descricao && touched.Descricao ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>








                    </Grid>
                  </CardContent>

                  <CardContent>
                    <Divider />
                    <CardHeader title="Dados do Cliente" style={{ marginBotton: 20, marginTop: -10 }} />

                    <Grid container spacing={2}>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          label="Nome"
                          margin="dense"
                          name="ContatoNome"
                          onChange={handleChange}
                          value={values.ContatoNome}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.ContatoNome && touched.ContatoNome ? errors.ContatoNome : ''}
                          error={errors.ContatoNome && touched.ContatoNome ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Grid item md={3} xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          label="Tamanho"
                          margin="dense"
                          name="TamanhoDaEmpresa"
                          onChange={handleChange}
                          value={values.TamanhoDaEmpresa}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.TamanhoDaEmpresa && touched.TamanhoDaEmpresa ? errors.TamanhoDaEmpresa : ''}
                          error={errors.TamanhoDaEmpresa && touched.TamanhoDaEmpresa ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          label="E-mail"
                          margin="dense"
                          name="ContatoMail"
                          onChange={handleChange}
                          value={values.ContatoMail}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.ContatoMail && touched.ContatoMail ? errors.ContatoMail : ''}
                          error={errors.ContatoMail && touched.ContatoMail ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Grid item md={2} xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          label="Telefone"
                          margin="dense"
                          name="ContatoTelefone"
                          onChange={handleChange}
                          value={values.ContatoTelefone}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.ContatoTelefone && touched.ContatoTelefone ? errors.ContatoTelefone : ''}
                          error={errors.ContatoTelefone && touched.ContatoTelefone ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>

                    </Grid>
                  </CardContent>



                  <CardContent>
                    <Divider />
                    <CardHeader title="Sobre a Oportunidade" style={{ marginBotton: 20, marginTop: -10 }} />



                    <Grid container spacing={2}>


                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          label="Possuem a tecnologia ofertada?"
                          margin="dense"
                          name="PossuemTecnologiasOfertadas"
                          onChange={handleChange}
                          value={values.PossuemTecnologiasOfertadas}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.PossuemTecnologiasOfertadas && touched.PossuemTecnologiasOfertadas ? errors.PossuemTecnologiasOfertadas : ''}
                          error={errors.PossuemTecnologiasOfertadas && touched.PossuemTecnologiasOfertadas ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          label="Quais?"
                          margin="dense"
                          name="QuaisTecnologias"
                          onChange={handleChange}
                          value={values.QuaisTecnologias}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.QuaisTecnologias && touched.QuaisTecnologias ? errors.QuaisTecnologias : ''}
                          error={errors.QuaisTecnologias && touched.QuaisTecnologias ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          label="Data da Oportunidade"
                          margin="dense"
                          name="DataOportunidade"
                          onChange={handleChange}
                          value={values.QuaisTecnologias}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.DataOportunidade && touched.DataOportunidade ? errors.DataOportunidade : ''}
                          error={errors.DataOportunidade && touched.DataOportunidade ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label="Status"
                          margin="dense"
                          name="StatusOportunidade"
                          onChange={handleChange}
                          value={values.StatusOportunidade}
                          variant="outlined"
                          disabled={true}
                          helperText={errors.StatusOportunidade && touched.StatusOportunidade ? errors.StatusOportunidade : ''}
                          error={errors.StatusOportunidade && touched.StatusOportunidade ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>


                    </Grid>
                  </CardContent>


                </div>
              }
              <Divider />
              <CardActions>
                {stateForm.action === 'Novo' ?
                  <Link to={cancelarNovo} style={{ textDecoration: 'none' }}>
                    <Button variant="contained" >
                      CANCELAR
                    </Button>
                  </Link>
                  : null}

                {stateForm.disabled ?
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={onEditOportunidade}
                    disabled={!perfil.funcoes.update.includes('Oportunidades')}
                  >
                    EDITAR
                  </Button>
                  :
                  <div>
                    {stateForm.action !== 'Novo' ?
                      <Button variant="contained" onClick={() => onCancelarEditar(handleReset)} style={{ marginRight: 10 }}>
                        CANCELAR
                      </Button>
                      : null}
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={stateForm.submitting}
                    > SALVAR
                      {stateForm.submitting ?
                        <CircularProgress
                          size={14}
                          color="secondary" />
                        : ''
                      }
                    </Button>
                  </div>
                }
              </CardActions>
            </Form>
          </Card>
        )
      }}
    </Formik>
  );
};

FormOportunidade.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = store => ({
  perfil: store.Perfil,
  usuario: store.Usuario.usuario,
})

export default connect(mapStateToProps, null)(withStyles(styles)(FormOportunidade));