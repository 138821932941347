import React, { useState, useEffect } from 'react';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton,
    Card, CardHeader, CardContent, CardActions, Divider, Button, TablePagination, Link, CircularProgress } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { getFuncoes, postFuncao, putFuncao, deleteFuncao } from '../../requests/funcaoAction';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { CustomSnackBar, Confirmation, TablePaginationActions } from '../../components';
import NovaFuncao from './components/NovaFuncao';
import EditarFuncao from './components/EditarFuncao';


function Funcoes({classes}){
  
  const [funcoes, setFuncoes] = useState([]);

  const [form, setForm] = useState({
    displayNovo: false,
    displayEditar: false,
    editarFuncao: {},
    idFuncao: 0,
    confirmation: false,
  });

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  const [loading, setLoading] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  useEffect(() => { carregarFuncoes() }, []);

  async function carregarFuncoes(){
    setLoading(true);

    const response = await getFuncoes();
    if (response.valid) {
      setFuncoes(response.data);
    } else {
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setLoading(loading);
  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function salvarNovaFuncao(funcao){
    const response = await postFuncao(funcao);

    if(response.valid){
      setForm({...form, displayNovo: false});
      setAlert({display: true, severity: 'success', text: 'Função criada com sucesso!'})
      carregarFuncoes();
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }
  }

  async function salvarEditarFuncao(funcao){
    const response = await putFuncao(funcao);

    if(response.valid){
      setForm({...form, displayEditar: false});
      setAlert({display: true, severity: 'success', text: 'Função alterada com sucesso!'})
      carregarFuncoes();
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }
  }

  async function onDeletarFuncao(){
    const response = await deleteFuncao({Id_Funcao: form.idFuncao});

    if(response.valid){
      carregarFuncoes();
      setAlert({display: true, severity: 'success', text: 'Função apagada com sucesso!',})
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setForm({...form, confirmation: false});
  }

  function openDialog(idFuncao, openConfirm) {
    setForm({...form, confirmation: openConfirm, idFuncao: idFuncao});
  }

  return(
    <div>
      <Card className={classes.card}>
        <CardHeader title="Funções"/>
        <Divider />
        {form.loading ?
          <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
            <CircularProgress />
          </div>
        :
          <CardContent>
            <TableContainer component={Paper} className={classes.table}>
            <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow className={classes.header}>
                    <TableCell className={classes.tableHeader}>Nome</TableCell>
                    <TableCell className={classes.tableHeader} align="center">Descrição</TableCell>
                    <TableCell className={classes.tableHeader} align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {(rowsPerPage > 0
                  ? funcoes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : funcoes
                  ).map((row) => (
                    <TableRow key={row.Id_Funcao}>
                      <TableCell>
                        <Link className={classes.TableCellLink} onClick={() => setForm({...form, editarFuncao: row, displayEditar: true})}>{row.Nome}</Link>
                      </TableCell>
                      <TableCell align="center">{row.Descricao}</TableCell>
                      <TableCell align="center">
                        <IconButton className={classes.button} onClick={() => openDialog(row.Id_Funcao, true)}>
                          <Delete fontSize='small'/>
                        </IconButton>
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
          </CardContent>
        }
        <Divider/>
        <CardActions>
          <Button variant="contained" color="primary" onClick={() => setForm({...form, displayNovo: true})}>NOVO</Button>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
            count={funcoes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
            style={{position: 'absolute', right: 10}}
          />
        </CardActions>
      </Card>
      
      <NovaFuncao display={form.displayNovo} onSalvar={salvarNovaFuncao} onFechar={() => setForm({...form, displayNovo: false})}/>
      <EditarFuncao display={form.displayEditar} data={form.editarFuncao} onSalvar={salvarEditarFuncao} onFechar={() => setForm({...form, displayEditar: false})}/>

      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setAlert({display: false, severity: '', text: ''})}
      />
      <Confirmation 
        title="Apagar função?"
        open={form.confirmation}
        setOpen={openDialog}
        onConfirm={onDeletarFuncao}
        >
          Você tem certeza que deseja apagar essa função?
      </Confirmation>
      
    </div>
  )

}

export default withStyles(styles)(Funcoes);