import React, { useState } from 'react';
import { CircularProgress, Paper, CardHeader, Grid, TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { AtomicModal } from '../../../../components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import * as yup from 'yup';
import { Formik, Form } from 'formik';

function NovoUsuario({classes, display, onSalvar, onFechar}){

  const initialValues = {
    Nome: '',
    Usuario: '',
    Senha: '',
    Email: '',
    Confirmar_Senha: '',
    Trocar_Senha: 0
  };
  
  const [loading, setLoading] = useState(false)

  function closeNovoUsuario(handleReset){
    handleReset();  
    onFechar();
  }

  function salvarUsuario(usuario, resetForm){
    setLoading(true);
    onSalvar(usuario);
    resetForm(initialValues);
    setLoading(false);
  }
  
  const schema = yup.object().shape({
    Nome: yup.string().required('Campo Obrigatório'),
    Usuario: yup.string().required('Campo Obrigatório'),
    Email: yup.string().email('Formato de email de inválido').required('Campo Obrigatório'),
    Senha: yup.string().required('Campo Obrigatório'),
    Confirmar_Senha: yup.string().oneOf([yup.ref('Senha'), null], 'Senhas não conferem'),
    Trocar_Senha: yup.number().required()
  })

  return (
    <Formik initialValues={initialValues} onSubmit={(values, { resetForm }) => salvarUsuario(values, resetForm)} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleReset,
        setFieldValue
      } = props
      return (
        <AtomicModal open={display} width={300} handleClose={() => closeNovoUsuario()}>
          <Form>
          <Paper className={classes.modalNew}>
            <CardHeader title="Novo Usuário" style={{textAlign: 'center'}}/>
              <Grid container spacing={1} className={classes.modalFields}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Nome Completo"
                    margin="dense"
                    name="Nome"
                    onChange={handleChange}
                    value={values.Nome}
                    variant="outlined"
                    helperText={errors.Nome && touched.Nome ? errors.Nome : ''}
                    error={errors.Nome && touched.Nome ? true : false}
                    onBlur={handleBlur}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Usuario"
                    margin="dense"
                    name="Usuario"
                    onChange={handleChange}
                    value={values.Usuario}
                    variant="outlined"
                    helperText={errors.Usuario && touched.Usuario ? errors.Usuario : ''}
                    error={errors.Usuario && touched.Usuario ? true : false}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    margin="dense"
                    name="Email"
                    onChange={handleChange}
                    value={values.Email}
                    variant="outlined"
                    helperText={errors.Email && touched.Email ? errors.Email : ''}
                    error={errors.Email && touched.Email ? true : false}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Senha"
                    margin="dense"
                    name="Senha"
                    onChange={handleChange}
                    value={values.Senha}
                    variant="outlined"
                    type="password"
                    helperText={errors.Senha && touched.Senha ? errors.Senha : ''}
                    error={errors.Senha && touched.Senha ? true : false}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Confirmar Senha"
                    margin="dense"
                    name="Confirmar_Senha"
                    onChange={handleChange}
                    value={values.Confirmar_Senha}
                    variant="outlined"
                    type="password"
                    helperText={errors.Confirmar_Senha && touched.Confirmar_Senha ? errors.Confirmar_Senha : ''}
                    error={errors.Confirmar_Senha && touched.Confirmar_Senha ? true : false}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.Trocar_Senha === 1 ? true : false}
                        onChange={(event, newValue) => {
                          if(newValue){ 
                            setFieldValue('Trocar_Senha', 1);
                          }else {
                            setFieldValue('Trocar_Senha', 0);
                          }
                        }}
                        color="primary"
                      />
                    }
                    label="Trocar Senha"
                  />
                </Grid>
              </Grid>
              
              <Button variant="contained" onClick={() => closeNovoUsuario(handleReset)} >
                CANCELAR
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading}
                className={classes.modalSaveButton}
              >
                SALVAR
                {loading ? 
                  <CircularProgress 
                  size={14}
                  color="secondary" />
                  : ''
                }
              </Button>
            </Paper>
            </Form>
          </AtomicModal>
        )
      }}
    </Formik>
  )
}

export default withStyles(styles)(NovoUsuario);