import React, { useState } from 'react';
import { Paper, CardHeader, Grid, TextField, Button, Typography, FormControlLabel, Checkbox, LinearProgress } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import { AtomicModal, Confirmation } from '../../../../../components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import moment from 'moment';


function NovoSolicitarFerias({classes, display, onSalvar, onFechar, periodo, histFerias}){

  const [ferias, setFerias] = useState({
    Data_Inicio: null,
    Data_Fim: null,
    Data_Retorno: null,
    Abono_Pecuniario: 0,
    Erro_Data_Inicio: null,
    Erro_Data_Fim: null,
    Erro_Submeter: null
  });

  const [confirmation, setConfirmation] = useState({
    open: false,
    confirm: false,
    title: '',
    text: '',
    button: null
  });
  
  const [agendado, setAgendado] = useState(0);
  const [loading, setLoading] = useState(false);
  
  function closeNovoFerias(){
    setFerias({
      Data_Inicio: null,
      Data_Fim: null,
      Data_Retorno: null,
      Abono_Pecuniario: 0,
      Erro_Data_Inicio: null,
      Erro_Data_Fim: null,
      Erro_Submeter: null
    });

    setAgendado(0);

    onFechar();
  }

  function validarSalvarFerias(){
    setLoading(true);
    let valid = true;

    if(!ferias.Data_Inicio){
      setFerias({...ferias, Erro_Data_Inicio: 'Campo obrigatório'});
      valid = false;
      setLoading(false);
    }

    if(valid && !ferias.Data_Fim){
      setFerias({...ferias, Erro_Data_Fim: 'Campo obrigatório'});
      valid = false;
      setLoading(false);
    }

    const periodos = periodo.Periodos_Completados + periodo.Periodos_Agendados;

    if(valid && periodo.Mais14 === false){
      if(periodos === 2 && agendado < 14){
        setFerias({...ferias, Erro_Submeter: 'Você precisa usar todo o saldo de férias restante'});
        setLoading(false);
        valid = false;
      }

      if(periodos === 1){
        if(agendado < 14){
          if(periodo.Saldo - periodo.Agendado + periodo.Pecuniario + periodo.Desfrutado + agendado < 14){
            setFerias({...ferias, Erro_Submeter: 'Esse período precisa ter pelo menos 14 dias'});
            setLoading(false);
            valid = false;
          }
        }
      }
    }else{
      if(periodos === 2 && periodo.Saldo - agendado > 0){
        setFerias({...ferias, Erro_Submeter: 'Você precisa usar todo o saldo de férias restante'});
        setLoading(false);
        valid = false;
      }
    }

    if(valid && (periodo.Saldo - agendado < 5 && periodo.Saldo - agendado !== 0)){
      setFerias({...ferias, Erro_Submeter: 'Você não pode manter um saldo menor do que 5 dias'});
      setLoading(false);
      valid = false;
    }

    /*
    if(valid && (periodo.Pecuniario === 0 && ferias.Abono_Pecuniario === 0 && ((periodo.Mais14 === true && periodo.Saldo - agendado < 15) || (periodo.Mais14 === false &&  periodo.Saldo === 30)){
      setConfirmation({open: true, confirm: false, button: null, title: 'Abono Pecuniário', text: 'Devido ao período selecionado, não será possível vender 10 dias de férias. Deseja continuar?'});
      valid = false;
    }
    */

    if(valid && (periodo.Pecuniario === 0 && ferias.Abono_Pecuniario === 0)){
      if(periodos < 2){
        if((agendado >= 14 && (periodo.Saldo - agendado < 15)) || (agendado < 14 && (periodo.Saldo - agendado < 24))){
          setConfirmation({open: true, confirm: false, button: null, title: 'Abono Pecuniário', text: 'Devido ao período selecionado, não será possível vender 10 dias de férias. Deseja continuar?'});
          valid = false;
        }
      }
    }

    if(valid){
      for(let i = 0; i < histFerias.length; i++){
        if(histFerias[i]['Status'] !== 'Cancelado'){
          if(moment(ferias.Data_Inicio, 'DD-MM-YYYY').isBetween(moment(histFerias[i]['Data_Inicio'], 'DD-MM-YYYY'), moment(histFerias[i]['Data_Fim'], 'DD-MM-YYYY'))){
            setConfirmation({open: true, confirm: false, button: 'OK', title: 'Conflito', text: 'As suas férias iniciam dentro do periodo de outras férias já solicitadas. Por favor, ajuste o período desejado.'});
            valid = false;
            setLoading(false);
            break;
          }

          if(moment(ferias.Data_Fim, 'DD-MM-YYYY').isBetween(moment(histFerias[i]['Data_Inicio'], 'DD-MM-YYYY'), moment(histFerias[i]['Data_Fim'], 'DD-MM-YYYY'))){
            setConfirmation({open: true, confirm: false, button: 'OK', title: 'Conflito', text: 'As suas férias terminam dentro do periodo de outras férias já solicitadas. Por favor, ajuste o período desejado.'});
            valid = false;
            setLoading(false);
            break;
          }
        }
      }
    }

    if(valid){
      salvarFerias();
    }
    
  }


  async function salvarFerias(){
    setConfirmation({
      open: false,
      confirm: false,
      title: '',
      text: '',
      button: null
    })

    let dadosFerias = {
      Periodo_Aquisitivo_Inicio: moment(periodo.Periodo_Aquisitivo.Inicio, 'DD-MM-YYYY'),
      Periodo_Aquisitivo_Fim: moment(periodo.Periodo_Aquisitivo.Fim, 'DD-MM-YYYY'),
      Data_Inicio: ferias.Data_Inicio,
      Data_Fim: ferias.Data_Fim,
      Data_Retorno: ferias.Data_Retorno,
      Duracao: ferias.Data_Fim.diff(ferias.Data_Inicio, 'days') + 1,
      Abono_Pecuniario: ferias.Abono_Pecuniario
    }

    setAgendado(0);

    await onSalvar(dadosFerias);

    setFerias({
      Data_Inicio: null,
      Data_Fim: null,
      Data_Retorno: null,
      Abono_Pecuniario: 0,
      Erro_Data_Inicio: null,
      Erro_Data_Fim: null,
      Erro_Submeter: null
    });

    setLoading(false);
  }


  function handleDataInicio(value){

    if(value){
      if(['Saturday', 'Sunday'].includes(value.format('dddd'))){
        setFerias({...ferias, Data_Inicio: null, Erro_Data_Inicio: 'As férias não podem iniciar em final de semana'});
        return;
      }
    }
    
    setFerias({...ferias, Data_Inicio: value, Data_Fim: null, Data_Retorno: null, Erro_Data_Inicio: null});
    
  }

  function handleDataFim(value){

    let dataRetorno = null;

    if(value){
      dataRetorno = value.clone();

      for(let i = 1; i < 3; i++){
        dataRetorno.add(1, 'd');
        if(!['Saturday', 'Sunday'].includes(dataRetorno.format('dddd'))){
          break;
        }
      }
    }
      
    setFerias({...ferias, Data_Fim: value, Data_Retorno: dataRetorno, Erro_Data_Fim: null, Erro_Submeter: null});
    setAgendado(value.diff(ferias.Data_Inicio, 'days') + 1);

  }

  function closeConfirmation(){
    setConfirmation({...confirmation, open: false});
    setLoading(false);
  }

  
  return (
    <AtomicModal open={display} width={'800px'} handleClose={closeNovoFerias}>
      <Paper className={classes.modal}>
        {loading && <LinearProgress />}
        <CardHeader title="Nova Solicitação de Férias" style={{textAlign: 'center'}} titleTypographyProps={{variant: 'h3'}}/>
        
        <Grid container spacing={1} className={classes.modalFields}>

          <Grid item md={3} xs={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                margin="dense"
                id="periodo-aquisitivo-inicio"
                name="Periodo_Aquisitivo_Inicio"
                label="Periodo Aquisitivo - Início"
                inputVariant="outlined"
                format="DD/MM/YYYY"
                value={moment(periodo.Periodo_Aquisitivo.Inicio, 'DD-MM-YYYY')}
                disabled={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item md={3} xs={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                margin="dense"
                id="periodo-aquisitivo-fim"
                name="Periodo_Aquisitivo_Fim"
                label="Periodo Aquisitivo - Fim"
                inputVariant="outlined"
                format="DD/MM/YYYY"
                value={moment(periodo.Periodo_Aquisitivo.Fim, 'DD-MM-YYYY')}
                disabled={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item md={3} xs={3}>
            <TextField
              fullWidth
              label="Saldo Restante"
              margin="dense"
              name="Saldo"
              disabled={true}
              value={`${periodo.Saldo - agendado - ferias.Abono_Pecuniario} dias`}
              variant="outlined"
            />
          </Grid>

          <Grid item md={3} xs={3}>
            <div></div>
          </Grid>

          <Grid item md={3} xs={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                clearable
                margin="dense"
                id="data-inicio"
                name="Data_Inicio"
                label="Data Início"
                inputVariant="outlined"
                format="DD/MM/YYYY"
                value={ferias.Data_Inicio}
                minDate={
                  moment().diff(moment(periodo.Periodo_Aquisitivo.Fim, 'DD-MM-YYYY').add(1, 'd')) > 0 ?
                  moment() :
                  moment(periodo.Periodo_Aquisitivo.Fim, 'DD-MM-YYYY').add(1, 'd')
                }
                maxDate={moment().add(1, 'y')}
                onChange={value => handleDataInicio(value)}
                disabled={loading}
              />
              <div style={{fontFamily: 'Arial', fontSize: 11, color: 'red'}}>{ferias.Erro_Data_Inicio}</div>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item md={3} xs={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                clearable
                margin="dense"
                id="data-fim"
                name="Data_Fim"
                label="Data Fim"
                inputVariant="outlined"
                format="DD/MM/YYYY"
                value={ferias.Data_Fim}
                minDate={ferias.Data_Inicio !== null ? moment(ferias.Data_Inicio).add(4, 'd') : null}
                maxDate={ferias.Data_Inicio !== null ? moment(ferias.Data_Inicio).add(periodo.Saldo - ferias.Abono_Pecuniario - 1, 'd') : null}
                onChange={value => handleDataFim(value)}
                disabled={ferias.Data_Inicio === null || loading}
              />
              <div style={{fontFamily: 'Arial', fontSize: 11, color: 'red'}}>{ferias.Erro_Data_Fim}</div>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item md={3} xs={3}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                margin="dense"
                id="data-retorno"
                name="Data_Retorno"
                label="Data Retorno"
                inputVariant="outlined"
                format="DD/MM/YYYY"
                value={ferias.Data_Retorno}
                disabled={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item md={3} xs={3}>
            <TextField
              fullWidth
              label="Duração em dias"
              margin="dense"
              name="Duracao"
              disabled={true}
              value={ ferias.Data_Fim !== null ? `${agendado} dias` : '0 dias'}
              variant="outlined"
            />
          </Grid>

          <Grid item md={12} xs={12}>
            {periodo.Pecuniario === 0 && (periodo.Saldo > 14 ? true : false) &&
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ferias.Abono_Pecuniario}
                    onChange={(event, newValue) => setFerias({...ferias, Abono_Pecuniario: newValue ? 10 : 0})}
                    name="Abono_Pecuniario"
                    color="primary"
                  />
                }
                label={`Desejo "vender" 10 dias de férias a título de abono pecuniário.`}
                disabled={loading}
              />
            }
          </Grid>
        </Grid>
        
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Button variant="contained" onClick={closeNovoFerias} disabled={loading}>
            CANCELAR
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={validarSalvarFerias}
            disabled={loading}
            className={classes.modalSaveButton}
          >
            SALVAR
          </Button>

          <div style={{marginLeft: '10px', fontFamily: 'Arial', fontSize: 14, color: 'red', width: '480px'}}>{ferias.Erro_Submeter}</div>

          <Button variant="contained">
            REGRAS
          </Button>
        </div>
      </Paper>

      <Confirmation 
        title={confirmation.title}
        open={confirmation.open}
        setOpen={closeConfirmation}
        onConfirm={salvarFerias}
        button={confirmation.button}
        >
          <Typography variant="subtitle2" component="div">{confirmation.text}</Typography>
      </Confirmation>
    </AtomicModal>
  )
}

export default withStyles(styles)(NovoSolicitarFerias);