import api from '../services/api';
import { handleRequestError } from '../services/security';


export async function listTipoHolerite( params ){

  let responseFromApi;

  try {
    const response = await api.get('/tipo-holerite', {params: params});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function getTipoHolerite( id, params ){

  let responseFromApi;

  try {
    const response = await api.get(`/tipo-holerite/${id}`, {params: params});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postTipoHolerite( body ){
    
  let responseFromApi;

  try {
    const response = await api.post('/tipo-holerite', body);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putTipoHolerite( id, body ){
    
  let responseFromApi;

  try {
    const response = await api.put(`/tipo-holerite/${id}`, body);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}


export async function deleteTipoHolerite(id, body){
  
  let response;
  let responseFromApi;
  
  try {
    if(id){
      response = await api.delete(`/tipo-holerite/${id}`);
    }else{
      response = await api.delete('/tipo-holerite', body);
    }

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}