export const styles = theme => ({
  card: {
    marginTop: 30,
    marginLeft: 25,
    height: 450
  },
    image: {
    maxWidth: 300,
    maxHeight: 500,
    marginTop: 20,
    marginLeft: 20,
  },
    cropContainer: {
    paddingLeft: 10,
    paddingBottom: 10,
    height: 300,
    position: 'relative',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
  },
  inputFile: {
      opacity: 0,
      position: 'absolute',
      zIndex: -1
  },
  inputFileLabel: {
    fontWeight: 'bold',
    color: '#FFFFFF',
    textAlign: 'center', 
    paddingTop: 8,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  inputFileLabelContainer: {
    width: 120,
    height: 30,
    backgroundColor: '#3F51B5',
    marginLeft: 35,
    '&:hover': {
      backgroundColor: '#A3A9F7',
      cursor: 'pointer'
    }
  }
})