

export function convertCurrencyBR(valor){
  if(valor !== undefined && valor !== null){
    valor = String(valor).replace(',', '').replace('.', ',');
  }
  return valor;
}

export function convertCurrencyUS(valor){
  if(valor !== undefined && valor !== null){
    valor = valor.replace('.', '').replace(',', '.');
  }
  return valor;
}

export function formatCurrencyDisplay(value){
  var formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  
  return formatter.format(value);
}

export function formatCEP(e, handleChange){
  if(e.target.value.length === 5  && e.target.defaultValue.length === 4){
    e.target.value = `${e.target.value}-`;
  }

  if(e.target.value.length === 5 && e.target.defaultValue.length === 0){
    let cep = e.target.value;
    cep = `${cep.substring(0, 5)}-${cep.substring(5, 8)}`;
    e.target.value = cep;
  }

  handleChange(e)
}

export function formatCNPJ(e, handleChange){
  if(e.target.value.length === 2 && e.target.defaultValue.length === 1){
    e.target.value = `${e.target.value}.`;
  }

  if(e.target.value.length === 6 && e.target.defaultValue.length === 5){
    e.target.value = `${e.target.value}.`;
  }

  if(e.target.value.length === 10  && e.target.defaultValue.length === 9){
    e.target.value = `${e.target.value}/`;
  }

  if(e.target.value.length === 15  && e.target.defaultValue.length === 14){
    e.target.value = `${e.target.value}-`;
  }

  if(e.target.value.length === 14 && e.target.defaultValue.length === 0){
    let cnpj = e.target.value;
    cnpj = `${cnpj.substring(0, 2)}.${cnpj.substring(2, 5)}.${cnpj.substring(5, 8)}/${cnpj.substring(8, 12)}-${cnpj.substring(12)}`;
    e.target.value = cnpj;
  }

  handleChange(e);
}