import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, List, Card, CardHeader, CardContent, CardActions, ListItem, ListItemText, ListItemIcon, Checkbox, Button, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { AtomicModal } from '../../../../components';
import { getPerfilUsuarioAtribuido, getPerfilUsuarioNaoAtribuido } from '../../../../requests/perfilUsuarioAction';



function AtribuirPerfil({classes, display, onSalvar, onFechar, data}) {
  
  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }
  
  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }
  
  function union(a, b) {
    return [...a, ...not(b, a)];
  }

  const [checked, setChecked] = useState([]);
  const [perfilAtribuido, setPerfilAtribuido] = useState([]);
  const [perfilNaoAtribuido, setPerfilNaoAtribuido] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);

  useEffect(() => { carregarPerfis() }, [data]);

  async function carregarPerfis(){
    
    if(data > 0){
      setLoading(true);

      let response = await getPerfilUsuarioAtribuido({Id_Usuario: data});
      if (!response.valid) return
      setPerfilAtribuido(response.data);

      response = await getPerfilUsuarioNaoAtribuido({Id_Usuario: data});
      if (!response.valid) return
      setPerfilNaoAtribuido(response.data);

      setLoading(false);
    }
  }

  const leftChecked = intersection(checked, perfilAtribuido);
  const rightChecked = intersection(checked, perfilNaoAtribuido);

  function handleToggle(value) {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setPerfilNaoAtribuido(perfilNaoAtribuido.concat(leftChecked));
    setPerfilAtribuido(not(perfilAtribuido, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setPerfilAtribuido(perfilAtribuido.concat(rightChecked));
    setPerfilNaoAtribuido(not(perfilNaoAtribuido, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  async function onSalvarAtribuirPerfil(){
    
    setSubmit(true);
    
    let inserirPerfil = [];
    let tempInserirPerfil = perfilAtribuido.filter((value) => !value.hasOwnProperty('Id_Usuario_Perfil'));
    
    for(var i = 0; i < tempInserirPerfil.length; i++){
      const updInserirPerfil = {Id_Perfil: tempInserirPerfil[i].Id_Perfil, Id_Usuario: data};
      inserirPerfil.push(updInserirPerfil);
    }
    
    const removerPerfil = perfilNaoAtribuido.filter((value) => value.hasOwnProperty('Id_Usuario_Perfil'));
    
    const params = {inserir: inserirPerfil, remover: removerPerfil};
    console.log(params);

    onSalvar(params);

    setSubmit(false);
  }

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'todos selecionados' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selecionado(s)`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          
          return (
            
            <ListItem key={value} role="listitem" button onClick={() => handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText id={value.Id_Perfil} primary={value.Perfil} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
    
  );

  return (
    <AtomicModal width="60%" open={display} handleClose={onFechar}>
      <Card>
        <CardHeader title="Atribuição de Perfis" titleTypographyProps={{variant: 'h3'}} style={{textAlign: 'center', marginBottom: -40}}/>
        {loading ?
          <div style={{ position: 'relative', top: 150, left: '50%', height: 400, width: 800}}>
            <CircularProgress />
          </div>
        :
          <CardContent>
            <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
              <Grid item>{customList('Atribuídos', perfilAtribuido)}</Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="mover para direita"
                  >
                    &gt;
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="mover para esquerda"
                  >
                    &lt;
                  </Button>
                </Grid>
              </Grid>
              <Grid item>{customList('Não atribuídos', perfilNaoAtribuido)}</Grid>
            </Grid>
          </CardContent>
        }
        <CardActions className={classes.cardActions}>
          <Button variant="contained" onClick={onFechar}>
            CANCELAR
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={onSalvarAtribuirPerfil}
            disabled={submit || loading}
          >
            SALVAR
            {submit ? 
              <CircularProgress 
              size={14}
              color="secondary" />
              : ''
            }
          </Button>
        </CardActions>
      </Card>
    </AtomicModal>
  );
}

export default withStyles(styles)(AtribuirPerfil)