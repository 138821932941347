//import api from './api';
import axios from 'axios';
import { getSession } from '../requests/sessionAction';

const token = localStorage.getItem('token')

export async function authenticateUser({ Usuario, Senha }) {
  try {
    const params = {
      Usuario,
      Senha: btoa(Senha)
    }
    
    //const responseFromApi = await axios.post('http://localhost:3333/session/authenticate', { params });
    const responseFromApi = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/session/authenticate`, { params });

    if (responseFromApi.status !== 200) {
      return responseFromApi
    }
    
    const session = responseFromApi.data;
    localStorage.setItem('token', session.Token);
        
    return session;    

  } catch (err) {
    return err;
  }
}

export function isLogged() {
  if (token !== null) return true;
  return false
}

export function getToken() {
  if (localStorage.getItem('token') !== null) return token;
  return false
}

export function onAuthenticationSuccess() {
    window.location.reload();
    console.log('teste');
    return true
}

export async function sessionStatus() {
  if(localStorage.getItem('token') !== null){
    const response = await getSession({params: { token: localStorage.getItem('token') }});
    if (response.valid) {
      return true
    }else{
      localStorage.clear();
      localStorage.setItem('urlPath', window.location.pathname);
      window.location.href = '/';
      return false
    }
  }else{
    localStorage.setItem('urlPath', window.location.pathname);
    return false;
  }
}

export function handleRequestError(error){
  
  let responseFromApi = {valid: false, status: 500, message: 'Erro desconhecido no servidor', data: []};
  
  if(error.response === undefined){
    if(error.message === 'Network Error'){
      responseFromApi = {valid: false, status: 503, message: 'Erro de comunicação com o servidor', data: []};
    }
  }else{
    responseFromApi = {valid: false, status: error.response.status, message: error.response.data, data: []};
  }

  return responseFromApi;
}