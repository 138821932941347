import api from '../services/api';
import { handleRequestError } from '../services/security';


export async function listReembolsos( params ){

  let responseFromApi;

  try {
    const response = await api.get('/reembolso', {params: params});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function getReembolso( id ){

  let responseFromApi;

  try {
    const response = await api.get(`/reembolso/${id}`, {});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postReembolso( body ){
    
  let responseFromApi;

  try {
    const response = await api.post('/reembolso', body);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putReembolso(body){
    
  let responseFromApi;

  try {
    const response = await api.put('/reembolso', body);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}


export async function deleteReembolso(id, body){
  
  let response;
  let responseFromApi;
  
  try {
    if(id){
      response = await api.delete(`/reembolso/${id}`);
    }else{
      response = await api.delete('/reembolso', body);
    }

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}