import axios from 'axios';
import api from './api';

export async function downloadFile(idArquivo){

  try{
    const response = await api.get(`/arquivo/${idArquivo}`);

    const fileName = response.data.fileName;

    axios({ 
      url: response.data.signedUrl, 
      method:'GET',
      responseType: 'blob' 
    }) 
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      
      window.URL.revokeObjectURL(url);
      link.remove();
    })
    .catch((err) => {
      console.log(err);
    })
    
    return true;
  }catch(err){
    console.log(err);
    return false;
  }

}