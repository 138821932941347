import React, { useState, useEffect } from 'react';
import { CircularProgress, Paper, CardHeader, Grid, TextField, Button, Typography, LinearProgress } from '@material-ui/core';
import { AtomicModal } from '../../../components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { getEstado } from '../../../requests/estadoRequest';
import { formatCEP, formatCNPJ } from '../../../utils';

function NovoCliente({classes, display, onSalvar, onFechar}){

  const initialValues = {
    Nome: '',
    Razao_Social: '',
    CNPJ: '',
    endereco: {
      Id_Tipo_Endereco: 2,
      Logradouro: '',
      Numero: '',
      Complemento: '',
      Bairro: '',
      CEP: '',
      Cidade: '',
      Id_Estado: 0
    }
  };
  
  const [loading, setLoading] = useState(false);
  const [estados, setEstados] = useState([]);

  useEffect(() => { carregarEstado() }, [])

  async function carregarEstado(){
    const response = await getEstado();
    if(response.valid){
      setEstados(response.data);
    }    
  }

  function closeNovoCliente(handleReset){
    handleReset();  
    onFechar();
  }

  async function salvarCliente(cliente, resetForm){
    setLoading(true);
    await onSalvar(cliente);
    resetForm(initialValues);
    setLoading(false);
  }


  const schema = yup.object().shape({
    Nome: yup.string().required('Campo Obrigatório'),
    Razao_Social: yup.string().required('Campo Obrigatório'),
    CNPJ: yup.string().required('Campo Obrigatório'),
    endereco: yup.object().shape({
      Logradouro: yup.string().required('Campo Obrigatório'),
      Numero: yup.number().required('Campo Obrigatório'),
      Complemento: yup.string().required('Campo Obrigatório'),
      Bairro: yup.string().required('Campo Obrigatório'),
      CEP: yup.string().required('Campo Obrigatório'),
      Cidade: yup.string().required('Campo Obrigatório'),
      Id_Estado: yup.number().required('Campo Obrigatório')
    })
  });

  return (
    <Formik initialValues={initialValues} onSubmit={(values, { resetForm }) => salvarCliente(values, resetForm)} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleReset
      } = props
      return (
        <AtomicModal open={display} width={800} handleClose={() => closeNovoCliente()}>
          <Form>
            <Paper className={classes.modal}>
              {loading && <LinearProgress />}
              <CardHeader title="Novo Cliente" style={{textAlign: 'center'}}/>
              
              {display && 
                <Grid container spacing={1} className={classes.modalFields}>
                  <Grid item md={3} xs={3}>
                    <TextField
                      fullWidth
                      label="Nome"
                      margin="dense"
                      name="Nome"
                      onChange={handleChange}
                      value={values.Nome}
                      variant="outlined"
                      helperText={errors.Nome && touched.Nome ? errors.Nome : ''}
                      error={errors.Nome && touched.Nome ? true : false}
                      onBlur={handleBlur}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <TextField
                      fullWidth
                      label="Razão Social"
                      margin="dense"
                      name="Razao_Social"
                      onChange={handleChange}
                      value={values.Razao_Social}
                      variant="outlined"
                      helperText={errors.Razao_Social && touched.Razao_Social ? errors.Razao_Social : ''}
                      error={errors.Razao_Social && touched.Razao_Social ? true : false}
                      onBlur={handleBlur}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <TextField
                      fullWidth
                      label="CNPJ"
                      margin="dense"
                      name="CNPJ"
                      onChange={e => formatCNPJ(e, handleChange)}
                      value={values.CNPJ}
                      variant="outlined"
                      helperText={errors.CNPJ && touched.CNPJ ? errors.CNPJ : ''}
                      error={errors.CNPJ && touched.CNPJ ? true : false}
                      onBlur={handleBlur}
                      disabled={loading}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Typography variant="subtitle1">Endereço</Typography>
                  </Grid>

                  <Grid item md={5} xs={5}>
                    <TextField
                      fullWidth
                      label="Logradouro"
                      margin="dense"
                      name="endereco.Logradouro"
                      onChange={handleChange}
                      value={values.endereco.Logradouro}
                      variant="outlined"
                      helperText={errors.endereco ? (errors.endereco.Logradouro && touched.endereco.Logradouro ? errors.endereco.Logradouro : '') : ''}
                      error={errors.endereco ? (errors.endereco.Logradouro && touched.endereco.Logradouro ? true : false) : ''}
                      onBlur={handleBlur}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item md={2} xs={2}>
                    <TextField
                      fullWidth
                      label="Numero"
                      margin="dense"
                      name="endereco.Numero"
                      onChange={handleChange}
                      value={values.endereco.Numero}
                      variant="outlined"
                      helperText={errors.endereco ? (errors.endereco.Numero && touched.endereco.Numero ? errors.endereco.Numero : '') : ''}
                      error={errors.endereco ? (errors.endereco.Numero && touched.endereco.Numero ? true : false) : ''}
                      onBlur={handleBlur}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item md={5} xs={5}>
                    <TextField
                      fullWidth
                      label="Complemento"
                      margin="dense"
                      name="endereco.Complemento"
                      onChange={handleChange}
                      value={values.endereco.Complemento}
                      variant="outlined"
                      helperText={errors.endereco ? (errors.endereco.Complemento && touched.endereco.Complemento ? errors.endereco.Complemento : '') : ''}
                      error={errors.endereco ? (errors.endereco.Complemento && touched.endereco.Complemento ? true : false) : ''}
                      onBlur={handleBlur}
                      disabled={loading}
                    />
                  </Grid>

                  <Grid item md={5} xs={5}>
                    <TextField
                      fullWidth
                      label="Bairro"
                      margin="dense"
                      name="endereco.Bairro"
                      onChange={handleChange}
                      value={values.endereco.Bairro}
                      variant="outlined"
                      helperText={errors.endereco ? (errors.endereco.Bairro && touched.endereco.Bairro ? errors.endereco.Bairro : '') : ''}
                      error={errors.endereco ? (errors.endereco.Bairro && touched.endereco.Bairro ? true : false) : ''}
                      onBlur={handleBlur}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item md={2} xs={2}>
                    <TextField
                      fullWidth
                      label="CEP"
                      margin="dense"
                      name="endereco.CEP"
                      onChange={e => formatCEP(e, handleChange)}
                      value={values.endereco.CEP}
                      variant="outlined"
                      helperText={errors.endereco ? (errors.endereco.CEP && touched.endereco.CEP ? errors.endereco.CEP : '') : ''}
                      error={errors.endereco ? (errors.endereco.CEP && touched.endereco.CEP ? true : false) : ''}
                      onBlur={handleBlur}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <TextField
                      fullWidth
                      label="Cidade"
                      margin="dense"
                      name="endereco.Cidade"
                      onChange={handleChange}
                      value={values.endereco.Cidade}
                      variant="outlined"
                      helperText={errors.endereco ? (errors.endereco.Cidade && touched.endereco.Cidade ? errors.endereco.Cidade : '') : ''}
                      error={errors.endereco ? (errors.endereco.Cidade && touched.endereco.Cidade ? true : false) : ''}
                      onBlur={handleBlur}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item md={2} xs={2}>
                    <TextField
                      fullWidth
                      label="Estado"
                      margin="dense"
                      name="endereco.Id_Estado"
                      onChange={handleChange}
                      value={values.endereco.Id_Estado}
                      select
                      SelectProps={{ native: true }}
                      variant="outlined"
                      helperText={errors.endereco ? (errors.endereco.Id_Estado && touched.endereco.Id_Estado ? errors.endereco.Id_Estado : '') : ''}
                      error={errors.endereco ? (errors.endereco.Id_Estado && touched.endereco.Id_Estado ? true : false) : ''}
                      onBlur={handleBlur}
                      disabled={loading}
                    >
                      {estados.map(option => (
                        <option key={option.Id_Estado} value={option.Id_Estado}>
                          {option.Sigla}
                        </option>
                      ))}
                    </TextField>
                  </Grid>

                </Grid>
              }

              <Button variant="contained" disabled={loading} onClick={() => closeNovoCliente(handleReset)} >
                CANCELAR
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading}
                className={classes.modalSaveButton}
              >
                SALVAR
              </Button>
            </Paper>
            </Form>
          </AtomicModal>
        )
      }}
    </Formik>
  )
}

export default withStyles(styles)(NovoCliente);