import api from '../services/api';
import { handleRequestError } from '../services/security';

export async function postFuncionario( params ){
    
  let responseFromApi;

  try {
    const response = await api.post('/funcionario', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putFuncionario( params ){
    
  let responseFromApi;

  try {
    const response = await api.put('/funcionario', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function deleteFuncionario( params ){
   
  let responseFromApi;

  try {
    const response = await api.delete('/funcionario', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function getFuncionarios(params){

  let responseFromApi;

  try {
    const response = await api.get('/funcionario', {params: params});
    

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}