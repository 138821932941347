import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

function ObservacaoConfirmar({classes, open, title, text, onClose, onConfirm, width}) {

  const [observacao, setObservacao] = useState('');
  
  return (
    <div>
      <Dialog open={open} onClose={onClose} className={classes.dialog} fullWidth={true} maxWidth={width}>
        <DialogTitle><div className={classes.title}>{title}</div></DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2">{text}</Typography>
          <TextField
            fullWidth
            multiline
            required={true}
            rows={3}
            label="Observação"
            margin="dense"
            name="Observacao"
            onChange={(e) => setObservacao(e.target.value)}
            value={observacao}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onConfirm(observacao)} className={classes.buttonConfirm}>Sim</Button>
          <Button onClick={onClose} autoFocus className={classes.buttonCancel}>Não</Button>
        </DialogActions>
      </Dialog>
    </div>
  );

};

export default withStyles(styles)(ObservacaoConfirmar);