export const styles = theme => ({
  modal: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    padding: theme.spacing(1, 1, 1),
    transform: `translate(-50%, -50%)`,
    outline: 'none',
    display: 'flex'
  }
})