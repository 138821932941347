import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { CustomSnackBar } from '../../../../components';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  CircularProgress,
  TextField,
  Checkbox,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { getAcessoAll, putAcesso } from '../../../../requests/acessoAction';
import { getPerfilUsuario } from '../../../../requests/perfilUsuarioAction';
import { putPerfil } from '../../../../requests/perfilAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPerfil } from '../../../../actions/perfilActions'
import { getFuncao } from '../../../../requests/funcaoAction';

function EditarPerfil({classes, perfil, setPerfil}){

  let location = useLocation();
  let history = useHistory();

  const [perfilAcesso, setPerfilAcesso] = useState({
    ...perfil,
    acessos: []
  });

  const [form, setForm] = useState({
    loading: false,
    confirmation: false,
    submitting: false
  });

  const [showAlert, setShowAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  useEffect(() => { carregarAcesso() }, []);

  async function carregarAcesso(){

    setForm({...form, loading: true});

    const response = await getAcessoAll({params: {Id_Perfil: location.perfil.Id_Perfil}});
    if (!response) return
    
    let pAcesso = {...location.perfil, acessos: response.data};
    setPerfilAcesso(pAcesso);

    setForm({...form, loading: false});

  }

  function handleCloseAlert(){
    setShowAlert({display: false, severity: '', text: ''});
  }

  async function salvarAcesso(perfilAcesso){

    setForm({...form, submitting: true});

    const {acessos, ...perfil} = perfilAcesso;
    
    const responsePerfil = await putPerfil(perfil);

    if(responsePerfil.valid){
      const responseAcesso = await putAcesso({params: acessos});
        
      if(responseAcesso.valid){
        const params = { Token: localStorage.getItem('token') }
        const responseFuncao = await getFuncao(params);
        const responsePerfil = await getPerfilUsuario(params);

        let nFuncao = {read: [], create: [], update: [], delete: []};

        for(var i = 0; i < responseFuncao.data.length; i++){
          let fArray = responseFuncao.data[i].Funcao.split(':');
          nFuncao = {...nFuncao, [fArray[0]]: fArray[1].split(',')}
        }

        let perfilUsuario = [];

        for(var i = 0; i < responsePerfil.data.length; i++){
          perfilUsuario.push(responsePerfil.data[i].Perfil);
        }

        const perfil = {funcoes: nFuncao, perfis: perfilUsuario};

        setPerfil(perfil);

        setShowAlert({display: true, severity: 'success', text: 'Perfil alterado com sucesso!'});
        setTimeout(returnToPerfis, 1500);
      }else{
        setShowAlert({display: true, severity: 'error', text: responseAcesso.message});
      }
    }else{
      setShowAlert({display: true, severity: 'error', text: responsePerfil.message});
    }

    setForm({...form, submitting: false});
  }

  function returnToPerfis(){
    history.push('/perfis');
  }


  const schema = yup.object().shape({
    Nome: yup.string().required('Campo Obrigatório'),
    Descricao: yup.string().required('Campo Obrigatório')
  });

  return (
    <Formik initialValues={perfilAcesso} onSubmit={(values) => salvarAcesso(values)} enableReinitialize={true} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldValue
      } = props
      return (
        <Card>
          <Form>
            <CustomSnackBar display={showAlert.display} severity={showAlert.severity} text={showAlert.text} onClose={handleCloseAlert} />
            <CardHeader title="Editar Perfil"/>
            <Divider />

            {form.loading ?
              <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
                <CircularProgress />
              </div>
            :
              <div>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item md={3} xs={6}>
                      <TextField
                        fullWidth
                        label="Perfil"
                        margin="dense"
                        name="Nome"
                        onChange={handleChange}
                        value={values.Nome}
                        variant="outlined"
                        helperText={errors.Nome && touched.Nome ? errors.Nome : ''}
                        error={errors.Nome && touched.Nome ? true : false}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <TextField
                        fullWidth
                        label="Descrição"
                        margin="dense"
                        name="Descricao"
                        onChange={handleChange}
                        value={values.Descricao}
                        variant="outlined"
                        helperText={errors.Descricao && touched.Descricao ? errors.Descricao : ''}
                        error={errors.Descricao && touched.Descricao ? true : false}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                  <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                          <TableRow className={classes.header}>
                            <TableCell className={classes.tableHeader}>Função</TableCell>
                            <TableCell className={classes.tableHeader}>Exibir</TableCell>
                            <TableCell className={classes.tableHeader}>Criar</TableCell>
                            <TableCell className={classes.tableHeader}>Atualizar</TableCell>
                            <TableCell className={classes.tableHeader}>Apagar</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {values.acessos.map((row, index) => (  
                            <TableRow key={row.Id_Perfil}>
                              <TableCell>{row.Funcao}</TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={row.Read === 1 ? true : false}
                                  onChange={(event, newValue) => {
                                    console.log(event);
                                    console.log(newValue);
                                    if(newValue){ 
                                      setFieldValue(`acessos.[${index}].Read`, 1);
                                    }else {
                                      setFieldValue(`acessos.[${index}].Read`, 0);
                                    }
                                  }}
                                  color="primary"
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={row.Create === 1 ? true : false}
                                  onChange={(event, newValue) => {
                                    console.log(event);
                                    console.log(newValue);
                                    if(newValue){ 
                                      setFieldValue(`acessos.[${index}].Create`, 1);
                                    }else {
                                      setFieldValue(`acessos.[${index}].Create`, 0);
                                    }
                                  }}
                                  color="primary"
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={row.Update === 1 ? true : false}
                                  onChange={(event, newValue) => {
                                    console.log(event);
                                    console.log(newValue);
                                    if(newValue){ 
                                      setFieldValue(`acessos.[${index}].Update`, 1);
                                    }else {
                                      setFieldValue(`acessos.[${index}].Update`, 0);
                                    }
                                  }}
                                  color="primary"
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={row.Delete === 1 ? true : false}
                                  onChange={(event, newValue) => {
                                    console.log(event);
                                    console.log(newValue);
                                    if(newValue){ 
                                      setFieldValue(`acessos.[${index}].Delete`, 1);
                                    }else {
                                      setFieldValue(`acessos.[${index}].Delete`, 0);
                                    }
                                  }}
                                  color="primary"
                                />
                              </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </div>
            }
            <Divider />
            <CardActions>
              <Button variant="contained" onClick={returnToPerfis}>VOLTAR</Button>
              <Button type="submit" variant="contained" color="primary" disabled={form.submitting}>
                SALVAR
                {form.submitting ? 
                  <CircularProgress 
                  size={14}
                  color="secondary" />
                  : ''
                }
              </Button>
            </CardActions>
          </Form>
        </Card>
      );
    }}
    </Formik>
  )

}


const mapDispatchToProps = dispatch  => {
  return bindActionCreators({
    setPerfil: setPerfil 
  }, 
  dispatch
  );
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(EditarPerfil));