import React from 'react';
import { Modal, IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

function ExibirImagem({classes, open, handleClose, src}) {
  return (
    <Modal open={open} onClose={handleClose} >
      <div className={classes.modal}>
        <IconButton color="secondary" onClick={handleClose} style={{marginTop: '0px', marginLeft: '0px', position: 'absolute', padding: '0px'}}>
          <HighlightOffIcon />
        </IconButton>
        <img src={src} alt="imagem"/>
      </div>
    </Modal>
  );
}

export default withStyles(styles)(ExibirImagem);