export const CST_BANCOS = [
  {
      Id_Banco: 1,
      Nome: 'Santander'
  },
  {
      Id_Banco: '2',
      Nome: 'Bradesco'
  },
  {
      Id_Banco: '3',
      Nome: 'Itaú'
  },
  {
      Id_Banco: '4',
      Nome: 'Banco do Brasil'
  },
  {
      Id_Banco: '5',
      Nome: 'Caixa'
  },
  {
      Id_Banco: '6',
      Nome: 'NuBank'
  },
  {
      Id_Banco: '7',
      Nome: 'Banco Inter'
  }
];

export const CST_TIPO_CONTA_BANCO = [
  {
    Id_Tipo_Conta_Banco: 1,
    Nome: 'Conta Corrente'
  },
  {
    Id_Tipo_Conta_Banco: 2,
    Nome: 'Conta Poupança'
  },
  {
    Id_Tipo_Conta_Banco: 3,
    Nome: 'Conta Salário'
  }
]

export const CST_TIPO_REMUNERACAO = [
  {
    Id_Tipo_Remuneracao: 1,
    Nome: 'Pró-labore'
  },
  {
    Id_Tipo_Remuneracao: 2,
    Nome: 'Salário'
  },
  {
    Id_Tipo_Remuneracao: 3,
    Nome: 'Taxa-Hora'
  },
  {
    Id_Tipo_Remuneracao: 4,
    Nome: 'Bolsa Auxílio'
  }
]

export const CST_STATUS_USUARIO = [
  {
    Id: 1,
    Nome: 'Ativo'
  },
  {
    Id: 0,
    Nome: 'Inativo'
  }
]

export const CST_TIPO_CRITERIO = [
  {
    Id: 1,
    Nome: 'Nota'
  },
  {
    Id: 2,
    Nome: 'Comentário'
  },
  {
    Id: 3,
    Nome: 'Nota e Comentário'
  }
]

export const CST_ANO = [
  { Ano: 2021 },
  { Ano: 2022 },
  { Ano: 2023 },
  { Ano: 2024 }
]

export const CST_SEMESTRE = [
  {
    Semestre: '1º Semestre',
    Valor: 1
  },
  {
    Semestre: '2º Semestre',
    Valor: 2
  }
]

export const CST_MES = [
  { Id: 1, Nome: 'Janeiro' },
  { Id: 2, Nome: 'Fevereiro' },
  { Id: 3, Nome: 'Março' },
  { Id: 4, Nome: 'Abril' },
  { Id: 5, Nome: 'Maio' },
  { Id: 6, Nome: 'Junho' },
  { Id: 7, Nome: 'Julho' },
  { Id: 8, Nome: 'Agosto' },
  { Id: 9, Nome: 'Setembro' },
  { Id: 10, Nome: 'Outubro' },
  { Id: 11, Nome: 'Novembro' },
  { Id: 12, Nome: 'Dezembro' }
]

export const CST_MES_NUMERO = [
  { Id: 1, Nome: '01 - Janeiro' },
  { Id: 2, Nome: '02 - Fevereiro' },
  { Id: 3, Nome: '03 - Março' },
  { Id: 4, Nome: '04 - Abril' },
  { Id: 5, Nome: '05 - Maio' },
  { Id: 6, Nome: '06 - Junho' },
  { Id: 7, Nome: '07 - Julho' },
  { Id: 8, Nome: '08 - Agosto' },
  { Id: 9, Nome: '09 - Setembro' },
  { Id: 10, Nome: '10 - Outubro' },
  { Id: 11, Nome: '11 - Novembro' },
  { Id: 12, Nome: '12 - Dezembro' }
]

export const CST_UNIDADE_NEGOCIO = [
  {
  Id_Unidade_Negocio: 1,
  Nome: 'RPA'
  },
  {
  Id_Unidade_Negocio: 2,
  Nome: 'ITSM'
  },
  {
   Id_Unidade_Negocio: 3,
   Nome: 'TEST'
  },
  {
  Id_Unidade_Negocio: 4,
  Nome: 'DEV'
  }
]
