import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { FormOportunidade } from '../../components';
import { date } from 'yup';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));



function NovoOportunidade({usuario}){
  const classes = useStyles();

  const funcData = {
    //'Id_Oportunidade': '',
    'Id_Usuario': usuario.Nome,
    'Descricao': '',
    'Data_Fechamento': '',
  };

  return (
    <div className={classes.root}>
        <Grid
          item
          lg={12}
          md={10}
          xl={12}
          xs={16}
        >
          <FormOportunidade disabled={false} funcData={funcData} acao='Novo' />
        </Grid>
    </div>
  );
};

function carregaUsuario(usuario) {
  return usuario;
  
}

const mapStateToProps = store => ({ 
  perfil: store.Perfil,
  usuario: store.Usuario.usuario,
})

export default connect(mapStateToProps, null) (NovoOportunidade);
