import React, { useState, useEffect } from 'react';
import { CircularProgress, Paper, CardHeader, Grid, TextField, Button, InputAdornment, Tooltip, Typography, IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { AtomicModal, UploadFile } from '../../../../../components';
import { listTipoReembolso } from '../../../../../requests/tipoReembolsoRequest';
import { TextMaskCurrency } from '../../../../../masks';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import * as yup from 'yup';
import { Formik, Form } from 'formik';

function NovoReembolso({classes, display, onSalvar, onFechar, idUsuario}){

  const initialValues = {
    Id_Usuario: idUsuario,
    Descricao: '',
    Id_Tipo_Reembolso: '',
    Valor: '0,00',
    Data_Gasto: ''
  };
  
  const [tipoReembolso, setTipoReembolso] = useState([{Id_Tipo_Reembolso: '', Nome: ''}]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState([]);

  useEffect(() => { carregarTipoReembolso() }, []);

  async function carregarTipoReembolso(){
    const response = await listTipoReembolso({});

    if(response.valid){
      setTipoReembolso([...response.data, {Id_Tipo_Reembolso: '', Nome: ''}]);
    }
  }

  function closeNovoReembolso(handleReset){
    handleReset();  
    onFechar();
    setFile([]);
  }

  async function salvarReembolso(reembolso, resetForm){
    setLoading(true);
    await onSalvar(reembolso, file);
    resetForm(initialValues);
    setFile([]);
    setLoading(false);
  }

  function handleUpload(newFile) {
    const uploadedFile = {
      file: newFile,
      name: newFile[0].name,
    };

    setFile([...file, uploadedFile]);
  };

  function removeFile(index){
    let newFileList = [...file];
    newFileList.splice(index, 1);
    setFile(newFileList);
  }
  
  const schema = yup.object().shape({
    Descricao: yup.string().required('Campo Obrigatório'),
    Id_Tipo_Reembolso: yup.number().required('Campo Obrigatório'),
    Valor: yup.mixed().required('Campo Obrigatório'),
    Data_Gasto: yup.date().required('Campo Obrigatório')
  })

  return (
    <Formik initialValues={initialValues} onSubmit={(values, { resetForm }) => salvarReembolso(values, resetForm)} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleReset
      } = props
      return (
        <AtomicModal open={display} width={'60%'}>
          <Form>
          <Paper className={classes.modal}>
            <CardHeader title="Novo Reembolso" style={{textAlign: 'center'}}/>
              <Grid container spacing={1} className={classes.modalFields}>
                <Grid item md={6} xs={6}>
                  <TextField
                    fullWidth
                    label="Tipo do Reembolso"
                    margin="dense"
                    name="Id_Tipo_Reembolso"
                    onChange={handleChange}
                    select
                    SelectProps={{ native: true }}
                    value={values.Id_Tipo_Reembolso}
                    variant="outlined"
                    defaultValue={{Id_Tipo_Reembolso: '', Nome: ''}}
                    helperText={errors.Id_Tipo_Reembolso && touched.Id_Tipo_Reembolso ? errors.Id_Tipo_Reembolso : ''}
                    error={errors.Id_Tipo_Reembolso && touched.Id_Tipo_Reembolso ? true : false}
                    onBlur={handleBlur}
                  >
                    {tipoReembolso.map(option => (
                      <option key={option.Id_Tipo_Reembolso} value={option.Id_Tipo_Reembolso}>
                        {option.Nome}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={3} xs={3}>
                  <TextField
                    fullWidth
                    label="Data do Gasto"
                    margin="dense"
                    name="Data_Gasto"
                    type="date"
                    onChange={handleChange}
                    value={values.Data_Gasto}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{paddingRight: 5}}
                    helperText={errors.Data_Gasto && touched.Data_Gasto ? errors.Data_Gasto : ''}
                    error={errors.Data_Gasto && touched.Data_Gasto ? true : false}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item md={3} xs={3}>
                  <TextField
                    fullWidth
                    label="Valor"
                    margin="dense"
                    name="Valor"
                    onChange={handleChange}
                    value={values.Valor}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                      inputComponent: TextMaskCurrency
                    }}
                    variant="outlined"
                    helperText={errors.Valor && touched.Valor ? errors.Valor : ''}
                    error={errors.Valor && touched.Valor ? true : false}
                    onBlur={handleBlur}
                  />
                </Grid>
                
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Descricao"
                    margin="dense"
                    name="Descricao"
                    onChange={handleChange}
                    value={values.Descricao}
                    variant="outlined"
                    helperText={errors.Descricao && touched.Descricao ? errors.Descricao : ''}
                    error={errors.Descricao && touched.Descricao ? true : false}
                    onBlur={handleBlur}
                    />
                </Grid>

                <Grid item xs={6} sm={6}>
                  <UploadFile onUpload={handleUpload}/>
                </Grid>
                
                <Grid item xs={6} sm={6} direction="column">
                  {file.map((fileItem, index) => 
                    (
                      <div className={classes.fileItemBlock}>
                        <Tooltip title="Remover">
                          <IconButton color="secondary" onClick={() => removeFile(index)} disabled={loading}>
                            <DeleteForeverIcon />
                          </IconButton>
                        </Tooltip>
                        <Typography variant="body1">{fileItem.name}</Typography>
                      </div>
                    ))
                  }
                </Grid>

              </Grid>
              
              <Button variant="contained" onClick={() => closeNovoReembolso(handleReset)} disabled={loading} >
                CANCELAR
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading}
                className={classes.modalSaveButton}
              >
                SALVAR
                {loading ? 
                  <CircularProgress 
                  size={14}
                  color="secondary" />
                  : ''
                }
              </Button>
            </Paper>
            </Form>
          </AtomicModal>
        )
      }}
    </Formik>
  )
}

export default withStyles(styles)(NovoReembolso);