import api from '../services/api';
import { handleRequestError } from '../services/security';

export async function getEstadoCivil( params ){

  let responseFromApi;

  try {
    const response = await api.get('/estadoCivil', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postEstadoCivil( params ){
    
  let responseFromApi;

  try {
    const response = await api.post('/estadoCivil', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putEstadoCivil( params ){
    
  let responseFromApi;

  try {
    const response = await api.put('/estadoCivil', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}


export async function deleteEstadoCivil( params ){
    
  let responseFromApi;

  try {
    const response = await api.delete('/estadoCivil', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}