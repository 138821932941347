import React, { useState, useEffect } from 'react';
import { Grid, Card, CardHeader, CardContent, CardActions, Divider, Button, CircularProgress, List,
  ListItem, ListItemText, IconButton, Switch, Typography, LinearProgress, Checkbox, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { CustomSnackBar, FolderTree, Confirmation } from '../../../components';
import { deleteHolerite, listHolerite, postHolerite, putHolerite } from '../../../requests/holeriteRequest';
import { postCargaHolerite } from '../../../requests/cargaHoleriteRequest';
import { postArquivo, deleteArquivo } from '../../../requests/arquivoRequest';
import DeleteIcon from '@material-ui/icons/Delete';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RateReviewIcon from '@material-ui/icons/RateReview';

import NovoHolerite from './NovoHolerite';
import CarregarHolerites from './CarregarHolerites';
import ExibirHolerite from './ExibirHolerite';
import HistoricoCarga from './HistoricoCarga';
import LogCarga from './LogCarga';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


function GestaoHolerite({classes, history, perfil}){
  
  const [pastasHolerite, setPastasHolerite] = useState([]);
  const [listaHolerite, setListaHolerite] = useState([]);
  const [mouseOverItem, setMouseOverItem] = useState(0);
  const [selectedPDF, setSelectedPDF] = useState(null);
  const [holeritesSelecionados, setHoleritesSelecionados] = useState([]);
  const [log, setLog] = useState('');

  const [selectedFolder, setSelectedFolder] = useState({
    name: null,
    id: null
  });

  const [selectedHolerite, setSelectedHolerite] = useState();

  const [folderTitle, setFolderTitle] = useState('');

  const [form, setForm] = useState({
    displayNovo: false,
    displayExibir: false,
    displayCarga: false,
    displayHistorico: false,
    displayLog: false,
    idHolerite: 0
  });

  const [loading, setLoading] = useState({
    pastas: false,
    lista: false,
    status: []
  });

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  });

  const [confirmation, setConfirmation] = useState({
    display: false,
    title: '',
    text: '',
    acao: function(){}
  });

  useEffect(() => { carregarPastasHolerite() }, []);

  async function carregarPastasHolerite(){
    setLoading({...loading, pastas: true});

    if(!perfil.funcoes.read.includes('GerirHolerite')){
      setLoading({...loading, pastas: false});
      history.push('/proibido');
    }

    const params = {Pastas: true};
    const response = await listHolerite(params);

    if (response.valid) {
      setPastasHolerite(response.data);
    } else {
      setAlert({display: true, severity: 'error', text: response.message});
    }
    
    setLoading({...loading, pastas: false});
  }

  async function carregarListaHolerites(ano, mes){
    setLoading({...loading, lista: true});

    setHoleritesSelecionados([]);

    const params = {Ano: ano, Mes: mes};
    const response = await listHolerite(params);

    if (response.valid) {
      setListaHolerite(response.data);
    } else {
      setAlert({display: true, severity: 'error', text: response.message});
    }
    
    setLoading({...loading, lista: false});
  }

  function onNovoHolerite(){
    setForm({...form, displayNovo: true});
  }

  function onSelectFolder(folder){
    setSelectedFolder(folder);

    if(String(folder.id).includes('|')){
      const id = String(folder.id).split('|');

      setFolderTitle(`${id[1].split(' - ')[1]} de ${id[0]}`);

      carregarListaHolerites(id[0], id[1]);
    }else{
      setFolderTitle('');
      setListaHolerite([]);
    }
  }

  async function onChangeHoleriteStatus(event, idHolerite){

    setLoading({...loading, status: [idHolerite]});

    let status = 'Disponível';

    if(!event.target.checked){
      status = 'Revisão';
    }

    const response = await putHolerite({Id_Holerite: idHolerite, Status: status});

    if(response.valid){
      let listaHol = listaHolerite.map(hol => {
        if(hol.Id_Holerite === idHolerite){
          return {...hol, Status: status, Id_Status: response.data.Id_Status};
        }else{
          return hol;
        }
      })

      setListaHolerite(listaHol);
    }

    setLoading({...loading, status: []});

  }

  async function salvarAnexo(arquivo, id, entidade){

    const body = new FormData();
    body.append('Entidade', entidade);
    body.append('Id_Entidade', id);
    body.append('file', arquivo[0], arquivo[0].name);
    
    const response = await postArquivo(body);

    return response.valid;

  }

  async function onSalvarNovoHolerite(holerite, file){

    const response = await postHolerite(holerite);

    if(response.valid){

      const responseFile = await salvarAnexo(file.file, response.data.Id_Holerite, 'Holerite');
      
      carregarPastasHolerite();
      setForm({...form, displayNovo: false});
      setAlert({display: true, severity: 'success', text: 'Holerite criado com sucesso!'});
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

  }

  async function onCarregarHolerite(holerite, file){

    const response = await postCargaHolerite({Ano: holerite.Ano, Mes: holerite.Mes, Id_Tipo_Holerite: holerite.Id_Tipo_Holerite});

    if(response.valid){

      const responseFile = await salvarAnexo(file.file, response.data.Id_Carga_Holerite, 'Carga_Holerite');
      
      if(responseFile){
        const responseCarga = await postCargaHolerite({Id_Carga_Holerite: response.data.Id_Carga_Holerite, executarCarga: true});
      }

      setForm({...form, displayCarga: false});
      setAlert({display: true, severity: 'success', text: 'Carga de Holerite gerado com sucesso!'});
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

  }

  async function exibirHolerite(arquivo){
    setSelectedPDF(arquivo);
    setForm({...form, displayExibir: true});
  }
  
  function onFecharExibirHolerite(){
    setSelectedPDF(null);
    setForm({...form, displayExibir: false});
  }

  function onDeletarHolerite(hol){
    setSelectedHolerite(hol);
    setConfirmation({
      display: true,
      title: 'Apagar holerite selecionado?',
      text: 'Você tem certeza que deseja apagar esse holerite?',
      acao: deletarHolerite
    });
  }

  async function deletarHolerite(){
    const response = await deleteHolerite({Id_Holerite: selectedHolerite.Id_Holerite});

    if(response.valid){
      if(selectedHolerite.arquivo){
        const responseArquivo = await deleteArquivo(selectedHolerite.arquivo.Id_Arquivo);
      }
      
      carregarListaHolerites(selectedHolerite.Ano, selectedHolerite.Mes);
      setAlert({display: true, severity: 'success', text: 'Holerite apagado com sucesso!',})
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setConfirmation({...confirmation, display: false});
  }

  function openDialog(idHolerite, openConfirm) {
    setConfirmation({...confirmation, display: openConfirm});
  }

  function onCarregarHolerites(){
    setForm({...form, displayCarga: true});
  }

  function onSelecionarHolerite(event, idHolerite){
    if(event.target.checked){
      setHoleritesSelecionados([...holeritesSelecionados, idHolerite]);
    }else{
      let index = holeritesSelecionados.indexOf(idHolerite);
      holeritesSelecionados.splice(index, 1);
      setHoleritesSelecionados(holeritesSelecionados);
    }    
  }

  function selectAllHolerites(event){
    if(event.target.checked){
      let holSelec = [];
      
      listaHolerite.forEach(hol => {
        holSelec.push(hol.Id_Holerite);
      });

      setHoleritesSelecionados(holSelec);
    }else{
      setHoleritesSelecionados([]);
    }
  }

  function onDeletarSelecionados(){
    setConfirmation({
      display: true,
      title: 'Apagar todos os holerites selecionado?',
      text: 'Você tem certeza que deseja apagar os holerites selecionados?',
      acao: deletarSelecionados
    });
  }

  async function deletarSelecionados(){
    const response = await deleteHolerite({Id_Holerite: holeritesSelecionados});

    if(response.valid){
      
      let arquivosSelecionados = [];

      listaHolerite.forEach(hol => {
        if(holeritesSelecionados.includes(hol.Id_Holerite)){
          if(hol.arquivo){
            arquivosSelecionados.push(hol.arquivo.Id_Arquivo);
          }
        }
      });
      
      if(arquivosSelecionados.length > 0){
        const responseArquivo = await deleteArquivo(arquivosSelecionados);
      }
      
      const id = String(selectedFolder.id).split('|');
      carregarListaHolerites(id[0], id[1]);
      setAlert({display: true, severity: 'success', text: 'Holerites apagados com sucesso!',})
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setConfirmation({...confirmation, display: false});
  }


  function onAlterarStatusSelecionados(status){
    setConfirmation({
      display: true,
      title: 'Alterar Status',
      text: `Você tem certeza que deseja alterar o status dos holerites selecionados para '${status}'?`,
      acao: () => alterarStatusSelecionados(status)
    });
  }

  async function alterarStatusSelecionados(status){
    setLoading({...loading, status: [...holeritesSelecionados]});
    setConfirmation({...confirmation, display: false});

    const id = String(selectedFolder.id).split('|');

    const response = await putHolerite({Id_Holerite: holeritesSelecionados, Status: status, Ano: id[0], Mes: id[1]});

    if(response.valid){
      let listaHol = listaHolerite.map(hol => {
        if(holeritesSelecionados.includes(hol.Id_Holerite)){
          return {...hol, Status: status, Id_Status: response.data.Id_Status};
        }else{
          return hol;
        }
      })

      setListaHolerite(listaHol);
    }

    setLoading({...loading, status: []});
  }

  
  return(
    <div>
      <Card className={classes.card}>
        <CardHeader title="Gestão Holerite"/>
        <Divider />
        
        <CardContent>
          <Grid container spacing={3} style={{height: '350px'}}>
            <Grid item md={3} xs={3} style={{border: '2px solid grey', borderRadius: '5px', height: '350px'}}>
              {loading.pastas ?
                <div style={{ position: 'relative', top: 100, left: '50%', height: 250}}>
                  <CircularProgress />
                </div>
              :
                <FolderTree  onSelectFolder={onSelectFolder} folderStructure={pastasHolerite} selectedFolder={selectedFolder} height="320px"/>
              }
            </Grid>
            <Grid item md={9} xs={9}>
              {loading.lista ?
                <div style={{ position: 'relative', top: 100, left: '50%', height: 250}}>
                  <CircularProgress />
                </div>
              :
                <div style={{position: 'relative', overflowY: 'auto', height: '320px'}}>
                  
                  {selectedFolder.id !== null &&
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <Checkbox color="primary" size="small" style={{padding: '2px', marginLeft: '16px', marginRight: '10px'}} checked={holeritesSelecionados.length === listaHolerite.length} onChange={selectAllHolerites} />
                      <Typography variant="h6" style={{fontWeight: 'bold'}}>{folderTitle}</Typography>

                      {holeritesSelecionados.length > 1 &&
                        <div style={{position: 'absolute', right: '50px'}}>
                          <Tooltip title="Revisar Selecionados">
                            <IconButton edge="end" className={classes.holeriteListButton} size="small" style={{marginRight: '20px'}} onClick={() => onAlterarStatusSelecionados('Revisão')}>
                              <RateReviewIcon fontSize="small" style={{ fill: 'orange' }}/>
                            </IconButton>
                          </Tooltip>
                          
                          <Tooltip title="Disponibilizar Selecionados">
                            <IconButton edge="end" className={classes.holeriteListButton} size="small" style={{marginRight: '20px'}} onClick={() => onAlterarStatusSelecionados('Disponível')}>
                              <CheckCircleOutlineIcon fontSize="small" style={{ fill: 'green' }}/>
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Apagar Selecionados">
                            <IconButton edge="end" className={classes.holeriteListButton} size="small" style={{marginRight: '20px'}} onClick={onDeletarSelecionados}>
                              <DeleteIcon fontSize="small" style={{ fill: 'red' }}/>
                            </IconButton>
                          </Tooltip>
                        </div>
                      }
                    </div>
                  }
                  
                  <List>
                    {
                      listaHolerite.map(hol => {
                        return(
                          <ListItem key={hol.Id_Holerite} dense className={classes.holeriteList} onMouseEnter={() => setMouseOverItem(hol.Id_Holerite)} onMouseLeave={() => setMouseOverItem(0)}>
                            { mouseOverItem === hol.Id_Holerite || holeritesSelecionados.includes(hol.Id_Holerite) ?
                              <Checkbox color="primary" size="small" style={{padding: '2px', marginRight: '4px'}} checked={holeritesSelecionados.includes(hol.Id_Holerite)} onChange={(event) => onSelecionarHolerite(event, hol.Id_Holerite)} />
                              :
                              <div style={{width: '28px'}}/>
                            }

                            <ListItemText id={hol.Id_Holerite} primary={hol.Funcionario}/>
                            <ListItemText style={{right: '300px', position: 'absolute'}} primary={hol.Tipo_Holerite}/>
                            
                            <div style={{right: '200px', position: 'absolute'}}>
                              <IconButton edge="end" disabled={hol.Id_Arquivo === null} className={classes.holeriteListButton} size="small" onClick={() => exibirHolerite(hol.arquivo)}>
                                <PictureAsPdfIcon fontSize="small" style={hol.Id_Arquivo === null ? {fill: '#EEEEEE'} : {fill: 'red'}}/>
                              </IconButton>
                            </div>
                            
                            <div style={{right: '50px', position: 'absolute'}}>
                              <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>
                                  {loading.status.includes(hol.Id_Holerite) ?
                                    <div style={{width: '50px'}}>
                                      <LinearProgress />
                                    </div>
                                  : 
                                    <Typography variant="body2">{hol.Status}</Typography>
                                  }
                                </Grid>
                                <Grid item>
                                  <Switch
                                    checked={hol.Status === 'Disponível'}
                                    onChange={(event) => onChangeHoleriteStatus(event, hol.Id_Holerite)}
                                    color="primary"
                                    disabled={loading.status.includes(hol.Id_Holerite)}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                            { mouseOverItem === hol.Id_Holerite &&
                              <IconButton edge="end" className={classes.holeriteListButton} size="small" onClick={() => onDeletarHolerite(hol)}>
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            }
                          </ListItem>
                        )
                      })
                    }
                  </List>
                </div>
              }
            </Grid>
          </Grid>
        </CardContent>
        
        <Divider/>
        <CardActions>
          <Button variant="contained" color="primary" onClick={onNovoHolerite}>NOVO</Button>
          <Button variant="contained" color="primary" onClick={onCarregarHolerites}>CARREGAR HOLERITES</Button>
          <Button variant="contained" color="primary" onClick={() => setForm({...form, displayHistorico: true})}>HISTÓRICO DE CARGA</Button>
        </CardActions>
      </Card>

      <NovoHolerite display={form.displayNovo} onSalvar={onSalvarNovoHolerite} onFechar={() => setForm({...form, displayNovo: false})} />
      <CarregarHolerites display={form.displayCarga} onSalvar={onCarregarHolerite} onFechar={() => setForm({...form, displayCarga: false})} />
      <ExibirHolerite display={form.displayExibir} onFechar={onFecharExibirHolerite} arquivo={selectedPDF}/>
      <HistoricoCarga display={form.displayHistorico} onFechar={() => setForm({...form, displayHistorico: false})} onSetLog={setLog} onExibirLog={() => setForm({...form, displayLog: true})}/>
      <LogCarga display={form.displayLog} onFechar={() => setForm({...form, displayLog: false})} log={log}/>

      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setAlert({display: false, severity: '', text: ''})}
      />

      <Confirmation 
        title={confirmation.title}
        open={confirmation.display}
        setOpen={openDialog}
        onConfirm={confirmation.acao}
      >
        <Typography variant="body">{confirmation.text}</Typography>
      </Confirmation>
      
    </div>
  )

}

const mapStateToProps = store => ({
  perfil: store.Perfil
})

export default connect(mapStateToProps, null)(withRouter(withStyles(styles)(GestaoHolerite)));