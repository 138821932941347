import api from '../services/api';
import { handleRequestError } from '../services/security';

export async function getExtratos(params){

  let responseFromApi;

  try {
    const response = await api.get('/extrato', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postExtrato( params ){
    
  let responseFromApi;

  try {
    const response = await api.post('/extrato', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putExtrato( params ){
    
  let responseFromApi;
  
  try {
    const response = await api.put('/extrato', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function deleteExtrato( params ){
    
  let responseFromApi;

  try {
    const response = await api.delete('/extrato', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}