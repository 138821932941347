import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getEndereco, putEndereco } from '../../requests/enderecoRequest';
import { getEstado } from '../../requests/estadoRequest';
import { CustomSnackBar } from '../../components';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  CircularProgress,
  TextField
} from '@material-ui/core';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { TextMaskCEP } from '../../masks';

function FormEndereco({classes, idEndereco}) {

  const [showAlert, setShowAlert] = useState({
    display: false,
    severity: '',
    text: ''
  });

  const [stateForm, setStateForm] = useState({
    submitting: false,
    loading: true
  });

  const [disabled, setDisabled] = useState(true);
  const [endereco, setEndereco] = useState({});
  const [estados, setEstados] = useState([]);

  const tipoEndereco = [
    {value: '1', label: 'Residencial' },
    {value: '2', label: 'Comercial' }
  ]

  useEffect(() => { carregarEndereco() }, [])

  async function carregarEndereco() {
    
    setStateForm({...stateForm, loading: true});

    await carregarEstado();
    
    if(idEndereco !== ""){
      const response = await getEndereco({params: {Id_Endereco: idEndereco}});
      if (response.valid){
        setEndereco(response.data);
      }      
    }

    setStateForm({...stateForm, loading: false});
  }

  async function carregarEstado(){
    const response = await getEstado();
    if (!response) return
    setEstados(response.data);
  }

  function handleCloseAlert() {
    setShowAlert({ display: false, severity: '', text: '' })
  }

  async function onSalvarEndereco(endereco) {

    setStateForm({...stateForm, submitting: true});
    
    const response = await putEndereco(endereco);
    
    if(response.valid){
      setShowAlert({ display: true, severity: 'success', text: 'Endereço alterado com sucesso!' });
      setDisabled(true);
    }else{
      setShowAlert({ display: true, severity: 'error', text: response.message });
    }

    setStateForm({...stateForm, submitting: false});
  }

  function onCancelarEditar(handleReset) {
    handleReset();
    setDisabled(true);
  }

  function onEditarEndereco(){
    setDisabled(false);
  }

  const schema = yup.object().shape({
    Id_Tipo_Endereco: yup.number().required('Campo Obrigatório'),
    Logradouro: yup.string().required('Campo Obrigatório'),
    Numero: yup.number().required('Campo Obrigatório'),
    Complemento: yup.string().nullable().default(null),
    Bairro: yup.string().required('Campo Obrigatório'),
    CEP: yup.string().required('Campo Obrigatório'),
    Cidade: yup.string().required('Campo Obrigatório'),
    Id_Estado: yup.number().required('Campo Obrigatório')
  });

  return (
    <Formik initialValues={endereco} onSubmit={(values) => onSalvarEndereco(values)} enableReinitialize={true} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleReset
      } = props
      return (
        <Card style={{marginLeft: -20, marginRight: -20}}>
          <Form>
            <CustomSnackBar display={showAlert.display} severity={showAlert.severity} text={showAlert.text} onClose={handleCloseAlert} />
            <CardHeader title="Endereço"/>
            <Divider />

            {stateForm.loading ?
              <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
                <CircularProgress />
              </div>
            :
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="Tipo Endereço"
                      margin="dense"
                      name="Id_Tipo_Endereco"
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.Id_Tipo_Endereco}
                      variant="outlined"
                      disabled={disabled}
                      helperText={errors.Id_Tipo_Endereco && touched.Id_Tipo_Endereco ? errors.Id_Tipo_Endereco : ''}
                      error={errors.Id_Tipo_Endereco && touched.Id_Tipo_Endereco ? true : false}
                      onBlur={handleBlur}
                    >
                      {tipoEndereco.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Logradouro"
                      margin="dense"
                      name="Logradouro"
                      onChange={handleChange}
                      value={values.Logradouro}
                      variant="outlined"
                      disabled={disabled}
                      helperText={errors.Logradouro && touched.Logradouro ? errors.Logradouro : ''}
                      error={errors.Logradouro && touched.Logradouro ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="Numero"
                      margin="dense"
                      name="Numero"
                      onChange={handleChange}
                      value={values.Numero}
                      variant="outlined"
                      disabled={disabled}
                      helperText={errors.Numero && touched.Numero ? errors.Numero : ''}
                      error={errors.Numero && touched.Numero ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Complemento"
                      margin="dense"
                      name="Complemento"
                      onChange={handleChange}
                      value={values.Complemento}
                      variant="outlined"
                      disabled={disabled}
                      helperText={errors.Complemento && touched.Complemento ? errors.Complemento : ''}
                      error={errors.Complemento && touched.Complemento ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Bairro"
                      margin="dense"
                      name="Bairro"
                      onChange={handleChange}
                      value={values.Bairro}
                      variant="outlined"
                      disabled={disabled}
                      helperText={errors.Bairro && touched.Bairro ? errors.Bairro : ''}
                      error={errors.Bairro && touched.Bairro ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="CEP"
                      margin="dense"
                      name="CEP"
                      onChange={handleChange}
                      value={values.CEP}
                      variant="outlined"
                      disabled={disabled}
                      InputProps={{
                        inputComponent: TextMaskCEP
                      }}
                      helperText={errors.CEP && touched.CEP ? errors.CEP : ''}
                      error={errors.CEP && touched.CEP ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Cidade"
                      margin="dense"
                      name="Cidade"
                      onChange={handleChange}
                      value={values.Cidade}
                      variant="outlined"
                      disabled={disabled}
                      helperText={errors.Cidade && touched.Cidade ? errors.Cidade : ''}
                      error={errors.Cidade && touched.Cidade ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="Estado"
                      margin="dense"
                      name="Id_Estado"
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.Id_Estado}
                      variant="outlined"
                      disabled={disabled}
                      helperText={errors.Id_Estado && touched.Id_Estado ? errors.Id_Estado : ''}
                      error={errors.Id_Estado && touched.Id_Estado ? true : false}
                      onBlur={handleBlur}
                    >
                      {estados.map(option => (
                        <option key={option.Id_Estado} value={option.Id_Estado}>
                          {option.Sigla}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
            }
            <Divider />
            <CardActions>
              {disabled ? 
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onEditarEndereco}>
                    EDITAR
                </Button>
              :
                <div>
                  <Button variant="contained" onClick={() => onCancelarEditar(handleReset)} >
                    CANCELAR
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={stateForm.submitting}
                    style={{marginLeft: 5}}
                  >
                    SALVAR
                    {stateForm.submitting ? 
                      <CircularProgress 
                      size={14}
                      color="secondary" />
                    : null }
                  </Button>
                </div>
              }
            </CardActions>
          </Form>
        </Card>
      );
    }}
    </Formik>
  );
}

FormEndereco.propTypes = {
  idEndereco: PropTypes.string
};

export default FormEndereco;