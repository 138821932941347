import api from '../services/api';
import { handleRequestError } from '../services/security';

export async function getEndereco( params ){

  let responseFromApi;

  try {
    const response = await api.get('/endereco', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postEndereco( params ){
    
  let responseFromApi;

  try {
    const response = await api.post('/endereco', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putEndereco( body ){
    
  let responseFromApi;

  try {
    const response = await api.put('/endereco', body);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}


export async function deleteEndereco( id ){
    
  let responseFromApi;

  try {
    const response = await api.delete(`/endereco/${id}`);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}