export const styles = theme => ({
  modal: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10
  },
  modalFields: {
    marginBottom: 20
  },
  modalSaveButton: {
    marginLeft: 10
  },
  list: {
    width: 300,
    height: 500,
    overflow: 'auto'
  },
})