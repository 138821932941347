import { getUsuario } from '../requests/usuarioRequest';
import { getFuncao } from '../requests/funcaoAction';
import { getPerfilUsuarioAtribuido } from '../requests/perfilUsuarioAction';

export const setUsuario = usuario => ({ type: 'SET_USUARIO', payload: usuario })

const getAllDataUser = () => async dispatch => {
  
  const params = { Token: localStorage.getItem('token') }
  
  if(params.Token){
    try{
      const response = await getUsuario(params);
      const responseFuncoes = await getFuncao(params);
      const responsePerfil = await getPerfilUsuarioAtribuido({Id_Usuario: response.data.Id_Usuario});

      let funcao = {read: [], create: [], update: [], delete: []};

      for(var i = 0; i < responseFuncoes.data.length; i++){
        let fArray = responseFuncoes.data[i].Funcao.split(':');
        funcao = {...funcao, [fArray[0]]: fArray[1].split(',')}
      }

      let perfilUsuario = [];

      for(var i = 0; i < responsePerfil.data.length; i++){
        perfilUsuario.push(responsePerfil.data[i].Perfil);
      }

      const perfil = {funcoes: funcao, perfis: perfilUsuario};

      // PEGA DADOS DO USUÁRIO
      if (response.valid) {
        dispatch({ type: 'SET_USUARIO', payload: response.data })
      }

      // ATRIBUI PERFIL DE USUARIO
      if (responseFuncoes.valid) {
        dispatch({ type: 'SET_PERFIL', payload: perfil })
      }
    }catch(error){
      console.log(error);
    }
  }

}

export default getAllDataUser;