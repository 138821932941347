const aws = require('aws-sdk');

export async function getFileURLFromS3(fileName){
    
  aws.config.update({region: 'us-east-1', credentials: new aws.Credentials ({
      accessKeyId: 'AKIA3EWZSYETGQLBVG5V', //process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: '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', //process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    })
  });

  var s3 = new aws.S3();

  var params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET, //'erp-atomic-dev',
    Key: fileName
  };

  let url = null;

  try {
    await s3.headObject(params).promise();
    url = s3.getSignedUrl('getObject', params);
  } catch (err) {
    url = null;
  }

  return url;
  
}

export function uploadFileToS3(file, fileName){
    
  aws.config.update({region: 'us-east-1', credentials: new aws.Credentials ({
      accessKeyId: 'AKIA3EWZSYETGQLBVG5V', //process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: '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', //process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    })
  });
  
  var upload = new aws.S3.ManagedUpload({
    params: {
      Bucket: process.env.REACT_APP_AWS_BUCKET, //'erp-atomic-dev',
      Key: fileName,
      Body: file
    }
  });

  var promise = upload.promise();

  promise.then(
    function(data) {
      console.log("Successfully uploaded photo.");
    },
    function(err) {
      return console.log("There was an error uploading your photo: ", err.message);
    }
  );
  
}