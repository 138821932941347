export const styles = theme => ({
  table: {
    width: '40',
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: 14,
    paddingTop: 8,
    paddingBottom: 8
  },
  mainRowCell: {
    paddingTop: 2,
    paddingBottom: 2
  },
  header: {
    backgroundColor: '#DDDDDD'
  },
  button: {
    paddingTop: 0,
    paddingBottom: 0
  },
  card: {
    marginTop: 30,
    marginLeft: 25,
    
  },
  TableCellLink: {
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer'
    }
  },
  rowButton: {
    paddingTop: 5,
    paddingBottom: 5,
    marginTop: -10,
    marginBottom: -10
  },
  row: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableHeaderCollapse: {
    paddingTop: 8,
    paddingBottom: 8,
    width: 20
  },
  subTableHeader: {
    fontWeight: 'bold',
    fontSize: 12,
    color: 'white',
    paddingTop: 2,
    paddingBottom: 2
  },
  subHeader: {
    backgroundColor: '#5C65D1'
  }
})