import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import { getFileURLFromS3 } from '../../../../../../services/aws-s3';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

function Profile({classes, className, nome, usuario, idUsuario}) {

  const [avatar, setAvatar] = useState('');

  useEffect(() => { getAvatar(); }, [idUsuario]);

  async function getAvatar(){
    let img = await getFileURLFromS3('usuario/perfil/'+ idUsuario +'.png');

    if(img !== null){
      setAvatar(img);
    }else{
      setAvatar('');
    }
  }

  function getNome(){
    const arrayNome = nome.split(' ');
    const exibirNome = arrayNome[0] + ' ' + arrayNome[arrayNome.length - 1]
    return exibirNome;
  }

  return (
    <div
      className={clsx(classes.root, className)}
    >
      <Avatar alt={nome} className={classes.avatar} src={avatar}/>
      <Typography className={classes.name} variant="h4">
        {getNome()}
      </Typography>
      <Typography className={classes.name} variant="h6">
        {usuario}
      </Typography>
      { /*<Typography variant="body2">{user.bio}</Typography> */}
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = store => ({ 
  nome: store.Usuario.usuario.Nome,
  usuario: store.Usuario.usuario.Usuario,
  idUsuario: store.Usuario.usuario.Id_Usuario
})

export default connect(mapStateToProps, null)(withStyles(styles) (Profile));