import React, { useState, useEffect } from 'react';
import { CircularProgress, Paper, CardHeader, Grid, TextField, Button } from '@material-ui/core';
import { AtomicModal } from '../../../components';
import { CST_UNIDADE_NEGOCIO } from '../../../constants';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getFuncionarios } from '../../../requests/funcionariosRequest';
import { listCliente } from '../../../requests/clienteRequest';
import { getAllUnidadeNegocio} from '../../../requests/unidadeNegocioRequest';


function EditarAlocacao({classes, display, onSalvar, onFechar, data}){


  const [gestor, setGestor] = useState([]);
  const [cliente, setCliente] = useState([]);
  const [unidadeNegocio, setUnidadeNegocio] = useState([]);
  const [submit, setSubmit] = useState(false)

  useEffect(() => {
    carregarGestor();
    carregarCliente();
    carregarUnidadeNegocio();
  }, []);

  async function carregarGestor(){
    const response = await getFuncionarios();
    
    if(response.valid){
      setGestor(response.data);
    }
  }

  async function carregarCliente(){
    const response = await listCliente();

    if(response.valid){
      setCliente(response.data);
    }
  }

  async function carregarUnidadeNegocio(){
    const response = await getAllUnidadeNegocio();

    if(response.valid){
      setUnidadeNegocio(response.data);
    }
  }


  function onSalvarEditarAlocacao(alocacao, resetForm){
    setSubmit(true);
    onSalvar(alocacao);
    resetForm();
    setSubmit(false);
   
  }

  function onFecharEditarAlocacao(handleReset){
    handleReset();
    onFechar();
  }
  
  const schema = yup.object().shape({
    Id_Unidade_Negocio: yup.number().required('Campo Obrigatório'),
    Id_Cliente: yup.number().required('Campo Obrigatório'),
    Data_Alocacao: yup.date().required('Campo Obrigatório'),
    Id_Gestor: yup.number().required('Campo Obrigatório')
  });

  return (
    
    <Formik initialValues={data} onSubmit={(values, { resetForm }) => onSalvarEditarAlocacao(values, resetForm)} enableReinitialize={true} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleReset,
        setFieldValue
      } = props
        return (
          <AtomicModal width={'50%'} open={display} handleClose={onFechar}>
            <Form>
              <Paper className={classes.modal}>
                <CardHeader title="Editar Alocação" />

                <Grid container spacing={1} className={classes.modalFields}>
                <Grid item md={2} xs={2} >
                  <Autocomplete
                      classes={errors.Id_Unidade_Negocio && touched.Id_Unidade_Negocio ? {inputRoot: classes.inputRoot} : ''}
                      options={unidadeNegocio}
                      getOptionLabel={(option) => option.Nome}
                      renderInput={(params) => <TextField {...params} label="Unidade_Negocio" variant="outlined" />}
                      size="small"
                      style={{marginTop: 8}}
                      onChange={(event, newValue) => {
                        if(newValue === null){ 
                          setFieldValue('', '')
                        }else {
                          setFieldValue('Id_Unidade_Negocio', newValue.Id_Unidade_Negocio)
                        }
                      }}
                    />
                    {errors.Id_Unidade_Negocio && touched.Id_Unidade_Negocio ? 
                    <div style={{fontFamily: 'Arial', fontSize: 11, color: 'red'}}>{errors.Id_Unidade_Negocio}</div>
                    : ''}
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <Autocomplete
                      classes={errors.Id_Cliente && touched.Id_Cliente ? {inputRoot: classes.inputRoot} : ''}
                      options={cliente}
                      getOptionLabel={(option) => option.Nome}
                      renderInput={(params) => <TextField {...params} label="Cliente" variant="outlined" />}
                      size="small"
                      style={{marginTop: 8}}
                      defaultValue={{Id_Cliente: values.Id_Cliente, Nome: values.Cliente}}
                      onChange={(event, newValue) => {
                        if(newValue === null){ 
                          setFieldValue('Id_Cliente', '')
                        }else {
                          setFieldValue('Id_Cliente', newValue.Id_Cliente)
                        }
                      }}
                    />
                    {errors.Id_Cliente && touched.Id_Cliente ? 
                    <div style={{fontFamily: 'Arial', fontSize: 11, color: 'red'}}>{errors.Id_Cliente}</div>
                    : ''}
                  </Grid>
                  
                  <Grid item md={4} xs={4}>
                    <Autocomplete
                      classes={errors.Id_Gestor && touched.Id_Gestor ? {inputRoot: classes.inputRoot} : ''}
                      options={gestor}
                      getOptionLabel={(option) => option.Nome}
                      renderInput={(params) => <TextField {...params} label="Gestor" variant="outlined" />}
                      size="small"
                      style={{marginTop: 8}}
                      defaultValue={{Id_Gestor: values.Id_Gestor, Nome: values.Gestor}}
                      onChange={(event, newValue) => {
                        if(newValue === null){ 
                          setFieldValue('Id_Gestor', '')
                        }else {
                          setFieldValue('Id_Gestor', newValue.Id_Funcionario)
                        }
                      }}
                    />
                    {errors.Id_Gestor && touched.Id_Gestor ? 
                    <div style={{fontFamily: 'Arial', fontSize: 11, color: 'red'}}>{errors.Id_Gestor}</div>
                    : ''}
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <TextField
                      fullWidth
                      label="Início Alocação"
                      margin="dense"
                      name="Data_Alocacao"
                      type="date"
                      onChange={handleChange}
                      value={values.Data_Alocacao}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      variant="outlined"
                      //style={{paddingRight: 5}}
                      helperText={errors.Data_Alocacao && touched.Data_Alocacao ? errors.Data_Alocacao : ''}
                      error={errors.Data_Alocacao && touched.Data_Alocacao ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>

                <Button variant="contained" onClick={() => onFecharEditarAlocacao(handleReset)} >
                  CANCELAR
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submit}
                  className={classes.modalSaveButton}
                >
                  SALVAR
                  {submit ? 
                    <CircularProgress 
                    size={14}
                    color="secondary" />
                    : ''
                  }
                </Button>
              </Paper>
            </Form>
          </AtomicModal>
        )
      }}
    </Formik>
  )
}

export default withStyles(styles)(EditarAlocacao);