import React, { useState, useEffect } from 'react';
import { CircularProgress, Paper, CardHeader, Grid, TextField, Button, InputAdornment, Tooltip, Typography, Link, IconButton, Backdrop } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { AtomicModal, UploadFile, ExibirImagem, StatusChip } from '../../../../../components';
import ObservacaoReembolso from '../ObservacaoReembolso';
import { listTipoReembolso } from '../../../../../requests/tipoReembolsoRequest';
import { listArquivos } from '../../../../../requests/arquivoRequest';
import { TextMaskCurrency } from '../../../../../masks';
import { downloadFile } from '../../../../../services/fileSystem';
import { getFileURLFromS3 } from '../../../../../services/aws-s3';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { connect } from 'react-redux';

function GerirReembolso({classes, display, onSalvar, onFechar, data, perfil}){

  const [tipoReembolso, setTipoReembolso] = useState([{Id_Tipo_Reembolso: '', Nome: ''}]);
  const [loading, setLoading] = useState(false);
  const [opening, setOpening] = useState(false);
  const [file, setFile] = useState([]);
  const [status, setStatus] = useState('');
  const [image, setImage] = useState({
    open: false,
    src: ''
  });

  const [observacao, setObservacao] = useState({
    open: false,
    title: '',
    text: ''
  });

  useEffect(() => { carregarTipoReembolso() }, []);
  useEffect(() => { carregarAnexos() }, [data, display]);

  async function carregarTipoReembolso(){
    const response = await listTipoReembolso({});

    if(response.valid){
      setTipoReembolso([...response.data, {Id_Tipo_Reembolso: '', Nome: ''}]);
    }
  }

  async function carregarAnexos(){
    
    setOpening(true);

    if(display && data){
      const params = {Entidade: 'Reembolso', Id_Entidade: data.Id_Reembolso}
      const responseArquivo = await listArquivos(params);

      if(responseArquivo.valid){
        setFile(responseArquivo.data);
      }
    }

    setOpening(false);

  }

  function closeEditarReembolso(){
    onFechar();
    setFile([]);
  }

  async function exibirOuBaixarArquivo(arquivo){

    if(['jpg', 'jpeg', 'gif', 'png'].includes(arquivo.Extensao)){
      const s3FileFullPath = arquivo.Url;
      const s3Url = `https://${process.env.REACT_APP_AWS_BUCKET}.s3.amazonaws.com/`;
      const s3FilePath = s3FileFullPath.slice(s3FileFullPath.indexOf(s3Url) + s3Url.length)
      
      const src = await getFileURLFromS3(s3FilePath);

      if(src !== null){
        setImage({open: true, src: src});
      }
    }else{
      downloadFile(arquivo.Id_Arquivo);
    }

  }

  function onCloseExibirImagem(){
    setImage({open: false, src: ''});
  }

  async function alterarStatus(status){
    setStatus(status);

    let title = '';
    let text = '';

    switch(status){
      case 'Aprovado':
        title = 'Confirmar Aprovação do Reembolso';
        text = 'Você confirma a aprovação do reembolso?';
        break;
      case 'Rejeitado':
        title = 'Confirmar Rejeição do Reembolso';
        text = 'Você confirma a rejeição do reembolso?';
        break;
      case 'Pago':
        title = 'Confirmar Pagamento do Reembolso';
        text = 'Você confirma o pagamento do reembolso?';
        break;
      case 'Cancelado':
        title = 'Confirmar Cancelamento do Reembolso';
        text = 'Você confirma o cancelamento do reembolso?';
        break;
    }

    setObservacao({open: true, title: title, text: text});
  }

  function cancelarAlterarStatus(){
    setObservacao({open: false, title: '', text: ''});
  }

  async function salvarTrocaStatus(obs){
    setLoading(true);
    setObservacao({open: false, title: '', text: ''});
    await onSalvar(status, obs);
    setObservacao({open: false, title: '', text: ''});
    setLoading(false);
  }

  
  return (
    <AtomicModal open={display} width={'60%'}>
      
      <Paper className={classes.modal}>
        <CardHeader title={'Gerir Reembolso'} style={{textAlign: 'center'}}/>
        {opening ?
          <div className={classes.loadingDiv}>
            <CircularProgress size={40} />
          </div>
        :
        <Grid container spacing={1} className={classes.modalFields} alignItems="center">
          <Grid item md={6} xs={6}>
            <TextField
              fullWidth
              label="Solicitante"
              margin="dense"
              name="Solicitante"
              value={data ? data.solicitante.Nome : ''}
              variant="outlined"
              InputLabelProps={{
                  shrink: true,
              }}
              disabled={true}
            />
          </Grid>

          <Grid item md={3} xs={3}>
            <TextField
              fullWidth
              label="Aberto em"
              margin="dense"
              name="Data_Hora"
              type="date"
              value={data.Data_Criacao}
              variant="outlined"
              InputLabelProps={{
                  shrink: true,
              }}
              style={{paddingRight: 5}}
              disabled={true}
            />
          </Grid>

          <Grid item md={3} xs={3}>
            <StatusChip status={data.Status}/>
          </Grid>

          <Grid item md={6} xs={6}>
            <TextField
              fullWidth
              label="Tipo do Reembolso"
              margin="dense"
              name="Id_Tipo_Reembolso"
              select
              SelectProps={{ native: true }}
              value={data.Id_Tipo_Reembolso}
              variant="outlined"
              disabled={true}
            >
              {tipoReembolso.map(option => (
                <option key={option.Id_Tipo_Reembolso} value={option.Id_Tipo_Reembolso}>
                  {option.Nome}
                </option>
              ))}
            </TextField>
          </Grid>

          <Grid item md={3} xs={3}>
            <TextField
              fullWidth
              label="Data do Gasto"
              margin="dense"
              name="Data_Gasto"
              type="date"
              value={data.Data_Gasto}
              variant="outlined"
              InputLabelProps={{
                  shrink: true,
              }}
              style={{paddingRight: 5}}
              disabled={true}
            />
          </Grid>

          <Grid item md={3} xs={3}>
            <TextField
              fullWidth
              label="Valor"
              margin="dense"
              name="Valor"
              value={data.Valor.includes(',') ? data.Valor : `${data.Valor},00`}
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                inputComponent: TextMaskCurrency
              }}
              variant="outlined"
              disabled={true}
            />
          </Grid>
          
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              label="Descricao"
              margin="dense"
              name="Descricao"
              value={data.Descricao}
              variant="outlined"
              disabled={true}
            />
          </Grid>
          
          <Grid item xs={6} sm={6} direction="column">
            {file.map((fileItem, index) => 
              (
                <div className={classes.fileItemBlock}>
                  <Tooltip title="Remover">
                    <IconButton color="secondary" disabled={true}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                  <Link onClick={() => exibirOuBaixarArquivo(fileItem)} className={classes.downloadLink}>
                    <Typography variant="body1" style={{color: 'blue', fontWeight: 'bold'}}>{`${fileItem.Arquivo}.${fileItem.Extensao}`}</Typography>
                  </Link>
                </div>
              ))
            }
          </Grid>
        </Grid>
        }
          
        <Button variant="contained" onClick={closeEditarReembolso}>
          CANCELAR
        </Button>
        {perfil.funcoes.update.includes('AprovarReembolso') && data.Status === 'Aberto' &&
          <>
            <Button color="primary" variant="contained" onClick={() => alterarStatus('Aprovado')} className={classes.modalGreenButton}>
              APROVAR
            </Button>
            <Button color="primary" variant="contained" onClick={() => alterarStatus('Rejeitado')} className={classes.modalOrangeButton}>
              REJEITAR
            </Button>
          </>
        }
        {perfil.funcoes.update.includes('PagarReembolso') && data.Status === 'Aprovado' &&
          <Button color="primary" variant="contained" onClick={() => alterarStatus('Pago')} className={classes.modalGreenButton} >
            PAGAR
          </Button>
        }
      </Paper>
        
      <ExibirImagem open={image.open} src={image.src} handleClose={onCloseExibirImagem} />
      <ObservacaoReembolso title={observacao.title} text={observacao.text} open={observacao.open} onClose={cancelarAlterarStatus} onConfirm={salvarTrocaStatus} width={'sm'} />

      <Backdrop
        style={{ color: '#fff', zIndex: 10 }}
        open={loading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </AtomicModal>
  )
}

const mapStateToProps = store => ({ 
  perfil: store.Perfil,
})

export default connect(mapStateToProps, null)(withStyles(styles)(GerirReembolso));