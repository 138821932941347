import React from 'react';
import { Chip } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ForwardIcon from '@material-ui/icons/Forward';
import PersonIcon from '@material-ui/icons/Person';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

function StatusAvaliacao({classes, status}){
    
  let color;
  let icon;

  switch(status){
    case 'Criado':
      color = '#4287F5';
      icon = (<CheckIcon style={{color: 'white'}}/>);
      break;
    case 'Enviado':
      color = '#4287F5';
      icon = (<ForwardIcon style={{color: 'white'}}/>);
      break;
    case 'Auto Avaliação':
      color = 'orange';
      icon = (<PersonIcon style={{color: 'white'}}/>);
      break;
    case 'Submetido':
      color = '#4287F5';
      icon = (<DoneAllIcon style={{color: 'white'}}/>);
      break;
    case 'Avaliação do Gestor':
      color = 'orange';
      icon = (<HowToRegIcon style={{color: 'white'}}/>);
      break;
    case 'Reunião de Avaliação':
      color = 'orange';
      icon = (<SupervisorAccountIcon style={{color: 'white'}}/>);
      break;
    case 'Completado':
      color = '#1E9C1C';
      icon = (<CheckCircleOutlineIcon style={{color: 'white'}}/>);
      break;
    case 'Cancelado':
      color = 'red';
      icon = (<HighlightOffIcon style={{color: 'white'}}/>);
      break;
    default:
      color = '#4287F5';
      icon = (<CheckIcon style={{color: 'white'}}/>);
  }
  
  return(
    <Chip className={classes.statusChip} style={{background: color}} size="small" icon={icon} label={status} />
  )
}

export default withStyles(styles)(StatusAvaliacao);