import React, { useState } from 'react';
import { CircularProgress, Paper, CardHeader, Grid, TextField, Button } from '@material-ui/core';
import { AtomicModal } from '../../../../components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import * as yup from 'yup';
import { Formik, Form } from 'formik';

function TrocarSenha({classes, display, onSalvar, onFechar}){

  const initialValue = { Senha: '', Confirmar_Senha: '' };

  const [loading, setLoading] = useState(false)

  const schema = yup.object().shape({
    Senha: yup.string().required('Campo Obrigatório'),
    Confirmar_Senha: yup.string().oneOf([yup.ref('Senha'), null], 'Senhas não conferem')
  })

  function salvarTrocarSenha(values, resetForm) {
    setLoading(true);
    onSalvar(values);
    resetForm();
    setLoading(false);
  }

  function onFecharTrocarSenha(handleReset){
    handleReset(initialValue);
    onFechar();
  }

  return(
    <Formik initialValues={initialValue} onSubmit={(values, resetForm) => salvarTrocarSenha(values, resetForm)} validationSchema={schema} enableReinitialize={true} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleReset
      } = props
      return (
        <AtomicModal open={display} width={300} handleClose={() => onFecharTrocarSenha(handleReset)}>
          <Form>
            <Paper className={classes.modalNew}>
              <CardHeader title="Trocar Senha" style={{textAlign: 'center'}}/>
                <Grid container spacing={1} className={classes.modalFields}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Senha"
                      margin="dense"
                      name="Senha"
                      onChange={handleChange}
                      value={values.Senha}
                      variant="outlined"
                      type="password"
                      helperText={
                        errors.Senha && touched.Senha
                          ? errors.Senha
                          : ''
                      }
                      error={
                        errors.Senha && touched.Senha
                          ? true
                          : false
                      }
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Confirmar Senha"
                      margin="dense"
                      name="Confirmar_Senha"
                      onChange={handleChange}
                      value={values.Confirmar_Senha}
                      variant="outlined"
                      type="password"
                      helperText={
                        errors.Confirmar_Senha && touched.Confirmar_Senha
                          ? errors.Confirmar_Senha
                          : ''
                      }
                      error={
                        errors.Confirmar_Senha && touched.Confirmar_Senha
                          ? true
                          : false
                      }
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
                <Button variant="contained" onClick={() => onFecharTrocarSenha(handleReset)} >
                  CANCELAR
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  className={classes.modalSaveButton}
                >
                  SALVAR
                  {loading ? 
                    <CircularProgress 
                    size={14}
                    color="secondary" />
                    : ''
                  }
                </Button>
              </Paper>
          </Form>
        </AtomicModal>
      )
      }}
    </Formik>
  )

}

export default withStyles(styles)(TrocarSenha);