import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getContaBanco, postContaBanco, putContaBanco, deleteContaBanco } from '../../requests/contaBancoAction';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography,
         IconButton, Card, CardHeader, CardContent, CardActions, Divider, Button } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import NovoContaBanco from './NovoContaBanco';
import EditarContaBanco from './EditarContaBanco';
import { CustomSnackBar, Confirmation } from '../../components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

function FormContaBanco({ classes, titular }) {

  const [contaBanco, setContaBanco] = useState([]);

  const [form, setForm] = useState({
    displayNovo: false,
    displayEditar: false,
    confirmation: false,
    idContaBanco: 0,
    editContaBanco: {}
  });

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  useEffect(() => { carregarContaBanco(titular) }, [titular]);

  async function carregarContaBanco(titular){

    if(titular !== ""){
      const response = await getContaBanco({params: {Id_Titular: titular}});
      if (!response) return
  
      setContaBanco(response.data);
    }
  }

  async function salvarNovaContaBanco(contaBanco){

    const response = await postContaBanco({...contaBanco, Id_Titular: titular});

    if(response.valid){
      setForm({...form, displayNovo: false});
      carregarContaBanco(titular);
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

  }

  async function salvarEditarContaBanco(contaBanco){
    
    const response = await putContaBanco(contaBanco);

    if(response.valid){
      setForm({...form, displayEditar: false});
      carregarContaBanco(titular);
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }
  
  }

  async function onDeletarContaBanco(){

    const response = await deleteContaBanco({Id_Conta_Banco: form.idContaBanco});

    if(response.valid){
      carregarContaBanco(titular);
      setAlert({display: true, severity: 'success', text: 'Conta bancária apagada com sucesso!'});
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setForm({...form, confirmation: false});
    
  }

  function openDialog(idContaBanco, openConfirm) {
    setForm({...form, confirmation: openConfirm, idContaBanco: idContaBanco});
  }

  function onEditarContaBanco(values){
    setForm({...form, displayEditar: true, editContaBanco: values});
  }

  return (
    <div>
      <Card style={{marginLeft: -20, marginRight: -20}}>
        <CardHeader title="Contas Bancárias"/>
        <Divider />
        <CardContent>
          <TableContainer component={Paper} className={classes.table}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.header}>
                  <TableCell className={classes.tableHeader}>Banco</TableCell>
                  <TableCell className={classes.tableHeader} align="center">Tipo da Conta</TableCell>
                  <TableCell className={classes.tableHeader} align="center">Agência</TableCell>
                  <TableCell className={classes.tableHeader} align="center">Conta</TableCell>
                  <TableCell className={classes.tableHeader} align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contaBanco.map((row) => (
                  <TableRow key={row.Id_Conta_Banco}>
                    <TableCell component="th" scope="row">{row.Banco}</TableCell>
                    <TableCell align="center">{row.Tipo_Conta_Banco}</TableCell>
                    <TableCell align="center">{row.Digito_Agencia !== null ? `${row.Agencia}-${row.Digito_Agencia}` : row.Agencia}</TableCell>
                    <TableCell align="center">{row.Digito_Conta !== null ? `${row.Conta}-${row.Digito_Conta}` : row.Conta}</TableCell>
                    <TableCell align="center">
                      <IconButton className={classes.button} onClick={() => openDialog(row.Id_Conta_Banco, true)}>
                        <Delete fontSize='small'/>
                      </IconButton>
                      <IconButton className={classes.button} onClick={() => onEditarContaBanco(row)}>
                        <Edit fontSize='small'/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <Divider/>
        <CardActions>
          <Button variant="contained" color="primary" onClick={() => setForm({...form, displayNovo: true})} >
          ADICIONAR
          </Button>
        </CardActions>
      </Card>

      <NovoContaBanco display={form.displayNovo} onSalvar={salvarNovaContaBanco} onFechar={() => setForm({...form, displayNovo: false})}/>
      <EditarContaBanco display={form.displayEditar} data={form.editContaBanco} onSalvar={salvarEditarContaBanco} onFechar={() => setForm({...form, displayEditar: false})}/>
      
      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setForm({display: false, severity: '', text: ''})}
      />
      <Confirmation 
        title="Apagar conta bancária?"
        open={form.confirmation}
        setOpen={openDialog}
        onConfirm={onDeletarContaBanco}
        >
          <Typography variant="subtitle2">Você tem certeza que deseja apagar essa conta bancária?</Typography>
      </Confirmation>
    </div>
  )

}

FormContaBanco.propTypes = {
  titular: PropTypes.string
};

export default withStyles(styles)(FormContaBanco);