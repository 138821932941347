import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton,
  TextField, Grid, Select, MenuItem, Checkbox, ListItemText, DialogContentText } from '@material-ui/core';
  import FilterListIcon from '@material-ui/icons/FilterList';
import CustomSelectInput from '../CustomSelectInput';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import moment from 'moment';

function FilterButton({classes, dataset, filterConfig, setFilteredData, width}) {
  
  const CST_OP_SELECT = ['incluir', 'excluir'];
  const CST_OP_DATE = ['igual', 'antes de', 'depois de', 'entre'];
  const CST_OP_TEXT = ['contém', 'igual', 'começa com', 'termina com'];

  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState([]);
  const [filter, setFilter] = useState([]);

  useEffect(() => {handleFilterConfig()}, [filterConfig]);

  function handleFilterConfig(){
    
    if(filter.length === 0){
      const newFilterConfig = filterConfig.map((ft) => {
        switch(ft.type){
          case 'select':
            return {...ft, op: CST_OP_SELECT[0], value: []};
          case 'date':
            return {...ft, op: CST_OP_DATE[0], value: ['', '']};
          case 'text':
            return {...ft, op: CST_OP_TEXT[0], value: ['']};
        }
      });

      setConfig(newFilterConfig);
    }else{
      applyFilter(filter);
    }
  }

  function handleValueChange(value, index){
      let newFilterConfig = [...config];
      newFilterConfig[index] = {...newFilterConfig[index], value: value};
      setConfig(newFilterConfig);
  }

  function handleOpChange(value, index){
    let newFilterConfig = [...config];
    newFilterConfig[index] = {...newFilterConfig[index], op: value};
    setConfig(newFilterConfig);
  }

  function confirmar(){

    let newFilterConfig = config.filter((ft) => {
      switch(ft.type){
        case 'select':
          if(ft.value.length > 0){
            return true;
          }else{
            return false;
          }
        case 'date':
          if(ft.value[0] !== ''){
            if(ft.op === 'entre' && ft.value[1] === ''){
              return false;
            }
            return true;
          }else{
            return false;
          }
        default:
          if(ft.value !== ''){
            return true;
          }else{
            return false;
          }
      }
    });

    setFilter(newFilterConfig)
    applyFilter(newFilterConfig);
    
  }

  function applyFilter(filtro){
    
    if(filtro.length > 0){
      const newFiltroReembolsos = dataset.filter((item) => {
  
        let returnItem = true;
  
        filtro.forEach((ft) => {
          switch(ft.type){
            case 'select':
              if(ft.op === 'incluir'){
                if(!ft.value.includes(item[ft.field])){
                  returnItem = false;
                }
              }else{
                if(ft.value.includes(item[ft.field])){
                  returnItem = false;
                }
              }
              break;
            case 'date':
              switch(ft.op){
                case 'igual':
                  if(!moment(item[ft.field]).isSame(ft.value[0], 'day')){
                    returnItem = false;
                  }
                  break;
                case 'antes de':
                  if(!moment(item[ft.field]).isBefore(ft.value[0], 'day')){
                    returnItem = false;
                  }
                  break;
                case 'depois de':
                  if(!moment(item[ft.field]).isAfter(ft.value[0], 'day')){
                    returnItem = false;
                  }
                  break;
                case 'entre':
                  if(!moment(item[ft.field]).isBetween(ft.value[0], ft.value[1], 'day', '[]')){
                    returnItem = false;
                  }
                  break;
              }
              break;
            case 'text':
              switch(ft.op){
                case 'contém':
                  if(!item[ft.field].toString().toLowerCase().includes(ft.value.toString().toLowerCase())){
                    returnItem = false;
                  }
                  break;
                case 'igual':
                  if(item[ft.field].toString().toLowerCase() !== ft.value.toString().toLowerCase()){
                    returnItem = false;
                  }
                  break;
                case 'começa com':
                  if(!item[ft.field].toString().toLowerCase().startsWith(ft.value.toString().toLowerCase())){
                    returnItem = false;
                  }
                  break;
                case 'termina com':
                  if(!item[ft.field].toString().toLowerCase().endsWith(ft.value.toString().toLowerCase())){
                    returnItem = false;
                  }
                  break;
              }
              break;
            default:
              if(ft.value !== item[ft.field]){
                returnItem = false;
              }
          }
        })
  
        return returnItem;
  
      })
  
      setFilteredData(newFiltroReembolsos);
    }else{
      setFilteredData(dataset);
    }

    setOpen(false);
  
  }

  function selectFilterBlock(data, index){
    return(
      <>
      <Grid item md={3} xs={3} style={{padding: '0px 4px'}}>
        <TextField
          fullWidth
          margin="dense"
          SelectProps={{ native: true }}
          value={data.name}
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      
      </>
    )
  }

  function dateFilterBlock(data, index){
    return(
      <>
      <Grid item md={3} xs={3} style={{padding: '0px 4px'}}>
        <TextField
          fullWidth
          margin="dense"
          SelectProps={{ native: true }}
          value={data.name}
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item md={2} xs={2} style={{padding: '0px 4px'}}>
        <TextField
          fullWidth
          margin="dense"
          select
          onChange={(e) => handleOpChange(e.target.value, index)}
          SelectProps={{ native: true }}
          value={data.op}
          variant="outlined"
        >
          {CST_OP_DATE.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </TextField>
      </Grid>
      <Grid item md={3} xs={3} style={{padding: '0px 4px'}}>
        <TextField
          fullWidth
          margin="dense"
          name={data.field}
          type="date"
          value={data.value[0]}
          variant="outlined"
          onChange={(e) => handleValueChange([e.target.value, data.value[1]], index)}
          InputLabelProps={{
              shrink: true,
          }}
          style={{paddingRight: 5}}
        />
      </Grid>
      {data.op === 'entre' &&
      <Grid item md={3} xs={3} style={{padding: '0px 4px'}}>
        <TextField
          fullWidth
          required
          margin="dense"
          name={data.field}
          type="date"
          value={data.value[1]}
          variant="outlined"
          disabled={data.value[0] === ''}
          onChange={(e) => handleValueChange([data.value[0], e.target.value], index)}
          InputLabelProps={{
              shrink: true,
          }}
          style={{paddingRight: 5}}
        />
      </Grid>
      }
      <Grid item md={1} xs={1} style={{padding: '0px 4px'}}>
        {data.value[0] !== '' &&
          <IconButton size="small" onClick={() => handleValueChange(['', ''], index)}>
            <ClearIcon fontSize="inherit"/>
          </IconButton>
        }
      </Grid>
      {data.op !== 'entre' && <Grid item md={3} xs={3} style={{padding: '0px 4px'}} />}
      </>
    )
  }

  function textFilterBlock(data, index){
    return(
      <>
      <Grid item md={3} xs={3} style={{padding: '0px 4px'}}>
        <TextField
          fullWidth
          margin="dense"
          SelectProps={{ native: true }}
          value={data.name}
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item md={2} xs={2} style={{padding: '0px 4px'}}>
        <TextField
          fullWidth
          margin="dense"
          select
          onChange={(e) => handleOpChange(e.target.value, index)}
          SelectProps={{ native: true }}
          value={data.op}
          variant="outlined"
        >
          {CST_OP_TEXT.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </TextField>
      </Grid>
      <Grid item md={3} xs={3} style={{padding: '0px 4px'}}>
        <TextField
          fullWidth
          margin="dense"
          name={data.field}
          value={data.value}
          variant="outlined"
          onChange={(e) => handleValueChange(e.target.value, index)}
          InputLabelProps={{
              shrink: true,
          }}
          style={{paddingRight: 5}}
        />
      </Grid>
      <Grid item md={1} xs={1} style={{padding: '0px 4px'}}>
        {data.value[0] !== '' &&
          <IconButton size="small" onClick={() => handleValueChange([''], index)}>
            <ClearIcon fontSize="inherit"/>
          </IconButton>
        }
      </Grid>
      <Grid item md={3} xs={3} style={{padding: '0px 4px'}} />
      </>
    )
  }

  return (
    <div>
      <Button variant="contained"
        color="primary"
        size="small"
        startIcon={<FilterListIcon />}
        onClick={() => setOpen(true)}
      >
        Filtrar
      </Button>
      <div>
        <Dialog disableEscapeKeyDown scroll="paper" open={open} onClose={() => setOpen(false)} className={classes.dialog} fullWidth={true} maxWidth={width}>
          <DialogTitle><div className={classes.title}>Filtro</div></DialogTitle>
          <DialogContent dividers={true}>
          <DialogContentText>
            <Grid container spacing={1} alignItems="center">
              {config.map((data, index) => {
                switch(data.type){
                  case 'select':
                    return selectFilterBlock(data, index);
                  case 'date':
                    return dateFilterBlock(data, index);
                  case 'text':
                    return textFilterBlock(data, index);
                  defaut:
                    return null;                
                }
              })}
              
            </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={confirmar} className={classes.buttonConfirm}>APLICAR</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );

};


export default withStyles(styles)(FilterButton);