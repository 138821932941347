import React, { useState, useEffect } from 'react';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, Link, IconButton, Collapse, Box,
    Card, CardHeader, CardContent, CardActions, Divider, Button, TablePagination, CircularProgress, Grid } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { getFerias, postFerias, putFerias } from '../../../requests/feriasRequest';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { CustomSnackBar, TablePaginationActions, Confirmation, StatusChip } from '../../../components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NovoSolicitarFerias from './components/NovoSolicitarFerias';
import EditarSolicitarFerias from './components/EditarSolicitarFerias';
import moment from 'moment';

function SolicitarFerias({classes, idFuncionario, idUsuario}){
  
  const [ferias, setFerias] = useState([]);
  const [resumo, setResumo] = useState([]);
  const [open, setOpen] = useState([]);
  const [periodo, setPeriodo] = useState({
    Periodo_Aquisitivo: {
      Inicio: '00-00-0000',
      Fim: '00-00-0000'
    },
    Desfrutado: 0,
    Agendado: 0,
    Saldo: 0,
    Pecuniario: 0,
    Periodos_Completados: 0,
    Periodos_Agendados: 0
  });
  const [periodosCompletados, setPeriodosCompletados] = useState(0);

  const [form, setForm] = useState({
    displayNovo: false,
    displayEditar: false,
    editDisabled: false,
    editarFerias: false,
    confirmation: false,
    idFerias: 0
  });

  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => { carregarFerias() }, [idFuncionario]);

  async function carregarFerias(){
    setLoading(true);

    const response = await getFerias(idFuncionario, {});
    
    if (response.valid) {
      const { ferias, funcionario, resumo } = response.data;

      if(resumo.length > 0){
        setFerias(ferias);
        setResumo(resumo);

        let perCompleto = 0;

        for(let i = 0; i < resumo.length; i++){
          if(resumo[i].Saldo === 0){
            perCompleto = perCompleto + 1;
          }
        }

        setPeriodo(resumo[resumo.length - 1]);
        
        if(resumo[resumo.length - 1].Saldo === 0){

          const perAquisitivoInicio = moment(resumo[perCompleto-1].Periodo_Aquisitivo.Fim, 'DD-MM-YYYY').add(1, 'days');
          const perAquisitivoFim = moment(perAquisitivoInicio).add(364, 'days');

          let proxFerias = {
            Periodo_Aquisitivo: {
              Inicio: perAquisitivoInicio.format('DD-MM-YYYY'),
              Fim: perAquisitivoFim.format('DD-MM-YYYY')
            },
            Desfrutado: 0,
            Agendado: 0,
            Saldo: 30,
            Pecuniario: 0,
            Periodos_Completados: 0,
            Periodos_Agendados: 0
          }          
  
          setPeriodo(proxFerias);
        }
        
        setPeriodosCompletados(perCompleto);
      }else{
        setFerias(ferias);

        let defaultFerias = {
          Periodo_Aquisitivo: {
            Inicio: funcionario.Data_Admissao,
            Fim: moment(funcionario.Data_Admissao, 'DD-MM-YYYY').add(364, 'days').format('DD-MM-YYYY')
          },
          Desfrutado: 0,
          Agendado: 0,
          Saldo: 30,
          Pecuniario: 0,
          Periodos_Completados: 0,
          Periodos_Agendados: 0
        }

        setPeriodo(defaultFerias);
      }

    } else {
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setLoading(false);
  }

  function handlePageChange(event, newPage) {
    setPage(newPage);
  };

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function salvarNovoFerias(novoFerias){

    const response = await postFerias({...novoFerias, Id_Funcionario: idFuncionario, Id_Usuario: idUsuario});

    if(response.valid){
      carregarFerias();
      setForm({...form, displayNovo: false});
      setAlert({display: true, severity: 'success', text: 'Férias solicitadas com sucesso!'});
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

  }

  function onEditarFerias(edtferias){
    setForm({...form, displayEditar: true, editarFerias: edtferias});
  }

  async function salvarEditarFerias(edtFerias){

    const response = await putFerias(form.editarFerias.Id_Ferias, {...edtFerias, Status: 'Solicitado', Id_Usuario: idUsuario});

    if(response.valid){
      carregarFerias();
      setForm({...form, displayEditar: false});
      setAlert({display: true, severity: 'success', text: 'Solicitação de férias alterada com sucesso!'});
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

  }

  async function cancelarFerias(){
    const response = await putFerias(form.editarFerias.Id_Ferias, {Status: 'Cancelado', Id_Usuario: idUsuario});

    if(response.valid){
      carregarFerias();
      setForm({...form, displayEditar: false});
      setAlert({display: true, severity: 'success', text: 'Solicitação de férias cancelada com sucesso!'});
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }
  }

  function onNovoSolicitarFerias(){
    setForm({...form, displayNovo: true})
  }

  function openDialog(idFerias, openConfirm) {
    setForm({...form, confirmation: openConfirm, idFerias: idFerias});
  }

  function handleOpenClose(index){
    let o = [...open];
    o[index] = !open[index];
    setOpen(o);
  }

  function Row({row, index}) {
  
    return (
      <React.Fragment>
        <TableRow className={classes.row}>
          <TableCell className={classes.mainRowCell}>
            <IconButton size="small" onClick={() => handleOpenClose(index)}>
              {open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell className={classes.mainRowCell}>
            <Link className={classes.TableCellLink} onClick={() => setForm({...form, editarFerias: row, displayEditar: true})}>#{row.Id_Ferias}</Link>
          </TableCell>
          <TableCell className={classes.mainRowCell} align="center">{row.Periodo_Aquisitivo_Inicio.substring(6)+'/'+row.Periodo_Aquisitivo_Fim.substring(6)}</TableCell>
          <TableCell className={classes.mainRowCell} align="center">{row.Data_Inicio}</TableCell>
          <TableCell className={classes.mainRowCell} align="center">{row.Data_Fim}</TableCell>
          <TableCell className={classes.mainRowCell} align="center">{row.Duracao} dias</TableCell>
          <TableCell className={classes.mainRowCell} align="center">
            { row.Abono_Pecuniario === 0 ? null : <LocalAtmIcon size="small" style={{ color: 'green' }}/> }
          </TableCell>
          <TableCell className={classes.mainRowCell} align="center">
            <StatusChip status={row.Status}/>
          </TableCell>
          
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open[index]} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small">
                  <TableHead className={classes.subHeader}>
                    <TableRow>
                      <TableCell className={classes.subTableHeader}>Nome</TableCell>
                      <TableCell className={classes.subTableHeader}>Observação</TableCell>
                      <TableCell className={classes.subTableHeader} align="center">Data/Hora</TableCell>
                      <TableCell className={classes.subTableHeader} align="center">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.historico.map((historicoRow) => (
                      <TableRow key={historicoRow.Id_Historico_Reembolso}>
                        <TableCell component="th" scope="row">{historicoRow.usuario.Nome}</TableCell>
                        <TableCell component="th" scope="row">{historicoRow.Observacao}</TableCell>
                        <TableCell align="center">{moment(historicoRow.Data_Hora).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                        <TableCell align="center">
                          <StatusChip status={historicoRow.Status}/>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  

  return(
    <div>
      <Card className={classes.card}>
        <CardHeader title="Solicitação de Férias"/>
        <Divider />
        <CardContent>
          <Paper elevation={2} className={classes.headerInfo}>
          {loading ?
            <div style={{ position: 'relative', top: 10, left: '50%', height: 50}}>
              <CircularProgress size={20}/>
            </div>
          :
            <Grid container>
              
              <Grid container md={12} xs={12}>
                <Typography variant="body2" component="div" className={classes.caption}>
                  Período Aquisitivo Atual:&nbsp;
                </Typography>
                <Typography variant="body2" component="div">
                  {`${periodo.Periodo_Aquisitivo.Inicio} à ${periodo.Periodo_Aquisitivo.Fim}`}
                </Typography>
              </Grid>
              
              <Grid container md={4} xs={4}>
                <Typography variant="body2" component="div" className={classes.caption}>
                  Dias Desfrutados:&nbsp;
                </Typography>
                <Typography variant="body2" component="div">
                  {`${periodo.Desfrutado} dias`}
                </Typography>
              </Grid>
              <Grid container md={8} xs={8}>
                <Typography variant="body2" component="div" className={classes.caption}>
                  Períodos Completados:&nbsp;
                </Typography>
                <Typography variant="body2" component="div">
                {periodo.Periodos_Completados}
                </Typography>
              </Grid>

              <Grid container md={4} xs={4}>
                <Typography variant="body2" component="div" className={classes.caption}>
                  Dias Agendados:&nbsp;
                </Typography>
                <Typography variant="body2" component="div">
                  {`${periodo.Agendado} dias`}
                </Typography>
              </Grid>
              <Grid container md={8} xs={8}>
                <Typography variant="body2" component="div" className={classes.caption}>
                  Períodos Agendados:&nbsp;
                </Typography>
                <Typography variant="body2" component="div">
                {periodo.Periodos_Agendados}
                </Typography>
              </Grid>

              <Grid container md={12} xs={12}>
                <Typography variant="body2" component="div" className={classes.caption}>
                  Saldo Restante:&nbsp;
                </Typography>
                <Typography variant="body2" component="div">
                  {`${periodo.Saldo} dias`}
                </Typography>
              </Grid>
              <Grid container md={12} xs={12}>
                <Typography variant="body2" component="div" className={classes.caption}>
                  Abono Pecuniário:&nbsp;
                </Typography>
                <Typography variant="body2" component="div">
                  {`${periodo.Pecuniario} dias`}
                </Typography>
              </Grid>
            </Grid>
          }
          </Paper>
        </CardContent>
        <Divider />
        {loading ?
          <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
            <CircularProgress />
          </div>
        :
          <CardContent>
            <TableContainer component={Paper} className={classes.table}>
              <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.header}>
                      <TableCell className={classes.tableHeaderCollapse}/>
                      <TableCell className={classes.tableHeader}>ID</TableCell>
                      <TableCell align="center" className={classes.tableHeader}>Per. Aquisitivo</TableCell>
                      <TableCell align="center" className={classes.tableHeader}>Início</TableCell>
                      <TableCell align="center" className={classes.tableHeader}>Fim</TableCell>
                      <TableCell align="center" className={classes.tableHeader}>Duração</TableCell>
                      <TableCell align="center" className={classes.tableHeader}>Abono</TableCell>
                      <TableCell align="center" className={classes.tableHeader}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                    ? ferias.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : ferias
                    ).map((row, index) => (
                      <Row key={row.Id_Ferias} row={row} index={index}/>
                  ))}
                  </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        }
        <Divider/>
        <CardActions style={{height: 30}}>
          <Button variant="contained" color="primary" onClick={onNovoSolicitarFerias} disabled={periodo.Saldo === 0}>NOVO</Button>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
            count={ferias.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
            style={{position: 'absolute', right: 10}}
          />
        </CardActions>
      </Card>

      <NovoSolicitarFerias display={form.displayNovo} onSalvar={salvarNovoFerias} onFechar={() => setForm({...form, displayNovo: false})} periodo={periodo} histFerias={ferias} />
      <EditarSolicitarFerias disabled={form.editDisabled} display={form.displayEditar} data={{...form.editarFerias, refresh: moment()}} periodo={periodo} onSalvar={salvarEditarFerias} histFerias={ferias} onCancelar={cancelarFerias} onFechar={() => setForm({...form, displayEditar: false})}/>
      

      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setAlert({display: false, severity: '', text: ''})}
      />

    </div>
  )

}

const mapStateToProps = store => ({ 
  nome: store.Usuario.usuario.Nome,
  usuario: store.Usuario.usuario.Usuario,
  idUsuario: store.Usuario.usuario.Id_Usuario,
  idFuncionario: store.Usuario.usuario.Id_Funcionario
})

export default connect(mapStateToProps, null)(withRouter(withStyles(styles)(SolicitarFerias)));