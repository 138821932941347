import React, { useState, useEffect } from 'react';
import { CircularProgress, CardHeader, Grid, TextField, Button, Card, Divider, CardContent, CardActions, InputLabel, RadioGroup, FormControlLabel, Radio, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { CustomSnackBar } from '../../../../components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import AdicionarCriterio from '../AdicionarCriterio';
import { putFormulario } from '../../../../requests/formularioAction';
import { getCriterioFormularios, putCriterioFormulario, postCriterioFormulario, deleteCriterioFormulario } from '../../../../requests/criterioFormularioAction';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useLocation } from "react-router-dom";

function EditarFormulario({classes, history}){

  const location = useLocation();

  const initialValues = {
    Nome: location.formulario.Nome,
    Id_Formulario: location.formulario.Id_Formulario,
  };

  const [form, setForm] = useState({
    adicionarCriteriosDisplay: false
  })
  
  const [idSelecionado, setIdSelecionado] = useState([0])
  const [criterios, setCriterios] = useState([]);
  const [criteriosAdicionados, setCriteriosAdicionados] = useState([]);
  const [criteriosRemovidos, setCriteriosRemovidos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  useEffect(() => { carregarCriteriosFormulario() }, []);

  async function carregarCriteriosFormulario(){
    setLoading(true);

    const response = await getCriterioFormularios({Id_Formulario: location.formulario.Id_Formulario});
    if (response.valid) {
      setCriterios(response.data);

      let ids = [0];
      for(var i = 0; i < response.data.length; i++){
        ids.push(response.data[i].Id_Criterio);
      }
      setIdSelecionado(ids);
    } else {
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setLoading(false);
  }

  function returnToFormularios(){
    history.push('/formularios');
  }

  async function salvarFormulario(formulario, resetForm){
    setSubmitting(true);
    
    const response = await putFormulario(formulario);

    if(response.valid){
      
      const idFormulario = response.data.Id_Formulario;

      for(var i = 0; i < criterios.length; i++){
        
        let responseCritForm;

        if(criterios[i].Id_Criterio_Formulario !== undefined){
          responseCritForm = await putCriterioFormulario({Id_Criterio_Formulario: criterios[i].Id_Criterio_Formulario, Order: i+1});
        }else{
          responseCritForm = await postCriterioFormulario({Id_Formulario: idFormulario, Id_Criterio: criterios[i].Id_Criterio, Order: i+1});
        }

        if(!responseCritForm.valid){
          setAlert({display: true, severity: 'error', text: responseCritForm.message});
          break;
        }
        
      }

      for(var j = 0; j < criteriosRemovidos.length; j++){
        if(criteriosRemovidos[j].Id_Criterio_Formulario !== undefined){
          let responseCritForm = await deleteCriterioFormulario({Id_Criterio_Formulario: criteriosRemovidos[j].Id_Criterio_Formulario});

          if(!responseCritForm.valid){
            setAlert({display: true, severity: 'error', text: responseCritForm.message});
            break;
          }
        }
      }
      
      setAlert({display: true, severity: 'success', text: 'Formulário de avaliação alterado com sucesso!'});
      setTimeout(returnToFormularios, 1500);
      
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }
    
    setSubmitting(false);
  }

  async function onAdicionarCriterios(criteriosSelecionados, idSelecionados){
    let novoCriterios = JSON.parse(JSON.stringify(criterios));
    
    novoCriterios.push(...criteriosSelecionados);

    setCriterios(novoCriterios);
    
    let novoIdSelecionado = [...idSelecionado];
    novoIdSelecionado.push(...idSelecionados);
    setIdSelecionado(novoIdSelecionado);
    
    criteriosAdicionados.push(...criteriosSelecionados);

    setForm({...form, adicionarCriteriosDisplay: false});
  }

  function removerCriterio(item){
    
    let novoCriterios = JSON.parse(JSON.stringify(criterios));

    let indexCriterios = novoCriterios.findIndex(el => el.Id_Criterio === item.Id_Criterio);
    novoCriterios.splice(indexCriterios, 1);
    
    setCriterios(novoCriterios);

    let novoIdSelecionado = [...idSelecionado];
    const index = novoIdSelecionado.indexOf(item.Id_Criterio);

    if(index > -1){
      novoIdSelecionado.splice(index, 1);
    }

    setIdSelecionado(novoIdSelecionado);

    if(item.Id_Criterio_Formulario !== undefined){
      criteriosRemovidos.push(item);
    }
  }

  function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  function onDragEnd(result){
    if (!result.destination) {
      return;
    }

    const items = reorder(
      criterios,
      result.source.index,
      result.destination.index
    );

    setCriterios(items);
  }

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    
    userSelect: "none",
    background: isDragging ? "lightgreen" : "white",
    ...draggableStyle

  });
  
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "white",
    padding: grid,
    width: 1200
  });
  
  const schema = yup.object().shape({
    Nome: yup.string().required('Campo Obrigatório')
  })

  return (
    <Formik initialValues={initialValues} onSubmit={(values, { resetForm }) => salvarFormulario(values, resetForm)} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange
      } = props
      return (
        <Card>

          <AdicionarCriterio display={form.adicionarCriteriosDisplay} onAdicionar={onAdicionarCriterios} onFechar={() => setForm({...form, adicionarCriteriosDisplay: false})} data={criterios} idSelecionados={idSelecionado}/>

          <CustomSnackBar display={alert.display} severity={alert.severity} text={alert.text} onClose={() => setAlert({display: false, severity: '', text: ''})}/>

          <Form>
            <CardHeader title="Editar Formulário"/>
            <Divider />
            
            {loading ?
              <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
                <CircularProgress />
              </div>
            :
              <div>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item md={3} xs={6}>
                      <TextField
                        fullWidth
                        label="Formulário"
                        margin="dense"
                        name="Nome"
                        onChange={handleChange}
                        value={values.Nome}
                        variant="outlined"
                        helperText={errors.Nome && touched.Nome ? errors.Nome : ''}
                        error={errors.Nome && touched.Nome ? true : false}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <Button className={classes.buttonAddCriteria} variant="contained" color="primary" onClick={() => setForm({...form, adicionarCriteriosDisplay: true})}>ADICIONAR CRITÉRIOS</Button>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    >                    

                    {criterios.map((item, index) => (
                      <Draggable key={item.Id_Criterio} draggableId={item.Criterio} index={index}>
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging,provided.draggableProps.style)}>
                          <Card className={classes.criterioBlock}>

                            <IconButton style={{marginLeft: -20, marginTop: -20}} onClick={() => removerCriterio(item)}>
                              <ClearIcon fontSize="small"/>
                            </IconButton>

                            <InputLabel className={classes.labelCriterio}>{index+1} - {item.Criterio}</InputLabel>

                            {item.Tipo === 1 || item.Tipo === 3 ?
                              <RadioGroup name="nota" value={1} row disabled={true} className={classes.labelNota}>
                                <FormControlLabel value={1} control={<Radio />} label="Não satisfatório" />
                                <FormControlLabel value={2} control={<Radio />} label="Precisa melhorar" />
                                <FormControlLabel value={3} control={<Radio />} label="Atende às expectativas" />
                                <FormControlLabel value={4} control={<Radio />} label="Acima da expectativa" />                          
                              </RadioGroup>
                            : null}

                            {item.Tipo === 2 || item.Tipo === 3 ?  
                              <TextField
                                fullWidth
                                margin="dense"
                                name="Criterio"
                                variant="outlined"
                                multiline
                                rows={4}
                                disabled={true}                  
                              />
                            : null}
                          </Card>
                        </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    </div>
                    )}
                    </Droppable>
                  </DragDropContext>
                </CardContent>
              </div>
            }
            <Divider />
            <CardActions>
              <Button variant="contained" onClick={returnToFormularios}>VOLTAR</Button>
              <Button type="submit" variant="contained" color="primary" disabled={submitting}>
                SALVAR
                {submitting ? 
                  <CircularProgress 
                  size={14}
                  color="secondary" />
                  : ''
                }
              </Button>
            </CardActions>
          </Form>
        </Card>
        )
      }}
    </Formik>
  )
}

export default withStyles(styles)(EditarFormulario);