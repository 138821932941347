import api from '../services/api';
import { handleRequestError } from '../services/security';

export async function getRecuperarSenha( chave ){

  let responseFromApi;

  try {
    const response = await api.get(`/recuperar-senha/${chave}`, {});

    if (response.status === 200) {
        responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postRecuperarSenha(email){

  let responseFromApi;

  const body = {Email: email};

  try {
    const response = await api.post('/recuperar-senha', body);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function putRecuperarSenha(body){

  let responseFromApi;

  try {
    const response = await api.put('/recuperar-senha', body);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}