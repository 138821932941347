import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { FormExtrato } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const funcData = {
  Nome: '',
  Registro: '',
  Id_Contratacao: '1',
  Email: '',
  Telefone: '',
  CPF: '',
  CNPJ: '',
  RG: '',
  Nascimento: '',
  Id_Genero: '1',
  Id_Estado_Civil: '1',
  Data_Admissao: '',
  Data_Desligamento: '',
  Id_Status: '1'
};

const NovoExtrato = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Grid
          item
          lg={12}
          md={10}
          xl={12}
          xs={16}
        >
          <FormExtrato disabled={false} funcData={funcData} acao='Novo' />
        </Grid>
    </div>
  );
};

export default NovoExtrato;
