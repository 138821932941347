import React, { useState, useEffect } from 'react';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Grid,
    Card, CardHeader, CardContent, CardActions, Divider, Button, TablePagination, Link, CircularProgress, Typography, Collapse, Box } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { listCliente, postCliente, putCliente, deleteCliente } from '../../requests/clienteRequest';
import { postEndereco, putEndereco, deleteEndereco } from '../../requests/enderecoRequest';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { CustomSnackBar, Confirmation, TablePaginationActions } from '../../components';
import NovoCliente from './NovoCliente';
import EditarCliente from './EditarCliente';


function Cliente({classes}){
  
  const [cliente, setCliente] = useState([]);

  const [form, setForm] = useState({
    displayNovo: false,
    displayEditar: false,
    editarCliente: {}
  });

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  });

  const [confirmation, setConfirmation] = useState({
    display: false,
    title: 'Apagar Cliente',
    text: 'Você tem certeza que deseja apagar esse cliente?',
    acao: function(){}
  });

  const [loading, setLoading] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState([]);

  useEffect(() => { carregarCliente() }, []);

  async function carregarCliente(){
    setLoading(true);

    const response = await listCliente();
    if(response.valid){
      setCliente(response.data);
    } else {
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setLoading(loading);
  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function salvarNovoCliente(cliente){
   
    const {endereco, ...clienteData} = cliente;

    const responseEndereco = await postEndereco(endereco);

    if(responseEndereco.valid){

      const responseCliente = await postCliente({...clienteData, Id_Endereco: responseEndereco.data.Id_Endereco});

      if(responseCliente.valid){
        setForm({...form, displayNovo: false});
        setAlert({display: true, severity: 'success', text: 'Cliente criado com sucesso!'})
        carregarCliente();
      }else{
        setAlert({display: true, severity: 'error', text: responseCliente.message});
      }

    }else{
      setAlert({display: true, severity: 'error', text: responseEndereco.message});
    }
  }

  async function salvarEditarCliente(cliente){
    const {endereco, ...clienteData} = cliente;

    const responseEndereco = await putEndereco(endereco);

    if(responseEndereco.valid){

      const responseCliente = await putCliente({...clienteData});

      if(responseCliente.valid){
        setForm({...form, displayEditar: false});
        setAlert({display: true, severity: 'success', text: 'Cliente atualizado com sucesso!'})
        carregarCliente();
      }else{
        setAlert({display: true, severity: 'error', text: responseCliente.message});
      }

    }else{
      setAlert({display: true, severity: 'error', text: responseEndereco.message});
    }
  }

  async function onDeletarCliente(){
    const responseCliente = await deleteCliente(form.editarCliente.Id_Cliente);

    if(responseCliente.valid){

      const responseEndereco = await deleteEndereco(form.editarCliente.Id_Endereco);

      if(responseEndereco.valid){
        carregarCliente();
        setAlert({display: true, severity: 'success', text: 'Cliente apagado com sucesso!',})
      }else{
        setAlert({display: true, severity: 'error', text: responseEndereco.message});
      }
      
    }else{
      setAlert({display: true, severity: 'error', text: responseCliente.message});
    }

    setConfirmation({...confirmation, display: false});
  }

  function openDialog(cliente, openConfirm) {
    setForm({...form, editarCliente: cliente});
    setConfirmation({...confirmation, display: openConfirm});
  }

  function closeConfirmation(cliente, open){
    setConfirmation({...confirmation, display: open});
  }

  function handleOpenClose(index){
    let o = [...open];
    o[index] = !open[index];
    setOpen(o);
  }


  function Row({row, index}) {
  
    return (
      <React.Fragment>
        <TableRow className={classes.row}>
          <TableCell className={classes.mainRowCell}>
            <IconButton size="small" onClick={() => handleOpenClose(index)}>
              {open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>
            <Link className={classes.TableCellLink} onClick={() => setForm({...form, editarCliente: row, displayEditar: true})}>{row.Nome}</Link>
          </TableCell>
          <TableCell className={classes.mainRowCell}>{row.Razao_Social}</TableCell>
          <TableCell className={classes.mainRowCell} align="center">{row.CNPJ}</TableCell>
          <TableCell align="center">
            <IconButton size="small" className={classes.button} onClick={() => openDialog(row, true)}>
              <Delete fontSize='small'/>
            </IconButton>
          </TableCell>
        </TableRow>
        
        
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open[index]} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Card className={classes.cardEndereco}>
                  {row.endereco &&
                    <Grid container>
                      <Grid item md={5} xs={5}>
                        <Typography variant="body2">Logradouro: {row.endereco.Logradouro}, {row.endereco.Numero}</Typography>
                      </Grid>
                      
                      <Grid item md={7} xs={7}>
                        <Typography variant="body2">Complemento: {row.endereco.Complemento}</Typography>
                      </Grid>
                                            
                      <Grid item md={5} xs={5}>
                        <Typography variant="body2">Bairro: {row.endereco.Bairro}</Typography>
                      </Grid>
                      
                      <Grid item md={7} xs={7}>
                        <Typography variant="body2">CEP: {row.endereco.CEP}</Typography>
                      </Grid>
                      
                      <Grid item md={12} xs={12}>
                        <Typography variant="body2">Cidade: {row.endereco.Cidade} - {row.endereco.estado.Sigla}</Typography>
                      </Grid>
                    </Grid>
                  }
                </Card>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }



  return(
    <div>
      <Card className={classes.card}>
        <CardHeader title="Clientes"/>
        <Divider />
        {loading ?
          <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
            <CircularProgress />
          </div>
        :
          <CardContent>
            <TableContainer component={Paper} className={classes.table}>
            <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow className={classes.header}>
                    <TableCell className={classes.tableHeaderCollapse}/>
                    <TableCell className={classes.tableHeader}>Nome</TableCell>
                    <TableCell className={classes.tableHeader}>Razão Social</TableCell>
                    <TableCell className={classes.tableHeader} align="center">CNPJ</TableCell>
                    <TableCell className={classes.tableHeader} align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {(rowsPerPage > 0
                  ? cliente.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : cliente
                  ).map((row, index) => (
                    <Row key={row.Id_Cliente} row={row} index={index}/>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
          </CardContent>
        }
        <Divider/>
        <CardActions>
          <Button variant="contained" color="primary" onClick={() => setForm({...form, displayNovo: true})}>NOVO</Button>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
            count={cliente.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
            style={{position: 'absolute', right: 10}}
          />
        </CardActions>
      </Card>
      
      <NovoCliente display={form.displayNovo} onSalvar={salvarNovoCliente} onFechar={() => setForm({...form, displayNovo: false})}/>
      <EditarCliente display={form.displayEditar} data={form.editarCliente} onSalvar={salvarEditarCliente} onFechar={() => setForm({...form, displayEditar: false})}/>

      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setAlert({display: false, severity: '', text: ''})}
      />
      <Confirmation 
        title={confirmation.title}
        open={confirmation.display}
        setOpen={closeConfirmation}
        onConfirm={onDeletarCliente}
      >
        <Typography variant="body2">{confirmation.text}</Typography>
      </Confirmation>
      
    </div>
  )

}

export default withStyles(styles)(Cliente);