import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, CardActions, Divider, Button, CircularProgress, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { getFormulario } from '../../../../requests/formularioAction';
import { CustomSnackBar } from '../../../../components';
import { getFuncionarios } from '../../../../requests/funcionariosRequest';

function ResumoNovaAvaliacao({classes, idFuncionarios, idFormulario, onSalvarAvaliacao, setStepAtivo, periodo}){
  
  const [funcionarios, setFuncionarios] = useState([]);
  const [formulario, setFormulario] = useState([]);
  
  const [loading, setLoading] = useState(false);
  
  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  useEffect(() => { carregarResumo() }, []);

  async function carregarResumo(){
    setLoading(true);

    const responseFuncionarios = await getFuncionarios({Id_Funcionario: idFuncionarios});
    if (responseFuncionarios.valid) {
      setFuncionarios(responseFuncionarios.data);
    } else {
      setAlert({display: true, severity: 'error', text: responseFuncionarios.message});
    }

    const responseFormulario = await getFormulario({Id_Formulario: idFormulario});
    if (responseFormulario.valid) {
      setFormulario(responseFormulario.data);
    } else {
      setAlert({display: true, severity: 'error', text: responseFormulario.message});
    }

    setLoading(false);
  }

  function onFinalizar(){

    let avaliacao = [];

    funcionarios.map((item) => (
      avaliacao.push({ Id_Funcionario: item.Id_Funcionario, Id_Gestor: item.Id_Usuario_Gestor, Id_Formulario: formulario.Id_Formulario, Id_Periodo_Avaliacao: periodo.Id_Periodo_Avaliacao })
    ));

    onSalvarAvaliacao(avaliacao);
  }

  function onVoltar(){
    setStepAtivo(1);
  }
  
  return(
    <div>
      <Card className={classes.card}>
        <CardHeader title="Resumo da Criação de Avaliações"/>
        <Divider />
        
          {loading ?
            <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
              <CircularProgress />
            </div>
          :
            <CardContent>
              <Typography variant="h3">Período Avaliado</Typography>
              <Typography variant="subtitle2">Ano {periodo.Ano} - {periodo.Semestre}º Semestre</Typography>
              <Typography variant="h3" style={{marginTop: 40}}>Funcionários</Typography>
              <Typography variant="subtitle2"> {funcionarios.length} selecionado(s)</Typography>
                <ul>
                  {funcionarios.map((row) => (
                    <li><Typography variant="h6">{row.Nome}</Typography></li>
                  ))}
                </ul>
              <Typography variant="h3" style={{marginTop: 40}}>Formulário</Typography>
              <ul>
                <li><Typography variant="h6">{formulario.Nome}</Typography></li>
              </ul>
            </CardContent>
          }
        
        <Divider/>
        <CardActions>
          <Button variant="contained" onClick={onVoltar}>VOLTAR</Button>
          <Button variant="contained" color="primary" onClick={onFinalizar}>FINALIZAR</Button>
        </CardActions>
      </Card>

      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setAlert({display: false, severity: '', text: ''})}
      />

    </div>
  )

}

export default withStyles(styles)(ResumoNovaAvaliacao);