import React, { useState, useEffect } from 'react';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Link, Collapse, Box,
    Card, CardHeader, CardContent, CardActions, Divider, Typography, TablePagination, CircularProgress } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { listFerias, putFerias } from '../../../requests/feriasRequest';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { CustomSnackBar, TablePaginationActions, Confirmation, StatusChip, SearchInput } from '../../../components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EditarFerias from './components/EditarFerias';
import moment from 'moment';

function GestaoFerias({classes, idUsuario, perfil, history}){
  
  const [ferias, setFerias] = useState([]);
  const [filtroFerias, setFiltroFerias] = useState([]);
  const [filterConfig, setFilterConfig] = useState({
    Funcionario: null,
    Data_Inicio: null,
    Data_Fim: null
  });
  const [selectOptsFilter, setSelectOptsFilter] = useState({
    funcionario: [],
    status: []
  });
  
  const [form, setForm] = useState({
    displayNovo: false,
    displayEditar: false,
    editDisabled: false,
    editarFerias: false,
    confirmation: false,
    idFerias: 0
  });

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => { carregarFerias() }, []);

  async function carregarFerias(){
    setLoading(true);

    if(!perfil.funcoes.read.includes('GerirFerias')){
      setLoading(false);
      history.push('/proibido');
    }

    const response = await listFerias();
    
    if (response.valid) {
      setFerias(response.data);
      setFiltroFerias(response.data);
    } else {
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setLoading(false);
  }

  function handlePageChange(event, newPage) {
    setPage(newPage);
  };

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function salvarEditarFerias(idFerias, body){

    const response = await putFerias(idFerias, {...body, Id_Usuario: idUsuario});

    if(response.valid){
      carregarFerias();
      setForm({...form, displayEditar: false});
      setAlert({display: true, severity: 'success', text: 'Solicitação de férias alterada com sucesso!'});
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

  }

  async function cancelarFerias(){

  }

  function openDialog(idFerias, openConfirm) {
    setForm({...form, confirmation: openConfirm, idFerias: idFerias});
  }

  function handleOpenClose(index){
    let o = [...open];
    o[index] = !open[index];
    setOpen(o);
  }

  function filtrarFuncionario(event){
    applyFilter('Funcionario', event.target.value);
  }

  function filtrarDataInicio(value){
    applyFilter('Data_Inicio', value);
  }

  function filtrarDataFim(value){
    applyFilter('Data_Fim', value);
  }

  function applyFilter(field, value){
    let dataValues = [];

    let filterOp = {...filterConfig, [field]: value};
    
    if(filterOp.Funcionario){
      dataValues = ferias.filter(fer => fer.Funcionario.toLowerCase().includes(filterOp.Funcionario.toLowerCase()));
    }else{
      dataValues = [...ferias];
    }

    if(filterOp.Data_Inicio){
      dataValues = dataValues.filter(fer => moment(fer.Data_Inicio, 'DD-MM-YYYY').diff(moment(filterOp.Data_Inicio, 'DD-MM-YYYY')) >= 0);
    }

    if(filterOp.Data_Fim){
      dataValues = dataValues.filter(fer => moment(fer.Data_Fim, 'DD-MM-YYYY').diff(moment(filterOp.Data_Fim, 'DD-MM-YYYY')) <= 0);
    }

    setFilterConfig(filterOp);
    setFiltroFerias(dataValues);
  }
  
  function Row({row, index}) {
  
    return (
      <React.Fragment>
        <TableRow className={classes.row}>
          <TableCell className={classes.mainRowCell}>
            <IconButton size="small" onClick={() => handleOpenClose(index)}>
              {open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell className={classes.mainRowCell}>
            <Link className={classes.TableCellLink} onClick={() => setForm({...form, editarFerias: row, displayEditar: true})}>#{row.Id_Ferias}</Link>
          </TableCell>
          <TableCell className={classes.mainRowCell} align="center">{row.Funcionario}</TableCell>
          <TableCell className={classes.mainRowCell} align="center">{row.Data_Inicio}</TableCell>
          <TableCell className={classes.mainRowCell} align="center">{row.Data_Fim}</TableCell>
          <TableCell className={classes.mainRowCell} align="center">{row.Duracao} dias</TableCell>
          <TableCell className={classes.mainRowCell} align="center">
            { row.Abono_Pecuniario === 0 ? null : <LocalAtmIcon size="small" style={{ color: 'green' }}/> }
          </TableCell>
          <TableCell className={classes.mainRowCell} align="center">
            <StatusChip status={row.Status}/>
          </TableCell>
          
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open[index]} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small">
                  <TableHead className={classes.subHeader}>
                    <TableRow>
                      <TableCell className={classes.subTableHeader}>Nome</TableCell>
                      <TableCell className={classes.subTableHeader} align="center">Status</TableCell>
                      <TableCell className={classes.subTableHeader}>Observação</TableCell>
                      <TableCell className={classes.subTableHeader} align="center">Data/Hora</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.historico.map((historicoRow) => (
                      <TableRow key={historicoRow.Id_Historico_Reembolso}>
                        <TableCell component="th" scope="row" style={{width: '25%'}}>{historicoRow.usuario.Nome}</TableCell>
                        <TableCell align="center" style={{width: '15%'}}>
                          <StatusChip status={historicoRow.Status}/>
                        </TableCell>
                        <TableCell component="th" scope="row" style={{width: '40%'}}>{historicoRow.Observacao}</TableCell>
                        <TableCell align="center" style={{width: '20%'}}>{moment(historicoRow.Data_Hora).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  

  return(
    <div>
      <Card className={classes.card}>
        <CardHeader title="Gestão de Férias"/>
        <Divider />
        {loading ?
          <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
            <CircularProgress />
          </div>
        :
          <CardContent>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <IconButton size="small" onClick={() => setOpenFilter(!openFilter)}>
                {openFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              <Typography variant="subtitle2">{openFilter ? 'Ocultar' : 'Exibir'} Filtro</Typography>
            </div>
            <Collapse in={openFilter} timeout="auto" unmountOnExit>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <SearchInput
                  className={classes.searchInput}
                  placeholder="Funcionário"
                  onChange={filtrarFuncionario}
                  style={{width: 300, height: 20}}
                />
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    clearable
                    margin="dense"
                    id="filtro-data-inicio"
                    name="Filtro_Data_Inicio"
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    style={{marginLeft: 5, width: 200, marginTop: 4}}
                    InputProps={{placeholder: 'Data Início'}}
                    inputProps={{style: {color: '#A4A9AB'}}}
                    InputLabelProps={{style: {color: '#A4A9AB'}}}
                    onChange={value => filtrarDataInicio(value)}
                    value={filterConfig.Data_Inicio}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    clearable
                    margin="dense"
                    id="filtro-data-fim"
                    name="Filtro_Data_Fim"
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    style={{marginLeft: 5, width: 200, marginTop: 4}}
                    InputProps={{placeholder: 'Data Fim'}}
                    inputProps={{style: {color: '#A4A9AB'}}}
                    InputLabelProps={{style: {color: '#A4A9AB'}}}
                    onChange={value => filtrarDataFim(value)}
                    value={filterConfig.Data_Fim}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Collapse>
            
            <TableContainer component={Paper} className={classes.table}>
              <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.header}>
                      <TableCell className={classes.tableHeaderCollapse}/>
                      <TableCell className={classes.tableHeader}>ID</TableCell>
                      <TableCell align="center" className={classes.tableHeader}>Funcionário</TableCell>
                      <TableCell align="center" className={classes.tableHeader}>Início</TableCell>
                      <TableCell align="center" className={classes.tableHeader}>Fim</TableCell>
                      <TableCell align="center" className={classes.tableHeader}>Duração</TableCell>
                      <TableCell align="center" className={classes.tableHeader}>Abono</TableCell>
                      <TableCell align="center" className={classes.tableHeader}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                    ? filtroFerias.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : filtroFerias
                    ).map((row, index) => (
                      <Row key={row.Id_Ferias} row={row} index={index}/>
                  ))}
                  </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        }
        <Divider/>
        <CardActions style={{height: 30}}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
            count={ferias.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
            style={{position: 'absolute', right: 10}}
          />
        </CardActions>
      </Card>

      <EditarFerias disabled={form.editDisabled} display={form.displayEditar} data={form.editarFerias} onSalvar={salvarEditarFerias} onFechar={() => setForm({...form, displayEditar: false})}/>
      

      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setAlert({display: false, severity: '', text: ''})}
      />

      <Confirmation 
        title="Cancelar solicitação de férias?"
        open={form.confirmation}
        setOpen={openDialog}
        onConfirm={cancelarFerias}
        >
          Você tem certeza que deseja cancelar essa solicitação de férias?
      </Confirmation>
      
    </div>
  )

}

const mapStateToProps = store => ({
  perfil: store.Perfil,
  idUsuario: store.Usuario.usuario.Id_Usuario,
})

export default connect(mapStateToProps, null)(withRouter(withStyles(styles)(GestaoFerias)));