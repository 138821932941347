import React from 'react';
import { Switch, Redirect, useLocation, Route, useParams } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';


import {
  Login as LoginView,
  MainPage as MainPageView,
  Funcionarios as FuncionariosView,
  NovoFuncionario as NovoFuncionarioView,
  ExibirFuncionario as ExibirFuncionarioView,
  Cliente as ClienteView,
  Ativos as AtivosView,
  ExibirAtivo as ExibirAtivoView,
  NovoAtivo as NovoAtivoView,
  Extratos as ExtratosView,
  ExibirExtrato as ExibirExtratoView,
  NovoExtrato as NovoExtratoView,
  Oportunidades as OportunidadesView,
  ExibirOportunidade as ExibirOportunidadeView,
  NovoOportunidade as NovoOportunidadeView,
  MinhaConta as MinhaContaView,
  Usuarios as UsuariosView,
  Perfis as PerfisView,
  EditarPerfil as EditarPerfilView,
  Funcoes as FuncoesView,
  Criterios as CriteriosView,
  Formularios as FormulariosView,
  NovoFormulario as NovoFormularioView,
  EditarFormulario as EditarFormularioView,
  Avaliacoes as AvaliacoesView,
  NovaAvaliacao as NovaAvaliacaoView,
  VisualizarAvaliacao as VisualizarAvaliacaoView,
  MinhasAvaliacoes as MinhasAvaliacoesView,
  RealizarAvaliacao as RealizarAvaliacaoView,
  AvaliarFuncionario as AvaliarFuncionarioView,
  RealizarAvaliacaoGestor as RealizarAvaliacaoGestorView,
  ConcluirAvaliacao as ConcluirAvaliacaoView,
  RecuperarSenha as RecuperarSenhaView,
  TrocarSenha as TrocarSenhaView,
  MeusReembolsos as MeusReembolsosView,
  GestaoReembolsos as GestaoReembolsosView,
  SolicitarFerias as SolicitarFeriasView,
  GestaoFerias as GestaoFeriasView,
  GestaoHolerite as GestaoHoleriteView,
  Holerite as HoleriteView,
  Proibido as ProibidoView,
  Cargos as CargosView,
  NovoCargo as NovoCargoView,
  ExibirCargo as ExibirCargoView,
  Vagas as VagasView,
} from './views';

const Routes = () => {

  const location = useLocation();

  return (
    <Switch>

      <Redirect
        exact
        from="/"
        to="/atomic"
      />
      <Redirect
        exact
        from="/"
        to="/atomic"
      />
      <RouteWithLayout
        component={MainPageView}
        exact
        layout={MainLayout}
        path="/atomic"
      />
      <Route exact path="/login"><MinimalLayout><LoginView /></MinimalLayout></Route>
      <Route exact path="/recuperarSenha" children={<MinimalLayout><RecuperarSenhaView /></MinimalLayout>} />
      <Route path="/trocarSenha/:chave" children={<MinimalLayout><TrocarSenhaView /></MinimalLayout>} />

      <RouteWithLayout
        component={MainPageView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={NovoFuncionarioView}
        exact
        layout={MainLayout}
        path="/funcionarios/novo"
      />
      <RouteWithLayout
        component={ExibirFuncionarioView}
        exact
        layout={MainLayout}
        path="/funcionarios/exibir"
      />
      <RouteWithLayout
        component={FuncionariosView}
        exact
        layout={MainLayout}
        path="/funcionarios"
      />
      <RouteWithLayout
        component={ClienteView}
        exact
        layout={MainLayout}
        path="/cliente"
      />
      <RouteWithLayout
        component={AtivosView}
        exact
        layout={MainLayout}
        path="/ativos"
      />
      <RouteWithLayout
        component={ExibirAtivoView}
        exact
        layout={MainLayout}
        path="/ativos/exibir"
      />
      <RouteWithLayout
        component={NovoAtivoView}
        exact
        layout={MainLayout}
        path="/ativos/novo"
      />
      <RouteWithLayout
        component={ExtratosView}
        exact
        layout={MainLayout}
        path="/extratos"
      />
      <RouteWithLayout
        component={ExibirExtratoView}
        exact
        layout={MainLayout}
        path="/extratos/exibir"
      />
      <RouteWithLayout
        component={NovoExtratoView}
        exact
        layout={MainLayout}
        path="/extratos/novo"
      />
      <RouteWithLayout
        component={OportunidadesView}
        exact
        layout={MainLayout}
        path="/oportunidades"
      />
      <RouteWithLayout
        component={ExibirOportunidadeView}
        exact
        layout={MainLayout}
        path="/oportunidades/exibir"
      />
      <RouteWithLayout
        component={NovoOportunidadeView}
        exact
        layout={MainLayout}
        path="/oportunidades/novo"
      />
      <RouteWithLayout
        component={MinhaContaView}
        exact
        layout={MainLayout}
        path="/minhaconta"
      />
      <RouteWithLayout
        component={UsuariosView}
        exact
        layout={MainLayout}
        path="/usuarios"
      />
      <RouteWithLayout
        component={PerfisView}
        exact
        layout={MainLayout}
        path="/perfis"
      />
      <RouteWithLayout
        component={EditarPerfilView}
        exact
        layout={MainLayout}
        path="/perfis/editar"
      />
      <RouteWithLayout
        component={FuncoesView}
        exact
        layout={MainLayout}
        path="/funcoes"
      />
      <RouteWithLayout
        component={CriteriosView}
        exact
        layout={MainLayout}
        path="/criterios"
      />
      <RouteWithLayout
        component={FormulariosView}
        exact
        layout={MainLayout}
        path="/formularios"
      />
      <RouteWithLayout
        component={NovoFormularioView}
        exact
        layout={MainLayout}
        path="/formularios/novo"
      />
      <RouteWithLayout
        component={EditarFormularioView}
        exact
        layout={MainLayout}
        path="/formularios/editar"
      />
      <RouteWithLayout
        component={AvaliacoesView}
        exact
        layout={MainLayout}
        path="/avaliacoes"
      />
      <RouteWithLayout
        component={NovaAvaliacaoView}
        exact
        layout={MainLayout}
        path="/avaliacoes/novo"
      />
      <RouteWithLayout
        component={VisualizarAvaliacaoView}
        exact
        layout={MainLayout}
        path="/avaliacoes/visualizar"
      />
      <RouteWithLayout
        component={MinhasAvaliacoesView}
        exact
        layout={MainLayout}
        path="/minhasAvaliacoes"
      />
      <RouteWithLayout
        component={RealizarAvaliacaoView}
        exact
        layout={MainLayout}
        path="/minhasAvaliacoes/realizar"
      />
      <RouteWithLayout
        component={AvaliarFuncionarioView}
        exact
        layout={MainLayout}
        path="/avaliarFuncionario"
      />
      <RouteWithLayout
        component={RealizarAvaliacaoGestorView}
        exact
        layout={MainLayout}
        path="/avaliarFuncionario/realizar"
      />
      <RouteWithLayout
        component={ConcluirAvaliacaoView}
        exact
        layout={MainLayout}
        path="/avaliarFuncionario/concluir"
      />
      <RouteWithLayout
        component={SolicitarFeriasView}
        exact
        layout={MainLayout}
        path="/rh/solicitarFerias"
      />
      <RouteWithLayout
        component={GestaoFeriasView}
        exact
        layout={MainLayout}
        path="/rh/gestaoFerias"
      />
      <RouteWithLayout
        component={HoleriteView}
        exact
        layout={MainLayout}
        path="/rh/holerite"
      />
      <RouteWithLayout
        component={GestaoHoleriteView}
        exact
        layout={MainLayout}
        path="/rh/gestaoHolerite"
      />
      <RouteWithLayout
        component={VagasView}
        exact
        layout={MainLayout}
        path="/rh/vagas"
      />
      <RouteWithLayout
        component={MeusReembolsosView}
        exact
        layout={MainLayout}
        path="/reembolso/meusReembolsos"
      />
      <RouteWithLayout
        component={GestaoReembolsosView}
        exact
        layout={MainLayout}
        path="/reembolso/gestao"
      />
      <RouteWithLayout
        component={NovoCargoView}
        exact
        layout={MainLayout}
        path="/cargos/novo"
      />
      <RouteWithLayout
        component={ExibirCargoView}
        exact
        layout={MainLayout}
        path="/cargos/exibir"
      />
      <RouteWithLayout
        component={CargosView}
        exact
        layout={MainLayout}
        path="/cargos"
      />
      <RouteWithLayout
        component={ProibidoView}
        exact
        layout={MainLayout}
        path="/proibido"
      />
      <RouteWithLayout
        component={MainPageView}
        exact
        layout={MainLayout}
        path="/not-found"
      />
      <Redirect to="/atomic" />
    </Switch>
  );
};

export default Routes;