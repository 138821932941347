import React from 'react';
import { Chip } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import FlagIcon from '@material-ui/icons/Flag';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

function StatusChip({classes, status, width}){
    
  let color;
  let icon;

  switch(status){
    case 'Aberto':
      color = '#4287F5';
      icon = (<CheckIcon style={{color: 'white'}}/>);
      break;
    case 'Solicitado':
      color = '#4287F5';
      icon = (<CheckIcon style={{color: 'white'}}/>);
      break;
    case 'Aprovado':
      color = '#7CC47D';
      icon = (<FlagIcon style={{color: 'white'}}/>);
      break;
    case 'Pago':
      color = 'green';
      icon = (<AttachMoneyIcon style={{color: 'white'}}/>);
      break;
    case 'Rejeitado':
      color = 'orange';
      icon = (<ThumbDownIcon style={{color: 'white'}}/>);
      break;
    case 'Revisão':
      color = 'orange';
      icon = (<FindReplaceIcon style={{color: 'white'}}/>);
      break;
    case 'Submetido':
      color = '#4287F5';
      icon = (<SwapVerticalCircleIcon style={{color: 'white'}}/>);
      break;
    case 'Carregando':
      color = 'orange';
      icon = (<SwapHorizontalCircleIcon style={{color: 'white'}}/>);
      break;
    case 'Concluído':
      color = 'green';
      icon = (<CheckCircleIcon style={{color: 'white'}}/>);
      break;
    case 'Cancelado':
      color = 'red';
      icon = (<CloseIcon style={{color: 'white'}}/>);
      break;
    default:
      return null;
  }
  
  return(
    <Chip className={classes.statusChip} style={{background: color, width: width | 100}} size="small" icon={icon} label={status} />
  )
}

export default withStyles(styles)(StatusChip);