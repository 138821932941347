export const styles = theme => ({
  table: {
    width: '40',
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: 14,
    paddingTop: 8,
    paddingBottom: 8
  },
  header: {
    backgroundColor: '#DDDDDD'
  },
  subHeader: {
    backgroundColor: '#5C65D1'
  },
  subTableHeader: {
    fontWeight: 'bold',
    fontSize: 12,
    color: 'white'
  },
  tableHeaderCollapse: {
    paddingTop: 8,
    paddingBottom: 8,
    width: 20
  },
  button: {
    paddingTop: 0,
    paddingBottom: 0
  },
  card: {
    marginTop: 30,
    marginLeft: 25,
    
  },
  TableCellLink: {
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer'
    }
  },
  row: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  warning: {
    fill: 'orange'
  },
  success: {
    fill: 'green'
  }
});