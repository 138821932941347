import React, { useState, useEffect } from 'react';
import { CircularProgress, Paper, CardHeader, Grid, TextField, Button, Tooltip, Typography, IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { AtomicModal, UploadFile } from '../../../../components';
import { listNivelCarreira } from '../../../../requests/nivelCarreiraRequest';
import { listCliente } from '../../../../requests/clienteRequest';
import { listUnidadeNegocio } from '../../../../requests/unidadeNegocioRequest';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { NetworkLockedOutlined, SignalCellularNullTwoTone } from '@material-ui/icons';

function NovaVaga({classes, display, onSalvar, onFechar, idUsuario}){

  const initialValues = {
    Titulo: '',
    Id_Usuario: idUsuario,
    Id_Nivel_Carreira: 1,
    Id_Cliente: 1,
    Id_Unidade_Negocio: '',
    Descricao: ''
  };
  
  const [cliente, setCliente] = useState([]);
  const [unidadeNegocio, setUnidadeNegocio] = useState([]);
  const [nivelCarreira, setNivelCarreira] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState([]);
  
  useEffect(() => { carregarCliente() }, []);
  useEffect(() => { carregarUnidadeNegocio() }, []);
  useEffect(() => { carregarNivelCarreira() }, []);

  async function carregarCliente(){
    const response = await listCliente({});

    if(response.valid){
      setCliente(response.data);
    }
  }

  async function carregarUnidadeNegocio(){
    const response = await listUnidadeNegocio({});

    if(response.valid){
      setUnidadeNegocio([...response.data, {Id_Unidade_Negocio: '', Nome: ''}]);
    }
  }

  async function carregarNivelCarreira(){
    const response = await listNivelCarreira({});

    if(response.valid){
      setNivelCarreira(response.data);
    }
  }

  function closeNovaVaga(handleReset){
    handleReset();  
    onFechar();
    setFile([]);
  }

  function salvarVaga(vaga, resetForm){
    setLoading(true);

    if(vaga['Id_Unidade_Negocio'] === ''){
      vaga = {...vaga, Id_Unidade_Negocio: null}
    }

    onSalvar(vaga, file);
    resetForm(initialValues);
    setFile([]);
    setLoading(false);
  }

  function handleUpload(newFile) {
    const uploadedFile = {
      file: newFile,
      name: newFile[0].name,
    };

    setFile([...file, uploadedFile]);
  };

  function removeFile(index){
    let newFileList = [...file];
    newFileList.splice(index, 1);
    setFile(newFileList);
  }
  
  const schema = yup.object().shape({
    Titulo: yup.string().required('Campo Obrigatório'),
    Id_Nivel_Carreira: yup.number().required('Campo Obrigatório'),
    Id_Cliente: yup.number().required('Campo Obrigatório'),
    Id_Unidade_Negocio: yup.number().nullable(),
    Descricao: yup.string().required('Campo Obrigatório')
  })

  return (
    <Formik initialValues={initialValues} onSubmit={(values, { resetForm }) => salvarVaga(values, resetForm)} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleReset
      } = props
      return (
        <AtomicModal open={display} width="500px" handleClose={() => closeNovaVaga()}>
          <Form>
          <Paper className={classes.modal}>
            <CardHeader title="Nova Vaga" style={{textAlign: 'center'}}/>
              <Grid container spacing={1} className={classes.modalFields}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Titulo"
                    margin="dense"
                    name="Titulo"
                    onChange={handleChange}
                    value={values.Titulo}
                    variant="outlined"
                    helperText={errors.Titulo && touched.Titulo ? errors.Titulo : ''}
                    error={errors.Titulo && touched.Titulo ? true : false}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item md={6} xs={6}>
                  <TextField
                    fullWidth
                    label="Nível de Carreira"
                    margin="dense"
                    name="Id_Nivel_Carreira"
                    onChange={handleChange}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    value={values.Id_Nivel_Carreira}
                    variant="outlined"
                    helperText={errors.Id_Nivel_Carreira && touched.Id_Nivel_Carreira ? errors.Id_Nivel_Carreira : ''}
                    error={errors.Id_Nivel_Carreira && touched.Id_Nivel_Carreira ? true : false}
                    onBlur={handleBlur}
                  >
                    {nivelCarreira.map(option => (
                      <option key={option.Id_Nivel_Carreira} value={option.Id_Nivel_Carreira}>
                        {option.Nome}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={6} xs={6}>
                  <TextField
                    fullWidth
                    label="Cliente"
                    margin="dense"
                    name="Id_Cliente"
                    onChange={handleChange}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    value={values.Id_Cliente}
                    variant="outlined"
                    helperText={errors.Id_Cliente && touched.Id_Cliente ? errors.Id_Cliente : ''}
                    error={errors.Id_Cliente && touched.Id_Cliente ? true : false}
                    onBlur={handleBlur}
                  >
                    {cliente.map(option => (
                      <option key={option.Id_Cliente} value={option.Id_Cliente}>
                        {option.Nome}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={6} xs={6}>
                  <TextField
                    fullWidth
                    label="Unidade de Negócio"
                    margin="dense"
                    name="Id_Unidade_Negocio"
                    onChange={handleChange}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    value={values.Id_Unidade_Negocio}
                    variant="outlined"
                    helperText={errors.Id_Unidade_Negocio && touched.Id_Unidade_Negocio ? errors.Id_Unidade_Negocio : ''}
                    error={errors.Id_Unidade_Negocio && touched.Id_Unidade_Negocio ? true : false}
                    onBlur={handleBlur}
                  >
                    {unidadeNegocio.map(option => (
                      <option key={option.Id_Unidade_Negocio} value={option.Id_Unidade_Negocio}>
                        {option.Nome}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Descrição"
                    margin="dense"
                    name="Descricao"
                    onChange={handleChange}
                    value={values.Descricao}
                    variant="outlined"
                    helperText={errors.Descricao && touched.Descricao ? errors.Descricao : ''}
                    error={errors.Descricao && touched.Descricao ? true : false}
                    onBlur={handleBlur}
                    multiline={true}
                    minRows="5"
                    maxRows="5"
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <UploadFile onUpload={handleUpload}/>
                </Grid>
                
                <Grid item xs={12} sm={12} direction="column">
                  {file.map((fileItem, index) => 
                    (
                      <div className={classes.fileItemBlock}>
                        <Tooltip title="Remover">
                          <IconButton color="secondary" onClick={() => removeFile(index)} disabled={loading}>
                            <DeleteForeverIcon />
                          </IconButton>
                        </Tooltip>
                        <Typography variant="body1">{fileItem.name}</Typography>
                      </div>
                    ))
                  }
                </Grid>

              </Grid>
              
              <Button variant="contained" onClick={() => closeNovaVaga(handleReset)} >
                CANCELAR
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading}
                className={classes.modalSaveButton}
              >
                SALVAR
                {loading ? 
                  <CircularProgress 
                  size={14}
                  color="secondary" />
                  : ''
                }
              </Button>
            </Paper>
            </Form>
          </AtomicModal>
        )
      }}
    </Formik>
  )
}

export default withStyles(styles)(NovaVaga);