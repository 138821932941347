export const styles = theme => ({
  table: {
    width: '40',
  },
  tableHeader: {
      fontWeight: 'bold',
      fontSize: 14,
      paddingTop: 8,
      paddingBottom: 8
  },
  header: {
      backgroundColor: '#DDDDDD'
  },
  button: {
      paddingTop: 0,
      paddingBottom: 0
  }
});