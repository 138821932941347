import api from '../services/api';
import { handleRequestError } from '../services/security';

export async function getExcel(data){

  let responseFromApi;

  try {
    const response = await api.post('/excel', {data: data}, {responseType: 'arraybuffer'});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}