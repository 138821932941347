import React, { useState, useEffect } from 'react';
import { CircularProgress, CardHeader, Grid, TextField, Button, Card, Divider, CardContent, CardActions, InputLabel, RadioGroup, FormControlLabel, Radio, IconButton, Typography } from '@material-ui/core';
import { CustomSnackBar, Confirmation } from '../../../../components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { getResposta } from '../../../../requests/respostaAction';
import { putRespostaCriterio } from '../../../../requests/respostaCriterioAction';
import { putAvaliacao } from '../../../../requests/avaliacaoAction';
import { useLocation } from "react-router-dom";

function RealizarAvaliacao({classes, history}){

  const location = useLocation();
  
  const [respostas, setRespostas] = useState([]);

  const [loading, setLoading] = useState(false);
  const [submitSave, setSubmitSave] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  useEffect(() => { carregarResposta() }, []);

  async function carregarResposta(){
    setLoading(true);

    const response = await getResposta({Id_Avaliacao: location.avaliacao.Id_Avaliacao, Tipo: 1});
    if (response.valid) {
      setRespostas(response.data);
    } else {
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setLoading(false);
  }

  function returnToMinhasAvaliacoes(){
    history.push('/minhasAvaliacoes');
  }

  function handleChangeComentario(event, index){
    let resp = {...respostas};
    resp.RespostaCriterio[index].Comentario = event.target.value;

    setRespostas(resp);
  }

  function handleChangeNota(event, index){
    let resp = {...respostas};
    resp.RespostaCriterio[index].Nota = parseInt(event.target.value);

    setRespostas(resp);
  }

  async function onSalvarAvaliacao(){
    setSubmitSave(true);

    const response = await putRespostaCriterio({Respostas: respostas.RespostaCriterio});
    
    if (response.valid) {
      setAlert({display: true, severity: 'success', text: 'Avaliação salva com sucesso!'});
      setTimeout(() => history.push('/minhasAvaliacoes'), 1500);
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setSubmitSave(false);
  }

  async function onSubmeterAvaliacao(){
    setConfirmation(false);
    setSubmit(true);

    const response = await putRespostaCriterio({Respostas: respostas.RespostaCriterio});
    
    if (response.valid) {

      const responseAtualizarStatus = await putAvaliacao({Id_Avaliacao: respostas.Id_Avaliacao, Status: 'Submetido'});

      if (responseAtualizarStatus.valid) {
        setAlert({display: true, severity: 'success', text: 'Avaliação salva com sucesso!'});
        setTimeout(() => history.push('/minhasAvaliacoes'), 1500);
      }else{
        setAlert({display: true, severity: 'error', text: responseAtualizarStatus.message});
      }
      
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setSubmit(false);
  }

  return (
    <div>
      <CustomSnackBar display={alert.display} severity={alert.severity} text={alert.text} onClose={() => setAlert({display: false, severity: '', text: ''})}/>
      <Confirmation 
        title="Submeter Avaliaçao?"
        open={confirmation}
        setOpen={setConfirmation}
        onConfirm={onSubmeterAvaliacao}
        >
          <Typography variant="subtitle2">Você tem certeza que deseja submeter essa avaliação?</Typography>
      </Confirmation>

      <Card>
        {loading ?
          <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
            <CircularProgress />
          </div>
        :
          <div>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={3} xs={6}>
                  <Typography variant="h3">Período Avaliado</Typography>
                  <Typography variant="subtitle2">Ano {location.avaliacao.Periodo.Ano} - {location.avaliacao.Periodo.Semestre}º Semestre</Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              {(respostas.RespostaCriterio === undefined ? [] : respostas.RespostaCriterio).map((item, index) => (
                <Card className={classes.criterioBlock}>

                  <InputLabel className={classes.labelCriterio}>{index+1} - {item.Criterio.Criterio}</InputLabel>

                  {item.Criterio.Tipo === 1 || item.Criterio.Tipo === 3 ?
                    <RadioGroup name="nota" value={item.Nota} row className={classes.labelNota}>
                      <FormControlLabel onChange={(event) => handleChangeNota(event, index)} value={1} control={<Radio />} label="Não satisfatório" />
                      <FormControlLabel onChange={(event) => handleChangeNota(event, index)} value={2} control={<Radio />} label="Precisa melhorar" />
                      <FormControlLabel onChange={(event) => handleChangeNota(event, index)} value={3} control={<Radio />} label="Atende às expectativas" />
                      <FormControlLabel onChange={(event) => handleChangeNota(event, index)} value={4} control={<Radio />} label="Acima da expectativa" />                          
                    </RadioGroup>
                  : null}

                  {item.Criterio.Tipo === 2 || item.Criterio.Tipo === 3 ?  
                    <TextField
                      fullWidth
                      margin="dense"
                      name="Criterio"
                      variant="outlined"
                      value={item.Comentario}
                      onChange={(event) => handleChangeComentario(event, index)}
                      multiline
                      rows={4}
                    />
                  : null}
                </Card>
              ))}
            </CardContent>
          </div>
        }
        <Divider />
        <CardActions>
          <Button variant="contained" onClick={returnToMinhasAvaliacoes}>VOLTAR</Button>
          <Button variant="contained" color="primary" disabled={submitSave} onClick={onSalvarAvaliacao}>
            SALVAR
            {submitSave ? 
              <CircularProgress 
              size={14}
              color="secondary" />
              : ''
            }
          </Button>
        
          <Button variant="contained" color="primary" disabled={submit} onClick={() => setConfirmation(true)}>
            SUBMETER
            {submit ? 
              <CircularProgress 
              size={14}
              color="secondary" />
              : ''
            }
          </Button>
        </CardActions>

      </Card>
    </div>
  )
}

export default withStyles(styles)(RealizarAvaliacao);