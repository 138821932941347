import React from 'react';
import { CardHeader, Card, TextField } from '@material-ui/core';
import { AtomicModal } from '../../../../components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';


function LogCarga({classes, display, onFechar, log}){

  return (
    <AtomicModal open={display} width={800} height={600} handleClose={onFechar}>
      <Card>
        <CardHeader title="Log de Carga de Holerites" style={{textAlign: 'center'}}/>
        
        <TextField
          fullWidth
          label="Log"
          multiline
          rows={30}
          defaultValue={log}
          variant="filled"
          disabled={true}
        />
      </Card>
    </AtomicModal>
  )
}

export default withStyles(styles)(LogCarga);