import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { getAtivos } from '../../requests/ativosAction';
import { CustomSnackBar } from '../../components';
import { AtivosToolbar } from './components';
import moment from 'moment';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination  
} from '@material-ui/core';
import TablePaginationActions from './TablePaginationActions';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';


function Ativos({classes, history}) {

  const [dataTable, setDataTable] = useState([]);
  const [listaAtivos, setListaAtivos] = useState([]);

  useEffect(() => { fetchDataForTable(); }, []);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const [showAlert, setShowAlert] = useState({
    display: false,
    severity: '',
    text: ''
  });

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function fetchDataForTable() {
    const response = await getAtivos();

    if (response.valid){
      setDataTable(response.data);
      setListaAtivos(response.data);
    }else{
      setShowAlert({
        display: true,
        severity: 'error',
        text: response.message
      });
    }
    
    return
  }

  function exibirAtivo(ativo){

    const toExibirAtivo = { 
      pathname: 'ativos/exibir', 
      ativo: ativo
    };
    
    return toExibirAtivo;

  }

  const handleCloseAlert = () => {
    setShowAlert({
        display: false,
        severity: '',
        text: ''
    });
  };

  function filtrarAtivo(event){
    let dataValues = listaAtivos.filter(func => func.description.toLowerCase().includes(event.target.value.toLowerCase()));;
    setDataTable(dataValues);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataTable.length - page * rowsPerPage);

  return (
    
    <div className={classes.rootOuter}>
      <AtivosToolbar onFilterAtivo={filtrarAtivo} />
      <div className={classes.contentOuter}>

        <Card className={clsx(classes.root)}>

          <CustomSnackBar display={showAlert.display} severity={showAlert.severity} text={showAlert.text} onClose={handleCloseAlert} />
          
          <CardContent className={classes.content}>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell>Número do Ativo</TableCell>
                      <TableCell>Descrição</TableCell>
                      <TableCell>Marca</TableCell>
                      <TableCell>Data de Compra</TableCell>
                      <TableCell>Preço</TableCell>
                      <TableCell>Loja</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? dataTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : dataTable
                  ).map(ativo => (
                    <TableRow
                    className={classes.tableRow}
                    hover
                     key={ativo.Id_Ativo}
                    //key={ativo.id_Ativo_Ativo}
                  >
                    <TableCell>
                    <Link to={exibirAtivo(ativo)} style={{ textDecoration: 'none' }}>
                        <Typography variant="body1">{ativo.assetNumber}</Typography>
                      </Link>
                          {/* <IconButton aria-label="Apagar" alt="Apagar" onClick={() => { onApagarAtivo(ativo) }}>
                            <Delete fontSize='small' />
                          </IconButton> */}
                        </TableCell>

                        <TableCell>{ativo.description}</TableCell>
                        <TableCell>{ativo.brand}</TableCell>
                        <TableCell>{moment(ativo.purchaseDate).format('DD/MM/YYYY')}</TableCell> 
                        <TableCell>{ativo.price}</TableCell>
                        <TableCell>{ativo.store}</TableCell>
                    
                    
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}

              </TableBody>
            </Table>
          </CardContent>
          <CardActions className={classes.actions}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
              count={dataTable.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              ActionsComponent={TablePaginationActions}
              
            />
          </CardActions>
        </Card>
      </div>
    </div>
  );
};


export default withRouter((withStyles(styles)(Ativos)));