import React, { useState, useEffect } from 'react';
import { CircularProgress, Paper, CardHeader, Grid, TextField, Button, Typography, Box, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AtomicModal } from '../../../components';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { UploadFile } from '../../../components';

function NovoDocumento({classes, display, onSalvar, onFechar}){

  const [file, setFile] = useState([]);

  const valorInicial = {
    Descricao: ''
  };
  
  const [loading, setLoading] = useState(false)

  function closeModal(handleReset){
      handleReset();
      setFile([]);
      onFechar();
  }

  async function salvarDocumento(documento, resetForm){
    setLoading(true);
    await onSalvar(file[0], documento.Descricao);
    resetForm();
    setFile([]);
    setLoading(false);
  }

  const handleUpload = files => {
    const uploadedFiles = files.map(file => ({
      file,
      name: file.name,
    }));

    setFile(uploadedFiles);
  };

  const schema = yup.object().shape({
    Descricao: yup.string().required('Campo Obrigatório')
  });

  return (
    <Formik initialValues={valorInicial} onSubmit={(values, { resetForm }) => salvarDocumento(values, resetForm)} enableReinitialize={true} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleReset,
        setFieldValue
      } = props
      return (
        <AtomicModal open={display} width={`30%`} handleClose={() => closeModal(handleReset)}>
          <Form>
            <Paper className={classes.modalNew}>
              <CardHeader title="Adicionar Novo Documento" />

              <Grid container spacing={1} className={classes.modalFields}>
                <Grid item xs={12} sm={12}>
                  <div>
                    {!!file.length ?
                      <div style={{display: 'flex'}}>
                        <Tooltip title="Remover">
                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => setFile([])}
                            disabled={loading}
                          >
                            <CloseIcon />
                          </Button>
                        </Tooltip>
                        <Typography variant="body1" style={{marginLeft: 10}}>{!!file.length ? file[0].name : ''}</Typography>
                      </div>
                    :
                      <UploadFile onUpload={handleUpload}/>
                    }
                  </div>
                </Grid>
                <Grid item md={12} xs={12} >
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    label="Descrição"
                    margin="dense"
                    name="Descricao"
                    onChange={handleChange}
                    value={values.Descricao}
                    variant="outlined"
                    helperText={errors.Descricao && touched.Descricao ? errors.Descricao : ''}
                    error={errors.Descricao && touched.Descricao ? true : false}
                    onBlur={handleBlur}
                    disabled={loading}
                  />
                </Grid>
              </Grid>
              
              <Button variant="contained" disabled={loading} onClick={() => closeModal(handleReset)} >
                CANCELAR
              </Button>

              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={loading}
                className={classes.modalSaveButton}
              >
                SALVAR
                {loading ? 
                  <CircularProgress 
                  size={14}
                  color="secondary" />
                  : ''
                }
              </Button>
            </Paper>
          </Form>
        </AtomicModal>
      )
      }}
    </Formik>
  )
}

export default withStyles(styles)(NovoDocumento);