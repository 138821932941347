export const styles = theme => ({
  modal: {
    paddingLeft: 10,
    paddingBottom: 10,
    paddingRight: 10
  },
  modalFields: {
    marginBottom: 20
  },
  modalSaveButton: {
    marginLeft: 10
  },
  loadingDiv: {
    width: '100%',
    position: 'relative',
    top: '50%',
    left: '50%'
  },
  modalGreenButton: {
    marginLeft: 10,
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: '#55C932',
    }
  },
  modalOrangeButton: {
    marginLeft: 10,
    backgroundColor: 'orange',
    '&:hover': {
      backgroundColor: '#FAC32A',
    }
  },
})