import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton,
    Card, CardHeader, CardContent, CardActions, Divider, Button, TablePagination, CircularProgress } from '@material-ui/core';
import { getFormularios, deleteFormulario } from '../../requests/formularioAction';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { CustomSnackBar, TablePaginationActions } from '../../components';
import { Delete } from '@material-ui/icons';
import { Confirmation } from '../../components';
import { withRouter } from 'react-router';

function Formularios({classes, history}){
  
  const [formularios, setFormularios] = useState([]);

  const [form, setForm] = useState({
    displayNovo: false,
    displayEditar: false,
    editarFormulario: {},
    confirmation: false,
    idFormulario: 0
  });

  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  useEffect(() => { carregarFormularios() }, []);

  async function carregarFormularios(){
    setLoading(true);

    const response = await getFormularios({});
    if (response.valid) {
      setFormularios(response.data);
    } else {
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setLoading(false);
  }

  function handlePageChange(event, newPage) {
    setPage(newPage);
  };

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function onNovoFormulario(){
    history.push('/formularios/novo')
  }

  function onEditarFormulario(formulario){
    const toEditarFormulario = {
      pathname: '/formularios/editar',
      formulario: formulario  
    };

    return toEditarFormulario;
  }

  function openDialog(idFormulario, openConfirm) {
    setForm({...form, confirmation: openConfirm, idFormulario: idFormulario});
  }

  async function onDeletarFormulario(){
    const response = await deleteFormulario({Id_Formulario: form.idFormulario});

    if(response.valid){
      carregarFormularios();
      setAlert({display: true, severity: 'success', text: 'Formulario apagado com sucesso!'});
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setForm({...form, confirmation: false});
  }
  
  return(
    <div>
      <Card className={classes.card}>
        <CardHeader title="Formulários de Avaliação"/>
        <Divider />
        {loading ?
          <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
            <CircularProgress />
          </div>
        :
          <CardContent>
            <TableContainer component={Paper} className={classes.table}>
              <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.header}>
                      <TableCell className={classes.tableHeader}>Formulario</TableCell>
                      <TableCell className={classes.tableHeader}>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                    ? formularios.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : formularios
                    ).map((row) => (
                      <TableRow key={row.Id_Formulario}>
                        <TableCell>
                          <Link className={classes.TableCellLink} to={onEditarFormulario(row)}>{row.Nome}</Link>
                        </TableCell>
                        <TableCell>
                          <IconButton className={classes.button} onClick={() => openDialog(row.Id_Formulario, true)}>
                            <Delete fontSize='small'/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                  ))}
                  </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        }
        <Divider/>
        <CardActions>
          <Button variant="contained" color="primary" onClick={onNovoFormulario}>NOVO</Button>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
            count={formularios.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
            style={{position: 'absolute', right: 10}}
          />
        </CardActions>
      </Card>

      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setAlert({display: false, severity: '', text: ''})}
      />
      <Confirmation 
        title="Apagar formulário de avaliação?"
        open={form.confirmation}
        setOpen={openDialog}
        onConfirm={onDeletarFormulario}
        >
          Você tem certeza que deseja apagar esse formulário de avaliação?
      </Confirmation>
    </div>
  )

}

export default withRouter(withStyles(styles)(Formularios));