import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { FormAtivo } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const funcData = {
  assetNumber: '',
  description: '',
  brand: '',
  purchaseDate: '',
  price: '',
  id_Extrato: '',
};

const NovoAtivo = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Grid
          item
          lg={12}
          md={10}
          xl={12}
          xs={16}
        >
          <FormAtivo disabled={false} funcData={funcData} acao='Novo' />
        </Grid>
    </div>
  );
};

export default NovoAtivo;
