import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { postCargo, putCargo, getCargos } from '../../requests/cargosRequest';
// import { getUsuarios } from '../../requests/usuarioRequest';
// import { getContratacao } from '../../requests/contratacaoAction';
// import { getGenero } from '../../requests/generoAction';
// import { getEstadoCivil } from '../../requests/estadoCivilAction';
// import { getStatus } from '../../requests/statusRequest';
import { TextMaskCurrency } from '../../masks';
import { CustomSnackBar } from '..';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  CircularProgress,
  TextField,
  FormControlLabel,
  Checkbox,
  InputAdornment
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
// import { TextMaskTelefone, TextMaskCPF, TextMaskCNPJ } from '../../masks';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { connect } from 'react-redux';
import { convertCurrencyBR, convertCurrencyUS, formatCurrencyDisplay } from '../../utils';

function FormCargo({ classes, disabled, acao, funcData, perfil }) {

  let history = useHistory();

  const [stateForm, setStateForm] = useState({
    submitting: false,
    disabled: disabled,
    action: acao,
    loading: true
  });

  const [showAlert, setShowAlert] = useState({
    display: false,
    severity: '',
    text: ''
  });

  // const [usuarios, setUsuarios] = useState([]);
  // const [contratacao, setContratacao] = useState([]);
  // const [genero, setGenero] = useState([]);
  // const [estadoCivil, setEstadoCivil] = useState([]);
  // const [statusCargo, setStatusCargo] = useState([]);
  // const [gestor, setGestor] = useState([]);

  useEffect(() => { carregarDados() }, []);

  async function carregarDados() {
    setStateForm({ ...stateForm, loading: true });
    // await carregarUsuarios();
    // await carregarContratacao();
    // await carregarGenero();
    // await carregarEstadoCivil();
    // await carregarStatusCargo();
    // await carregarGestor();
    setStateForm({ ...stateForm, loading: false });
  }


  function onEditCargo() {
    setStateForm({ ...stateForm, disabled: false });
  }

  function handleCloseAlert() {
    setShowAlert({ display: false, severity: '', text: '' });
  };

  async function salvarCargo(values) {

    setStateForm({ ...stateForm, submitting: true });

    let valor = convertCurrencyUS(values.Valor);
    
    let response;
    let text;
    

    if (stateForm.action === 'Novo') {
      response = await postCargo({...values, Valor:valor} );
    } else {
      response = await putCargo({...values, Valor:valor} );
    }

    if (response.valid) {

      if (stateForm.action === 'Novo') {
        text = 'Novo cargo cadastrado com sucesso!';
        history.push('/cargos');
      } else {
        stateForm.disabled = true;
        stateForm.action = 'Exibir';

        text = 'Cargo alterado com sucesso!';
        setStateForm({ ...stateForm, disabled: true });
      }

      setShowAlert({ display: true, severity: 'success', text: text });

    } else {
      setShowAlert({ display: true, severity: 'error', text: response.message });
      setStateForm({ ...stateForm, submitting: false });
    }
  }

  function onCancelarEditar(handleReset) {
    handleReset();
    setStateForm({ action: 'Exibir', disabled: true });
  }

  function cancelarNovo() {

    const toCargos = {
      pathname: '/cargos'
    };

    return toCargos;

  }

  const schema = yup.object().shape({
    Descricao: yup.string().required('Campo Obrigatório'),
  });

  return (
    <Formik initialValues={funcData} onSubmit={(values) => salvarCargo(values)} enableReinitialize={true} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          handleReset,
          setFieldValue
        } = props
        return (
          <Card className={classes.cardStyle}>
            <Form>

              <CustomSnackBar display={showAlert.display} severity={showAlert.severity} text={showAlert.text} onClose={handleCloseAlert} />

              <CardHeader title={stateForm.action + ' Cargo'} />
              <Divider />
              {stateForm.loading ?
                <div style={{ position: 'relative', top: 150, left: '50%', height: 300 }}>
                  <CircularProgress />
                </div>
                :
                <div>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Descrição"
                          margin="dense"
                          name="Descricao"
                          onChange={handleChange}
                          required
                          value={values.Descricao}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.Descricao && touched.Descricao ? errors.Descricao : ''}
                          error={errors.Descricao && touched.Descricao ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Grid item md={3} xs={6}>
                        <TextField
                          fullWidth
                          label="Valor"
                          margin="dense"
                          name="Valor"
                          onChange={handleChange}
                          value={values.Valor}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            inputComponent: TextMaskCurrency
                          }}
                          variant="outlined"
                          helperText={errors.Valor && touched.Valor ? errors.Valor : ''}
                          error={errors.Valor && touched.Valor ? true : false}
                          onBlur={handleBlur}
                          disabled={stateForm.disabled}                          
                        />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.Ativo === 1 ? true : false}
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  setFieldValue('Ativo', 1);
                                } else {
                                  setFieldValue('Ativo', 0);
                                }
                              }}
                              color="primary"
                            />
                          }
                          label="Cargo Ativo"
                          onChange={handleChange}
                          disabled={stateForm.disabled}
                        />
                      </Grid>



                    </Grid>
                  </CardContent>
                  <Divider />
                </div>
              }
              <Divider />
              <CardActions>
                {stateForm.action === 'Novo' ?
                  <Link to={cancelarNovo} style={{ textDecoration: 'none' }}>
                    <Button variant="contained" >
                      CANCELAR
                    </Button>
                  </Link>
                  : null}

                {stateForm.disabled ?
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={onEditCargo}
                    disabled={!perfil.funcoes.update.includes('Cargos')}
                  >
                    EDITAR
                  </Button>
                  :
                  <div>
                    {stateForm.action !== 'Novo' ?
                      <Button variant="contained" onClick={() => onCancelarEditar(handleReset)} style={{ marginRight: 10 }}>
                        CANCELAR
                      </Button>
                      : null}
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={stateForm.submitting}
                    > SALVAR
                      {stateForm.submitting ?
                        <CircularProgress
                          size={14}
                          color="secondary" />
                        : ''
                      }
                    </Button>
                  </div>
                }
              </CardActions>
            </Form>
          </Card>
        )
      }}
    </Formik>
  );
};

FormCargo.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = store => ({
  perfil: store.Perfil,
})

export default connect(mapStateToProps, null)(withStyles(styles)(FormCargo));