import React, { useState } from 'react';
import { Grid, AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';
import {} from '@material-ui/lab';
import { TabPanel } from '../../components'
import { FormOportunidade, } from '../../components';
import { useLocation } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';


function ExibirOportunidade({classes}) {
  
  const location = useLocation();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  function validateData(value){
    if(location.oportunidade === undefined) {
      window.location.href = '/atomic';
    }else{
      return value;
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="oportunidade-tabs">
              <Tab label="Oportunidade" id="OportunidadeTab" />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <FormOportunidade disabled={true} funcData={validateData(location.oportunidade)} acao='Exibir' />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(ExibirOportunidade);