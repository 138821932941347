import React, { useState, useEffect } from 'react';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Checkbox,
    Card, CardContent, CardActions, Divider, Button, TablePagination, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { getFuncionarios } from '../../../../requests/funcionariosRequest';
import { CustomSnackBar, TablePaginationActions } from '../../../../components';
import { withRouter } from 'react-router';

function SelecionarFuncionarios({classes, funcionarios, setFuncionarios, periodo, setStepAtivo, history}){
  
  const [func, setFunc] = useState([]);
  const [funcSelecionados, setFuncSelecionados] = useState(funcionarios);
  const [loading, setLoading] = useState(false);
  
  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => { carregarFuncionarios() }, []);

  async function carregarFuncionarios(){
    setLoading(true);

    const response = await getFuncionarios({Status: ['Ativo'], Contratacao: ['CLT', 'PJ', 'Estagiário'], Id_Periodo_Avaliacao: periodo.Id_Periodo_Avaliacao});
    if (response.valid) {
      setFunc(response.data);
    } else {
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setLoading(false);
  }

  function handlePageChange(event, newPage) {
    setPage(newPage);
  };

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function onSelecionarFuncionario(idFuncionario){
    let novoFuncSelecionados = [...funcSelecionados];

    const index = novoFuncSelecionados.indexOf(idFuncionario);

    if(index < 0){
      novoFuncSelecionados.push(idFuncionario);
    }else{
      novoFuncSelecionados.splice(index, 1);
    }

    setFuncSelecionados(novoFuncSelecionados);

  }

  function onAvancar(){
    setFuncionarios(funcSelecionados);
    setStepAtivo(1);
  }

  function onCancelar(){
    history.push('/avaliacoes');
  }
  
  return(
    <div>
      <Card className={classes.card}>
        <Divider />
        <CardContent>
          {loading ?
            <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
              <CircularProgress />
            </div>
          :
            <TableContainer component={Paper} className={classes.table}>
              <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow className={classes.header}>
                      <TableCell className={classes.tableHeader}></TableCell>
                      <TableCell className={classes.tableHeader}>Funcionário</TableCell>
                      <TableCell className={classes.tableHeader}>Gestor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                    ? func.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : func
                    ).map((row) => (
                      <TableRow key={row.Id_Funcionario} hover onClick={() => onSelecionarFuncionario(row.Id_Funcionario)}>
                        <TableCell>
                          <Checkbox  style={{paddingTop: 0, paddingBottom: 0}} size="small" checked={funcSelecionados.indexOf(row.Id_Funcionario) > -1 ? true : false} onChange={() => onSelecionarFuncionario(row.Id_Funcionario)}/>
                        </TableCell>
                        <TableCell>{row.Nome}</TableCell>
                        <TableCell>{row.Nome_Gestor}</TableCell>
                      </TableRow>
                  ))}
                  </TableBody>
              </Table>
            </TableContainer>
          }
        </CardContent>
        <Divider/>
        <CardActions>
          <Button variant="contained" onClick={onCancelar}>CANCELAR</Button>
          <Button variant="contained" color="primary" onClick={onAvancar} disabled={funcSelecionados.length === 0}>AVANÇAR</Button>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
            count={func.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
            style={{position: 'absolute', right: 10}}
          />
        </CardActions>
      </Card>

      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setAlert({display: false, severity: '', text: ''})}
      />

    </div>
  )

}

export default withRouter(withStyles(styles)(SelecionarFuncionarios));