import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';
import {} from '@material-ui/lab';

import { FormAtivo } from '../../components';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`ativo-tabs-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ExibirAtivo = props => {
  
  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  function validateData(value){
    if(props.location.ativo === undefined) {
      window.location.href = '/atomic';
    }else{
      return value;
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="ativo-tabs">
            <Tab label="Ativo" id="AtivoTab" />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <FormAtivo disabled={true} funcData={validateData(props.location.ativo)} acao='Exibir' />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
}

export default ExibirAtivo;