import React, { useState, useEffect } from 'react';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton,
    Card, CardHeader, CardContent, CardActions, Divider, Button, TablePagination, Link, CircularProgress } from '@material-ui/core';
import { getUsuarios, postUsuario, putUsuario } from '../../requests/usuarioRequest';
import { putPerfilUsuario, getPerfilUsuario } from '../../requests/perfilUsuarioAction';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { CustomSnackBar, TablePaginationActions } from '../../components';
import NovoUsuario from './components/NovoUsuario';
import EditarUsuario from './components/EditarUsuario';
import TrocarSenha from './components/TrocarSenha';
import AtribuirPerfil from './components/AtribuirPerfil';
import SecurityIcon from '@material-ui/icons/Security';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPerfil } from '../../actions/perfilActions'
import { getFuncao } from '../../requests/funcaoAction';

function Usuarios({classes, setPerfil, perfil, history}){
  
  const [usuarios, setUsuarios] = useState([]);

  const [form, setForm] = useState({
    displayNovo: false,
    displayEditar: false,
    displayTrocarSenha: false,
    displayAtribuirPerfil: false,
    idUsuario: 0,
    editarUsuario: {},
    alertDisplay: false,
    alertSeverity: '',
    alertText: '',
    confirmation: false,
  });

  const [loading, setLoading] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  useEffect(() => { carregarUsuarios() }, []);

  async function carregarUsuarios(){
    
    if(!perfil.perfis.includes('Sócio')){
      history.push('/proibido');
    }
    
    setLoading(true);

    const response = await getUsuarios();
    if (response.valid){
      setUsuarios(response.data);
    }else{
      setForm({...form, alertDisplay: true, alertSeverity: 'error', alertText: response.message});
    }    

    setLoading(false);
  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function salvarNovoUsuario(usuario){

    const response = await postUsuario(usuario);

    if(response.valid){
      setForm({...form, displayNovo: false, alertDisplay: true, alertSeverity: 'success', alertText: 'Usuário criado com sucesso!'});
      carregarUsuarios();
    }else{
      setForm({...form, alertDisplay: true, alertSeverity: 'error', alertText: response.message});
    }

  }

  async function salvarEditarUsuario(usuario){

    const response = await putUsuario(usuario);

    if(response.valid){
        setForm({...form, displayEditar: false, alertDisplay: true, alertSeverity: 'success', alertText: 'Usuário salvo com sucesso!'});
        carregarUsuarios();
    }else{
      setForm({...form, alertDisplay: true, alertSeverity: 'error', alertText: response.message});
    }

  }

  async function salvarTrocarSenha(usuario){
    
    const response = await putUsuario({...usuario,  Id_Usuario: form.editarUsuario.Id_Usuario});

    if(response.valid){
      setForm({...form, displayTrocarSenha: false, alertDisplay: true, alertSeverity: 'success', alertText: 'Senha alterada com sucesso!'});
    }else{
      setForm({...form, alertDisplay: true, alertSeverity: 'error', alertText: response.message});
    }

  }

  async function salvarAtribuirPerfil(params){
    const response = await putPerfilUsuario(params);

    if(response.valid){
        const params = { Token: localStorage.getItem('token') }
        const responseFuncao = await getFuncao(params);
        const responsePerfil = await getPerfilUsuario(params);
        
        let nFuncao = {read: [], create: [], update: [], delete: []};

        for(var i = 0; i < responseFuncao.data.length; i++){
          let pArray = responseFuncao.data[i].Funcao.split(':');
          nFuncao = {...nFuncao, [pArray[0]]: pArray[1].split(',')}
        }

        let perfilUsuario = [];

        for(var i = 0; i < responsePerfil.data.length; i++){
          perfilUsuario.push(responsePerfil.data[i].Perfil);
        }

        const perfil = {funcoes: nFuncao, perfis: perfilUsuario};

        setPerfil(perfil);
        
        setForm({...form, displayAtribuirPerfil: false, alertDisplay: true, alertSeverity: 'success', alertText: 'Perfil alterado com sucesso!'});
        carregarUsuarios();
    }else{
      setForm({...form, alertDisplay: true, alertSeverity: 'error', alertText: response.message});
    }
  }

  return(
    <div>
      <Card className={classes.card}>
        <CardHeader title="Usuários"/>
        <Divider />
        {loading ?
          <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
            <CircularProgress />
          </div>
        :
          <CardContent>
            <TableContainer component={Paper} className={classes.table}>
            <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow className={classes.header}>
                    <TableCell className={classes.tableHeader}>Nome</TableCell>
                    <TableCell className={classes.tableHeader} align="center">Usuário</TableCell>
                    <TableCell className={classes.tableHeader} align="center">E-mail</TableCell>
                    <TableCell className={classes.tableHeader} align="center">Status</TableCell>
                    <TableCell className={classes.tableHeader} align="center">Senha</TableCell>
                    <TableCell className={classes.tableHeader} align="center">Perfil</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {(rowsPerPage > 0
                  ? usuarios.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : usuarios
                  ).map((row) => (
                    <TableRow key={row.Id_Usuario}>
                      <TableCell>
                        <Link className={classes.TableCellLink} onClick={() => setForm({...form, editarUsuario: row, displayEditar: true})}>{row.Nome}</Link>
                      </TableCell>
                      <TableCell align="center">{row.Usuario}</TableCell>
                      <TableCell align="center">{row.Email}</TableCell>
                      <TableCell align="center">{row.Status === 1 ? 'Ativo' : 'Inativo'}</TableCell>
                      <TableCell align="center">
                        <IconButton alt="Trocar Senha" onClick={() => {setForm({...form, displayTrocarSenha: true, editarUsuario: row})}}>
                          <SecurityIcon fontSize='small' />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton alt="Perfil" onClick={() => {setForm({...form, displayAtribuirPerfil: true, idUsuario: row.Id_Usuario})}}>
                          <AssignmentIcon fontSize='small' />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
          </CardContent>
        }
        <Divider/>
        <CardActions>
          <Button variant="contained" color="primary" onClick={() => setForm({...form, displayNovo: true})}>NOVO</Button>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
            count={usuarios.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
            style={{position: 'absolute', right: 10}}
          />
        </CardActions>
      </Card>
      
      <NovoUsuario display={form.displayNovo} onSalvar={salvarNovoUsuario} onFechar={() => setForm({...form, displayNovo: false})}/>
      <EditarUsuario display={form.displayEditar} data={form.editarUsuario} onSalvar={salvarEditarUsuario} onFechar={() => setForm({...form, displayEditar: false})}/>
      <TrocarSenha display={form.displayTrocarSenha} onSalvar={salvarTrocarSenha} onFechar={() => setForm({...form, displayTrocarSenha: false})} initialValue={{Senha: '', Confirmar_Senha: ''}}/>
      <AtribuirPerfil display={form.displayAtribuirPerfil} data={form.idUsuario} onSalvar={salvarAtribuirPerfil} onFechar={() => setForm({...form, displayAtribuirPerfil: false})}/>

      <CustomSnackBar 
        display={form.alertDisplay}
        severity={form.alertSeverity}
        text={form.alertText}
        onClose={() => setForm({...form, alertDisplay: false})}
      />
      
    </div>
  )

}

const mapDispatchToProps = dispatch  => {
  return bindActionCreators({
    setPerfil: setPerfil 
  }, 
  dispatch
  );
};

const mapStateToProps = store => ({ 
  perfil: store.Perfil,
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Usuarios));