import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getRemuneracao, postRemuneracao, putRemuneracao, deleteRemuneracao } from '../../requests/remuneracaoAction';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, 
         IconButton, Card, CardHeader, CardContent, CardActions, Divider, Button } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import NovoRemuneracao from './NovoRemuneracao';
import EditarRemuneracao from './EditarRemuneracao';
import { CustomSnackBar, Confirmation } from '../../components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { convertCurrencyBR, convertCurrencyUS, formatCurrencyDisplay } from '../../utils';


function FormRemuneracao({ classes, idFuncionario }) {

  const [remuneracao, setRemuneracao] = useState([]);

  const [remuneracaoProps, setRemuneracaoProps] = useState({
    displayNovo: false,
    displayEditar: false,
    alertDisplay: false,
    alertSeverity: '',
    alertText: '',
    confirmation: false,
    idRemuneracao: 0,
    editRemuneracao: {
      Id_Funcionario: 0,
      Id_Remuneracao: 0,
      Id_Tipo_Remuneracao: 1,
      Id_Cargo: 0,
      Cargo: '',
      Inicio_Validade: '',
      Taxa_Hora: 0,
      Tipo_Remuneracao: '',
      Valor: 0
    }
  });

  useEffect(() => { carregarRemuneracao(idFuncionario) }, [idFuncionario]);

  async function carregarRemuneracao(idFuncionario){

    if(idFuncionario !== ""){
      const response = await getRemuneracao({params: {Id_Funcionario: idFuncionario}});
      if (!response) return
  
      setRemuneracao(response.data);
    }
  }

  async function salvarNovaRemuneracao(remuneracao){

    let taxaHora = convertCurrencyUS(remuneracao.Taxa_Hora);
    let valor = convertCurrencyUS(remuneracao.Valor);

    const response = await postRemuneracao({...remuneracao, Taxa_Hora: taxaHora, Valor: valor, Id_Funcionario: idFuncionario});

    if(response.valid){
      setRemuneracaoProps({...remuneracaoProps, displayNovo: false});
      carregarRemuneracao(idFuncionario);
    }else{
      setRemuneracaoProps({...remuneracaoProps, alertDisplay: true, alertSeverity: 'error', alertText: response.message});
    }

  }

  async function salvarEditarRemuneracao(remuneracao){
    
    let taxaHora = convertCurrencyUS(remuneracao.Taxa_Hora);
    let valor = convertCurrencyUS(remuneracao.Valor);

    const response = await putRemuneracao({...remuneracao, Taxa_Hora: taxaHora, Valor: valor});

    if(response.valid){
      setRemuneracaoProps({...remuneracaoProps, displayEditar: false});
      carregarRemuneracao(idFuncionario);
    }else{
      setRemuneracaoProps({...remuneracaoProps, alertDisplay: true, alertSeverity: 'error', alertText: response.message});
    }
  
  }

  async function onDeletarRemuneracao(){

    const response = await deleteRemuneracao({Id_Remuneracao: remuneracaoProps.idRemuneracao});

    if(response.valid){
      carregarRemuneracao(idFuncionario);
      setRemuneracaoProps({...remuneracaoProps, alertDisplay: true, alertSeverity: 'success', alertText: 'Remuneração apagada com sucesso!', confirmation: false});
    }else{
      setRemuneracaoProps({...remuneracaoProps, alertDisplay: true, alertSeverity: 'error', alertText: response.message, confirmation: false});
    }
    
  }


  function openDialog(idRemuneracao, openConfirm) {
    setRemuneracaoProps({...remuneracaoProps, confirmation: openConfirm, idRemuneracao: idRemuneracao});
  }

  function onEditarRemuneracao(values){
    let taxaHora = convertCurrencyBR(values.Taxa_hora);
    let valor = convertCurrencyBR(values.Valor);

    setRemuneracaoProps({...remuneracaoProps, displayEditar: true, editRemuneracao: {...values, Taxa_Hora: taxaHora, Valor: valor, date: Date()}});
  }

  return (
    <div>
      <Card style={{marginLeft: -20, marginRight: -20}}>
        <CardHeader title="Remuneração"/>
        <Divider />
        <CardContent>
          <TableContainer component={Paper} className={classes.table}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.header}>
                  <TableCell className={classes.tableHeader}>Tipo Remuneração</TableCell>
                  <TableCell className={classes.tableHeader} align="center">Taxa/Hora</TableCell>
                  <TableCell className={classes.tableHeader} align="center">Valor</TableCell>
                  <TableCell className={classes.tableHeader} align="center">Cargo</TableCell>
                  <TableCell className={classes.tableHeader} align="center">Início Validade</TableCell>
                  <TableCell className={classes.tableHeader} align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {remuneracao.map((row) => (
                  <TableRow key={row.Id_Remuneracao}>
                    <TableCell component="th" scope="row">{row.Tipo_Remuneracao}</TableCell>
                    <TableCell align="center">{row.Taxa_Hora !== null ? formatCurrencyDisplay(row.Taxa_Hora) : '-'}</TableCell>
                    <TableCell align="center">{row.Valor !== null ? formatCurrencyDisplay(row.Valor) : '-'}</TableCell>
                    <TableCell align="center">{row.Cargo}</TableCell>
                    <TableCell align="center">{moment(row.Inicio_Validade).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align="center">
                      <IconButton className={classes.button} onClick={() => openDialog(row.Id_Remuneracao, true)}>
                        <Delete fontSize='small'/>
                      </IconButton>
                      <IconButton className={classes.button} onClick={() => onEditarRemuneracao(row)}>
                        <Edit fontSize='small'/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <Divider/>
        <CardActions>
          <Button variant="contained" color="primary" onClick={() => setRemuneracaoProps({...remuneracaoProps, displayNovo: true})} >
          ADICIONAR
          </Button>
        </CardActions>
      </Card>

      <NovoRemuneracao display={remuneracaoProps.displayNovo} onSalvar={salvarNovaRemuneracao} onFechar={() => setRemuneracaoProps({...remuneracaoProps, displayNovo: false})}/>
      <EditarRemuneracao display={remuneracaoProps.displayEditar} data={remuneracaoProps.editRemuneracao} onSalvar={salvarEditarRemuneracao} onFechar={() => setRemuneracaoProps({...remuneracaoProps, displayEditar: false})}/>
      
      <CustomSnackBar 
        display={remuneracaoProps.alertDisplay}
        severity={remuneracaoProps.alertSeverity}
        text={remuneracaoProps.alertText}
        onClose={() => setRemuneracaoProps({...remuneracaoProps, alertDisplay: false})}
      />
      <Confirmation 
        title="Apagar remuneração?"
        open={remuneracaoProps.confirmation}
        setOpen={openDialog}
        onConfirm={onDeletarRemuneracao}
        >
          <Typography variant="subtitle2">Você tem certeza que deseja apagar essa remuneração?</Typography>
      </Confirmation>
    </div>
  )

}

FormRemuneracao.propTypes = {
  idFuncionario: PropTypes.string
};

export default withStyles(styles)(FormRemuneracao);