import styled, { css } from "styled-components";

export const styles = theme => ({
  table: {
    width: '40',
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: 14,
    paddingTop: 8,
    paddingBottom: 8
  },
  header: {
    backgroundColor: '#DDDDDD'
  },
  button: {
    paddingTop: 0,
    paddingBottom: 0
  },
  imageIcon: {
    height: '100%'
  },
  iconRoot: {
    textAlign: 'center',
    height: 75,
    width: 75
  },
  fileButton: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 125,
    width: 110,
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#CCCCCC',
    }
  },
  downloadLink: {
    cursor: 'pointer'
  }
});

const dragActive = css`
  border-color: #32A852;
  border-width: 3px;
`;

const dragReject = css`
  border-color: #D93030;
  border-width: 3px;
`;

export const DropContainer = styled.div.attrs({
  className: "dropzone"
})`
  border: 1px dashed #ddd;
  border-radius: 4px;
  transition: height 0.2s ease;
  ${props => props.isDragActive && dragActive};
  ${props => props.isDragReject && dragReject};
  width: 100%;
  height: 100;
`;

const messageColors = {
  default: "#999",
  error: "#D93030",
  success: "#32A852"
};

export const UploadMessage = styled.p`
  display: flex;
  color: ${props => messageColors[props.type || "default"]};
  justify-content: center;
  align-items: center;
  padding: 15px 0;
`;