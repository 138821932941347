import React, { useState } from 'react';
import styled from 'styled-components';
import FolderOpenTwoToneIcon from '@material-ui/icons/FolderOpenTwoTone';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import { Typography } from '@material-ui/core';

const StyledTree = styled.div`
  line-height: 1.5;
`;

export const Tree = ({ data, children, selected, onSelectFolder }) => {
  const isImperative = data && !children;

  return <StyledTree>{isImperative ? <TreeRecursive data={data} onSelectFolder={onSelectFolder} selected={selected}/> : children}</StyledTree>;
};

const StyledFolder = styled.div`
  padding-left: 20px;
  
  .folder--label {
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
      padding-top: 2px;
      padding-bottom: 2px;
      padding-left: 5px;
      padding-right: 5px;
      :hover {
        background-color: #BDBDBD;
        cursor: pointer;
      }
    }
    
  }
  
`;

const Collapsible = styled.div`
  height: ${p => (p.isOpen ? 'auto' : '0')};
  overflow: hidden;
`;

export const Folder = ({ name, id, children, selected, onSelectFolder }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <StyledFolder>
      <div className="folder--label">
        { isOpen ? 
          ( <div><IndeterminateCheckBoxOutlinedIcon style={children === null ? {fill: '#FFFFFF'} : null} onClick={handleToggle} fontSize="small"/> <FolderOpenTwoToneIcon fontSize="small" style={{fill: '#F7DD7E'}}/></div> )
        : 
          ( <div><AddBoxOutlinedIcon style={children === null ? {fill: '#FFFFFF'} : null} onClick={handleToggle} fontSize="small"/> <FolderOpenTwoToneIcon fontSize="small" style={{fill: '#F7DD7E'}}/></div> )
        }
        <span style={id === selected ? {backgroundColor: '#7186F0'} : null} onClick={() => onSelectFolder({name: name, id: id})}>
          <Typography variant="body2">{name}</Typography>
        </span>
      </div>
      <Collapsible isOpen={isOpen}>{children}</Collapsible>
    </StyledFolder>
  );
};

export const TreeRecursive = ({ data, selected, onSelectFolder }) => {

  return data.map(item => {
      return (
        <Folder name={item.name} id={item.id} onSelectFolder={onSelectFolder} selected={selected}>
          { item.children !== undefined ? <TreeRecursive data={item.children} selected={selected} onSelectFolder={onSelectFolder} /> : null}
        </Folder>
      )
  });
};