import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { postExtrato, putExtrato, getExtratos } from '../../requests/extratosAction';
import { listCliente } from '../../requests/clienteRequest';
import { CustomSnackBar } from '..';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  CircularProgress,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { connect } from 'react-redux';

function FormExtrato({ classes, disabled, acao, funcData, perfil }) {

  let history = useHistory();

  const [stateForm, setStateForm] = useState({
    submitting: false,
    disabled: disabled,
    action: acao,
    loading: true
  });

  const [showAlert, setShowAlert] = useState({
    display: false,
    severity: '',
    text: ''
  });

  const [usuarios, setUsuarios] = useState([]);
  const [cliente, setCliente] = useState([]);
  const [genero, setGenero] = useState([]);
  const [estadoCivil, setEstadoCivil] = useState([]);
  const [statusExtrato, setStatusExtrato] = useState([]);
  const [gestor, setGestor] = useState([]);

  useEffect(() => { carregarDados() }, []);

  async function carregarDados() {
    setStateForm({ ...stateForm, loading: true });
    // await carregarUsuarios();
     await carregarCliente();
    // await carregarGenero();
    // await carregarEstadoCivil();
    // await carregarStatusExtrato();
    // await carregarGestor();
    setStateForm({ ...stateForm, loading: false });
  }


  async function carregarCliente(){
    const response = await listCliente();
    if (!response) return
    setCliente(response.data);
  }


  function onEditExtrato() {
    setStateForm({ ...stateForm, disabled: false });
  }

  function handleCloseAlert() {
    setShowAlert({ display: false, severity: '', text: '' });
  };

  async function salvarExtrato(values) {

    setStateForm({ ...stateForm, submitting: true });

    let response;
    let text;

    if (stateForm.action === 'Novo') {
      response = await postExtrato(values);
    } else {
      response = await putExtrato(values);
    }

    if (response.valid) {

      if (stateForm.action === 'Novo') {
        text = 'Novo extrato cadastrado com sucesso!';
        history.push('/extratos');
      } else {
        stateForm.disabled = true;
        stateForm.action = 'Exibir';

        text = 'Extrato alterado com sucesso!';
        setStateForm({ ...stateForm, disabled: true });
      }

      setShowAlert({ display: true, severity: 'success', text: text });

    } else {
      setShowAlert({ display: true, severity: 'error', text: response.message });
      setStateForm({ ...stateForm, submitting: false });
    }
  }

  function onCancelarEditar(handleReset) {
    handleReset();
    setStateForm({ action: 'Exibir', disabled: true });
  }

  function cancelarNovo() {

    const toExtratos = {
      pathname: '/extratos'
    };

    return toExtratos;

  }

  const schema = yup.object().shape({
    // Nome: yup.string().required('Campo Obrigatório'),
    // Registro: yup.string().required('Campo Obrigatório'),
    // Id_Cliente: yup.number().required('Campo Obrigatório'),
    // Email: yup.string().email('Formato Inválido').required('Campo Obrigatório'),
    // Telefone: yup.string().min(14).required('Campo Obrigatório'),
    // CPF: yup.string().min(14).required('Campo Obrigatório'),
    // RG: yup.string().required('Campo Obrigatório'),
    data: yup.date().required('Campo Obrigatório'),
    // Id_Genero: yup.number().required('Campo Obrigatório'),
    // Id_Estado_Civil: yup.number().required('Campo Obrigatório'),
    // Id_Endereco: yup.number().nullable().default(null),
    // Data_Admissao: yup.date().required('Campo Obrigatório'),
    // Data_Desligamento: yup.date().nullable().default(null),
    // Id_Status: yup.number().required('Campo Obrigatório')
  });

  return (
    <Formik initialValues={funcData} onSubmit={(values) => salvarExtrato(values)} enableReinitialize={true} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          handleReset,
          setFieldValue
        } = props
        return (
          <Card className={classes.cardStyle}>
            <Form>

              <CustomSnackBar display={showAlert.display} severity={showAlert.severity} text={showAlert.text} onClose={handleCloseAlert} />

              <CardHeader title={stateForm.action + ' Extrato'} />
              <Divider />
              {stateForm.loading ?
                <div style={{ position: 'relative', top: 150, left: '50%', height: 300 }}>
                  <CircularProgress />
                </div>
                :
                <div>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Descrição"
                          margin="dense"
                          name="descricao"
                          onChange={handleChange}
                          value={values.descricao}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.descricao && touched.descricao ? errors.descricao : ''}
                          error={errors.descricao && touched.descricao ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="valor"
                          margin="dense"
                          name="valor"
                          onChange={handleChange}
                          value={values.valor}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.valor && touched.valor ? errors.valor : ''}
                          error={errors.valor && touched.valor ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid
                        item
                        md={2}
                        xs={6}
                      >
                        <TextField
                          fullWidth
                          label="Data"
                          margin="dense"
                          name="data"
                          type="date"
                          onChange={handleChange}
                          value={values.data}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={stateForm.disabled}
                          helperText={errors.data && touched.data ? errors.data : ''}
                          error={errors.data && touched.data ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item md={3} xs={6}>
                        <TextField
                          fullWidth
                          label="Cliente"
                          margin="dense"
                          name="id_Cliente"
                          onChange={handleChange}
                          select
                          SelectProps={{ native: true }}
                          value={values.id_Cliente}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.id_Cliente && touched.id_Cliente ? errors.id_Cliente : ''}
                          error={errors.id_Cliente && touched.id_Cliente ? true : false}
                          onBlur={handleBlur}
                        >
                          {cliente.map(option => (
                            <option key={option.id_Cliente} value={option.id_Cliente}>
                              {option.Nome}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="funcionario_Socio"
                          margin="dense"
                          name="funcionario_Socio"
                          onChange={handleChange}
                          value={values.funcionario_Socio}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.funcionario_Socio && touched.funcionario_Socio ? errors.funcionario_Socio : ''}
                          error={errors.funcionario_Socio && touched.funcionario_Socio ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>                      

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="nf"
                          margin="dense"
                          name="nf"
                          onChange={handleChange}
                          value={values.nf}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.nf && touched.nf ? errors.nf : ''}
                          error={errors.nf && touched.nf ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="validado"
                          margin="dense"
                          name="validado"
                          onChange={handleChange}
                          value={values.validado}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.validado && touched.validado ? errors.validado : ''}
                          error={errors.validado && touched.validado ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="nf_Terceiro"
                          margin="dense"
                          name="nf_Terceiro"
                          onChange={handleChange}
                          value={values.nf_Terceiro}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.nf_Terceiro && touched.nf_Terceiro ? errors.nf_Terceiro : ''}
                          error={errors.nf_Terceiro && touched.nf_Terceiro ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="investimento"
                          margin="dense"
                          name="investimento"
                          onChange={handleChange}
                          value={values.investimento}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.investimento && touched.investimento ? errors.investimento : ''}
                          error={errors.investimento && touched.investimento ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="compensado_Por"
                          margin="dense"
                          name="compensado_Por"
                          onChange={handleChange}
                          value={values.compensado_Por}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.compensado_Por && touched.compensado_Por ? errors.compensado_Por : ''}
                          error={errors.compensado_Por && touched.compensado_Por ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="incluir_Como_Dividendo"
                          margin="dense"
                          name="incluir_Como_Dividendo"
                          onChange={handleChange}
                          value={values.incluir_Como_Dividendo}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.incluir_Como_Dividendo && touched.incluir_Como_Dividendo ? errors.incluir_Como_Dividendo : ''}
                          error={errors.incluir_Como_Dividendo && touched.incluir_Como_Dividendo ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>

                    </Grid>
                  </CardContent>
                  <Divider />

                </div>
              }
              <Divider />
              <CardActions>
                {stateForm.action === 'Novo' ?
                  <Link to={cancelarNovo} style={{ textDecoration: 'none' }}>
                    <Button variant="contained" >
                      CANCELAR
                  </Button>
                  </Link>
                  : null}

                {stateForm.disabled ?
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={onEditExtrato}
                    disabled={!perfil.funcoes.update.includes('Extrato')}
                  >
                    EDITAR
                </Button>
                  :
                  <div>
                    {stateForm.action !== 'Novo' ?
                      <Button variant="contained" onClick={() => onCancelarEditar(handleReset)} style={{ marginRight: 10 }}>
                        CANCELAR
                    </Button>
                      : null}
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={stateForm.submitting}
                    > SALVAR
                    {stateForm.submitting ?
                        <CircularProgress
                          size={14}
                          color="secondary" />
                        : ''
                      }
                    </Button>
                  </div>
                }
              </CardActions>
            </Form>
          </Card>
        )
      }}
    </Formik>
  );
};

FormExtrato.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = store => ({
  perfil: store.Perfil,
})

export default connect(mapStateToProps, null)(withStyles(styles)(FormExtrato));