import styled, { css } from "styled-components";

const dragActive = css`
  border-color: #32A852;
  border-width: 3px;
`;

const dragReject = css`
  border-color: #D93030;
  border-width: 3px;
`;

export const DropContainer = styled.div.attrs({
  className: "dropzone"
})`
  border: 1px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: height 0.2s ease;
  ${props => props.isDragActive && dragActive};
  ${props => props.isDragReject && dragReject};
  width: 100%;
`;

const messageColors = {
  default: "#999",
  error: "#D93030",
  success: "#32A852"
};

export const UploadMessage = styled.p`
  display: flex;
  color: ${props => messageColors[props.type || "default"]};
  justify-content: center;
  align-items: center;
  padding: 15px 0;
`;