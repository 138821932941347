import React, { useState, useEffect } from 'react';
import { Card, CardHeader, Divider, CardContent, Grid, CardMedia, Button, Input, Paper, Typography, Slider, InputLabel, TextField, CircularProgress } from '@material-ui/core';
import defaultImage from '../../img/default-user.png';
import { getFileURLFromS3, uploadFileToS3 } from '../../services/aws-s3';
import { AtomicModal } from '../../components';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../img/CropUtil';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import getMeuPefil, { putUsuario } from '../../requests/usuarioRequest';


function MinhaConta({classes}){

  useEffect(() => { getDadosMeuPerfil(); }, []);

  const [meuPerfil, setMeuPerfil] = useState({
    Id_Usuario: 0,
    Nome: '',
    Usuario: '',
    Email: '',
  });

  const [readOnly, setReadOnly] = useState(true);
  const [loading, setLoading] = useState(true);

  const [meuPerfilEdit, setMeuPerfilEdit] = useState({
    Id_Usuario: 0,
    Nome: '',
    Usuario: '',
    Email: '',
  });

  const [imgPerfil, setImgPerfil] = useState(defaultImage);
  
  const [displayCrop, setDisplayCrop] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  async function getDadosMeuPerfil(){
    const response = await getMeuPefil();
    
    if (response.valid){
      setReadOnly(true);
      setMeuPerfil(response.data);
      let img = await getFileURLFromS3('usuario/perfil/'+ response.data.Id_Usuario +'.png');

      if(img !== null){
        setImgPerfil(img);
      }else{
        setImgPerfil(defaultImage);
      }
    }

    setLoading(false);

  }

  async function showCroppedImage() {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);

      uploadFileToS3(croppedImage, 'usuario/perfil/'+ meuPerfil.Id_Usuario +'.png');
      setDisplayCrop(false);
      
      let img = await getFileURLFromS3(`usuario/perfil/${meuPerfil.Id_Usuario}.png`);

      if(img !== null){
        setImgPerfil(img);
      }else{
        setImgPerfil(defaultImage);
      }
    } catch (e) {
      console.error(e)
    }
  }

  async function onFileChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      
      setImageSrc(imageDataUrl);
      setDisplayCrop(true);
    }
  }

  function onCropChange(crop) {
    setCrop(crop);
  }

  function onCropComplete(croppedArea, croppedAreaPixels) {
    setCroppedAreaPixels(croppedAreaPixels);
    console.log(croppedArea, croppedAreaPixels);
  }

  function onZoomChange(zoom) {
    setZoom(zoom);
  }

  function closeModal(){
    setDisplayCrop(false);
  }

  function readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  function handleChange(event) {
    setMeuPerfil({...meuPerfil, [event.target.name]: event.target.value});
  };

  function onEditarMeuPerfil(){
    setMeuPerfilEdit(meuPerfil);
    setReadOnly(false);
  }

  function onCancelarEditar(){
    setMeuPerfil(meuPerfilEdit);
    setReadOnly(true);
  }

  async function onSalvarEditarMeuPerfil(){
    await putUsuario(meuPerfil);
    setReadOnly(true);
  }

  return (
    <div style={{width: '100%'}}>
    
      <Card className={classes.card}>
        <CardHeader title="Minha Conta" />
        <Divider />
        
        {loading ?
          <div style={{ position: 'relative', top: '35%', left: '50%'}}>
            <CircularProgress />
          </div>
        :
          <CardContent>
            <div style={{width: '20%', float: 'left'}}>
              <CardMedia title="Foto" className={classes.image}>
                <img alt="Foto" src={imgPerfil} style={{width: 150, height: 200}}/>
              </CardMedia>
              <div className={classes.inputFileLabelContainer}>
                <InputLabel htmlFor="inputPhoto" className={classes.inputFileLabel} >Alterar Foto</InputLabel>
                <Input id="inputPhoto" type="file" onChange={onFileChange} className={classes.inputFile} /> 
              </div>
            </div>
            <div style={{marginLeft: '20%', width: '50%', marginTop: 20}}>
              <Grid container spacing={3}>
                <Grid item md={9} xs={9}>
                  <TextField
                    fullWidth
                    label="Usuario"
                    margin="dense"
                    name="Usuario"
                    onChange={handleChange}
                    value={meuPerfil.Usuario}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid item md={9} xs={9}>
                  <TextField
                    fullWidth
                    label="Nome"
                    margin="dense"
                    name="Nome"
                    onChange={handleChange}
                    value={meuPerfil.Nome}
                    variant="outlined"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item md={9} xs={9}>
                  <TextField
                    fullWidth
                    label="Email"
                    margin="dense"
                    name="Email"
                    onChange={handleChange}
                    value={meuPerfil.Email}
                    variant="outlined"
                    disabled={readOnly}
                  />
                </Grid>
                <Grid item md={9} xs={9}>
                  
                  {readOnly ?
                    <Button variant="contained" onClick={onEditarMeuPerfil} color="primary">Editar</Button>
                  :
                    <>
                      <Button variant="contained" onClick={onSalvarEditarMeuPerfil} color="primary">Salvar</Button>
                      <Button variant="contained" onClick={onCancelarEditar} style={{marginLeft: 5}}>Cancelar</Button>
                    </>
                  }
                </Grid>
              </Grid>
            </div>
          </CardContent>
        }
      </Card>
    
    


    <AtomicModal open={displayCrop} width={'40%'} handleClose={() => closeModal()}>
      <Paper className={classes.cropContainer}>
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={3 / 4}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
          />
      </Paper>

        <div className={classes.sliderContainer}>
          <Typography variant="overline">Zoom</Typography>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(e, zoom) => setZoom(zoom)}
            className={classes.slider}
          />
        <Button onClick={showCroppedImage}> 
          Save 
        </Button>
      </div>
    </AtomicModal>
  </div>

  )
}

export default withStyles(styles)(MinhaConta);