import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { postAtivo, putAtivo, getAtivos } from '../../requests/ativosAction';
import { CustomSnackBar } from '..';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  CircularProgress,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { TextMaskTelefone, TextMaskCPF, TextMaskCNPJ } from '../../masks';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { connect } from 'react-redux';
import { getExtratos } from '../../requests/extratosAction';
import { TextMaskCurrency } from '../../masks';
import { InputAdornment } from '@material-ui/core';

function FormAtivo({ classes, disabled, acao, funcData, perfil }) {

  let history = useHistory();

  const [stateForm, setStateForm] = useState({
    submitting: false,
    disabled: disabled,
    action: acao,
    loading: true
  });

  const [showAlert, setShowAlert] = useState({
    display: false,
    severity: '',
    text: ''
  });


  const [extratos, setExtratos] = useState([]);

  useEffect(() => { carregarDados() }, []);

  async function carregarDados() {
    setStateForm({ ...stateForm, loading: true });
    await carregarExtratos();
    setStateForm({ ...stateForm, loading: false });
  }

  async function carregarExtratos() {
    const response = await getExtratos();
    if (!response) return
    setExtratos(response.data);
  }


  function onEditAtivo() {
    setStateForm({ ...stateForm, disabled: false });
  }

  function handleCloseAlert() {
    setShowAlert({ display: false, severity: '', text: '' });
  };

  async function salvarAtivo(values) {

    setStateForm({ ...stateForm, submitting: true });

    let response;
    let text;

    if (stateForm.action === 'Novo') {
      response = await postAtivo(values);
    } else {
      response = await putAtivo(values);
    }

    if (response.valid) {

      if (stateForm.action === 'Novo') {
        text = 'Novo ativo cadastrado com sucesso!';
        history.push('/ativos');
      } else {
        stateForm.disabled = true;
        stateForm.action = 'Exibir';

        text = 'Ativo alterado com sucesso!';
        setStateForm({ ...stateForm, disabled: true });
      }

      setShowAlert({ display: true, severity: 'success', text: text });

    } else {
      setShowAlert({ display: true, severity: 'error', text: response.message });
      setStateForm({ ...stateForm, submitting: false });
    }
  }

  function onCancelarEditar(handleReset) {
    handleReset();
    setStateForm({ action: 'Exibir', disabled: true });
  }

  function cancelarNovo() {

    const toAtivos = {
      pathname: '/ativos'
    };

    return toAtivos;

  }

  const schema = yup.object().shape({

    assetNumber: yup.number().required(),
    description: yup.string().required('Campo Obrigatório'),
    brand: yup.string().required(),
    purchaseDate: yup.date(),
    price: yup.mixed().required(),
    store: yup.string().required(),
    id_Extrato: yup.number(),

    // id_Ativo: yup.string().required('Campo Obrigatório'),
    // assetNumber: yup.string().required('Campo Obrigatório'),
    // description: yup.string().required('Campo Obrigatório'),
    // brand: yup.string().required('Campo Obrigatório'),
    // purchaseDate: yup.date().default(null),
    // price: yup.number().required('Campo Obrigatório'),
    // store: yup.string().required('Campo Obrigatório'),
    // id_Extrato: yup.number(),
  });

  return (
    <Formik initialValues={funcData} onSubmit={(values) => salvarAtivo(values)} enableReinitialize={true} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          handleReset,
          setFieldValue
        } = props
        return (
          <Card className={classes.cardStyle}>
            <Form>

              <CustomSnackBar display={showAlert.display} severity={showAlert.severity} text={showAlert.text} onClose={handleCloseAlert} />

              <CardHeader title={stateForm.action + ' Ativo'} />
              <Divider />
              {stateForm.loading ?
                <div style={{ position: 'relative', top: 150, left: '50%', height: 300 }}>
                  <CircularProgress />
                </div>
                :
                <div>
                  <CardContent>
                    <Grid container spacing={4}>
                      <Grid
                        item
                        md={2}
                        xs={6}
                      >
                        <TextField
                          fullWidth
                          label="Número do ativo"
                          margin="dense"
                          name="assetNumber"
                          onChange={handleChange}
                          required
                          value={values.assetNumber}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.assetNumber && touched.assetNumber ? errors.assetNumber : ''}
                          error={errors.assetNumber && touched.assetNumber ? true : false}
                          onBlur={handleBlur}
                        />

                      </Grid>

                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Descrição"
                          margin="dense"
                          name="description"
                          onChange={handleChange}
                          value={values.description}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.description && touched.description ? errors.description : ''}
                          error={errors.description && touched.description ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid
                        item
                        md={2}
                        xs={6}
                      >
                        <TextField
                          fullWidth
                          label="Marca"
                          margin="dense"
                          name="brand"
                          onChange={handleChange}
                          required
                          value={values.brand}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.brand && touched.brand ? errors.brand : ''}
                          error={errors.brand && touched.brand ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid
                        item
                        md={2}
                        xs={6}
                      >
                        <TextField
                          fullWidth
                          label="Data da Compra"
                          margin="dense"
                          name="purchaseDate"
                          type="date"
                          onChange={handleChange}
                          value={values.purchaseDate}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={stateForm.disabled}
                          helperText={errors.purchaseDate && touched.purchaseDate ? errors.purchaseDate : ''}
                          error={errors.purchaseDate && touched.purchaseDate ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid
                        item
                        md={3}
                        xs={6}
                      >
                        <TextField
                          fullWidth
                          label="Preço"
                          margin="dense"
                          name="price"
                          type="text"
                          onChange={handleChange}
                          value={values.price}
                          variant="outlined"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            //inputComponent: TextMaskCurrency
                          }}
                          disabled={stateForm.disabled}
                          helperText={errors.price && touched.price ? errors.price : ''}
                          error={errors.price && touched.price ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid
                        item
                        md={2}
                        xs={6}
                      >
                        <TextField
                          fullWidth
                          label="Loja"
                          margin="dense"
                          name="store"
                          onChange={handleChange}
                          required
                          value={values.store}
                          variant="outlined"
                          disabled={stateForm.disabled}
                          helperText={errors.store && touched.store ? errors.store : ''}
                          error={errors.store && touched.store ? true : false}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item md={3} xs={3}>
                        <Autocomplete
                          options={extratos}
                          getOptionLabel={(option) => option.descricao}
                          renderInput={(params) => <TextField {...params} label="Extrato" variant="outlined" />}
                          size="small"
                          style={{ marginTop: 8 }}
                          onChange={(event, newValue) => {
                            console.log(newValue);
                            if (newValue === null) {
                              setFieldValue('id_Extrato', null)
                            } else {
                              setFieldValue('id_Extrato', newValue.id_Extrato)
                            }
                          }}
                          defaultValue={{ id_Extrato: values.id_Extrato, Extrato: values.Extrato }}
                          disabled={stateForm.disabled}
                        />
                      </Grid>


                    </Grid>
                  </CardContent>

                </div>
              }

              <CardActions>
                {stateForm.action === 'Novo' ?
                  <Link to={cancelarNovo} style={{ textDecoration: 'none' }}>
                    <Button variant="contained" >
                      CANCELAR
                  </Button>
                  </Link>
                  : null}

                {stateForm.disabled ?
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={onEditAtivo}
                    disabled={!perfil.funcoes.update.includes('Ativos')}
                  >
                    EDITAR
                </Button>
                  :
                  <div>
                    {stateForm.action !== 'Novo' ?
                      <Button variant="contained" onClick={() => onCancelarEditar(handleReset)} style={{ marginRight: 10 }}>
                        CANCELAR
                    </Button>
                      : null}
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={stateForm.submitting}
                    > SALVAR
                    {stateForm.submitting ?
                        <CircularProgress
                          size={14}
                          color="secondary" />
                        : ''
                      }
                    </Button>
                  </div>
                }
              </CardActions>
            </Form>
          </Card>
        )
      }}
    </Formik>
  );
};

FormAtivo.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = store => ({
  perfil: store.Perfil,
})

export default connect(mapStateToProps, null)(withStyles(styles)(FormAtivo));