export const styles = theme => ({
  table: {
    width: '40',
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: 14,
    paddingTop: 8,
    paddingBottom: 8
  },
  header: {
    backgroundColor: '#DDDDDD'
  },
  button: {
    paddingTop: 0,
    paddingBottom: 0
  },
  card: {
    marginTop: 30,
    marginLeft: 25,
    
  },
  TableCellLink: {
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer'
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

  Aberta: {borderLeft: '4px solid #3F51B5'},
  Selecionado: {borderLeft: '4px solid #ED972D'},
  Congelada: {borderLeft: '4px solid #74E6F2'},
  Preenchida: {borderLeft: '4px solid #2ADE23'},
  Cancelada: {borderLeft: '4px solid #DE2323'},

})