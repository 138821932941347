import React, { useState, useEffect } from 'react';
import { CircularProgress, Button, Typography, Paper, TextField, IconButton, Tooltip } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import GetAppIcon from '@material-ui/icons/GetApp';

import { AtomicModal } from '../../../../components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

//import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import { getFileURLFromS3 } from '../../../../services/aws-s3';

import { downloadFile } from '../../../../services/fileSystem';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function ExibirHolerite({classes, display, onFechar, arquivo}){

  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [goToPage, setGoToPage] = useState(1);

  const [file, setFile] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setLoading(false)
  }

  useEffect(() => { carregarPDFHolerite() }, [display]);

  async function carregarPDFHolerite(){
    
    if(arquivo){
      setLoading(true);
      if(arquivo.Extensao === 'pdf'){
        const s3FileFullPath = arquivo.Url;
        const s3Url = `https://${process.env.REACT_APP_AWS_BUCKET}.s3.amazonaws.com/`;
        const s3FilePath = s3FileFullPath.slice(s3FileFullPath.indexOf(s3Url) + s3Url.length)
        
        const src = await getFileURLFromS3(s3FilePath);

        if(src !== null){
          setFile(src);
        }
      }
      setLoading(false);
    }else{
      setFile(null);
    }

  }

  function closeExibirHolerite(){
    setFile(null);
    onFechar();
  }

  function previousPage() {
    setGoToPage(pageNumber - 1);
    changePage(-1);
  }

  function nextPage() {
    setGoToPage(pageNumber + 1);
    changePage(1);
  }

  function firstPage() {
    setPageNumber(1);
    setGoToPage(1);
  }

  function lastPage() {
    setPageNumber(numPages);
    setGoToPage(numPages);
  }

  function onGoToPage(key) {
    if(key === 'Enter'){
      if(Number.isInteger(goToPage)){
        if(goToPage > numPages){
          setPageNumber(numPages);
          setGoToPage(numPages);
        }else if(goToPage < 1){
          setPageNumber(1);
          setGoToPage(1);
        }else{
          setPageNumber(goToPage);
        }
      }else{
        setPageNumber(1);
        setGoToPage(1);
      }
    }
  }

  function changeGoToPage(pg){

    if(pg === ''){
      setGoToPage(pg);
    }    

    if(Number.isInteger(parseInt(pg))){
      setGoToPage(parseInt(pg));
    }
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  async function downloadHolerite(){
    setLoadingDownload(true);
    await downloadFile(arquivo.Id_Arquivo);
    setLoadingDownload(false);
  }

  return (
    <AtomicModal open={display} width="620px" height="600px" handleClose={closeExibirHolerite}>
      
      
      {loadingDownload ?
        <CircularProgress size="18px" style={{marginTop: '2px', marginLeft: '575px', position: 'absolute', padding: '0px'}}/>
      :
        <Tooltip title="Download">
          <IconButton color="primary" onClick={downloadHolerite} style={{marginTop: '0px', marginLeft: '575px', position: 'absolute', padding: '0px'}}>
            <GetAppIcon />
          </IconButton>
        </Tooltip>
      }
      

      <Tooltip title="Fechar">
        <IconButton color="secondary" onClick={closeExibirHolerite} style={{marginTop: '0px', marginLeft: '600px', position: 'absolute', padding: '0px'}}>
          <HighlightOffIcon />
        </IconButton>
      </Tooltip>

      {loading ?
        <div style={{ position: 'relative', top: 100, left: '50%', height: 250}}>
          <CircularProgress />
        </div>
        :
        <div>
          
          <div style={{textAlign: 'center', marginBottom: '20px'}}>
            <Typography style={{fontWeight: 'bold'}} variant="subtitle2">Exibir Holerite</Typography>
          </div>
          
          <Paper style={{position: 'relative', width: '610px', height: '520px', overflowY: 'auto', overflowX: 'hidden'}}>
            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error) => {setLoading(false); console.log(error.message)}}
            >
              <Page pageNumber={pageNumber}/>
            </Document>
          </Paper>
          
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5px'}}>
            <Button disabled={pageNumber <= 1} variant="outlined" size="small" onClick={firstPage} style={{marginRight: '5px'}} >
              {'<<'}
            </Button>
            <Button disabled={pageNumber <= 1} variant="outlined" size="small" onClick={previousPage} style={{marginRight: '5px'}}>
              {'<'}
            </Button>
            <TextField name="irParaPagina" value={goToPage} size="small" onChange={(e) => changeGoToPage(e.target.value)} style={{width: '20px', marginRight: '5px'}} onKeyPress={(e) => onGoToPage(e.key)} inputProps={{min: 0, style: { textAlign: 'center' }}}/>
            <Typography variant="body2" style={{marginRight: '5px'}}>de {numPages}</Typography>
            <Button disabled={pageNumber >= numPages} variant="outlined" size="small" onClick={nextPage} style={{marginRight: '5px'}}>
              {'>'}
            </Button>
            <Button disabled={pageNumber >= numPages} variant="outlined" size="small" onClick={lastPage} style={{marginRight: '5px'}}>
              {'>>'}
            </Button>
          </div>
        </div>
      }
    </AtomicModal>
  )
}

export default withStyles(styles)(ExibirHolerite);