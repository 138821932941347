import api from '../services/api';
import { handleRequestError } from '../services/security';

export async function listCargaHolerite( params ){

  let responseFromApi;

  try {
    const response = await api.get('/cargaHolerite', {params: params});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function getCargaHolerite( params ){

  let responseFromApi;

  try {
    const response = await api.get('/cargaHolerite', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postCargaHolerite( body ){
    
  let responseFromApi;

  try {
    const response = await api.post('/cargaHolerite', body);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putCargaHolerite( params ){
    
  let responseFromApi;

  try {
    const response = await api.put('/cargaHolerite', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}


export async function deleteCargaHolerite( params ){
    
  let responseFromApi;

  try {
    const response = await api.delete('/cargaHolerite', {params: params});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}