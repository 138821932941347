export const styles = theme => ({
  rootOuter: {
    padding: theme.spacing(3)
  },
  contentOuter: {
    marginTop: theme.spacing(2)
  },
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  simpleModal: {
    fontFamily: 'Arial'
  },
  simpleModalName: {
    fontFamily: 'Arial',
    fontSize: 12,
    fontWeight: 'bold'
  },
  container: {
    maxHeight: 440,
  },
  TableCellLink: {
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer'
    }
  },
  rowButton: {
    paddingTop: 5,
    paddingBottom: 5,
    marginTop: -10,
    marginBottom: -10
  },
  row: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableHeaderCollapse: {
    paddingTop: 8,
    paddingBottom: 8,
    width: 20
  },
  subTableHeader: {
    fontWeight: 'bold',
    fontSize: 12,
    color: 'white',
    paddingTop: 2,
    paddingBottom: 2
  },
  subHeader: {
    backgroundColor: '#5C65D1'
  },
  cardEndereco: {
    padding: 20
  }
});