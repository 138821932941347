import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { SearchInput } from '../../../../components';
import { connect } from 'react-redux';

function ExtratosToolbar({classes, onFilterExtrato, perfil}) {

  return (
    <div>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Link to="/extratos/novo" style={{ textDecoration: 'none' }}>
          <Button
            color="primary"
            variant="contained"
            disabled={!perfil.funcoes.create.includes('Extratos')}
          >
            Adicionar
          </Button>
        </Link>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Localizar"
          onChange={onFilterExtrato}
        />
      </div>
    </div>
  );
};

ExtratosToolbar.propTypes = {
  onFilterExtrato: PropTypes.func
};

const mapStateToProps = store => ({ 
  perfil: store.Perfil,
})

export default connect(mapStateToProps, null) (withStyles(styles)(ExtratosToolbar));
