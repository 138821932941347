import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Box, Collapse, Grid, MenuItem, Menu,
    Card, CardHeader, CardActions, Divider, Button, TablePagination, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ForwardIcon from '@material-ui/icons/Forward';
import { getPeriodoAvaliacoes, postPeriodoAvaliacao } from '../../requests/periodoAvaliacaoAction';
import { putAvaliacao } from '../../requests/avaliacaoAction';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { CustomSnackBar, TablePaginationActions, StatusAvaliacao, Confirmation } from '../../components';
import { withRouter } from 'react-router';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/Warning';
import DoneIcon from '@material-ui/icons/Done';
import NovoPeriodo from './components/NovoPeriodo';
import { connect } from 'react-redux';


function Avaliacoes({classes, history, perfil}){
  
  const [open, setOpen] = useState([]);

  const [avaliacoes, setAvaliacoes] = useState([]);

  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  const [displayNovoPeriodo, setDisplayNovoPeriodo] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState('');
  const [enviarAvaliacao, setEnviarAvaliacao] = useState({});
  const [acao, setAcao] = useState({});
  const [idAvaliacao, setIdAvaliacao] = useState(0);
  const [idPeriodo, setIdPeriodo] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => { carregarAvaliacoes() }, []);

  async function carregarAvaliacoes(){
    setLoading(true);

    const response = await getPeriodoAvaliacoes({});
    if (response.valid) {
      setAvaliacoes(response.data);
      
      let o = [];
      for(var i = 0; i < response.data.length; i++){
        o.push(false);
      }
      setOpen(o);
    } else {
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setLoading(false);
  }

  function handlePageChange(event, newPage) {
    setPage(newPage);
  };

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function onNovaAvaliacao(periodo){
    const toNovaAvaliacao = {
      pathname: '/avaliacoes/novo',
      periodo: periodo
    };

    return toNovaAvaliacao;
  }

  async function onEnviarAvaliacao(){
    setConfirmation(false);

    if(idAvaliacao > 0){
      const responseAtualizarStatus = await putAvaliacao({Id_Avaliacao: idAvaliacao, Status: 'Enviado'});

      if (responseAtualizarStatus.valid) {
        setAlert({display: true, severity: 'success', text: 'Avaliação enviada com sucesso!'});
        setTimeout(carregarAvaliacoes(), 1500);
      }else{
        setAlert({display: true, severity: 'error', text: responseAtualizarStatus.message});
      }
    }else{
      const responseAtualizarStatus = await putAvaliacao({Enviar: idPeriodo});

      if (responseAtualizarStatus.valid) {
        setAlert({display: true, severity: 'success', text: 'Avaliações do período enviadas com sucesso!'});
        setTimeout(carregarAvaliacoes(), 1500);
      }else{
        setAlert({display: true, severity: 'error', text: responseAtualizarStatus.message});
      }
    }
  }

  async function onCancelarAvaliacao(){
    setConfirmation(false);

    const responseAtualizarStatus = await putAvaliacao({Id_Avaliacao: idAvaliacao, Status: 'Cancelado'});

    if (responseAtualizarStatus.valid) {
      setAlert({display: true, severity: 'success', text: 'Avaliação cancelada com sucesso!'});
      setTimeout(carregarAvaliacoes(), 1500);
    }else{
      setAlert({display: true, severity: 'error', text: responseAtualizarStatus.message});
    }

  }

  function onEnviarAvaliacaoConfirmar(){
    setAnchorEl(null);
    setIdAvaliacao(acao.idAvaliacao);
    setIdPeriodo(0);

    setEnviarAvaliacao(
      <div>
        <Typography style={{fontWeight: 'bold'}} variant="h5">Período Avaliado</Typography>
        <Typography variant="subtitle2">Ano {acao.ano} - {acao.semestre}º Semestre</Typography>
        <br/>
        <Typography style={{fontWeight: 'bold'}} variant="h5">Funcionário</Typography>
        <Typography variant="subtitle2">{acao.funcionario}</Typography>
      </div>
    );

    setConfirmationTitle('Enviar Avaliação?');

    setConfirmation(true);
  }

  function onEnviarAvaliacaoPeriodoConfirmar(periodo){
    setIdPeriodo(periodo.Id_Periodo_Avaliacao);
    setIdAvaliacao(0);

    setEnviarAvaliacao(
      <div>
        <Typography style={{fontWeight: 'bold'}} variant="h5">Período Avaliado</Typography>
        <Typography variant="subtitle2">Ano {periodo.Ano} - {periodo.Semestre}º Semestre</Typography>
        <br/>
        <Typography style={{fontWeight: 'bold'}} variant="h5">Enviar Todos</Typography>
        <Typography variant="subtitle2">Enviar todas as avaliações criadas do período selecionado?</Typography>
      </div>
    );

    setConfirmationTitle('Enviar Avaliação?');

    setConfirmation(true);
  }

  function onCancelarAvaliacaoConfirmar(){
    setAnchorEl(null);
    setIdAvaliacao(acao.idAvaliacao);
    setIdPeriodo(0);

    setEnviarAvaliacao(
      <div>
        <Typography style={{fontWeight: 'bold'}} variant="h5">Período Avaliado</Typography>
        <Typography variant="subtitle2">Ano {acao.ano} - {acao.semestre}º Semestre</Typography>
        <br/>
        <Typography style={{fontWeight: 'bold'}} variant="h5">Funcionário</Typography>
        <Typography variant="subtitle2">{acao.funcionario}</Typography>
      </div>
    );

    setConfirmationTitle('Cancelar Avaliação?');

    setConfirmation(true);
  }

  async function salvarNovoPeriodo(periodo){

    const response = await postPeriodoAvaliacao({Ano: periodo.Ano, Semestre: periodo.Semestre});

    if (response.valid) {
      setDisplayNovoPeriodo(false);
      setAlert({display: true, severity: 'success', text: 'Período criado com sucesso!'});
      setTimeout(carregarAvaliacoes(), 1500);
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }
  }

  function abrirMenuOpcoes(event, status, idAvaliacao, funcionario, gestor, ano, semestre){
    setAcao({Status: status, idAvaliacao: idAvaliacao, funcionario: funcionario, gestor: gestor, ano: ano, semestre: semestre});
    let anchor = {top: event.clientY, left: event.clientX};
    setAnchorEl(anchor);
  }

  function fecharMenuOpcoes(){
    setAnchorEl(null);
  }

  function handleOpenClose(index){
    let o = [...open];
    o[index] = !open[index];
    setOpen(o);
  }

  function onVisualizarAvaliacao(){
    setAnchorEl(null);
    
    const toVisualizarAvaliacao = {
      pathname: '/avaliacoes/visualizar',
      avaliacao: acao,
      disabled: true
    };

    history.push(toVisualizarAvaliacao);
  }

  function Row({row, index}) {
  
    return (
      <React.Fragment>
        <TableRow className={classes.row}>
          <TableCell>
            <IconButton size="small" onClick={() => handleOpenClose(index)}>
              {open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {`Ano ${row.Ano} - ${row.Semestre}º Semestre`}
          </TableCell>
          <TableCell align="center">{row.Quantidade}</TableCell>
          <TableCell align="center">
            <Grid container spacing={3} justify="center">
              <Grid item xs={1} >
                <Typography variant="h6" style={{fontWeight: 'bold'}}>{row.Quantidade > 0 ? Math.round((row.Completado / (row.Quantidade - row.Cancelado)) * 100) : 0}%</Typography>
              </Grid>
              <Grid item xs={1} >
                { row.Completado === 0 ?
                  <CancelIcon color="error" />
                : row.Completado < row.Quantidade ?
                  <WarningIcon className={classes.warning} />
                :
                  <DoneIcon className={classes.success} />
                }
              </Grid>
            </Grid>
            </TableCell>
          <TableCell align="center">
            <Link to={onNovaAvaliacao(row)} style={{ textDecoration: 'none' }}>
              <Tooltip title="Criar Avaliações">
                <IconButton size="small">
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Link>
            <Tooltip title="Enviar Avaliação do Período">
              <IconButton size="small" onClick={() => onEnviarAvaliacaoPeriodoConfirmar(row)}>
                <ForwardIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open[index]} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small">
                  <TableHead className={classes.subHeader}>
                    <TableRow>
                      <TableCell className={classes.subTableHeader}>Funcionário</TableCell>
                      <TableCell className={classes.subTableHeader}>Gestor</TableCell>
                      <TableCell className={classes.subTableHeader} align="center">Status</TableCell>
                      <TableCell className={classes.subTableHeader} align="center">Iniciado</TableCell>
                      <TableCell className={classes.subTableHeader} align="center">Finalizado</TableCell>
                      <TableCell className={classes.subTableHeader} align="center">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.Avaliacoes.map((avaliacaoRow) => (
                      <TableRow key={avaliacaoRow.Id_Avaliacao}>
                        <TableCell component="th" scope="row">{avaliacaoRow.Funcionario}</TableCell>
                        <TableCell>{avaliacaoRow.Gestor}</TableCell>
                        <TableCell align="center">
                          <StatusAvaliacao status={avaliacaoRow.Status}/>
                        </TableCell>
                        <TableCell align="center">{moment(avaliacaoRow.Data_Inicio).format('DD/MM/YYYY')}</TableCell>
                        <TableCell align="center">{avaliacaoRow.Data_Fim !== null ? moment(avaliacaoRow.Data_Fim).format('DD/MM/YYYY') : ''}</TableCell>
                        <TableCell align="center">
                          <Tooltip title="Opções">
                            <IconButton size="small" onClick={(event) => abrirMenuOpcoes(event, avaliacaoRow.Status, avaliacaoRow.Id_Avaliacao, avaliacaoRow.Funcionario, avaliacaoRow.Gestor, row.Ano, row.Semestre)} id={`icon-button-${avaliacaoRow.Id_Avaliacao}`}>
                              <MoreVertIcon />
                            </IconButton>
                          </Tooltip>
                          <Menu anchorReference="anchorPosition" anchorPosition={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={fecharMenuOpcoes} 
                            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} transformOrigin={{vertical: 'top', horizontal: 'right'}}>
                            <MenuItem disabled={acao.Status === 'Criado' ? false : true} onClick={onEnviarAvaliacaoConfirmar}>Enviar</MenuItem>
                            {perfil.perfis.includes('Sócio') &&
                              <MenuItem disabled={acao.Status === 'Criado' || acao.Status === 'Enviado' ? true : false} onClick={onVisualizarAvaliacao}>Visualizar</MenuItem>
                            }
                            <MenuItem onClick={onCancelarAvaliacaoConfirmar}>Cancelar</MenuItem>
                          </Menu>
                        </TableCell>
                        
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  
  return(
    <div>
      <Card className={classes.card}>
        <CardHeader title="Avaliações"/>
        <Divider />
        {loading ?
          <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
            <CircularProgress />
          </div>
        :
          <TableContainer component={Paper} className={classes.table}>
            <Table className={classes.table} size="small">
              <TableHead className={classes.header}>
                <TableRow>
                  <TableCell className={classes.tableHeaderCollapse}/>
                  <TableCell className={classes.tableHeader}>Período</TableCell>
                  <TableCell className={classes.tableHeader} align="center">Avaliações</TableCell>
                  <TableCell className={classes.tableHeader} align="center">Completado</TableCell>
                  <TableCell className={classes.tableHeader} align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {avaliacoes.map((row, index) => (
                  <Row key={`Ano ${row.Ano} - ${row.Semestre}º Semestre`} row={row} index={index}/>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
        <Divider/>
        <CardActions>
          <Button variant="contained" color="primary" onClick={() => setDisplayNovoPeriodo(true)}>NOVO PERÍODO</Button>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
            count={avaliacoes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
            style={{position: 'absolute', right: 10}}
          />
        </CardActions>
      </Card>

      <NovoPeriodo display={displayNovoPeriodo} onSalvar={salvarNovoPeriodo} onFechar={() => setDisplayNovoPeriodo(false)} />

      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setAlert({display: false, severity: '', text: ''})}
      />

      <Confirmation 
        title={confirmationTitle}
        open={confirmation}
        setOpen={setConfirmation}
        onConfirm={confirmationTitle === 'Enviar Avaliação?' ? onEnviarAvaliacao : onCancelarAvaliacao}
        width={'sm'}
      >
          {enviarAvaliacao}
      </Confirmation>

    </div>
  )

}

const mapStateToProps = store => ({ 
  perfil: store.Perfil,
})

export default connect(mapStateToProps, null)(withRouter(withStyles(styles)(Avaliacoes)));