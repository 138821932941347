import api from '../services/api';
import { handleRequestError } from '../services/security';

export async function getPerfis( params ){

  let responseFromApi;

  try {
    const response = await api.get('/perfilAll', params);
  
    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    console.log(error);
    responseFromApi = {valid: false, status: error.response.status, message: error.response.data, data: []};
  }
  
  return responseFromApi;

}

export async function getPerfil( params ){

  let responseFromApi;

  try {
    const response = await api.get('/perfil', params);

    if (response.status === 200) {
        responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postPerfil( params ){
    
  let responseFromApi;

  try {
    const response = await api.post('/perfil', params);

    if (response.status === 200) {
        responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putPerfil( params ){
    
  let responseFromApi;

  try {
    const response = await api.put('/perfil', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}


export async function deletePerfil( params ){
    
  let responseFromApi;

  try {
    const response = await api.delete('/perfil', params);

    if (response.status === 200) {
        responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}