import api from '../services/api';
import { handleRequestError } from '../services/security';


export async function listTipoReembolso( params ){

  let responseFromApi;

  try {
    const response = await api.get('/tipo-reembolso', {params: params});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function getTipoReembolso( id, params ){

  let responseFromApi;

  try {
    const response = await api.get(`/tipo-reembolso/${id}`, {params: params});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postTipoReembolso( body ){
    
  let responseFromApi;

  try {
    const response = await api.post('/tipo-reembolso', body);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putTipoReembolso( id, body ){
    
  let responseFromApi;

  try {
    const response = await api.put(`/tipo-reembolso/${id}`, body);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}


export async function deleteReembolso(id, body){
  
  let response;
  let responseFromApi;
  
  try {
    if(id){
      response = await api.delete(`/tipo-reembolso/${id}`);
    }else{
      response = await api.delete('/tipo-reembolso', body);
    }

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}