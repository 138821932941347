export const styles = theme => ({
  modal: {
    paddingLeft: 10,
    paddingBottom: 10,
    paddingRight: 10
  },
  modalFields: {
    marginBottom: 20
  },
  modalSaveButton: {
    marginLeft: 10
  },
  fileItemBlock: {
    display: 'flex', 
    alignItems: 'center',
    height: '25px'
  }
})