import api from '../services/api';
import { handleRequestError } from '../services/security';

export async function getReunioesAvaliacao( params ){

  let responseFromApi;

  try {
    const response = await api.get('/reuniaoAvaliacaoAll', {params: params});
  
    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    console.log(error);
    responseFromApi = {valid: false, status: error.response.status, message: error.response.data, data: []};
  }
  
  return responseFromApi;

}

export async function getReuniaoAvaliacao( params ){

  let responseFromApi;

  try {
    const response = await api.get('/reuniaoAvaliacao', {params: params});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }else{
      responseFromApi = {valid: false, status: response.status, message: response.statusText, data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postReuniaoAvaliacao( params ){
    
  let responseFromApi;

  try {
    const response = await api.post('/reuniaoAvaliacao', params);

    if (response.status === 200) {
        responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putReuniaoAvaliacao( params ){
    
  let responseFromApi;

  try {
    const response = await api.put('/reuniaoAvaliacao', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}


export async function deleteReuniaoAvaliacao( params ){
    
  let responseFromApi;

  try {
    const response = await api.delete('/reuniaoAvaliacao', params);

    if (response.status === 200) {
        responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}