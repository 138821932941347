import React, { useState, useEffect } from 'react';
import { CircularProgress, Paper, CardHeader, Button, Card, Divider, CardContent, CardActions, Table, TableContainer, TableHead, TableRow, TableCell, Checkbox, TableBody, TablePagination } from '@material-ui/core';
import { AtomicModal, TablePaginationActions } from '../../../../components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { getCriterios } from '../../../../requests/criterioAction';



function AdicionarCriterio({classes, display, onAdicionar, onFechar, data, idSelecionados}){

  const [loading, setLoading] = useState(false);
  const [criterios, setCriterios] = useState([]);
  const [selecionados, setSelecionados] = useState(data);
  const [idSelecionado, setIdSelecionado] = useState(idSelecionados);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  useEffect(() => { carregarCriterios(idSelecionados) }, [idSelecionados]);

  async function carregarCriterios(id){
    setLoading(true);

    const response = await getCriterios({Id_Criterio: id});
    if (response.valid) {
      setCriterios(response.data);
      setIdSelecionado([]);
    }

    setLoading(false);
  }

  function handlePageChange(event, newPage) {
    setPage(newPage);
  };

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function selecionarCriterio(event, newValue, criterio){
    
    let selecionadosAlterado = [...selecionados];
    let novoCriterios = JSON.parse(JSON.stringify(criterios));
    
    let index = novoCriterios.findIndex(el => el.Id_Criterio === criterio.Id_Criterio);
    
    if(newValue){
      novoCriterios[index].Selecionado = 1;                  
      selecionadosAlterado.push(criterio);
      idSelecionado.push(criterio.Id_Criterio);
    }else{
      novoCriterios[index].Selecionado = 0;

      let indexSelecionado = selecionadosAlterado.findIndex(el => el.Id_Criterio === criterio.Id_Criterio);
      selecionadosAlterado.splice(indexSelecionado, 1);
    }
    
    setCriterios(novoCriterios);
    setSelecionados(selecionadosAlterado);
    
  }

  function adicionarSelecionados(){
    onAdicionar(selecionados, idSelecionado);
    setSelecionados([]);
  }

  return (
    <AtomicModal open={display} width={1000} handleClose={onFechar}>
      <Card className={classes.card}>
        <CardHeader title="Critérios de Avaliação"/>
        <Divider />
        {loading ?
          <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
            <CircularProgress />
          </div>
        :
          <CardContent>
            <TableContainer component={Paper} className={classes.table}>
              <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.header}>
                      <TableCell className={classes.tableHeader}><Checkbox/></TableCell>
                      <TableCell className={classes.tableHeader}>Tipo</TableCell>
                      <TableCell className={classes.tableHeader}>Criterio</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                    ? criterios.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : criterios
                    ).map((row) => (
                      <TableRow key={row.Id_Criterio}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={row.Selecionado}
                            onChange={(event, newValue) => selecionarCriterio(event, newValue, row)}
                          />
                        </TableCell>
                        <TableCell>{row.Tipo === 1 ? 'Nota' : row.Tipo === 2 ? 'Comentário' : 'Nota e Comentário'}</TableCell>
                        <TableCell>{row.Criterio}</TableCell>
                      </TableRow>
                  ))}
                  </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        }
        <Divider/>
        <CardActions>
          <Button variant="contained" color="primary" onClick={adicionarSelecionados}>ADICIONAR</Button>
          <Button variant="contained" onClick={onFechar}>FECHAR</Button>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
            count={criterios.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
            style={{position: 'absolute', right: 10}}
          />
        </CardActions>
      </Card>
    </AtomicModal>
  )
}

export default withStyles(styles)(AdicionarCriterio);