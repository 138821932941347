import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { Grid, Button, Paper, FormControlLabel, Checkbox, CardHeader, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { AtomicModal } from '..';


function ExportFields({classes, display, fields, onClose, onConfirm}){

  const [selectedFields, setSelectedFields] = useState([]);

  function onAddField(field){

    const selected = [...selectedFields];

    if(!selected.includes(field)){
      selected.push(field)
    }else{
      const index = selected.indexOf(field);
      selected.splice(index, 1)
    }

    setSelectedFields(selected);
  }

  function selectAll(e){
    if(e.target.checked){
      setSelectedFields([...fields]);
    }else{
      setSelectedFields([]);
    }
  }

  function exportFields(){
    onConfirm(selectedFields);
    onClose();
  }

  return (
    <AtomicModal width="150" open={display} handleClose={onClose}>
      <Paper className={classes.modal}>
        <CardHeader title="Selecione os campos" avatar={<Checkbox color="primary" onClick={(e) => selectAll(e)} />} />

        <Grid container spacing={1} className={classes.modalFields} direction="column">
          <List className={classes.list} dense component="div" role="list">
            { fields.map((field) => (
              <ListItem key={field} role="listitem" button onClick={() => onAddField(field)} style={{paddingTop: 0, paddingBottom: 0}}>
                <ListItemIcon>
                  <Checkbox
                    checked={selectedFields.includes(field)}
                    tabIndex={-1}
                    disableRipple
                    color="primary"
                    size="small"
                  />
                </ListItemIcon>
                <ListItemText id={field} primary={field} />
              </ListItem>
            ))}
            
          </List>
        </Grid>
        <Grid container justify="flex-end">
          <Button variant="contained" onClick={onClose} style={{marginRight: 5}}>Cancelar</Button>
          <Button variant="contained" color="primary" onClick={exportFields}>Exportar</Button>
        </Grid>
      </Paper>
    </AtomicModal>
  )
}

export default withStyles(styles)(ExportFields);