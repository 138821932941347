import React, { useState, useEffect } from 'react';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, RadioGroup, Radio, FormControlLabel, 
    Card, CardContent, CardActions, Divider, Button, TablePagination, CircularProgress, InputLabel, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { getFormularios } from '../../../../requests/formularioAction';
import { CustomSnackBar, TablePaginationActions } from '../../../../components';
import { getCriterioFormularios } from '../../../../requests/criterioFormularioAction';

function SelecionarFormulario({classes, formulario, setFormulario, setStepAtivo}){
  
  const [formularios, setFormularios] = useState([]);
  const [formularioSelecionado, setFormularioSelecionado] = useState(formulario);

  const [criterios, setCriterios] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingFormulario, setLoadingFormulario] = useState(false);
  
  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => { carregarFormularios() }, []);

  async function carregarFormularios(){
    setLoading(true);

    const response = await getFormularios();
    if (response.valid) {
      setFormularios(response.data);
    } else {
      setAlert({display: true, severity: 'error', text: response.message});
    }

    if(formulario !== undefined){
      carregarCriteriosFormulario(formulario);
    }

    setLoading(false);
  }

  async function carregarCriteriosFormulario(idFormulario){
    setLoadingFormulario(true);

    const response = await getCriterioFormularios({Id_Formulario: idFormulario});
    if (response.valid) {
      setCriterios(response.data);
    } else {
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setLoadingFormulario(false);
  }

  function handlePageChange(event, newPage) {
    setPage(newPage);
  };

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  function onSelecionarFormulario(idFormulario){
    setFormularioSelecionado(idFormulario);
    carregarCriteriosFormulario(idFormulario);
  }

  function onAvancar(){
    setFormulario(formularioSelecionado);
    setStepAtivo(2);
  }

  function onVoltar(){
    setFormulario(formularioSelecionado);
    setStepAtivo(0);
  }
  
  return(
    <div>
      <Card className={classes.card}>
        <Divider />
        <CardContent>
          {loading ?
            <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
              <CircularProgress />
            </div>
          :
            <TableContainer component={Paper} className={classes.table}>
              <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow className={classes.header}>
                      <TableCell className={classes.tableHeader}></TableCell>
                      <TableCell className={classes.tableHeader}>Formulário</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                    ? formularios.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : formularios
                    ).map((row) => (
                      <TableRow key={row.Id_Formulario} hover onClick={() => onSelecionarFormulario(row.Id_Formulario)}>
                        <TableCell>
                          <Checkbox  style={{paddingTop: 0, paddingBottom: 0}} size="small" checked={formularioSelecionado === row.Id_Formulario ? true : false} onChange={() => onSelecionarFormulario(row.Id_Formulario)}/>
                        </TableCell>
                        <TableCell>{row.Nome}</TableCell>
                      </TableRow>
                  ))}
                  </TableBody>
              </Table>
            </TableContainer>
          }
        </CardContent>
        <Divider/>
        <CardActions>
          <Button variant="contained" onClick={onVoltar}>VOLTAR</Button>
          <Button variant="contained" color="primary" onClick={onAvancar} disabled={formularioSelecionado === undefined}>AVANÇAR</Button>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
            count={formularios.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
            style={{position: 'absolute', right: 10}}
          />
        </CardActions>
        <CardContent>
          {loadingFormulario ?
            <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
              <CircularProgress />
            </div>
          :
            <div>
              {criterios.map((item, index) => (
                <Card className={classes.criterioBlock}>

                  <InputLabel className={classes.labelCriterio}>{index+1} - {item.Criterio}</InputLabel>

                  {item.Tipo === 1 || item.Tipo === 3 ?
                    <RadioGroup name="nota" value={1} row disabled={true} className={classes.labelNota}>
                      <FormControlLabel value={1} control={<Radio />} label="Não satisfatório" />
                      <FormControlLabel value={2} control={<Radio />} label="Precisa melhorar" />
                      <FormControlLabel value={3} control={<Radio />} label="Atende às expectativas" />
                      <FormControlLabel value={4} control={<Radio />} label="Acima da expectativa" />                          
                    </RadioGroup>
                  : null}

                  {item.Tipo === 2 || item.Tipo === 3 ?  
                    <TextField
                      fullWidth
                      margin="dense"
                      name="Criterio"
                      variant="outlined"
                      multiline
                      rows={4}
                      disabled={true}                  
                    />
                  : null}
                </Card>
              ))}
            </div>
          }
        </CardContent>
      </Card>

      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setAlert({display: false, severity: '', text: ''})}
      />

    </div>
  )

}

export default withStyles(styles)(SelecionarFormulario);