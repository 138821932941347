import React, { useState } from 'react';
import { Grid, AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';
import {} from '@material-ui/lab';
import { TabPanel } from '../../components'
import { FormFuncionario, FormEndereco, FormContaBanco, FormRemuneracao, FormDocumentosV2, FormAlocacao } from '../../components';
import { useLocation } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';


function ExibirFuncionario({classes}) {
  
  const location = useLocation();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  function validateData(value){
    if(location.funcionario === undefined) {
      window.location.href = '/atomic';
    }else{
      return value;
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="funcionario-tabs">
              <Tab label="Dados Pessoais" id="DadosTab" />
              <Tab label="Endereço" id="EnderecoTab" />
              <Tab label="Banco" id="BancoTab" />
              <Tab label="Remuneração" id="RemuneracaoTab" />
              <Tab label="Alocação" id="AlocacaoTab"/>
              <Tab label="Documentos" id="DocumentosTab" />

            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <FormFuncionario disabled={true} funcData={validateData(location.funcionario)} acao='Exibir' />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FormEndereco idEndereco={validateData(location.funcionario.Id_Endereco)} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <FormContaBanco disabled={true} titular={validateData(location.funcionario.Id_Funcionario)} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <FormRemuneracao idFuncionario={validateData(location.funcionario.Id_Funcionario)}/>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <FormAlocacao idFuncionario={validateData(location.funcionario.Id_Funcionario)}/>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <FormDocumentosV2 idFuncionario={validateData(location.funcionario.Id_Funcionario)}/>
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(ExibirFuncionario);