import React, { useState } from 'react';
import { CircularProgress, Paper, CardHeader, Grid, TextField, Button } from '@material-ui/core';
import { AtomicModal } from '../../../../components';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { CST_ANO, CST_SEMESTRE } from '../../../../constants';

function NovoPeriodo({classes, display, onSalvar, onFechar}){

  const initialValues = {
    Ano: 2020,
    Semestre: 1
  };
  
  const [loading, setLoading] = useState(false)

  function closeNovoPeriodo(handleReset){
    handleReset();  
    onFechar();
  }

  function salvarPeriodo(periodo, resetForm){
    setLoading(true);
    onSalvar(periodo);
    resetForm(initialValues);
    setLoading(false);
  }
  
  const schema = yup.object().shape({
    Ano: yup.number().required('Campo Obrigatório'),
    Semestre: yup.number().required('Campo Obrigatório')
  })

  return (
    <Formik initialValues={initialValues} onSubmit={(values, { resetForm }) => salvarPeriodo(values, resetForm)} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleReset
      } = props
      return (
        <AtomicModal open={display} width={300} handleClose={() => closeNovoPeriodo()}>
          <Form>
          <Paper className={classes.modal}>
            <CardHeader title="Novo Período" style={{textAlign: 'center'}}/>
              <Grid container spacing={1} className={classes.modalFields}>
                <Grid item md={12} xs={12}>
                <TextField
                    fullWidth
                    label="Ano"
                    margin="dense"
                    name="Ano"
                    select
                    SelectProps={{ native: true }}
                    onChange={(event) => { handleChange(event) }}
                    value={values.Ano}
                    variant="outlined"
                    helperText={errors.Ano && touched.Ano ? errors.Ano : ''}
                    error={errors.Ano && touched.Ano ? true : false}
                    onBlur={handleBlur}
                  >
                    {CST_ANO.map(option => (
                      <option key={option.Ano} value={option.Ano}>
                        {option.Ano}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Semestre"
                    margin="dense"
                    name="Semestre"
                    select
                    SelectProps={{ native: true }}
                    onChange={(event) => { handleChange(event) }}
                    value={values.Semestre}
                    variant="outlined"
                    helperText={errors.Semestre && touched.Semestre ? errors.Semestre : ''}
                    error={errors.Semestre && touched.Semestre ? true : false}
                    onBlur={handleBlur}
                  >
                    {CST_SEMESTRE.map(option => (
                      <option key={option.Valor} value={option.Valor}>
                        {option.Semestre}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              
              <Button variant="contained" onClick={() => closeNovoPeriodo(handleReset)} >
                CANCELAR
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading}
                className={classes.modalSaveButton}
              >
                SALVAR
                {loading ? 
                  <CircularProgress 
                  size={14}
                  color="secondary" />
                  : ''
                }
              </Button>
            </Paper>
            </Form>
          </AtomicModal>
        )
      }}
    </Formik>
  )
}

export default withStyles(styles)(NovoPeriodo);