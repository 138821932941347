import axios from 'axios';

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  //baseURL: 'http://localhost:3333',
  /*
  headers: {
    'Content-Type': 'application/json',
  },
  */
};

// Criar instância
let api = axios.create(defaultOptions);

// Atribui o authorization no header
api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});

export default api;