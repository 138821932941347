import React, { useState } from 'react';
import { Grid, AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';
import {} from '@material-ui/lab';
import { TabPanel } from '../../components'
import { FormCargo } from '../../components';
import { useLocation } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';


function ExibirCargo({classes}) {
  
  const location = useLocation();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  function validateData(value){
    if(location.cargo === undefined) {
      window.location.href = '/atomic';
    }else{
      return value;
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <TabPanel value={value} index={0}>
            <FormCargo disabled={true} funcData={validateData(location.cargo)} acao='Exibir' />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(ExibirCargo);