import api from '../services/api';
import { handleRequestError } from '../services/security';


export async function listArquivos( params ){

  let responseFromApi;

  try {
    const response = await api.get('/arquivo', {params: params});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function getArquivo( id, params ){

  let responseFromApi;

  try {
    const response = await api.get(`/arquivo/${id}`, {params: params});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postArquivo( body ){
    
  let responseFromApi;

  try {
    const response = await api.post('/arquivo', body);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putArquivo( id, body ){
    
  let responseFromApi;

  try {
    const response = await api.put(`/arquivo/${id}`, body);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}


export async function deleteArquivo(id, body){
  
  let response;
  let responseFromApi;
  
  try {
    if(id){
      response = await api.delete(`/arquivo/${id}`);
    }else{
      response = await api.delete('/arquivo', body);
    }

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}