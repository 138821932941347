const initialState = {perfis: [], funcoes: {read: [], create: [], update: [], delete: []}};
//const initialState = {perfil: []};

export default function Perfil(state = initialState, action) {
  switch (action.type) {
    case 'SET_PERFIL':
        return {...state, perfis: action.payload.perfis, funcoes: {read: action.payload.funcoes.read, create: action.payload.funcoes.create, update: action.payload.funcoes.update, delete: action.payload.funcoes.delete}};
        //return {...state, perfil: action.payload};
    default:
        return state;
  }
}