import React, { useState, useEffect } from 'react';
import { CircularProgress, Paper, CardHeader, Grid, TextField, Button,
  Tabs, Tab, Tooltip, Typography, Link, IconButton, AppBar } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { AtomicModal, UploadFile, ExibirImagem, TabPanel } from '../../../../components';
import { listNivelCarreira } from '../../../../requests/nivelCarreiraRequest';
import { listCliente } from '../../../../requests/clienteRequest';
import { listUnidadeNegocio } from '../../../../requests/unidadeNegocioRequest';
import { listArquivos, postArquivo, deleteArquivo } from '../../../../requests/arquivoRequest';
import { downloadFile } from '../../../../services/fileSystem';
import { getFileURLFromS3 } from '../../../../services/aws-s3';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import * as yup from 'yup';
import { Formik, Form } from 'formik';

function EditarVaga({classes, disabled, display, onSalvar, onFechar, data}){

  const [selectedTab, setSelectedTab] = useState(0);
  const [cliente, setCliente] = useState([]);
  const [unidadeNegocio, setUnidadeNegocio] = useState([]);
  const [nivelCarreira, setNivelCarreira] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opening, setOpening] = useState(false);
  const [file, setFile] = useState([]);
  const [image, setImage] = useState({
    open: false,
    src: ''
  });
  
  useEffect(() => { carregarCliente() }, []);
  useEffect(() => { carregarUnidadeNegocio() }, []);
  useEffect(() => { carregarNivelCarreira() }, []);
  useEffect(() => { carregarAnexos() }, [data]);

  async function carregarCliente(){
    const response = await listCliente({});

    if(response.valid){
      setCliente([...response.data, {Id_Cliente: 0, Nome: ''}]);
    }
  }

  async function carregarUnidadeNegocio(){
    const response = await listUnidadeNegocio({});

    if(response.valid){
      setUnidadeNegocio([...response.data, {Id_Unidade_Negocio: 0, Nome: ''}]);
    }
  }

  async function carregarNivelCarreira(){
    const response = await listNivelCarreira({});

    if(response.valid){
      setNivelCarreira(response.data);
    }
  }

  async function carregarAnexos(){
    
    setOpening(true);

    setSelectedTab(0);

    if(display && data){
      const params = {Entidade: 'Vaga', Id_Entidade: data.Id_Vaga}
      const responseArquivo = await listArquivos(params);

      if(responseArquivo.valid){
        setFile(responseArquivo.data);
      }
    }

    setOpening(false);

  }

  function closeEditarVaga(handleReset){
    handleReset();  
    onFechar();
    setFile([]);
  }

  function salvarVaga(vaga){
    setLoading(true);
    onSalvar(vaga);
    setLoading(false);
  }

  async function handleUpload(newFile) {
    
    const body = new FormData();
    body.append('file', newFile[0], newFile[0].name);
    body.append('Entidade', 'Vaga');
    body.append('Id_Entidade', data.Id_Vaga);

    const response = await postArquivo(body);

    if(response.valid){
      let newFileList = [...file, response.data];
      setFile(newFileList);
    }
  };

  async function removeFile(index){

    let newFileList = [...file];
    const removedFile = newFileList.splice(index, 1);
    
    const response = await deleteArquivo(removedFile[0].Id_Arquivo, {});

    if(response.valid){
      setFile(newFileList);
    }

  }

  async function exibirOuBaixarArquivo(arquivo){

    if(['jpg', 'jpeg', 'gif', 'png'].includes(arquivo.Extensao)){
      const s3FileFullPath = arquivo.Url;
      const s3Url = `https://${process.env.REACT_APP_AWS_BUCKET}.s3.amazonaws.com/`;
      const s3FilePath = s3FileFullPath.slice(s3FileFullPath.indexOf(s3Url) + s3Url.length)
      
      const src = await getFileURLFromS3(s3FilePath);

      if(src !== null){
        setImage({open: true, src: src});
      }
    }else{
      downloadFile(arquivo.Id_Arquivo);
    }

  }

  function onCloseExibirImagem(){
    setImage({open: false, src: ''});
  }

  function handleChangeTab(event, newValue){
    setSelectedTab(newValue);
  }
  
  const schema = yup.object().shape({
    Id_Vaga: yup.number().required('Campo Obrigatório'),
    Titulo: yup.string().required('Campo Obrigatório'),
    Id_Nivel_Carreira: yup.number().required('Campo Obrigatório'),
    Id_Cliente: yup.number(),
    Id_Unidade_Negocio: yup.number(),
    Descricao: yup.string().required('Campo Obrigatório')
  })

  return (
    <Formik initialValues={data} onSubmit={(values, { resetForm }) => salvarVaga(values)} enableReinitialize={true} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleReset
      } = props
      return (
        <AtomicModal open={display} width="800px" height="500px">
          <Form>
            <Paper className={classes.modal}>
              <CardHeader title={`${disabled ? 'Visualizar' : 'Editar'} Vaga #${values.Id_Vaga}`} style={{textAlign: 'center', fontWeight: 'bold'}}/>
                {opening ?
                  <div className={classes.loadingDiv}>
                    <CircularProgress size={40} />
                  </div>
                :
                <>
                  <AppBar position="static">
                    <Tabs
                      value={selectedTab}
                      onChange={handleChangeTab}
                    >
                      <Tab label="Geral" />
                      <Tab label="Anexos" />
                      <Tab label="Comentários" />
                    </Tabs>
                  </AppBar>

                  <TabPanel value={selectedTab} index={0}>
                    <Grid container spacing={1} className={classes.modalFields}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          fullWidth
                          label="Titulo"
                          margin="dense"
                          name="Titulo"
                          onChange={handleChange}
                          value={values.Titulo}
                          variant="outlined"
                          helperText={errors.Titulo && touched.Titulo ? errors.Titulo : ''}
                          error={errors.Titulo && touched.Titulo ? true : false}
                          onBlur={handleBlur}
                          disabled={loading || disabled}
                        />
                      </Grid>

                      <Grid item md={4} xs={4}>
                        <TextField
                          fullWidth
                          label="Status"
                          margin="dense"
                          name="Status"
                          onChange={handleChange}
                          value={values.Status}
                          variant="outlined"
                          helperText={errors.Status && touched.Status ? errors.Status : ''}
                          error={errors.Status && touched.Status ? true : false}
                          onBlur={handleBlur}
                          disabled={true}
                        />
                      </Grid>

                      <Grid item md={4} xs={4}>
                        <TextField
                          fullWidth
                          label="Data Abertura"
                          margin="dense"
                          name="Data_Abertura"
                          type="date"
                          onChange={handleChange}
                          value={String(values.Data_Abertura).substring(0, 10)}
                          variant="outlined"
                          InputLabelProps={{
                              shrink: true,
                          }}
                          style={{paddingRight: 5}}
                          helperText={errors.Data_Abertura && touched.Data_Abertura ? errors.Data_Abertura : ''}
                          error={errors.Data_Abertura && touched.Data_Abertura ? true : false}
                          onBlur={handleBlur}
                          disabled={true}
                        />
                      </Grid>

                      <Grid item md={4} xs={4}>
                        <TextField
                          fullWidth
                          label="Data Encerramento"
                          margin="dense"
                          name="Data_Encerramento"
                          type="date"
                          onChange={handleChange}
                          value={String(values.Data_Encerramento).substring(0, 10)}
                          variant="outlined"
                          InputLabelProps={{
                              shrink: true,
                          }}
                          style={{paddingRight: 5}}
                          helperText={errors.Data_Encerramento && touched.Data_Encerramento ? errors.Data_Encerramento : ''}
                          error={errors.Data_Encerramento && touched.Data_Encerramento ? true : false}
                          onBlur={handleBlur}
                          disabled={true}
                        />
                      </Grid>

                      <Grid item md={4} xs={4}>
                        <TextField
                          fullWidth
                          label="Nível de Carreira"
                          margin="dense"
                          name="Id_Nivel_Carreira"
                          onChange={handleChange}
                          select
                          SelectProps={{ native: true }}
                          value={values.Id_Nivel_Carreira}
                          variant="outlined"
                          helperText={errors.Id_Nivel_Carreira && touched.Id_Nivel_Carreira ? errors.Id_Nivel_Carreira : ''}
                          error={errors.Id_Nivel_Carreira && touched.Id_Nivel_Carreira ? true : false}
                          onBlur={handleBlur}
                          disabled={loading || disabled}
                        >
                          {nivelCarreira.map(option => (
                            <option key={option.Id_Nivel_Carreira} value={option.Id_Nivel_Carreira}>
                              {option.Nome}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item md={4} xs={4}>
                        <TextField
                          fullWidth
                          label="Cliente"
                          margin="dense"
                          name="Id_Cliente"
                          onChange={handleChange}
                          select
                          SelectProps={{ native: true }}
                          value={values.Id_Cliente}
                          variant="outlined"
                          defaultValue={{Id_Cliente: '', Nome: ''}}
                          helperText={errors.Id_Cliente && touched.Id_Cliente ? errors.Id_Cliente : ''}
                          error={errors.Id_Cliente && touched.Id_Cliente ? true : false}
                          onBlur={handleBlur}
                          disabled={loading || disabled}
                        >
                          {cliente.map(option => (
                            <option key={option.Id_Cliente} value={option.Id_Cliente}>
                              {option.Nome}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item md={4} xs={4}>
                        <TextField
                          fullWidth
                          label="Unidade de Negócio"
                          margin="dense"
                          name="Id_Unidade_Negocio"
                          onChange={handleChange}
                          select
                          SelectProps={{ native: true }}
                          value={values.Id_Unidade_Negocio}
                          variant="outlined"
                          defaultValue={{Id_Unidade_Negocio: '', Nome: ''}}
                          helperText={errors.Id_Unidade_Negocio && touched.Id_Unidade_Negocio ? errors.Id_Unidade_Negocio : ''}
                          error={errors.Id_Unidade_Negocio && touched.Id_Unidade_Negocio ? true : false}
                          onBlur={handleBlur}
                          disabled={loading || disabled}
                        >
                          {unidadeNegocio.map(option => (
                            <option key={option.Id_Unidade_Negocio} value={option.Id_Unidade_Negocio}>
                              {option.Nome}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <TextField
                          fullWidth
                          label="Descrição"
                          margin="dense"
                          name="Descricao"
                          onChange={handleChange}
                          value={values.Descricao}
                          variant="outlined"
                          helperText={errors.Descricao && touched.Descricao ? errors.Descricao : ''}
                          error={errors.Descricao && touched.Descricao ? true : false}
                          onBlur={handleBlur}
                          multiline={true}
                          minRows="5"
                          maxRows="5"
                          disabled={loading || disabled}
                        />
                      </Grid>
                    </Grid>
                    
                  </TabPanel>
                  
                  <TabPanel value={selectedTab} index={1}>
                    { !disabled &&
                      <Grid item xs={12} sm={12}>
                        <UploadFile onUpload={handleUpload}/>
                      </Grid>
                    }
              
                    <Grid item xs={12} sm={12} direction="column">
                      {file.map((fileItem, index) => 
                        (
                          <div className={classes.fileItemBlock}>
                            <Tooltip title="Remover">
                              <IconButton color="secondary" onClick={() => removeFile(index)} disabled={loading || disabled}>
                                <DeleteForeverIcon />
                              </IconButton>
                            </Tooltip>
                            <Link onClick={() => exibirOuBaixarArquivo(fileItem)} className={classes.downloadLink}>
                              <Typography variant="body1" style={{color: 'blue', fontWeight: 'bold'}}>{`${fileItem.Arquivo}.${fileItem.Extensao}`}</Typography>
                            </Link>
                          </div>
                        ))
                      }
                    </Grid>
                  </TabPanel>
                  <TabPanel value={selectedTab} index={2}>
                    Item Three
                  </TabPanel>
                </>
              }
              </Paper>
              <div style={{marginTop: 10}}>
                <Button variant="contained" onClick={() => closeEditarVaga(handleReset)} >
                  CANCELAR
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={disabled || opening || loading}
                  className={classes.modalSaveButton}
                >
                  SALVAR
                  {loading && <CircularProgress size={14} color="secondary" />}
                </Button>
              </div>
            </Form>
            <ExibirImagem open={image.open} src={image.src} handleClose={onCloseExibirImagem} />
          </AtomicModal>
        )
      }}
    </Formik>
  )
}

export default withStyles(styles)(EditarVaga);