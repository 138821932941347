import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton,
    Card, CardHeader, CardContent, CardActions, Divider, Button, TablePagination, CircularProgress } from '@material-ui/core';
import { getCriterios, postCriterio, putCriterio, deleteCriterio } from '../../requests/criterioAction';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { CustomSnackBar, TablePaginationActions } from '../../components';
import NovoCriterio from './components/NovoCriterio';
import EditarCriterio from './components/EditarCriterio';
import { Delete } from '@material-ui/icons';
import { Confirmation } from '../../components';


function Criterios({classes}){
  
  const [criterios, setCriterios] = useState([]);

  const [form, setForm] = useState({
    displayNovo: false,
    displayEditar: false,
    editarCriterio: {},
    confirmation: false,
    idCriterio: 0
  });

  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  useEffect(() => { carregarCriterios() }, []);

  async function carregarCriterios(){
    setLoading(true);

    const response = await getCriterios({});
    if (response.valid) {
      setCriterios(response.data);
    } else {
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setLoading(false);
  }

  function handlePageChange(event, newPage) {
    setPage(newPage);
  };

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function salvarNovoCriterio(criterio){

    const response = await postCriterio(criterio);

    if(response.valid){
      carregarCriterios();
      setForm({...form, displayNovo: false});
      setAlert({display: true, severity: 'success', text: 'Criterio criado com sucesso!'});
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

  }

  async function salvarEditarCriterio(criterio){

    const response = await putCriterio(criterio);

    if(response.valid){
      carregarCriterios();
      setForm({...form, displayEditar: false});
      setAlert({display: true, severity: 'success', text: 'Criterio alterado com sucesso!'});
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

  }

  function onNovoCriterio(){
    setForm({...form, displayNovo: true});
  }

  function openDialog(idCriterio, openConfirm) {
    setForm({...form, confirmation: openConfirm, idCriterio: idCriterio});
  }

  async function onDeletarCriterio(){
    const response = await deleteCriterio({Id_Criterio: form.idCriterio});

    if(response.valid){
      carregarCriterios();
      setAlert({display: true, severity: 'success', text: 'Criterio apagado com sucesso!'});
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setForm({...form, confirmation: false});
  }
  
  return(
    <div>
      <Card className={classes.card}>
        <CardHeader title="Critérios de Avaliação"/>
        <Divider />
        {loading ?
          <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
            <CircularProgress />
          </div>
        :
          <CardContent>
            <TableContainer component={Paper} className={classes.table}>
              <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.header}>
                    <TableCell className={classes.tableHeader}>Criterio</TableCell>
                      <TableCell className={classes.tableHeader}>Tipo</TableCell>
                      <TableCell className={classes.tableHeader}>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                    ? criterios.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : criterios
                    ).map((row) => (
                      <TableRow key={row.Id_Criterio}>
                        <TableCell>
                          <Link className={classes.TableCellLink} onClick={() => setForm({...form, editarCriterio: row, displayEditar: true})}>{row.Criterio}</Link>
                        </TableCell>
                        <TableCell>{row.Tipo === 1 ? 'Nota' : row.Tipo === 2 ? 'Comentário' : 'Nota e Comentário'}</TableCell>
                        <TableCell>
                          <IconButton className={classes.button} onClick={() => openDialog(row.Id_Criterio, true)}>
                            <Delete fontSize='small'/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                  ))}
                  </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        }
        <Divider/>
        <CardActions>
          <Button variant="contained" color="primary" onClick={onNovoCriterio}>NOVO</Button>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
            count={criterios.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
            style={{position: 'absolute', right: 10}}
          />
        </CardActions>
      </Card>

      <NovoCriterio display={form.displayNovo} onSalvar={salvarNovoCriterio} onFechar={() => setForm({...form, displayNovo: false})}/>
      <EditarCriterio display={form.displayEditar} data={form.editarCriterio} onSalvar={salvarEditarCriterio} onFechar={() => setForm({...form, displayEditar: false})}/>
      
      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setAlert({display: false, severity: '', text: ''})}
      />
      <Confirmation 
        title="Apagar critério de avaliação?"
        open={form.confirmation}
        setOpen={openDialog}
        onConfirm={onDeletarCriterio}
        >
          Você tem certeza que deseja apagar esse critério de avaliação?
      </Confirmation>
    </div>
  )

}

export default withStyles(styles)(Criterios);