import React, { useState, useEffect } from 'react';
import { CircularProgress, Paper, CardHeader, Grid, TextField, Button, Tooltip, Typography, IconButton, LinearProgress } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { AtomicModal, UploadFile } from '../../../../components';
import { CST_ANO, CST_MES_NUMERO } from '../../../../constants';
import { listTipoHolerite } from '../../../../requests/tipoHoleriteRequest';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import * as yup from 'yup';
import { Formik, Form } from 'formik';

function CarregarHolerites({classes, display, onSalvar, onFechar}){

  const initialValues = {
    Ano: CST_ANO[0].Ano,
    Mes: CST_MES_NUMERO[0].Nome,
    Id_Tipo_Holerite: 1
  };
  
  const [loading, setLoading] = useState(false);
  const [tipoHolerite, setTipoHolerite] = useState([]);
  const [file, setFile] = useState([]);

  useEffect(() => { carregarTipoHolerite() }, []);

  async function carregarTipoHolerite(){
    const response = await listTipoHolerite({});

    if (response.valid) {
      setTipoHolerite(response.data);
    }
  }

  function closeCarregarHolerite(handleReset){
    handleReset();
    setFile([]);
    onFechar();
  }

  async function processarCargaHolerites(holerite, resetForm){
    setLoading(true);
    await onSalvar(holerite, file[0]);
    resetForm(initialValues);
    setFile([]);
    setLoading(false);
  }

  function handleUpload(newFile) {
    const uploadedFile = {
      file: newFile,
      name: newFile[0].name,
    };

    setFile([...file, uploadedFile]);
  };
  
  const schema = yup.object().shape({
    Ano: yup.string().required('Campo Obrigatório'),
    Mes: yup.string().required('Campo Obrigatório'),
    Id_Tipo_Holerite: yup.string().required('Campo Obrigatório')
  })

  return (
    <>
    <Formik initialValues={initialValues} onSubmit={(values, { resetForm }) => processarCargaHolerites(values, resetForm)} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleReset
      } = props
      return (
        <AtomicModal open={display} width={400} handleClose={() => closeCarregarHolerite()}>
          
          {loading ?

            <Paper className={classes.modal}>
              <CardHeader title="Carregando Holerites" style={{textAlign: 'center'}}/>
              Carregando...
              <LinearProgress />
            </Paper>

          :
            <Form>
            <Paper className={classes.modal}>
              <CardHeader title="Carga de Holerites" style={{textAlign: 'center'}}/>
                <Grid container spacing={1} className={classes.modalFields}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Ano"
                      margin="dense"
                      name="Ano"
                      onChange={handleChange}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={values.Ano}
                      variant="outlined"
                      disabled={loading}
                      helperText={errors.Ano && touched.Ano ? errors.Ano : ''}
                      error={errors.Ano && touched.Ano ? true : false}
                      onBlur={handleBlur}
                    >
                      {CST_ANO.map(option => (
                        <option key={option.Ano} value={option.Ano}>
                          {option.Ano}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Mês"
                      margin="dense"
                      name="Mes"
                      onChange={handleChange}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={values.Mes}
                      variant="outlined"
                      disabled={loading}
                      helperText={errors.Mes && touched.Mes ? errors.Mes : ''}
                      error={errors.Mes && touched.Mes ? true : false}
                      onBlur={handleBlur}
                    >
                      {CST_MES_NUMERO.map(option => (
                        <option key={option.Id} value={option.Nome}>
                          {option.Nome}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Tipo"
                      margin="dense"
                      name="Id_Tipo_Holerite"
                      onChange={handleChange}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={values.Id_Tipo_Holerite}
                      variant="outlined"
                      disabled={loading}
                      helperText={errors.Id_Tipo_Holerite && touched.Id_Tipo_Holerite ? errors.Id_Tipo_Holerite : ''}
                      error={errors.Id_Tipo_Holerite && touched.Id_Tipo_Holerite ? true : false}
                      onBlur={handleBlur}
                    >
                      {tipoHolerite.map(option => (
                        <option key={option.Id_Tipo_Holerite} value={option.Id_Tipo_Holerite}>
                          {option.Nome}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  
                  {file.length === 0 ?
                    <Grid item xs={12} sm={12}>
                      <UploadFile onUpload={handleUpload} accept={['application/pdf']}/>
                    </Grid>
                  :
                    <Grid item xs={12} sm={12} direction="column">
                      <div className={classes.fileItemBlock}>
                        <Tooltip title="Remover">
                          <IconButton color="secondary" onClick={() => setFile([])} disabled={loading}>
                            <DeleteForeverIcon />
                          </IconButton>
                        </Tooltip>
                        <Typography variant="body1">{file[0].name}</Typography>
                      </div>
                    </Grid>
                  }
                </Grid>
                
                <Button variant="contained" onClick={() => closeCarregarHolerite(handleReset)} >
                  CANCELAR
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={loading || file.length === 0}
                  className={classes.modalSaveButton}
                >
                  CARREGAR
                  {loading ? 
                    <CircularProgress 
                    size={14}
                    color="secondary" />
                    : ''
                  }
                </Button>
              </Paper>
              </Form>
            }

          </AtomicModal>
        )
      }}
    </Formik>
    </>
  )
}

export default withStyles(styles)(CarregarHolerites);