import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import getout from '../../img/getout.jpg';

function Proibido({classes}){
  
  return(
    <div className={classes.image}>
      <img src={getout} alt="Proibido"/>
    </div>
  )

}

export default withStyles(styles)(Proibido);