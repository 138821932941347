import api from '../services/api';
import { handleRequestError } from '../services/security';

export async function getUsuario(session){

  let responseFromApi;
  
  try {
    const response = await api.get('/usuario', {params: {token: session.Token}, headers: {Authorization: 'Bearer ' + session.Token }});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function getUsuarioByUsername(Usuario){

  let responseFromApi;
  
  try {
    const response = await api.get(`/usuario/${Usuario}`);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function getUsuarios(){

  let responseFromApi;
  
  try {
    const response = await api.get('/usuario', {});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postUsuario( params ){
    
  let responseFromApi;
  
  try {
    const response = await api.post('/usuario', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
  
}

export async function putUsuario(body){

  let responseFromApi;
  
  try {
    const response = await api.put('/usuario', body);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export default async function getMeuPerfil() {

  let responseFromApi;
  
  try {
    const response = await api.get('/meuperfil', { params: { Token: localStorage.getItem('token') } });

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data[0]};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}