import React, { useState, useEffect } from 'react';
import { CircularProgress, Paper, CardHeader, Grid, TextField, Button, InputAdornment, Tooltip, Typography, Link, IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { AtomicModal, UploadFile, ExibirImagem } from '../../../../../components';
import { listTipoReembolso } from '../../../../../requests/tipoReembolsoRequest';
import { listArquivos, postArquivo, deleteArquivo } from '../../../../../requests/arquivoRequest';
import { TextMaskCurrency } from '../../../../../masks';
import { downloadFile } from '../../../../../services/fileSystem';
import { getFileURLFromS3 } from '../../../../../services/aws-s3';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import * as yup from 'yup';
import { Formik, Form } from 'formik';

function EditarReembolso({classes, disabled, display, onSalvar, onFechar, data}){

  const [tipoReembolso, setTipoReembolso] = useState([{Id_Tipo_Reembolso: '', Nome: ''}]);
  const [loading, setLoading] = useState(false);
  const [opening, setOpening] = useState(false);
  const [file, setFile] = useState([]);
  const [image, setImage] = useState({
    open: false,
    src: ''
  });

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  useEffect(() => { carregarTipoReembolso() }, []);
  useEffect(() => { carregarAnexos() }, [data, display]);

  async function carregarTipoReembolso(){
    const response = await listTipoReembolso({});

    if(response.valid){
      setTipoReembolso([...response.data, {Id_Tipo_Reembolso: '', Nome: ''}]);
    }
  }

  async function carregarAnexos(){
    
    setOpening(true);

    if(display && data){
      const params = {Entidade: 'Reembolso', Id_Entidade: data.Id_Reembolso}
      const responseArquivo = await listArquivos(params);

      if(responseArquivo.valid){
        setFile(responseArquivo.data);
      }
    }

    setOpening(false);

  }

  function closeEditarReembolso(handleReset){
    handleReset();  
    onFechar();
    setFile([]);
  }

  function salvarReembolso(reembolso){
    setLoading(true);
    onSalvar(reembolso);
    setLoading(false);
  }

  async function handleUpload(newFile) {
    
    const body = new FormData();
    body.append('file', newFile[0], newFile[0].name);
    body.append('Entidade', 'Reembolso');
    body.append('Id_Entidade', data.Id_Reembolso);

    const response = await postArquivo(body);

    if(response.valid){
      let newFileList = [...file, response.data];
      setFile(newFileList);
    }
  };

  async function removeFile(index){

    let newFileList = [...file];
    const removedFile = newFileList.splice(index, 1);
    
    const response = await deleteArquivo(removedFile[0].Id_Arquivo, {});

    if(response.valid){
      setFile(newFileList);
    }

  }

  async function exibirOuBaixarArquivo(arquivo){

    if(['jpg', 'jpeg', 'gif', 'png'].includes(arquivo.Extensao)){
      const s3FileFullPath = arquivo.Url;
      const s3Url = `https://${process.env.REACT_APP_AWS_BUCKET}.s3.amazonaws.com/`;
      const s3FilePath = s3FileFullPath.slice(s3FileFullPath.indexOf(s3Url) + s3Url.length)
      
      const src = await getFileURLFromS3(s3FilePath);

      if(src !== null){
        setImage({open: true, src: src});
      }
    }else{
      downloadFile(arquivo.Id_Arquivo);
    }

  }

  function onCloseExibirImagem(){
    setImage({open: false, src: ''});
  }
  
  const schema = yup.object().shape({
    Descricao: yup.string().required('Campo Obrigatório'),
    Id_Tipo_Reembolso: yup.number().required('Campo Obrigatório'),
    Valor: yup.mixed().required('Campo Obrigatório'),
    Data_Gasto: yup.date().required('Campo Obrigatório')
  })

  return (
    <Formik initialValues={data} onSubmit={(values, { resetForm }) => salvarReembolso(values)} enableReinitialize={true} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleReset
      } = props
      return (
        <AtomicModal open={display} width={'60%'}>
          <Form>
            <Paper className={classes.modal}>
              <CardHeader title={`${disabled ? 'Visualizar' : 'Editar'} Reembolso`} style={{textAlign: 'center'}}/>
                {opening ?
                  <div className={classes.loadingDiv}>
                    <CircularProgress size={40} />
                  </div>
                :
                <Grid container spacing={1} className={classes.modalFields}>
                  <Grid item md={6} xs={6}>
                    <TextField
                      fullWidth
                      label="Tipo do Reembolso"
                      margin="dense"
                      name="Id_Tipo_Reembolso"
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.Id_Tipo_Reembolso}
                      variant="outlined"
                      defaultValue={{Id_Tipo_Reembolso: '', Nome: ''}}
                      helperText={errors.Id_Tipo_Reembolso && touched.Id_Tipo_Reembolso ? errors.Id_Tipo_Reembolso : ''}
                      error={errors.Id_Tipo_Reembolso && touched.Id_Tipo_Reembolso ? true : false}
                      onBlur={handleBlur}
                      disabled={loading || disabled}
                    >
                      {tipoReembolso.map(option => (
                        <option key={option.Id_Tipo_Reembolso} value={option.Id_Tipo_Reembolso}>
                          {option.Nome}
                        </option>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item md={3} xs={3}>
                    <TextField
                      fullWidth
                      label="Data do Gasto"
                      margin="dense"
                      name="Data_Gasto"
                      type="date"
                      onChange={handleChange}
                      value={values.Data_Gasto}
                      variant="outlined"
                      InputLabelProps={{
                          shrink: true,
                      }}
                      style={{paddingRight: 5}}
                      helperText={errors.Data_Gasto && touched.Data_Gasto ? errors.Data_Gasto : ''}
                      error={errors.Data_Gasto && touched.Data_Gasto ? true : false}
                      onBlur={handleBlur}
                      disabled={loading || disabled}
                    />
                  </Grid>

                  <Grid item md={3} xs={3}>
                    <TextField
                      fullWidth
                      label="Valor"
                      margin="dense"
                      name="Valor"
                      onChange={handleChange}
                      value={values.Valor.includes(',') ? values.Valor : `${values.Valor},00`}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        inputComponent: TextMaskCurrency
                      }}
                      variant="outlined"
                      helperText={errors.Valor && touched.Valor ? errors.Valor : ''}
                      error={errors.Valor && touched.Valor ? true : false}
                      onBlur={handleBlur}
                      disabled={loading || disabled}
                    />
                  </Grid>
                  
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Descricao"
                      margin="dense"
                      name="Descricao"
                      onChange={handleChange}
                      value={values.Descricao}
                      variant="outlined"
                      helperText={errors.Descricao && touched.Descricao ? errors.Descricao : ''}
                      error={errors.Descricao && touched.Descricao ? true : false}
                      onBlur={handleBlur}
                      disabled={loading || disabled}
                    />
                  </Grid>

                  { !disabled &&
                    <Grid item xs={6} sm={6}>
                      <UploadFile onUpload={handleUpload}/>
                    </Grid>
                  }
                  
                  <Grid item xs={6} sm={6} direction="column">
                    {file.map((fileItem, index) => 
                      (
                        <div className={classes.fileItemBlock}>
                          <Tooltip title="Remover">
                            <IconButton color="secondary" onClick={() => removeFile(index)} disabled={loading || disabled}>
                              <DeleteForeverIcon />
                            </IconButton>
                          </Tooltip>
                          <Link onClick={() => exibirOuBaixarArquivo(fileItem)} className={classes.downloadLink}>
                            <Typography variant="body1" style={{color: 'blue', fontWeight: 'bold'}}>{`${fileItem.Arquivo}.${fileItem.Extensao}`}</Typography>
                          </Link>
                        </div>
                      ))
                    }
                  </Grid>
                </Grid>
                }
                
                <Button variant="contained" onClick={() => closeEditarReembolso(handleReset)} >
                  CANCELAR
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={disabled || opening || loading}
                  className={classes.modalSaveButton}
                >
                  SALVAR
                  {loading && <CircularProgress size={14} color="secondary" />}
                </Button>
              </Paper>
            </Form>
            <ExibirImagem open={image.open} src={image.src} handleClose={onCloseExibirImagem} />
          </AtomicModal>
        )
      }}
    </Formik>
  )
}

export default withStyles(styles)(EditarReembolso);