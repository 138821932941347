import api from '../services/api';
import { handleRequestError } from '../services/security';

export async function getSession( params ){

  let responseFromApi;

  try {
    const response = await api.get('/session', params);

    switch(response.status){
      case 200:
        responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
        break;
      case 204:
        responseFromApi = {valid: false, status: response.status, message: 'Sessão inválida ou expirada', data: ''};
        break;
      default:
        responseFromApi = {valid: false, status: response.status, message: 'Não foi possível obter dados da sessão', data: ''};
    }
    
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}