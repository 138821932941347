import React from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';


function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
}

function AtomicModal({ children, open, width, height, handleClose }){

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: width || `60%`,
      height: height || null,
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1, 1, 1),
    },
  }));

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
        
    <Modal open={open} onClose={handleClose} >
      <div style={modalStyle} className={classes.paper}>
        {children}
      </div>
    </Modal>
       
  )

}

export default AtomicModal;