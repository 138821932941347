import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { getFuncionarios } from '../../requests/funcionariosRequest';
import { getContratacao } from '../../requests/contratacaoAction';
import { getGenero } from '../../requests/generoAction';
import { getEstadoCivil } from '../../requests/estadoCivilAction';
import { getStatus } from '../../requests/statusRequest';
import { CustomSnackBar } from '../../components';
import { SearchInput } from '../../components';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  CircularProgress,
  Grid,
  Button
} from '@material-ui/core';
import TablePaginationActions from './TablePaginationActions';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { connect } from 'react-redux';
import ExportButton from '../../components/ExportButton';
import FilterButton from '../../components/FilterButton';


function Funcionarios({classes, perfil}) {

  const [dataTable, setDataTable] = useState([]);
  const [listaFuncionarios, setListaFuncionarios] = useState([]);
  const [filterConfig, setFilterConfig] = useState([]);

  const [loading, setLoading] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const [showAlert, setShowAlert] = useState({
    display: false,
    severity: '',
    text: ''
  });

  useEffect(() => { fetchDataForTable(); }, []);

  function handlePageChange(event, newPage){
    setPage(newPage);
  };

  function handleRowsPerPageChange(event){
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function fetchDataForTable() {
    setLoading(true);

    const response = await getFuncionarios();

    if (response.valid){
      setDataTable(response.data);
      setListaFuncionarios(response.data);
    }else{
      setShowAlert({
        display: true,
        severity: 'error',
        text: response.message
      });
    }

    let loadData = [];

    loadData.push(carregarContratacao());
    loadData.push(carregarGenero());
    loadData.push(carregarEstadoCivil());
    loadData.push(carregarStatusFuncionario());

    const resultData = await Promise.all(loadData);

    setFilterConfig([
      {field: 'Nome', name: 'Nome', type: 'text'},
      {field: 'Contratacao', name: 'Contratação', type: 'select', select: resultData[0]},
      {field: 'Genero', name: 'Gênero', type: 'select', select: resultData[1]},
      {field: 'Estado_Civil', name: 'Estado Civil', type: 'select', select: resultData[2]},
      {field: 'Status', name: 'Status', type: 'select', select: resultData[3]},
      {field: 'Email', name: 'Email', type: 'text'},
      {field: 'Telefone', name: 'Telefone', type: 'text'},
      {field: 'CPF', name: 'CPF', type: 'text'},
      {field: 'CPF', name: 'CPF', type: 'text'},
      {field: 'Nascimento', name: 'Nascimento', type: 'date'},
      {field: 'Data_Admissao', name: 'Data de Admissão', type: 'date'},
      {field: 'Data_Desligamento', name: 'Data de Desligamento', type: 'date'}
    ]);
    
    setLoading(false);
  }

  async function carregarContratacao(){
    const response = await getContratacao();
    if (response.valid) {
      let values = response.data.sort();
      
      values = values.map((tr) => {
        return tr.Nome;
      });
      
      return values;
    }
  }

  async function carregarGenero(){
    const response = await getGenero();
    if (response.valid) {
      let values = response.data.sort();
      
      values = values.map((tr) => {
        return tr.Nome;
      });
      
      return values;
    }
  }

  async function carregarEstadoCivil(){
    const response = await getEstadoCivil();
    if (response.valid) {
      let values = response.data.sort();
      
      values = values.map((tr) => {
        return tr.Nome;
      });
      
      return values;
    }
  }

  async function carregarStatusFuncionario(){
    const response = await getStatus({Tipo: 'Funcionario'});
    if (response.valid) {
      let values = response.data.sort();
      
      values = values.map((tr) => {
        return tr.Nome;
      });
      
      return values;
    }
  }

  function exibirFuncionario(funcionario){

    const toExibirFuncionario = { 
      pathname: 'funcionarios/exibir', 
      funcionario: funcionario
    };
    
    return toExibirFuncionario;

  }

  const handleCloseAlert = () => {
    setShowAlert({
        display: false,
        severity: '',
        text: ''
    });
  };

  function filtrarFuncionario(event){
    let dataValues = listaFuncionarios.filter(func => func.Nome.toLowerCase().includes(event.target.value.toLowerCase()));;
    setDataTable(dataValues);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataTable.length - page * rowsPerPage);

  return (
    
    <div className={classes.rootOuter}>
      { /* <FuncionariosToolbar data={dataTable} onFilterFuncionario={filtrarFuncionario}/> */}
      <Grid container direction="row" wrap="nowrap" justify="flex-end">
        <SearchInput
          className={classes.searchInput}
          placeholder="Localizar"
          onChange={filtrarFuncionario}
        />
        <span className={classes.spacer} />
        <Link to="/funcionarios/novo" style={{ textDecoration: 'none', marginRight: 5 }}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            disabled={!perfil.funcoes.create.includes('Funcionários')}
          >
            Adicionar
          </Button>
        </Link>
        <div style={{marginRight: 5}}>
          <FilterButton
            dataset={listaFuncionarios}
            filterConfig={filterConfig}
            setFilteredData={setDataTable}
            width={'md'}
          />
        </div>
        <ExportButton data={dataTable} />
      </Grid>
      <div className={classes.contentOuter}>

        <Card className={clsx(classes.root)}>

          <CustomSnackBar display={showAlert.display} severity={showAlert.severity} text={showAlert.text} onClose={handleCloseAlert} />
          {loading ?
            <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
              <CircularProgress />
            </div>
          :
            <CardContent className={classes.content}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Contratação</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Telefone</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? dataTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : dataTable
                    ).map(funcionario => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={funcionario.Id_Funcionario}
                    >
                      <TableCell>
                        <div className={classes.nameContainer}>
                        <Link to={exibirFuncionario(funcionario)} style={{ textDecoration: 'none' }}>
                          <Avatar className={classes.avatar} src={funcionario.avatarUrl}>
                            {funcionario.Nome.substring(0, 1).toUpperCase()}
                          </Avatar>
                        </Link>
                        <Link to={exibirFuncionario(funcionario)} style={{ textDecoration: 'none' }}>
                          <Typography variant="body1">{funcionario.Nome}</Typography>
                        </Link>
                        </div>
                      </TableCell>
                      <TableCell>{funcionario.Contratacao}</TableCell>
                      <TableCell>{funcionario.Email}</TableCell>
                      <TableCell>{funcionario.Telefone}</TableCell>
                      <TableCell>{funcionario.Status}</TableCell>
                      
                      
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}

                </TableBody>
              </Table>
            </CardContent>
          }
          <CardActions className={classes.actions}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
              count={dataTable.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              ActionsComponent={TablePaginationActions}
              
            />
          </CardActions>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = store => ({ 
  perfil: store.Perfil,
})

export default connect(mapStateToProps, null) (withRouter((withStyles(styles)(Funcionarios))));