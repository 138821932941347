import api from '../services/api';
import { handleRequestError } from '../services/security';


export async function listCliente( params ){

  let responseFromApi;

  try {
    const response = await api.get('/cliente', {params: params});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function getCliente( id, params ){

  let responseFromApi;

  try {
    const response = await api.get(`/cliente/${id}`, {params: params});

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;

}

export async function postCliente( params ){
    
  let responseFromApi;

  try {
    const response = await api.post('/cliente', params);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}

export async function putCliente( body ){
    
  let responseFromApi;

  try {
    const response = await api.put('/cliente', body);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
}


export async function deleteCliente( id ){
    
  let responseFromApi;

  try {
    const response = await api.delete(`/cliente/${id}`);

    if (response.status === 200) {
      responseFromApi = {valid: true, status: response.status, message: 'success', data: response.data};
    }
  } catch(error) {
    responseFromApi = handleRequestError(error);
  }

  return responseFromApi;
    
  
}