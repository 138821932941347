import React from 'react';
import { Tree } from './styles';

function FolderTree({ onSelectFolder, folderStructure, selectedFolder, height }) {
  
  return (
    <div style={{position: 'relative', overflowY: 'auto', height: height}}>
      <Tree data={folderStructure} selected={selectedFolder.id} onSelectFolder={onSelectFolder}/>
    </div>
  )
}

export default FolderTree;