import React, { useState } from 'react';
import { CircularProgress, Paper, CardHeader, Grid, TextField, Button } from '@material-ui/core';
import { AtomicModal } from '../../../components';
import { CST_BANCOS, CST_TIPO_CONTA_BANCO } from '../../../constants';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

function EditarContaBanco({classes, display, onSalvar, onFechar, data}){

  const [submit, setSubmit] = useState(false)

  function onSalvarEditarContaBanco(conta, resetForm){
    setSubmit(true);
    onSalvar(conta);
    resetForm();
    setSubmit(false);
  }

  const schema = yup.object().shape({
    Id_Banco: yup.number().required('Campo Obrigatório'),
    Id_Tipo_Conta_Banco: yup.number().required('Campo Obrigatório'),
    Agencia: yup.number().required('Campo Obrigatório'),
    Digito_Agencia: yup.number(),
    Conta: yup.number().required('Campo Obrigatório'),
    Digito_Conta: yup.number(),
  });

  return (
    <Formik initialValues={data} onSubmit={(values, { resetForm }) => onSalvarEditarContaBanco(values, resetForm)} enableReinitialize={true} validationSchema={schema} validateOnChange={false} validateOnBlur={false}>
      {(props) => {
      const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange
      } = props
        return (
          <AtomicModal width={'50%'} open={display} handleClose={onFechar}>
            <Form>
              <Paper className={classes.modal}>
                <CardHeader title="Editar Conta Bancária" />

                <Grid container spacing={1} className={classes.modalFields}>
                  <Grid item md={3} xs={6} >
                    <TextField
                      fullWidth
                      label="Banco"
                      margin="dense"
                      name="Id_Banco"
                      select
                      SelectProps={{ native: true }}
                      onChange={handleChange}
                      value={values.Id_Banco}
                      variant="outlined"
                      helperText={errors.Id_Banco && touched.Id_Banco ? errors.Id_Banco : ''}
                      error={errors.Id_Banco && touched.Id_Banco ? true : false}
                      onBlur={handleBlur}
                    >
                      {CST_BANCOS.map(option => (
                        <option key={option.Id_Banco} value={option.Id_Banco}>
                          {option.Nome}
                        </option>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="Tipo da Conta"
                      margin="dense"
                      name="Id_Tipo_Conta_Banco"
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      value={values.Id_Tipo_Conta_Banco}
                      variant="outlined"
                      helperText={errors.Id_Tipo_Conta_Banco && touched.Id_Tipo_Conta_Banco ? errors.Id_Tipo_Conta_Banco : ''}
                      error={errors.Id_Tipo_Conta_Banco && touched.Id_Tipo_Conta_Banco ? true : false}
                      onBlur={handleBlur}
                    >
                      {CST_TIPO_CONTA_BANCO.map(option => (
                        <option key={option.Id_Tipo_Conta_Banco} value={option.Id_Tipo_Conta_Banco}>
                          {option.Nome}
                        </option>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      fullWidth
                      label="Agência"
                      margin="dense"
                      name="Agencia"
                      onChange={handleChange}
                      value={values.Agencia}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={errors.Agencia && touched.Agencia ? errors.Agencia : ''}
                      error={errors.Agencia && touched.Agencia ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item md={1} xs={1}>
                    <TextField
                      fullWidth
                      label="Dígito"
                      margin="dense"
                      name="Digito_Agencia"
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.Digito_Agencia}
                      variant="outlined"
                      helperText={errors.Digito_Agencia && touched.Digito_Agencia ? errors.Digito_Agencia : ''}
                      error={errors.Digito_Agencia && touched.Digito_Agencia ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      fullWidth
                      label="Conta"
                      margin="dense"
                      name="Conta"
                      onChange={handleChange}
                      value={values.Conta}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={errors.Conta && touched.Conta ? errors.Conta : ''}
                      error={errors.Conta && touched.Conta ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item md={1} xs={2}>
                    <TextField
                      fullWidth
                      label="Dígito"
                      margin="dense"
                      name="Digito_Conta"
                      onChange={handleChange}
                      value={values.Digito_Conta}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      helperText={errors.Digito_Conta && touched.Digito_Conta ? errors.Digito_Conta : ''}
                      error={errors.Digito_Conta && touched.Digito_Conta ? true : false}
                      onBlur={handleBlur}
                    />
                  </Grid>

                </Grid>
                
                <Button variant="contained" onClick={onFechar} >
                  CANCELAR
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submit}
                  className={classes.modalSaveButton}
                >
                  SALVAR
                  {submit ? 
                    <CircularProgress 
                    size={14}
                    color="secondary" />
                    : ''
                  }
                </Button>
              </Paper>
            </Form>
          </AtomicModal>
        )
      }}
    </Formik>
  )
}

export default withStyles(styles)(EditarContaBanco);