import React, { Component } from 'react';
//import { Router } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './App.css';

import theme from './theme';
import Routes from './Routes';
import store from './store';
import { ThemeProvider } from '@material-ui/styles';


export default class App extends Component{

  render() {
    window.onbeforeunload = function () {
      console.log("Do you really want to close?");
    };

    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    );
  }
}
