import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
    Card, CardHeader, CardContent, CardActions, Divider, Button, TablePagination, CircularProgress } from '@material-ui/core';
import { getPerfis, postPerfil } from '../../requests/perfilAction';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { CustomSnackBar, TablePaginationActions } from '../../components';
import NovoPerfil from './components/NovoPerfil'


function Perfis({classes}){
  
  const [perfis, setPerfis] = useState([]);

  const [form, setForm] = useState({
    displayNovo: false,
    displayEditar: false,
    editarPerfil: {},
    confirmation: false,
  });

  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  })

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  useEffect(() => { carregarPerfis() }, []);

  async function carregarPerfis(){
    setLoading(true);

    const response = await getPerfis({});
    if (response.valid) {
      setPerfis(response.data);
    } else {
      setAlert({display: true, severity: 'error', text: response.message});
    }

    setLoading(false);
  }

  function handlePageChange(event, newPage) {
    setPage(newPage);
  };

  function handleRowsPerPageChange(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function onEditarPerfil(perfil){
    const toEditarPerfil = { 
      pathname: 'perfis/editar',
      perfil: perfil
    };
    
    return toEditarPerfil;
  }

  async function salvarNovoPerfil(perfil){

    const response = await postPerfil(perfil);

    if(response.valid){
      carregarPerfis();
      setForm({...form, displayNovo: false});
      setAlert({display: true, severity: 'success', text: 'Perfil criado com sucesso!'});
    }else{
      setAlert({display: true, severity: 'error', text: response.message});
    }

  }

  function onNovoPerfil(){
    setForm({...form, displayNovo: true});
  }
  
  return(
    <div>
      <Card className={classes.card}>
        <CardHeader title="Perfis"/>
        <Divider />
        {loading ?
          <div style={{ position: 'relative', top: 150, left: '50%', height: 300}}>
            <CircularProgress />
          </div>
        :
          <CardContent>
            <TableContainer component={Paper} className={classes.table}>
              <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.header}>
                      <TableCell className={classes.tableHeader}>Perfil</TableCell>
                      <TableCell className={classes.tableHeader}>Descrição</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                    ? perfis.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : perfis
                    ).map((row) => (
                      <TableRow key={row.Id_Perfil}>
                        <TableCell>
                          <Link className={classes.TableCellLink} to={onEditarPerfil(row)}>{row.Nome}</Link>
                        </TableCell>
                        <TableCell>{row.Descricao}</TableCell>
                      </TableRow>
                  ))}
                  </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        }
        <Divider/>
        <CardActions>
          <Button variant="contained" color="primary" onClick={onNovoPerfil}>NOVO</Button>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
            count={perfis.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
            style={{position: 'absolute', right: 10}}
          />
        </CardActions>
      </Card>

      <NovoPerfil display={form.displayNovo} onSalvar={salvarNovoPerfil} onFechar={() => setForm({...form, displayNovo: false})}/>
      
      <CustomSnackBar 
        display={alert.display}
        severity={alert.severity}
        text={alert.text}
        onClose={() => setAlert({display: false, severity: '', text: ''})}
      />
      
    </div>
  )

}

export default withStyles(styles)(Perfis);