import React, {useState} from 'react';
import logo from '../../img/atomic-logo3.png';
import { authenticateUser } from '../../services/security';
import './Login.css';
import { withRouter } from 'react-router-dom';
import { CustomSnackBar } from '../../components';
import { Container, TextField, Link, Button } from '@material-ui/core';

function Login({history}) {

  const [dataInput, setDataInput] = useState({
    Usuario: '',
    Senha: ''
  });

  const [alert, setAlert] = useState({
    display: false,
    severity: '',
    text: '',
  });

  function handleValueInput(fieldName, fieldValue) {
    setDataInput({ ...dataInput, [fieldName]: fieldValue })
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    const retorno = await authenticateUser(dataInput);

    if (retorno.Id_Session !== undefined) {
      if(retorno.trocarSenha){
        history.push(`/trocarSenha/${retorno.Usuario}`);
      }else{
        if(localStorage.getItem('urlPath') !== null){
          history.push(localStorage.getItem('urlPath'));
          localStorage.removeItem('urlPath');
        }else{
          history.push('/atomic');
        }
      }      
    }else{
      if(retorno.response === undefined){
        setAlert({display: true, severity: 'error', text: 'Erro de conexão com o servidor'});
      }else{
        setAlert({display: true, severity: 'error', text: retorno.response.data});
      }
    }
  }
  

  return (
    <div className="login">
      
      <CustomSnackBar display={alert.display} severity={alert.severity} text={alert.text} onClose={() => setAlert({display: false, severity: '', text: ''})}/>

      <div className="logo">
        <img src={logo} alt="Atomic Solutions" />
      </div><br />

      <form className="form-login" method="POST" onSubmit={onSubmit}>
          
        <Container component="main" maxWidth="xs">
              
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Usuário"
            type="text"
            name="Usuario"
            value={dataInput.Usuario}
            onChange={(e) => { handleValueInput(e.target.name, e.target.value) }}
            autoFocus={true}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Senha"
            type="password"
            name="Senha"
            value={dataInput.Senha}
            onChange={(e) => { handleValueInput(e.target.name, e.target.value) }}
            autoFocus={false}
          />

          <Link className="linkRecuperarSenha" onClick={() => { history.push('/recuperarSenha') }}>Esqueci a senha</Link>

          <Button
            style={{marginTop: 20}}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Entrar
          </Button>

        </Container>

        
      </form>

    </div>
  );
};


export default withRouter(Login);