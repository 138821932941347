import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

function Confirmation({classes, title, children, open, setOpen, onConfirm, width, button}) {

  return (
    <div>
      <Dialog open={open} onClose={() => setOpen("", false)} className={classes.dialog} fullWidth={true} maxWidth={width}>
        <DialogTitle><div className={classes.title}>{title}</div></DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          {button === 'OK' && 
            <Button onClick={() => setOpen("", false)} autoFocus className={classes.buttonCancel}>OK</Button>
          }

          {(button === 'SIM_NAO' || !button) &&
            <>
              <Button onClick={() => onConfirm()} className={classes.buttonConfirm}>Sim</Button>
              <Button onClick={() => setOpen("", false)} autoFocus className={classes.buttonCancel}>Não</Button>
            </>
          }
        </DialogActions>
      </Dialog>
    </div>
  );

};

export default withStyles(styles)(Confirmation);